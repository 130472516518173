import React, { useCallback, useEffect, useState } from 'react'
import { Card, Row, Col } from 'react-bootstrap'
import { useTheme } from '../../../../contexts/themeContext'
import AdCards from '../../advertisements/subcomponents/adCards'
import EarningsCards from '../../payment/subcomponents/creator/earningsCards'
import { useAuth } from '../../../../contexts/authContext'
import { BookmarkCheckFill, Bullseye, CollectionPlayFill, Eye, EyeFill, JournalMedical, Link45deg, PersonFillCheck, TrophyFill } from 'react-bootstrap-icons'
import formatDollars from '../../../../utils/formatDollars'

const AtAGlance = (props) => {
    const {type} = useAuth()
    const {getCardStyle} = useTheme()

    return (
        <Card className={getCardStyle()} style={{transition: 'all 0.5s'}}>
            <Card.Header className='fw-bold rounded-top'>Account At-A-Glance</Card.Header>
            <Card.Body>
                <AdCards isDemo={props.isDemo}/>
                {(props.isDemo ? props.viewType === 1 : type === 1) ? <EarningsCards isDemo={props.isDemo}/> : <AdSuccessCards isDemo={props.isDemo}/>}
                {(props.isDemo ? props.viewType === 1 : type === 1) ? <MiscCreatorCards isDemo={props.isDemo}/> : <MiscAdvertiserCards isDemo={props.isDemo}/>}
            </Card.Body>
        </Card>
    )
}

const MiscCreatorCards = (props) => {
    const { userData } = useAuth()
    const {getCardStyle, isSmallScreen} = useTheme()

    return (
        <Row className='gx-2'>
            <Col className='my-2'>
                <Card className={getCardStyle()}>
                    <Card.Body>
                        <Row className='align-items-center flex-nowrap'>
                            <Col lg={8} className='me-2'>
                                <div className='text-uppercase fw-bold mb-1 text-nowrap my-text-light'>
                                    Linked Channels
                                </div>
                                <h5 className='fw-bold mb-0 my-text-light'>
                                    {props.isDemo ? 1 : (userData.channels ? userData.channels.length : 0)}
                                </h5>
                            </Col>
                            {isSmallScreen ? <></> :
                            <Col className='d-flex justify-content-end bs-icon bs-icon-lg'>
                                <CollectionPlayFill className='my-text-light' />
                            </Col>
                            }
                        </Row>
                    </Card.Body>
                </Card>
            </Col>
            <Col className='my-2'>
                <Card className={getCardStyle()}>
                    <Card.Body>
                        <Row className='align-items-center flex-nowrap'>
                            <Col lg={8} className='me-2'>
                                <div className='text-uppercase fw-bold mb-1 text-nowrap my-text-light'>
                                    SociAble Connections
                                </div>
                                <h5 className='fw-bold mb-0 my-text-light'>
                                    {props.isDemo ? 10 : (userData.social ? userData.social.connections.length : 0)}
                                </h5>
                            </Col>
                            {isSmallScreen ? <></> :
                            <Col className='d-flex justify-content-end bs-icon bs-icon-lg'>
                                <Link45deg className='my-text-light'/>
                            </Col>
                            }
                        </Row>
                    </Card.Body>
                </Card>
            </Col>
            <Col className='my-2'>
                <Card className={getCardStyle()}>
                    <Card.Body>
                        <Row className='align-items-center flex-nowrap'>
                            <Col lg={8} className='me-2'>
                                <div className='text-uppercase fw-bold mb-1 my-text-light text-nowrap'>
                                    SociAble Score
                                </div>
                                <h5 className='fw-bold mb-0 my-text-light'>
                                    {props.isDemo ? 43 : (userData.social ? userData.social.score : 0)}
                                </h5>
                            </Col>
                            {isSmallScreen ? <></> :
                            <Col className='d-flex justify-content-end bs-icon bs-icon-lg'>
                                <TrophyFill className='my-text-light'/>
                            </Col>
                            }
                        </Row>
                    </Card.Body>
                </Card>
            </Col>
        </Row>
    )
}



const AdSuccessCards = (props) => {
    const { userData } = useAuth()
    const { isSmallScreen, getCardStyle } = useTheme()

    const [cardData, setCardData] = useState(['-', '-', '-'])

    const getCardData = useCallback(() => {
        let adData = Object.keys(userData.history).filter(ad => !userData.history[ad].isPotential)

        const workedWith = []
        let totalImpressions = 0
        for ( const ad of adData ) {
            if (userData.history[ad].creators) {
                for ( const creatorID of userData.history[ad].creators ) {
                    if ( !workedWith.includes(creatorID) ) workedWith.push(creatorID)
                }
            }
            if ( userData.history[ad].currentImpressions ) totalImpressions += userData.history[ad].currentImpressions
            //TODO count platforms worked on?
        }

        setCardData([totalImpressions, totalImpressions / (adData.filter(ad => ad.currentImpressions && ad.currentImpressions > 0).length || 1), workedWith.length])
    }, [userData.history])

    useEffect(() => {
        if (!props.isDemo) {
            getCardData()
        }
    }, [getCardData, props.isDemo])

    return (
        <Row className='gx-2 mb-2'>
            <Col className='my-2'>
                <Card className={getCardStyle() + ' my-bg-info-dark'}>
                    <Card.Body>
                        <Row className='align-items-center flex-nowrap'>
                            {isSmallScreen ? <></> :
                            <Col className='d-flex justify-content-start bs-icon bs-icon-lg'>
                                <EyeFill className='my-text-primary-light'/>
                            </Col>
                            }
                            <Col className='me-2 text-end' style={{flex: '2 2 75%'}}>
                                <div className='text-uppercase text-nowrap fw-bold mb-1 my-text-primary-light'>
                                    Total Impressions
                                </div>
                                <h5 className='fw-bold mb-0 fs-3 my-text-primary-light'>
                                    {props.isDemo ? '456,039' : cardData[0]}
                                </h5>
                            </Col>
                        </Row>
                    </Card.Body>
                </Card>
            </Col>
            <Col className='my-2'>
                <Card className={getCardStyle() + ' my-bg-info-dark'}>
                    <Card.Body>
                        <Row className='align-items-center flex-nowrap'>
                            {isSmallScreen ? <></> :
                            <Col className='d-flex justify-content-start bs-icon bs-icon-lg'>
                                <Eye className='my-text-primary-light'/>
                            </Col>
                            }
                            <Col className='me-2 text-end' style={{flex: '2 2 75%'}}>
                                <div className='text-uppercase text-nowrap fw-bold mb-1 my-text-primary-light text-nowrap'>
                                    Average Impressions
                                </div>
                                <h5 className='fw-bold mb-0 fs-3 my-text-primary-light'>
                                    {props.isDemo ? '132,432' : cardData[1]}
                                </h5>
                            </Col>
                        </Row>
                    </Card.Body>
                </Card>
            </Col>
            <Col className='my-2'>
                <Card className={getCardStyle() + ' my-bg-info-dark'}>
                    <Card.Body>
                        <Row className='align-items-center flex-nowrap'>
                            {isSmallScreen ? <></> :
                            <Col className='d-flex justify-content-start bs-icon bs-icon-lg'>
                                <PersonFillCheck className='my-text-primary-light'/>
                            </Col>
                            }
                            <Col className='me-2 text-end' style={{flex: '2 2 75%'}}>
                                <div className='text-uppercase text-nowrap fw-bold mb-1 my-text-primary-light'>
                                    Creators Sponsored
                                </div>
                                <h5 className='fw-bold mb-0 fs-3 my-text-primary-light'>
                                    {props.isDemo ? 29 : cardData[2]}
                                </h5>
                            </Col>
                        </Row>
                    </Card.Body>
                </Card>
            </Col>
        </Row>
    )
}

const MiscAdvertiserCards = (props) => {
    const { userData } = useAuth()
    const {getCardStyle, isSmallScreen} = useTheme()

    const [cardData, setCardData] = useState(['-', '-', '-'])

    const getCardData = useCallback(() => {
        let adData = Object.keys(userData.history).filter(ad => !userData.history[ad].isPotential)

        let engagementRate = 0
        let averageCost = 0
        let categories = []
        for ( const ad of adData ) {
            for ( const bucket of userData.history[ad].buckets) {
                if (!categories.includes(bucket)) categories.push(bucket)
            }
            
            const adProgress = Object.values(userData.history[ad].progress || {})
            engagementRate += adProgress.reduce((sum, curr) => sum + curr.engagementRate, 0) / Math.max(adProgress.length, 1)

            averageCost += userData.history[ad].totalCost
        }

        setCardData([engagementRate / Math.max(adData.length, 1), averageCost / Math.max(adData.length, 1), categories.length])
    }, [userData.history])

    useEffect(() => {
        if (!props.isDemo) {
            getCardData()
        }
    }, [getCardData, props.isDemo])

    return (
        <Row className='gx-2'>
            <Col className='my-2'>
                <Card className={getCardStyle()}>
                    <Card.Body>
                        <Row className='align-items-center flex-nowrap'>
                            <Col className='me-2' style={{flex: '2 2 75%'}}>
                                <div className='text-uppercase fw-bold mb-1 text-nowrap my-text-light'>
                                    Engagement Rate
                                </div>
                                <h5 className='fw-bold mb-0 my-text-light'>
                                    {props.isDemo ? 2.3 : (cardData[0] * 100).toLocaleString() + '%'}
                                </h5>
                            </Col>
                            {isSmallScreen ? <></> :
                            <Col className='d-flex justify-content-end bs-icon bs-icon-lg'>
                                <Bullseye className='my-text-light' />
                            </Col>
                            }   
                        </Row>
                    </Card.Body>
                </Card>
            </Col>
            <Col className='my-2'>
                <Card className={getCardStyle()}>
                    <Card.Body>
                        <Row className='align-items-center flex-nowrap'>
                            <Col className='me-2' style={{flex: '2 2 75%'}}>
                                <div className='text-uppercase fw-bold mb-1 text-nowrap my-text-light'>
                                    Average Cost
                                </div>
                                <h5 className='fw-bold mb-0 my-text-light'>
                                    {props.isDemo ? formatDollars(375.31) : formatDollars(cardData[1])}
                                </h5>
                            </Col>
                            {isSmallScreen ? <></> :
                            <Col className='d-flex justify-content-end bs-icon bs-icon-lg'>
                                <BookmarkCheckFill className='my-text-light' />
                            </Col>
                            }
                        </Row>
                    </Card.Body>
                </Card>
            </Col>
            <Col className='my-2'>
                <Card className={getCardStyle()}>
                    <Card.Body>
                        <Row className='align-items-center flex-nowrap'>
                            <Col className='me-2' style={{flex: '2 2 75%'}}>
                                <div className='text-uppercase fw-bold mb-1 text-nowrap my-text-light'>
                                    Categories Used
                                </div>
                                <h5 className='fw-bold mb-0 my-text-light'>
                                    {props.isDemo ? 6 : cardData[2]}
                                </h5>
                            </Col>
                            {isSmallScreen ? <></> :
                            <Col className='d-flex justify-content-end bs-icon bs-icon-lg'>
                                <JournalMedical className='my-text-light' />
                            </Col>
                            }
                        </Row>
                    </Card.Body>
                </Card>
            </Col>
        </Row>
    )
}

export default AtAGlance