import React, { useState } from 'react'
import { useTheme } from '../../../../contexts/themeContext'
import { Button, Card, Col, Collapse, Row } from 'react-bootstrap'

const SocialDetails = ({socialData}) => {
    const {darkMode} = useTheme()

    return (
        <Card className={'h-100 w-75 border border-4 border-primary' + (darkMode ? '' : '-light')} style={{transition: 'border 0.5s', maxHeight: '100%'}}>
            <Card.Body className='text-start d-flex flex-column justify-content-between'>
                <Row>
                    <Col>
                        <TextFoldRow string={`Channels: ${socialData.channels.length ? socialData.channels.map(str => (str.split('-')[1] + ' (' + str.split('-')[0] + ')')).join(', ') : 'None'}`} />
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <TextFoldRow string={`Connections: ${socialData.connections.length ? socialData.connections.join(', ') : 'None'}`} />
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <TextFoldRow string={`Categories: ${socialData.buckets.length ? socialData.buckets.join(', ') : 'None'}`} />
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <TextFoldRow string={`Sub-Categories: ${socialData.subBuckets.length ? socialData.subBuckets.join(', ') : 'None'}`} fs={6} p={0} />
                    </Col>
                </Row>
            </Card.Body>
        </Card>
    )
}

const TextFoldRow = (props) => {
    const [showMore, setShowMore] = useState(false)

    return (
        <Row>
            {props.string.length > 120 ?
                <div>
                    <h6 className='mb-0'>
                        <span className='fw-bold'>{props.string.split(':')[0] + ':'}</span>
                        <span className='fst-italic'>{props.string.split(':')[1].split(', ').slice(0, 5).join(', ')}</span>&nbsp;
                        <Collapse in={showMore}>
                            <div><span className='fst-italic mb-0'>{props.string.split(', ').slice(5).join(', ')}</span></div>
                        </Collapse>
                        <Button className='bg-transparent text-nowrap border-0 p-0 m-0 my-text-primary' onClick={() => setShowMore(!showMore)}>
                            <span className='mb-0'>{showMore ? '-' : '+'}{props.string.split(', ').length - 5} more</span>
                        </Button>
                    </h6>
                </div>
            :
                <h6 className='mb-0'><span className='fw-bold'>{props.string.split(':')[0] + ':'}</span>&nbsp;<span className='fst-italic'>{props.string.split(':')[1]}</span></h6>
            }
        </Row>
    )
}

export default SocialDetails