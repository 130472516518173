import React from 'react'
import { Container } from 'react-bootstrap';
import { useTheme } from '../../contexts/themeContext';

const PageHeader = (props) => {
    const { getThemeTextInv } = useTheme()

    return (
        <Container fluid className={'m-0 p-0 py-4 text-center bg-transparent'} style={{transition: 'background 0.5s'}}>
            <div className={getThemeTextInv('secondary')} style={{transition: 'color 0.5s'}}>{props.children[0]}</div>
            <div className={getThemeTextInv('info')} style={{transition: 'color 0.5s'}}>{props.children[1]}</div>
        </Container>
    )
}

export default PageHeader;