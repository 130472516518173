import React, { useState } from 'react'
import { Container, Row, Col } from 'react-bootstrap'

import UserCard from './subcomponents/userCard';
import PotentialAdsCard from './subcomponents/creator/potentialAdsCard';
import UserSettingsCard from './subcomponents/userSettingsCard';
import AccountActions from './subcomponents/accountActions';
import { useAuth } from '../../../contexts/authContext';
import AtAGlance from './subcomponents/atAGlance';
import { useTheme } from '../../../contexts/themeContext';

const Profile = () => {
    const { currentUser, userData, type } = useAuth()
    const { isSmallScreen } = useTheme()

    const [username, setUsername] = useState(currentUser.displayName)
    const [nickname, setNickname] = useState(userData.nickname)

    return (isSmallScreen ?
        <MobileView username={username} nickname={nickname} />
        :
        <Container fluid className='p-2'>
            <Row>
                <Col>{userData.firstTimeUser || userData.pending || type === 0 ? <></> : <PotentialAdsCard />}</Col>
            </Row>
            <Row>
                <Col><AccountActions /></Col>
            </Row>
            <Row className='pb-3 gx-2'>
                <Col lg={4}><UserCard username={username} nickname={nickname} /></Col>
                <Col lg={8}><UserSettingsCard username={username} setUsername={setUsername} setNickname={setNickname} /></Col>
            </Row>
        </Container>
    )
}

const MobileView = (props) => {
    return (
        <Container fluid className='p-2'>
            <Row className='pb-3'>
                <Col><AtAGlance isDemo={false}/></Col>
            </Row>
            <Row>
                <Col lg={4}><UserCard username={props.username} nickname={props.nickname} /></Col>
            </Row>
        </Container>
    )
}

export default Profile;