import React from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import PotentialAdsCard from '../profile/subcomponents/creator/potentialAdsCard'
import AdRevenuesTable from './subcomponents/creator/adRevenuesTable'
import AdRecordsTable from './subcomponents/creator/adRecordsTable'
import UserContentTable from './subcomponents/creator/userContentTable'
import { useAuth } from '../../../contexts/authContext'
import AdHistoryTable from './subcomponents/advertiser/adHistoryTable'
import CreatorsWorkedWithTable from './subcomponents/advertiser/creatorsWorkedWithTable'
import PaymentHistoryTable from './subcomponents/advertiser/paymentHistoryTable'

const History = () => {
    const {type} = useAuth()

    return (
        <Container fluid className='p-2'>
            {type === 1 && <PotentialAdsCard />}
            <Row className='pb-3'>
                <Col>{type === 1 ? <AdRecordsTable /> : <AdHistoryTable />}</Col>
            </Row>
            <Row className='pb-3'>
                <Col>{type === 1 ? <UserContentTable /> : <CreatorsWorkedWithTable />}</Col>
            </Row>
            <Row>
                <Col>{type === 1 ? <AdRevenuesTable /> : <PaymentHistoryTable />}</Col>
            </Row>
        </Container>
    )
}

export default History