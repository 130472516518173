import React from 'react'
import { Container, Row } from 'react-bootstrap'
import { CompanySummary, MissionVisionValues } from '../../../about/about'

const AboutSummary = () => {
    return (
        <Container fluid style={{width: '90%'}}>
            <Row>
                <CompanySummary />
                <MissionVisionValues />
            </Row>
        </Container>
    )
}

export default AboutSummary