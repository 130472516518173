import React from 'react'
import PageHeader from '../../common/pageHeader'
import { Container, Row, Col, Card } from 'react-bootstrap'

import { useTheme } from '../../../contexts/themeContext'

const About = () => {
    const { getThemeBGInv, darkMode, getThemeText } = useTheme()

    const FiableFullSummary = "Fiable is a pioneering force in the realm of digital advertising, redefining the landscape with its suite of comprehensive solutions designed to meet the diverse needs of modern businesses. At the core of our approach lies a commitment to innovation and effectiveness, reflected in our cutting-edge platform that seamlessly connects advertisers with a vast network of content creators. Through strategic partnerships and advanced AI technology, we empower advertisers to engage their target audience with unparalleled precision and impact. Our real-time insights provide invaluable data and analytics, enabling advertisers to make informed decisions, optimize campaigns, and drive measurable results. From dynamic collaborations to authentic connections, Fiable offers a holistic advertising solution that prioritizes authenticity, efficiency, and success. With Fiable, unlock the full potential of your advertising endeavors and thrive in the ever-evolving digital landscape."

    return (
        <section className='py-5'>
            <PageHeader>
                <h1 className={'fw-bold arvo-bold ' + (darkMode ? 'my-text-primary-light' : 'my-text-dark')} style={{fontSize: '64px', transition: 'all 0.5s'}}>About Us</h1>
                <h3 className={'fw-bold arvo-bold ' + (darkMode ? 'my-text-primary-light' : 'my-text-dark')} style={{transition: 'all 0.5s'}}>Who Are We?</h3>
            </PageHeader>
            <Container className='pt-2 pb-4'>
                <Row className='me-1'>
                    <Card className={'fs-5 p-2 m-2 my-text-dark shadow border-2 arvo-regular ' + getThemeBGInv('primary') + ' ' + (darkMode ? 'border-primary' : 'border-white')} style={{transition: 'all 0.5s'}}>
                    <Card.Title className={'fs-1 fw-bold arvo-bold-italic text-center ' + getThemeText('primary')} style={{transition: 'all 0.5s'}}>Fiable</Card.Title>
                        <Card.Body>{FiableFullSummary}</Card.Body>
                    </Card>
                </Row>
                <Row>
                    <CompanySummary />
                    {window.innerWidth < 750 ? <></> : <MissionVisionValues />}
                </Row>
                {window.innerWidth < 750 ? <Row><MissionVisionValues /></Row> : <></>}
                <Row className='me-1'>
                    <OurTeam />
                </Row>
            </Container>
        </section>
    )
}



export const CompanySummary = () => {
    const { darkMode, getTextInv, getThemeTextInv} = useTheme()

    const FiableSummary1 = "At Fiable, we recognize each brand is unique. We tailor our innovative solutions to cater to our clients individual needs. Utilizing our network of creators and proprietary AI-powered solutions, we seamlessly connect brands with creators to foster authentic partnerships that drive engagement, click-throughs, and impressions."
    const FiableSummary2 = "From the integrity of our data to the transparency of our processes, we prioritize reliability at every step to demonstrate the accuracy and efficacy of our solutions. At Fiable, reliability is not just a value; it's a commitment to deliver unparalleled service, unwavering support, and tangible results to empower our clients to thrive in the digital age with confidence and peace of mind."
    const FiableSummary3 = ""

    return (
        <Col className='pb-3 pe-0'>
            <Card className={'p-3 m-2 ms-0 shadow border-1 border-primary arvo-regular ' + getTextInv() + ' my-bg-' + (darkMode ? 'secondary-darkmode' : 'secondary-light')} style={{transition: 'all 0.5s', height: '100%'}}>
                <Card.Title className={'fw-bold fs-3 ' + getThemeTextInv('primary')}>Fiable Means Reliable</Card.Title>
                <Card.Body className='fs-5'>
                    <p>{FiableSummary1}</p>
                    <p>{FiableSummary2}</p>
                    <p>{FiableSummary3}</p>
                </Card.Body>
            </Card>
        </Col>
    )
}

export const MissionVisionValues = () => {
    const { getTextInv, getThemeBGInv, darkMode, getThemeText, getThemeTextInv} = useTheme()

    const mission = "To transform digital advertising, to get creators paid their due, and provide advertisers a more effective means of reaching their customers than traditional alternatives."
    const vision = "Fiable is the next step in the digital marketing evolution, providing creators access to novel monetization streams and marketers with instant mass advertising opportunities."
    const values = "Innovation, Authenticity, Effectiveness, and Collaboration form our commitment to producing cutting-edge digital advertising solutions."

    return (
        <Col className='d-flex flex-column justify-content-between me-3' style={{transition: 'all 0.5s'}}>
            <Row className='h-100'>
                <Card className={'p-2 m-2 my-text-dark shadow border-2 ' + getThemeBGInv('primary') + ' ' + (darkMode ? 'border-primary' : 'border-white')} style={{transition: 'all 0.5s'}}>
                    <Card.Title className={'fs-3 fw-bold arvo-bold-italic ' + getThemeText('primary')} style={{transition: 'all 0.5s'}}>Mission</Card.Title>
                    <Card.Body className='fs-5 arvo-regular '>{mission}</Card.Body>
                </Card>
            </Row>
            <Row className='h-100'>
                <Card className={'p-2 m-2 my-text-dark shadow border-1 border-primary my-bg-' + (darkMode ? 'secondary-darkmode' : 'secondary-light')} style={{transition: 'all 0.5s'}}>
                    <Card.Title className={'fs-3 fw-bold arvo-bold-italic ' + getThemeTextInv('primary')} style={{transition: 'all 0.5s'}}>Vision</Card.Title>
                    <Card.Body className={'fs-5 arvo-regular ' + getTextInv()}>{vision}</Card.Body>
                </Card>
            </Row>
            <Row className='h-100'>
                <Card className={'p-2 m-2 my-text-dark shadow border-2 ' + getThemeBGInv('primary') + ' ' + (darkMode ? 'border-primary' : 'border-white')} style={{transition: 'all 0.5s'}}>
                    <Card.Title className={'fs-3 fw-bold arvo-bold-italic ' + getThemeText('primary')} style={{transition: 'all 0.5s'}}>Values</Card.Title>
                    <Card.Body className='fs-5 arvo-regular '>{values}</Card.Body>
                </Card>
            </Row>
        </Col>
    )
}

const OurTeam = () => {
    const { getThemeText, getThemeBGInv, darkMode} = useTheme()

    return (
        <Card className={'p-4 pb-2 m-2 arvo-regular border-2 ' + getThemeBGInv('primary') + ' ' + getThemeText('primary') + ' ' + (darkMode ? 'border-primary' : 'border-white')} style={{transition: 'all 0.5s'}}>
            <Card.Title className='fw-bold text-center'>Our Founders</Card.Title>
            <Card.Body>
                <Row>
                    <Col className='text-center'>
                        {/* <Image src={john} rounded /> */}
                        <h4 className='fw-bold'>John Popera, CEO</h4>
                        <p className='fs-5 my-text-dark'>A visionary leader deeply passionate about content creation and enhancing viewer experiences, driving innovation and authenticity in the digital landscape.</p>
                    </Col>
                    <Col className='text-center'>
                        {/* <Image src={tyler} rounded /> */}
                        <h4 className='fw-bold'>Tyler Sweeney, CFO</h4>
                        <p className='fs-5 my-text-dark'>A dedicated financial analyst devoted to optimizing any business's success through sound financial management and strategic decision making.</p>
                    </Col>
                    <Col className='text-center'>
                        {/* <Image src={maxim} rounded /> */}
                        <h4 className='fw-bold'>Maxim Kapelina, COO/CTO</h4>
                        <p className='fs-5 my-text-dark'>An innovative business strategist with a background in computer science. His commitment to excellence has propelled Fiable into a flourishing platform</p>
                    </Col>
                </Row>
            </Card.Body>
        </Card>
    )
}

export default About