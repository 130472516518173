import React, {useState, useEffect, useCallback} from 'react'
import { useTheme } from '../../../../../contexts/themeContext';
import useIntersectionObserver from '../../../../../utils/useIntersectionObserver';

const OurSolutions = () => {
    const {isSmallScreen, screenWidth, screenHeight, darkMode} = useTheme()

    const [currentNumber, setCurrentNumber] = useState(0)
    const [done, setDone] = useState(false)
    const maxRadius = 0.32
    const animDuration = 1000
    const maxBigFont = isSmallScreen ? 64 : 164
    const maxSmallFont = isSmallScreen ? 16 : 48

    const [ref, isIntersecting] = useIntersectionObserver({
        root: null, 
        threshold: 0.35 
      })
      
    const increaseTheNumber = useCallback((timeStamp, radius, start) => {
        if (radius <= maxRadius) {
            const elapsed = timeStamp - start
            setCurrentNumber(maxRadius * (elapsed / animDuration))
            window.requestAnimationFrame((t) => increaseTheNumber(t, maxRadius * (elapsed / animDuration), start))
        } else {
            setDone(true)
        }
    }, [])

    useEffect(() => {
        if (isIntersecting && !done) {
            window.requestAnimationFrame((t) => increaseTheNumber(t, 0.2, t))
        } 
        if (isIntersecting || done) {
            const ctrCircle = document.getElementById('ctrCircleWrapper')
            const savedCircle = document.getElementById('percentSavedCircleWrapper')
            if (ctrCircle && savedCircle) {
                ctrCircle.style.transform = 'translateY(0)'
                savedCircle.style.transform = 'translateY(0)'
            }
        }
    }, [isIntersecting, done, increaseTheNumber])


    return (
        <div ref={ref} className={'mt-4 mx-3 position-relative arvo-regular w-100 my-bg-' + (!darkMode ? 'light' : 'dark') + ' ' + (isSmallScreen ? 'py-1' : 'py-3')} style={{transition: 'background 0.5s', height: (isSmallScreen ? '700px' : screenHeight), maxHeight: '1000px'}}>
            {!isSmallScreen && Array.from({length: Math.round(Math.min(screenHeight, 1000) / 180)}, (_, i) => i).flatMap(offset => (
                Array.from({length: Math.round(screenWidth / 140)}, (_, i) => i).flatMap((i) => (
                    <div key={`dot-${i}-${offset}`} className='rounded-circle position-absolute' style={{height: '110px', width:'110px', top: `${offset * 180}px`, left: `${(140 * i)}px`, opacity: (Math.random() > 0.9 ? '0%' : '100%'), background: 'linear-gradient(45deg, rgba(209,120,12,1) 12%, rgba(255,184,0,1) 25%, rgba(255,184,0,1) 45%, rgba(255,238,205,1) 100%)', transition: 'opacity 0.3s ease', display: (isSmallScreen && 'none')}}/>
                ))
            ))}
            <div style={{minHeight: (isSmallScreen ? '600px' : screenHeight)}}>
                <div id='ctrCircleWrapper' className='w-100 h-50 d-flex flex-col px-5' style={{transform: 'translateY(200%)', transition: 'transform 1.2s ease-out'}}>
                    <div className='w-100 d-flex justify-content-center align-items-center overflow-y-visible ' style={{flex: '1 1 100%', height: 'min-content'}}>
                        <div 
                            className='d-flex flex-column justify-content-center text-center align-items-center rounded-circle position-relative back-blur'
                            style={{
                                transformOrigin: 'center center', 
                                transform: 'translateY(10%)', 
                                left: '-20%', 
                                height: (isSmallScreen ? '275px' : Math.min((screenHeight * currentNumber), (screenWidth * currentNumber)) * 1.5),
                                width: (isSmallScreen ? '300px' : Math.min((screenHeight * currentNumber), (screenWidth * currentNumber)) * 1.5), 
                                minWidth: Math.min((screenHeight * currentNumber), (screenWidth * currentNumber)),
                                transition: 'color 0.5s, background 0.5s',
                                opacity: '0.95',
                                background: 'linear-gradient(45deg, rgba(209,120,12,1) 12%, rgba(255,184,0,1) 25%, rgba(255,184,0,1) 45%, rgba(255,238,205,1) 100%)'
                                }}
                            >
                            <p className={'fw-bold w-100 my-text-dark'} style={{fontSize: Math.round(maxBigFont * (currentNumber / maxRadius) / (isSmallScreen ? 1 : 2)) + 'px', transition: 'color 0.5s'}}>{`${(Math.min(3 * (currentNumber / maxRadius), 3)).toFixed(1)}x`}</p>
                            <p className='fw-bold w-100 position-absolute ' style={{bottom: '20%', fontSize: Math.round(maxSmallFont * (currentNumber / maxRadius) / (isSmallScreen ? 1 : 2)) + 'px'}}>Click Through Rate</p>
                        </div>
                    </div>
                </div>
                <div id='percentSavedCircleWrapper' className='w-100 h-50 d-flex flex-col px-5' style={{transform: 'translateY(200%)', transition: 'transform 1.2s ease-out'}}>
                    <div className='w-100 h-50 d-flex justify-content-center overflow-y-visible ' style={{flex: '1 1 100%', height: 'min-content'}}>
                        <div 
                            className='d-flex flex-column justify-content-center text-center align-items-center rounded-circle position-relative my-bg-dark back-blur'
                            style={{
                                transformOrigin: 'center center', 
                                transform: isSmallScreen ? 'translateY(10%)' : 'translateY(-50%)', 
                                left: (isSmallScreen ? '10%' : '20%'), 
                                height: (isSmallScreen ? '275px' : Math.min((screenHeight * currentNumber), (screenWidth * currentNumber)) * 1.5),
                                width: (isSmallScreen ? '300px' : Math.min((screenHeight * currentNumber), (screenWidth * currentNumber)) * 1.5), 
                                minWidth: Math.min((screenHeight * currentNumber), (screenWidth * currentNumber)),
                                transition: 'color 0.5s, background 0.5s',
                                opacity: '0.95',
                                background: 'linear-gradient(45deg, rgba(209,120,12,1) 12%, rgba(255,184,0,1) 25%, rgba(255,184,0,1) 45%, rgba(255,238,205,1) 100%)'
                            }}
                        >
                            <p className='fw-bold w-100 my-text-dark' style={{fontSize: Math.round(maxBigFont * (currentNumber / maxRadius) / (isSmallScreen ? 1 : 2)) + 'px', transition: 'color 0.5s'}}>{`${(Math.min(50 * (currentNumber / maxRadius), 50)).toFixed(1)}%`}</p>
                            <p className='fw-bold w-100 position-absolute ' style={{bottom: '20%', fontSize: Math.round(maxSmallFont * (currentNumber / maxRadius) / (isSmallScreen ? 1 : 2)) + 'px'}}>Cost of Advertising</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default OurSolutions;