import React, { createContext, useContext, useState, useEffect } from 'react'

const ThemeContext = createContext()

export function useTheme() {
    return useContext(ThemeContext)
}

export function ThemeProvider( { children } ) {
    const [darkMode, setDarkMode] = useState(false)

    const [screenWidth, setScreenWidth] = useState(window.innerWidth)
    const [screenHeight, setScreenHeight] = useState(window.innerHeight)
    const [isSmallScreen, setIsSmallScreen] = useState(window.innerWidth < 750)

    // return bg-dark if dark mode or bg-light if light mode
    function getBG() {
        return 'my-bg-' + (darkMode ? 'dark' : 'light')
    }

    // return text-dark if dark mode or text-white if light mode
    function getText() {
        return 'my-text-' + (darkMode ? 'dark' : 'light')
    }
 
    // returns the inverse of getBG
    function getBGInv() {
        return 'my-bg-' + (!darkMode ? 'dark' : 'light')
    }

    //returns the inverse of getText
    function getTextInv() {
        return 'my-text-' + (!darkMode ? 'dark' : 'light')
    }

    // accepts a value from primary, secondary, info, success, warning, danger
    function getThemeBG(bg) {
        return 'my-bg-' + bg + (darkMode ? '-dark' : '-light')
    }

    function getThemeBGMain(bg) {
        return 'my-bg-' + bg + (darkMode ? '-dark' : '')
    }

    function getThemeBGPlatform(bg) {
        return 'my-bg-' + (darkMode ? 'dark' : bg + '-light')
    }

    // returns the inverse of getThemeBG
    function getThemeBGInv(bg) {
        return 'my-bg-' + bg + (!darkMode ? '-dark' : '-light')
    }

    function getThemeBGInvMain (bg) {
        return 'my-bg-' + bg + (!darkMode ? '-dark' : '')
    }
    
    function getThemeBGPlatformInv(bg) {
        return 'my-bg-' + bg + (bg === '-secondary' && !darkMode ? '-darkmode' : '') + (darkMode ? '-dark' : '-light')
    }

    // accepts a value from primary, secondary, info, success, warning, danger
    function getThemeText(color) {
        return 'my-text-' + color + (darkMode ? '-dark' : '-light')
    }

    function getThemeTextMain(color) {
        return 'my-text-' + color + (darkMode ? '-dark' : '')
    }


    // returns the inverse of getThemeText
    function getThemeTextInv(color) {
        return 'my-text-' + color + (!darkMode ? '-dark' : '-light')
    }

    function getButtonStyle(size) {
        return `btn-${size} arvo-regular border-1 border-primary shadow bg-gradient border-transition text-transition bg-transition ref-button ${(darkMode ? 'my-bg-secondary-darkmode-light my-text-light' : 'my-bg-secondary-light my-text-dark')}`
    }

    function getButtonThemeStyle(size, color) {
        return `btn-${size} arvo-regular fw-bold border-2 border-${(darkMode ? 'white' : 'dark')} shadow bg-gradient text-transition bg-transition ${(darkMode ? `my-bg-${color}-light my-text-light` : `my-bg-${color}-light my-text-dark`)}`
    }

    function getCardStyle(color) {
        if (color === 'primary') {
            return 'shadow border-2 border-transition text-transition bg-transition ' + (darkMode ? 'border-primary my-bg-primary-light my-text-light' : 'border-white my-bg-primary my-text-dark')
        } else {
            return 'shadow border-1 border-primary border-transition text-transition bg-transition my-bg-' + (darkMode ? 'secondary-darkmode my-text-light' : 'secondary-light my-text-dark')
        }
    }

    function getCardStyleInv(color) {
        if (color === 'primary') {
            return 'shadow border-1 bg-transition ' + (darkMode ?  'border-white my-bg-primary-dark my-text-dark' : 'border-primary my-bg-primary-light my-text-light')
        } else {
            return 'shadow border-1 border-primary bg-transition my-bg-' + (darkMode ? 'secondary-light my-text-dark' : 'secondary-darkmode my-text-light')
        }
    }

    function getFormStyle() {
        return 'border-0 bg-transition ' + (darkMode ? 'my-bg-secondary-darkmode-dark' : 'my-bg-secondary')
    }
    
    useEffect(() => {
        window.addEventListener('resize', () => {
            setScreenWidth(window.innerWidth)
            setScreenHeight(window.innerHeight)
            setIsSmallScreen(window.innerWidth < 750)
        })
    }, [])

    const value = { 
        darkMode, 
        setDarkMode, 
        getBG, 
        getBGInv,
        getText,
        getTextInv,
        getThemeBG,
        getThemeBGMain,
        getThemeBGPlatform,
        getThemeBGInv,
        getThemeBGInvMain,
        getThemeBGPlatformInv,
        getThemeText,
        getThemeTextMain,
        getThemeTextInv,
        getButtonStyle,
        getButtonThemeStyle,
        getCardStyle,
        getCardStyleInv,
        getFormStyle,
        screenWidth,
        screenHeight,
        isSmallScreen,
    }
    return (
        <ThemeContext.Provider value={value} >
            { children }
        </ThemeContext.Provider>
    )
}