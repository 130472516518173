import React from 'react'
import { Image } from 'react-bootstrap'
import { useTheme } from '../contexts/themeContext';
import graphic from '../assets/img/fiable_network.png'

const NoPage = () => {
    const { getTextInv } = useTheme()
    return (
        <section className='text-center' style={{paddingTop: '85px'}}>
            <h1 className={getTextInv()}>Error 404: Page Not Found</h1>
            <Image fluid height='75%' width='75%' src={graphic} />
        </section>
    )
}

export default NoPage;