import React from 'react'
import { Card, Container, Row } from 'react-bootstrap'
import { useTheme } from '../../contexts/themeContext'
import { Link } from 'react-router-dom'

const ContactUs = () => {
    const { getTextInv, getThemeBGInv, getThemeTextInv, darkMode } = useTheme()

    return (
        <Container fluid style={{width: (window.innerWidth < 750 ? '100%' : '82%')}}>
            <Row>
                <Card className={'border-1 border-primary shadow ' + (darkMode ? 'my-bg-dark' : 'my-bg-secondary-light')} style={{transition: 'background 0.5s'}}>
                    <Card.Body className='d-flex justify-content-between align-items-center'>
                        <div className='my-4'>
                            <h4 className={getThemeTextInv('primary')} style={{transition: 'color 0.5s'}}>Contact Us</h4>
                            <h2 className={getTextInv()} style={{transition: 'color 0.5s'}}>Got any questions? Reach out to us now!</h2>
                        </div>
                        <div>
                            <Link
                                to='/contact' 
                                className={'btn btn-lg ms-3 my-text-dark ' + getThemeBGInv('primary')} 
                                style={{transition: 'color 0.5s, background 0.5s'}} 
                                role='button'
                            >
                                Get in Touch!
                            </Link>
                        </div>
                    </Card.Body>
                </Card>
            </Row>
        </Container>
    )
}

export default ContactUs