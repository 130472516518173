import React from 'react'
import { Row, Col, Card, Image, Button, OverlayTrigger, Tooltip } from 'react-bootstrap'
import { useAuth } from '../../../../../contexts/authContext'
import { useTheme } from '../../../../../contexts/themeContext'
import { Download, QuestionCircleFill } from 'react-bootstrap-icons'

const AdDeploymentDetailsCard = (props) => {
    const { userData } = useAuth()
    const { getCardStyle } = useTheme()

    const linkInstructions = 'Include a hyperlink with the supplied text in the description of your content. Ensure this hyperlink is connected to the main link so that Fiable can track your metrics and pay you accurately for your conversions.'
    const logoInstructions = 'Place this logo in a corner of your content with a height around 60px. Also try to maintain the aspect ratio'
    const readInstructions = 'Near the beginning of your content, read this full script and potentially reference the link in your description.'
    const clipInstructions = 'Include a clip with a full advertisement following the description and instructions provided above.'

    const convertToFiableLink = (link) => {
        return `https://fiable-solutions.com/redirect/link?link=${encodeURIComponent(link)}&referrer=${userData.channels[0].handle}`
    }

    return (
        <Card className={getCardStyle()} style={{minHeight: '380px', transition: 'background 0.5s, color 0.5s'}}>
            <Card.Header className='py-3'>
                <p className='m-0 fw-bold'>Advertisement Deployment Details</p>
            </Card.Header>
            <Card.Body>
                {props.selected ? <>
                    <Row>
                        <Col>
                            <Row>
                                <div className='d-inline-flex justify-content-center'>
                                    <h2 className='fw-bold'>Link</h2>
                                    <OverlayTrigger placement='right' overlay={<Tooltip id='tooltip-link'>{linkInstructions}</Tooltip>}>
                                        <QuestionCircleFill className='ms-2 mb-2' />
                                    </OverlayTrigger>
                                </div>
                            </Row>
                            <Row>
                                <div className='d-inline-flex'>
                                    <h4 className='text-nowrap'>Main Link: &nbsp;</h4>
                                    <h4 className='text-decoration-underline'>{convertToFiableLink(userData.history[props.selected].productLink)}</h4>
                                </div>
                            </Row>
                            <Row>
                                <div className='d-inline-flex'>
                                    <h4>Hyperlink: &nbsp;</h4>
                                    <h4 className='text-decoration-underline'>{userData.history[props.selected].productLink}</h4>
                                </div>
                            </Row>
                        </Col>
                        {userData.history[props.selected].logoImage &&
                            <Col>
                                <Row>
                                    <div className='d-inline-flex justify-content-center'>
                                        <h2 className='fw-bold'>Logo</h2>
                                        <OverlayTrigger placement='right' overlay={<Tooltip id='tooltip-link'>{logoInstructions}</Tooltip>}>
                                            <QuestionCircleFill className='ms-2 mb-2' />
                                        </OverlayTrigger>
                                    </div>
                                </Row>
                                <Row>
                                    <div className='d-flex justify-content-center'>
                                        <Image src={userData.history[props.selected].logoImage} height={60} />
                                    </div>
                                </Row>
                                <Row className='p-3'>
                                    <a className='d-flex justify-content-center' href={userData.history[props.selected].logoImage} download={`${userData.history[props.selected].advertisementName} - Logo`}>
                                        <Button className='rounded-1 btn-sm'>
                                            <Download className='fs-5 me-2'/>&nbsp;Download Logo
                                        </Button>
                                    </a>
                                </Row>
                            </Col>
                        }
                    </Row>
                    {userData.history[props.selected].readText && 
                        <Row>
                            <Col>
                                <Row>
                                    <div className='d-inline-flex justify-content-center'>
                                        <h2 className='fw-bold'>Ad Script</h2>
                                        <OverlayTrigger placement='right' overlay={<Tooltip id='tooltip-link'>{readInstructions}</Tooltip>}>
                                            <QuestionCircleFill className='ms-2 mb-2' />
                                        </OverlayTrigger>
                                    </div>
                                </Row>
                                <Row>
                                    <div className='d-inline-flex'>
                                        <h4 className='text-nowrap'>Ad Script Text: &nbsp;</h4>
                                        <h4>{userData.history[props.selected].readText}</h4>
                                    </div>
                                </Row>
                            </Col>
                        </Row>
                    }
                    {userData.history[props.selected].clipText && 
                        <Row>
                            <Col>
                                <Row>
                                    <div className='d-inline-flex justify-content-center'>
                                        <h2 className='fw-bold'>Clip</h2>
                                        <OverlayTrigger placement='right' overlay={<Tooltip id='tooltip-link'>{clipInstructions}</Tooltip>}>
                                            <QuestionCircleFill className='ms-2 mb-2' />
                                        </OverlayTrigger>
                                    </div>
                                </Row>
                                <Row>
                                    <div className='d-inline-flex'>
                                        <h4 className='text-nowrap'>Clip Description: &nbsp;</h4>
                                        <h4>{userData.history[props.selected].clipText || 'None Provided'}</h4>
                                    </div>
                                </Row>
                                {userData.history[props.selected].clipVideo ? <>
                                    <Row>
                                        <video className='rounded-1' height={300} controls>
                                            <source src={userData.history[props.selected].clipVideo} />
                                            Your Browser does not support embedded videos.
                                        </video>
                                    </Row>
                                    <Row className='p-3'>
                                        <a className='d-flex justify-content-center' href={userData.history[props.selected].clipVideo} download={`${userData.history[props.selected].advertisementName} - Clip`}>
                                            <Button className='rounded-1 btn-sm'>
                                                <Download className='fs-5 me-2'/>&nbsp;Download Clip
                                            </Button>
                                        </a>
                                    </Row>
                                </>
                                :
                                    <Row>No Clip Provided</Row>
                                }
                            </Col>
                        </Row>
                    }
                    </>
                :
                    <div className='mt-5 pt-5 fs-3 text-center'>{Object.keys(userData.history).length ? 'Select an Advertisement from the list to view deployment details' : 'No Active Advertisements'}</div>
                }
            </Card.Body>
        </Card>
    )
}

export default AdDeploymentDetailsCard