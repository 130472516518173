import React, { useCallback, useEffect, useState, useRef } from "react";
import { useTheme } from "../../../../../contexts/themeContext";
import WaveDivider from "./waveDivider";
import BookDemo from "../../../../common/bookDemo";
import { Collapse } from "react-bootstrap";
import HowItWorks from "./howItWorks";

const BookDemoExtended = (props) => {
    const { darkMode, isSmallScreen } = useTheme()

    const [scale, setScale] = useState(1)
    const scaleStep = 8

    const ref = useRef(null)

    const handleScroll = useCallback(() => {
        const topSvg = document.getElementById('topWave')
        const bottomSvg = document.getElementById('bottomWave')
        const firstViewScrollY = ref.current.getBoundingClientRect().top
        if (topSvg && bottomSvg) {
            if ((window.innerHeight * 0.2) > (firstViewScrollY)) {
                setScale(Math.min(scaleStep, scale + scaleStep))
            } else {
                setScale(Math.max(1, scale - scaleStep))
            }
        }
    }, [scale, ref])

    useEffect(() => {    
        if (!isSmallScreen){
            window.addEventListener('scroll', handleScroll);
        }
        return () => {
            window.removeEventListener('scroll', handleScroll);
        }
    }, [handleScroll, isSmallScreen])

    return (
        <div className='w-100 bg-transition ' style={{marginBottom: (!isSmallScreen && scale !== scaleStep ? '400px' : '0px'), marginTop: isSmallScreen ? '0px' : '150px', transition: 'all 0.5s'}}>
            <WaveDivider/>
            <div ref={ref} className={'mt-0 py-2 my-bg-' + (!darkMode ? 'primary' : 'primary-light')} style={{transition: 'background 0.5s, transform 0.4s'}}>
                <BookDemo onMainPage={true}/>
                <Collapse in={!isSmallScreen && scale === scaleStep} timeout={500} className='py-3'>
                    <div>
                        <div id='howItWorksSection'>
                            <HowItWorks playVideo={props.playVideo} />
                        </div>
                    </div>
                </Collapse>
            </div>
            <WaveDivider orientation={0}/>
        </div>
    )
}

export default BookDemoExtended