import React from 'react'
import { useAuth } from '../../../../../contexts/authContext'
import AdTable from '../adTable'

const AdHistoryTable = () => {
    const { userData } = useAuth()

    const getAdStatus = (ad) => {
        let adDate = ad.startDate.toDate()
        if (adDate > new Date()) return 'Scheduled'

        adDate.setMonth(adDate.getMonth() + 1)
        if (adDate < new Date()) return 'Completed'
        return 'Active'
    } 

    const cols = ['Ad Name', 'Start Date', 'Target Imps.', 'Current Imps.', 'Cost', 'Platforms', 'Categories', 'Status']
    const data = Object.values(userData.history).filter(ad => !ad.isPotential).map(ad => ({
        name: ad.advertisementName,
        startDate: ad.startDate.toDate().toLocaleDateString(),
        targetImps: Number(ad.totalImpressions).toLocaleString(),
        currentImps: ad.currentImpressions ? Number(ad.currentImpressions).toLocaleString() : 0,
        cost: `$${Number(ad.totalCost).toLocaleString()}`,
        platforms: ad.platforms.join(', '),
        categories: ad.buckets.join(', '),
        status: getAdStatus(ad)
    }))

    return (
        <AdTable title='Advertisement History' tableCols={cols} data={data} bgTheme='secondary' />
    )
}

export default AdHistoryTable