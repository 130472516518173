import React, { useState } from 'react'
import { loadStripe } from '@stripe/stripe-js'
import { useAuth } from '../../../../../contexts/authContext'
import { useTheme } from '../../../../../contexts/themeContext'
import { Button, Card, Col, Form, Modal, Row } from 'react-bootstrap'
import AdSummary from '../../../advertisements/subcomponents/adSummary'
import { Elements } from '@stripe/react-stripe-js'
import CheckoutForm from './checkoutForm'
import { useNavigate } from 'react-router-dom'

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY)
// const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_TEST_KEY)

const BookAdvertisementCard = (props) => {
    const {userData} = useAuth()
    const {getCardStyle, getButtonStyle} = useTheme()
    const navigate = useNavigate()

    const [showBookAd, setShowBookAd] = useState(false)

    return (
        <Card className={'h-100 ' + getCardStyle()} style={{transition: 'background 0.5s, color 0.5s'}}>
            <Card.Header>
                <Card.Title>
                    <h3 className='fw-bold'>Book Advertisement</h3>
                </Card.Title>
            </Card.Header>
            <Card.Body className='px-0'>
                {props.selected ? 
                    <AdSummary data={userData.history[props.selected]} />
                :
                    <h1 className='py-5 my-5 text-center'>No Advertisement Selected</h1>
                }
            </Card.Body>
            <Card.Footer>
                <Row className='d-flex justify-content-between p-1'>
                    {/* Disabling edit advertisement for now
                    <Col>
                        <Button 
                            className={'w-75 m-1 border-1 shadow border-primary bg-gradient ' + (darkMode ? 'my-bg-dark' : 'my-bg-secondary-light') + ' ' + getTextInv()} 
                            style={{transition: 'background 0.5s, color 0.5s'}} 
                            disabled={!props.selected}
                            onClick={() => navigate('/create-ad/adId=' + props.selected)}    
                        >
                            Edit Advertisement
                        </Button>
                    </Col> */}
                    <Col className='d-flex justify-content-center'>
                        <Button 
                            className={'w-75 ' + getButtonStyle('md')} 
                            style={{transition: 'background 0.5s, color 0.5s'}} 
                            disabled={!props.selected}
                            onClick={() => setShowBookAd(true)}
                        >
                            Go to Checkout
                        </Button>
                    </Col>
                </Row>
            </Card.Footer>
            {props.selected && <BookAdModal show={showBookAd} setShow={setShowBookAd} onSubmit={() => navigate('/dashboard/' + props.selected) || props.setSelected(null)} adId={props.selected} />}
        </Card>
    )
}

const BookAdModal = (props) => {
    const {getBG, getTextInv} = useTheme()

    const [agree, setAgree] = useState(false)

    const handleClose = () => {
        setAgree(false)
        props.setShow(false)
        props.onSubmit()
    }

    return (
        <Modal size='lg' show={props.show} onHide={() => props.setShow(false)}>
            <Modal.Header className={getBG() + ' ' + getTextInv()} closeButton>
                <Modal.Title className='fs-3'>Final Review</Modal.Title>
            </Modal.Header>
            <Modal.Body className={getBG() + ' rounded-bottom ' + getTextInv()}>
                <Row><h4 className='text-start'>Before you book:</h4></Row> 
                <ul style={{fontSize: '12px'}}>
                    <li>Fiable will distribute this ad to the creators we determine are best associated with the ad categories, your product, and your brand. Additionally, creators are vetted by Fiable before receiving any ad. If you find your ad on a channel you do not approve of, please reach out at legal@fiable-solutions.com</li>
                    <li>Once you Book an ad, you will have a short period to cancel it and withdraw your funds. After the cancellation period, once a creator accepts the advertisement, you will no longer be able to cancel it, and you will not be able to withdraw the amount of the ad.</li>
                    <li>You will recieve frequent updates from Fiable regarding the status of the Advertisement. Ads have a default life cycle of one month, and you will receive a detailed report at the end of that cycle. Additionally, you can see live feedback in the dashboard.</li>
                    <li>Fiable does not guarantee you will achieve the target impressions during the advertisements life cycle, however if the ad does not reach the desired impressions, You will have the option to be refunded for the missed impressions.</li>
                </ul>
                <Row className='ps-3'>
                    <Form.Check onChange={(e) => setAgree(!agree)} className='form-control-lg' label={<h5>I agree to the <a href='/advertiserAgreement'>Fiable Advertiser Agreement</a></h5>} />
                </Row>
                <Elements stripe={stripePromise}>
                    <CheckoutForm onSubmit={handleClose} onError={() => setAgree(false) || props.setShow(false)} adId={props.adId} disabled={!agree} />
                </Elements>
            </Modal.Body>
        </Modal>
    )
}

export default BookAdvertisementCard