import React, { useState } from 'react'
import { Button, Col, Container, Form, InputGroup, Row } from 'react-bootstrap'
import { useTheme } from '../../../../../../contexts/themeContext'
import { useAuth } from '../../../../../../contexts/authContext'

import { adTypes } from '../creator/adGuideTool'

const BudgetMakerTool = () => {
    const { userData, updateUserData, setError } = useAuth()
    const {darkMode, getTextInv, getButtonStyle} = useTheme() 

    const [min, setMin] = useState(userData.budget ? userData.budget.minimum : 0)
    const [budget, setBudget] = useState(userData.budget ? userData.budget.budget : 0)
    const [max, setMax] = useState(userData.budget ? userData.budget.maximum : 0)

    const [loading, setLoading] = useState(false)
    const [saved, setSaved] = useState(false)

    const updateBudget = () => {
        setError()
        setLoading(true)

        const newBudget = {
            minimum: min,
            budget: budget,
            maximum: max
        }

        try {
            updateUserData({budget: newBudget})
            setSaved(true)
        } catch (err) {
            setError('Failed to update budget')
        } finally {
            setLoading(false)
        }
    }

    return (
        <Container fluid >
            <Row className={'text-center pb-1 ' + getTextInv()}>
                <h3>Set a Budget for your Ad Campaign, and review what that Budget could get you.</h3>
            </Row>
            <Row>
                <Col md={3}>
                    <Form.Label className={'mb-0 ms-3 ' + getTextInv()}>Minimum</Form.Label>
                    <InputGroup>
                        <InputGroup.Text className='rounded-start'>$</InputGroup.Text>
                        <Form.Control id='min' onChange={(e) => setMin(Number(e.target.value))} type='number' defaultValue={min > 0 ? min : ''} />
                    </InputGroup>
                    
                </Col>
                <Col className='d-inline-flex justify-content-center' md={6}>
                    <div className={'text-center fs-4 pe-4 pt-4 ' + getTextInv()}>{'<'}</div>
                    <div className='w-100'>
                        <Form.Label className={'mb-0 ms-3 ' + getTextInv()}>Budget</Form.Label>
                        <InputGroup>
                            <InputGroup.Text className='rounded-start'>$</InputGroup.Text>
                            <Form.Control onChange={(e) => setBudget(Number(e.target.value)) } type='number' defaultValue={budget > 0 ? budget : ''} />
                        </InputGroup>
                    </div>
                    <div className={'text-center fs-4 ps-4 pt-4 ' + getTextInv()}>{'<'}</div>
                </Col>
                <Col md={3}>
                    <Form.Label className={'mb-0 ms-3 ' + getTextInv()}>Maximum</Form.Label>
                    <InputGroup>
                        <InputGroup.Text className='rounded-start'>$</InputGroup.Text>
                        <Form.Control id='max' onChange={(e) => setMax(Number(e.target.value))} type='number' defaultValue={max > 0 ? max : ''} />
                    </InputGroup>
                </Col>
            </Row>
            <Row className='text-center pt-2'>
                <Col>
                    <Row className={'d-flex justify-content-center fs-4 border-bottom border-' + (darkMode ? 'light' : 'dark') + ' ' + getTextInv()}>Ad Type</Row>
                    {adTypes.filter(type => type.dataName !== 'product').map(type => (
                        <Row key={type.name} className={'d-flex justify-content-center fs-4 ' + getTextInv()}>{type.name}</Row>
                    ))}
                </Col>
                <Col>
                    <Row className={'d-flex justify-content-center fs-4 border-bottom border-' + (darkMode ? 'light' : 'dark') + ' ' + getTextInv()}>Impressions</Row>
                    {adTypes.filter(type => type.dataName !== 'product').map(type => (
                        <Row key={type.name} className={'d-flex justify-content-center fs-4 ' + getTextInv()}>
                            {min ? Math.round(min / type.cost * 100).toLocaleString() : ''}
                            {min && (budget || max) ? '-' : ''}
                            {budget ? Math.round(budget / type.cost * 100).toLocaleString() : ''}
                            {budget && max ? '-' : ''}
                            {max ? Math.round(max / type.cost * 100).toLocaleString() : ''}
                        </Row>
                    ))}
                </Col>
            </Row>
            <Row className='d-flex justify-content-center pt-2'>
                <Button className={'w-25 ' + getButtonStyle('md')} onClick={updateBudget} disabled={loading}>Save Budget</Button>
                {saved ? <h5 className='pt-2 mb-0 text-center my-text-success-dark'>Saved Budget!</h5> : <></>}
            </Row>
            <Row className={'text-center mt-3 border-top border-' + (darkMode ? 'light' : 'dark') + ' ' + getTextInv()}>
                <h4>Recommended Ad at current budget:</h4>
            </Row>
            <Row className={'text-center ' + getTextInv()}>
                <h3>
                    {(!budget || budget === 0) && 'Set a budget to see your recommended bundle'}
                    {budget > 100 && budget <= 1000 && 'A basic ad featuring a link and a logo'}
                    {budget > 1000 && budget <= 10000 && 'A standard ad featuring a link, logo, and 10-30 second shoutout'}
                    {budget > 10000 && 'A full ad featuring a link, logo, and a 1-2 minute ad read with a video clip'}
                </h3>
            </Row>
        </Container>
    )
}

export default BudgetMakerTool