import React, { useCallback } from 'react'
import { BrowserRouter, Outlet, Route, Routes } from 'react-router-dom'
import { Container } from 'react-bootstrap'

import About from './Components/mainPage/about/about'
import Blog from './Components/mainPage/blog/blog'
import Home from './Components/mainPage/home/home'
import Solutions from './Components/mainPage/solutions/solutions'
import Demo from './Components/mainPage/demo/demo'
import ContactUs from './Components/mainPage/contact/contactUs'

import PrivacyPolicy from './Components/policyPages/privacyPolicy/privacyPolicy'
import TermsOfService from './Components/policyPages/termsOfService/termsOfService'

import NoPage from './Components/noPage'
import ResetPassword from './Components/resetPassword';

import { AuthProvider, useAuth } from './contexts/authContext'
import { ThemeProvider, useTheme } from './contexts/themeContext'

import ErrorMessage from './Components/common/errorMessage'
import SuccessMessage from './Components/common/successMessage'
import WithPrivateRoute from './utils/withPrivateRoute'

import Profile from './Components/userHome/profile/profile'
import Dashboard from './Components/userHome/dashboard/dashboard'
import History from './Components/userHome/history/history'
import Payment from './Components/userHome/payment/payment'
import Channel from './Components/userHome/channel/channel'

import SimpleHeader from './Components/header/simpleHeader';
import Footer from './Components/footer/footer'
import SideNavbar from './Components/userHome/sideNavbar'
import BlogPage from './Components/mainPage/blog/blogPage';
import CreateAd from './Components/userHome/createAd/createAd';
import Advertisements from './Components/userHome/advertisements/advertisements';
import AdCalendar from './Components/userHome/adCalendar/adCalendar';
import RedirectPage from './Components/common/redirectPage';

import ReactGA from 'react-ga4'
import TagManager from 'react-gtm-module';
import AdvertiserAgreement from './Components/policyPages/advertiserAgreement/advertiserAgreement';
import CreatorAgreement from './Components/policyPages/creatorAgreement/creatorAgreement';
import Header from './Components/header/header'
import Achievements from './Components/userHome/achievements/achievements'
import NewMedalModal from './Components/userHome/achievements/subcomponents/medals/newMedalModal'
import WarningMessage from './Components/common/warningMessage'
import Social from './Components/userHome/social/social'

const tagManagerArgs = {
    gtmId: 'GTM-KFWL4HQT'
}

TagManager.initialize(tagManagerArgs)
ReactGA.initialize('G-NLMGT1762G')

const App = () => {
    return (
        <ThemeProvider>
            <AuthProvider>
                <BrowserRouter>
                    <Routes>
                        <Route path='/' element={<Outlet />}>
                            <Route index element={<MainPageComp comp={<Home />} />} />
                            <Route path='/solutions' element={<MainPageComp comp={<Solutions />} />} />
                            <Route path='/demo' element={<MainPageComp comp={<Demo />} />} />
                            <Route path='/about' element={<MainPageComp comp={<About />} />} />
                            <Route path='/blog' element={<MainPageComp comp={<Blog />} />} />
                            <Route path='/blog/:id' element={<MainPageComp comp={<BlogPage />} />} />
                            <Route path='/contact' element={<MainPageComp comp={<ContactUs />} />} />

                            <Route path='/privacy' element={<MainPageComp comp={<PrivacyPolicy />} />} />
                            <Route path='/terms' element={<MainPageComp comp={<TermsOfService />} />} />
                            <Route path='/advertiser-agreement' element={<UserPageComp comp={<AdvertiserAgreement />} type={0} />} />
                            <Route path='/creator-agreement' element={<UserPageComp comp={<CreatorAgreement />} type={1} />} />

                            <Route path='/dashboard' element={<UserPageComp comp={<Dashboard />} type={5} />}>
                                <Route path='/dashboard/:adId' element={<UserPageComp comp={<Dashboard />} type={5} />} />
                            </Route>
                            <Route path='/channel' element={<UserPageComp comp={<Channel />} type={4} />} />
                            <Route path='/payment' element={<UserPageComp comp={<Payment />} type={5} />}>
                                <Route path='/payment/:adId' element={<UserPageComp comp={<Payment />} type={0} />} />
                            </Route>
                            <Route path='/social' element={<UserPageComp comp={<Social />} type={1} />} />
                            <Route path='/profile' element={<UserPageComp comp={<Profile />} type={5} />} />
                            <Route path='/history' element={<UserPageComp comp={<History />} type={2} />} />
                            <Route path='/achievements' element={<UserPageComp comp={<Achievements />} type={1} />} />

                            <Route path='/advertisements' element={<UserPageComp comp={<Advertisements />} type={5} />} />
                            <Route path='/create-ad' element={<UserPageComp comp={<CreateAd />} type={0} />}>
                                <Route path='/create-ad/:params' element={<UserPageComp comp={<CreateAd />} type={0} />} />
                            </Route>
                            <Route path='/calendar' element={<UserPageComp comp={<AdCalendar />} type={0} />} />

                            <Route path='/redirect'>
                                <Route path='/redirect/:link' element={<MainPageComp comp={<RedirectPage />} />} />
                            </Route>

                            <Route path='/*' element={<MainPageComp comp={<NoPage />} />} />
                            <Route path='/resetpassword' element={<MainPageComp comp={<ResetPassword />} />} />
                        </Route>
                    </Routes>
                </BrowserRouter>
            </AuthProvider>
        </ThemeProvider>
    );
}

const MainPageComp = ( props ) => {
    const { getBG, isSmallScreen } = useTheme()

    return (
        <>
            <Container fluid className={'px-0 bg-transition ' + getBG()}>
                {props.comp}
            </Container>
            {isSmallScreen ? <Header id='header' appear={true} /> : <SimpleHeader/>}
            <div className='d-flex justify-content-center '>
                <ErrorMessage />
                <WarningMessage />
                <SuccessMessage />
            </div>
            <Footer />
        </>
    )
}

const UserPageComp = ( props ) => {
    const { type } = useAuth()
    const { darkMode, isSmallScreen } = useTheme()

    // note: temporarily redefining page render logic from side navbar to prevent backdoor access to advertiser pages from creator account or vice versa
    // 0 for advertiser page no mobile, 1 for creator page no mobile, 2 for all no mobile, 3 for advertiser mobile enabled, 4 for creator mobile enabled, 5 for creator and advertiser mobile enabled
    const getPageAllowed = useCallback(() => {
        if (props.type === 5 || props.type === type + 3) return true
        else if (!isSmallScreen && (props.type === 2 || props.type === type )) return true
        else return false
    }, [isSmallScreen, type, props.type])

    return (
        <WithPrivateRoute isAllowed={getPageAllowed}>
            <Container className={'m-0 p-0 d-inline-flex bg-transition arvo-regular ' + (darkMode ? 'my-bg-secondary-darkmode-dark' : 'my-bg-secondary')} style={{minWidth: '100%'}}>
                <SideNavbar />
                <Container fluid className='m-0 p-0' style={{minHeight: '101vh'}}>
                    {props.comp}
                    <div className='d-flex justify-content-center'>
                        <ErrorMessage />
                        <WarningMessage />
                        <SuccessMessage />
                    </div>
                    <NewMedalModal />
                </Container>
            </Container>
        </WithPrivateRoute>
    )
}

export default App;
