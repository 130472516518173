import React, { useState } from 'react'
import { Form, Button, Col, Container, Row } from 'react-bootstrap';
import PageHeader from '../../common/pageHeader';
import { useTheme } from '../../../contexts/themeContext';
import ContactUs from '../../common/contactUs';
import { useAuth } from '../../../contexts/authContext';

const Demo = () => {
    const { sendEmail, setError, setSuccess } = useAuth()
    const { getTextInv, getThemeBGPlatform, darkMode } = useTheme()

    const [loading, setLoading] = useState( false )

    const handleSubmit = async (e) => {
        e.preventDefault()
        setLoading(true)

        const data = [...(new FormData(e.target)).entries()]
        
        try {
            await sendEmail('fiable@fiable-solutions.com', 'New Demo Request!', 'Someone just submitted the demo form with the following info: \n' + data.map(entry => entry[0] + ': ' + entry[1] + '\n'), false)
            setSuccess('Message sent! You will hear from us soon!')
        } catch (err) {
            setError('Failed to send message, please try again later')
        } finally {
            document.getElementById('demoForm').reset()
            setLoading(false)
        }
    }

    return (
        <section className='py-5' style={{minHeight: '100vh'}}>
            <PageHeader>
                <h1 className={'fw-bold arvo-bold ' + (darkMode ? 'my-text-primary-light' : 'my-text-dark')} style={{fontSize: '64px', transition: 'all 0.5s'}}>Book A Demo</h1>
                <h3 className={'fw-bold arvo-bold-italic ' + (darkMode ? 'my-text-primary-light' : 'my-text-dark')} style={{transition: 'all 0.5s'}}>Don't take our word for it,<br/>Submit a demo request or sign-up now!</h3>
            </PageHeader>
            <Container className='pt-2 pb-4'>
                <Form onSubmit={handleSubmit} id='demoForm' className='mx-5 pb-3'>
                    <Row>
                        <Col>
                            <Form.Control required type='text' name='name' placeholder='Name' className={'bg-transition m-1 ' + (darkMode ? 'my-bg-secondary-darkmode-light my-text-primary-light border-dark' : 'my-bg-secondary-light my-text-dark')}/>
                        </Col>
                        <Col>
                            <Form.Control required type='text' name='companyName' placeholder='Company Name' className={'bg-transition m-1 ' + (darkMode ? 'my-bg-secondary-darkmode-light my-text-primary-light border-dark' : 'my-bg-secondary-light my-text-dark')}/>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <Form.Control required type='email' name='email' placeholder='Company Email' className={'bg-transition m-1 ' + (darkMode ? 'my-bg-secondary-darkmode-light my-text-primary-light border-dark' : 'my-bg-secondary-light my-text-dark')}/>
                        </Col>
                        <Col>
                            <Form.Control type='tel' name='yourTel' placeholder='Phone Number  (optional)' className={'bg-transition m-1 ' + (darkMode ? 'my-bg-secondary-darkmode-light my-text-primary-light border-dark' : 'my-bg-secondary-light my-text-dark')}/>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <Form.Control required type='text' name='message' as='textarea' rows='4' placeholder='How Would a Demo Benefit You?' className={'bg-transition m-1 ' + (darkMode ? 'my-bg-secondary-darkmode-light my-text-primary-light border-dark' : 'my-bg-secondary-light my-text-dark')}/>
                        </Col>
                    </Row>
                    <Row className='d-flex justify-content-center pt-3'>
                        <Button 
                            type='submit' 
                            disabled={loading} 
                            className={'fw-bold fs-4 btn-md border-1 border-primary w-50 mb-4 shadow bg-gradient ' + getThemeBGPlatform('secondary') + ' ' + getTextInv()}
                            style={{transition: 'background 0.5s, color 0.5s'}}
                        >
                            Submit
                        </Button>
                    </Row>
                </Form>
                <ContactUs />
            </Container>
        </section>
    )
}

export default Demo;