import React, { useRef, useState, useEffect, useCallback } from 'react'
import { Image, Card, Button, Spinner } from 'react-bootstrap'

import { generateAvatar } from '../../../../utils/generateAvatar'
import { useAuth } from '../../../../contexts/authContext'
import { useTheme } from '../../../../contexts/themeContext'

const UserCard = (props) => {
    const { currentUser, setSocialData, updateUserProfile, setError, uploadAvatar, type, earnMedal } = useAuth()
    const { getCardStyle, getButtonStyle } = useTheme()

    const [avatar, setAvatar] = useState(null)
    const [loading, setLoading] = useState(true)

    const fileInputRef = useRef(null)

    const handleAvatarChange = async (e) => {
        const file = e.target.files[0]
        if (file) {
            const reader = new FileReader()
            reader.onload = () => {
                setAvatar(reader.result)
            }
            reader.readAsDataURL(file)

            try {
                setLoading(true)
                setError('')
                
                const url = await uploadAvatar(file, currentUser.uid)
                await updateUserProfile( {photoURL: url} )
                await setSocialData({photoURL: url})

                earnMedal(2)
            } catch (e) {
                setError('Failed to Update Profile Picture')
            } finally {
                setLoading(false)
            }
        }
    }

    const fetchAvatarData = useCallback(() => {
        if ( !currentUser.photoURL ) {
            const fillerAvatar = generateAvatar(type)
            setAvatar(fillerAvatar)
        } else {
            setAvatar(currentUser.photoURL)
        }
    }, [currentUser.photoURL, type])

    useEffect(() => {
        fetchAvatarData()
        setLoading(false)
    }, [fetchAvatarData])

    return (
        <Card className={'h-100 ' + getCardStyle()} style={{transition: 'all 0.5s'}}>
            <Card.Body className='text-center'>
                <h4 className='fw-bold my-1'>{props.nickname || props.username || 'Set a Username!'}</h4>
                { loading ?
                    <Spinner className='my-5' animation='border'/>
                :
                    <Image roundedCircle className='mb-3 mt-3' src={avatar} width={195} height={195} />
                }
                <div>
                    <Button 
                        className={'m-2 ' + getButtonStyle('sm')} 
                        style={{transition: 'color 0.5s, background 0.5s'}} 
                        onClick={() => fileInputRef.current.click()}
                    >
                        Change Photo
                    </Button>
                    <input ref={fileInputRef} id='imageUpload' type='file' accept='image/*' style={{ display: 'none' }} onChange={handleAvatarChange} />
                </div>
            </Card.Body>
        </Card>
    )
}

export default UserCard;