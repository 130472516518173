import React, { forwardRef, useEffect, useState } from 'react'
import { useAuth } from '../../../../../contexts/authContext'

import './medals.css'
import { Image } from 'react-bootstrap'

import medal_0 from '../../../../../assets/icons/medals/medal_0.svg'
import medal_1 from '../../../../../assets/icons/medals/medal_1.svg'
import medal_2 from '../../../../../assets/icons/medals/medal_2.svg'
import medal_3 from '../../../../../assets/icons/medals/medal_3.svg'
import medal_4 from '../../../../../assets/icons/medals/medal_4.svg'
import medal_5 from '../../../../../assets/icons/medals/medal_5.svg'
import medal_6 from '../../../../../assets/icons/medals/medal_6.svg'
import medal_7 from '../../../../../assets/icons/medals/medal_7.svg'
import medal_8 from '../../../../../assets/icons/medals/medal_8.svg'
import medal_9 from '../../../../../assets/icons/medals/medal_9.svg'
import medal_10 from '../../../../../assets/icons/medals/medal_10.svg'
import medal_11 from '../../../../../assets/icons/medals/medal_11.svg'
import medal_12 from '../../../../../assets/icons/medals/medal_12.svg'
import medal_13 from '../../../../../assets/icons/medals/medal_13.svg'
import medal_14 from '../../../../../assets/icons/medals/medal_14.svg'
import medal_15 from '../../../../../assets/icons/medals/medal_15.svg'

// id is the medal id, just a number
// name and desc define the user visible aspects of the medal
// tier is the 1-indexed tier of the medal
// type is 0 if scored medal, 1 if one-time medal, 2 if recurring medal
// variant is the default medal variant, 1 is bronze, 2 is silver, 3 is gold
// icon is the icon associated with the medal
// payout is optionally defined for medals with a custom token value

// this data is meant to be merged with user medal data, which would bring in:
// the awarded variant
// the achievement details (associated with the medal type)
// date of achievement
// isRedeemed

export const medals = {
    0: {
        name: 'New Kid on the Block',
        desc: 'Create a Fiable Account',
        tier: 1,
        type: 1,
        variant: 3,
        payout: 5,
        icon: medal_0
    },
    1: {
        name: 'IdentiFiable',
        desc: 'Set a Username, Nickname, and a Name',
        tier: 1,
        type: 1,
        variant: 3,
        icon: medal_1
    },
    2: {
        name: 'Picture Perfect',
        desc: 'Add a Profile Photo',
        tier: 1,
        type: 1,
        variant: 3,
        icon: medal_2
    },
    3: {
        name: 'Join the Club!',
        desc: 'Complete the Creator Activation Form',
        tier: 1,
        type: 1,
        variant: 3,
        icon: medal_3
    },
    4: {
        name: 'Certified Classic',
        desc: 'Link a Channel to your Account',
        tier: 2,
        type: 0,
        // variant: bronze for 1 channel, silver for 2, gold for 3
        icon: medal_4
    },
    5: {
        name: 'Categorizer',
        desc: 'Update your content categories',
        tier: 2,
        type: 0,
        // variant: bronze for < 10, silver for < 25
        icon: medal_5
    },
    6: {
        name: 'Feeling Refreshed',
        desc: 'Refresh the Data on a Channel',
        tier: 2,
        type: 1,
        variant: 3,
        icon: medal_6
    },
    7: {
        name: 'Acceptance Speech',
        desc: 'Accept a Fiable Ad',
        tier: 3,
        type: 1,
        icon: medal_7
    },
    8: {
        name: 'Pre-Flight Checked',
        desc: 'Successfully Complete a Video Verification',
        tier: 3,
        type: 1,
        variant: 3,
        icon: medal_8
    },
    9: { // unimplemented
        name: 'Re-Monetized',
        desc: 'Post a Video with a Fiable Ad',
        tier: 3,
        type: 1,
        variant: 3,
        icon: medal_9
    },
    10: { // unimplemented
        name: 'Watch This!',
        desc: 'Get Impressions on an Advertised Video',
        tier: 3,
        type: 1,
        // variant: 3,
        icon: medal_10
    },
    11: { // unimplemented
        name: 'Brand Ally',
        desc: 'Complete a Fiable Advertisement',
        tier: 4,
        type: 0,
        variant: 3,
        icon: medal_11
    },
    12: { // unimplemented
        name: 'Paid in Full',
        desc: 'Withdraw Earnings from Fiable',
        tier: 4,
        type: 1,
        // variant: bronze for < $50, silver < $100
        icon: medal_12
    },
    13: { // unimplemented
        name: 'Valued Customers',
        desc: 'Receive Click-Through Metrics',
        tier: 4,
        type: 0,
        // variant: bronze for < 1%, silver for < 2.5%
        icon: medal_13
    },
    14: { // unimplemented
        name: 'Ahoy, Captain!',
        desc: 'Become a Creator Captain',
        tier: 5,
        type: 1,
        variant: 3,
        icon: medal_14
    },
    15: { // unimplemented
        name: 'Network Effect',
        desc: 'Earn Money from an Ad Referral',
        tier: 5,
        type: 1,
        // variant: bronze for < $20, silver < $50
        icon: medal_15
    },
}

export const variantList = ['gray', 'bronze', 'silver', 'gold']

const Medal = forwardRef((props, ref) => {
    const {userData} = useAuth()

    const [medal, setMedal] = useState({})

    useEffect(() => {
        // if rendering a medal associated with another user
        if (props.medalData) {
            setMedal({...medals[props.medalId], ...props.medalData})
        }
        else {
            // merge static medal data with user data
            if (userData.social && userData.social.medals) {
                if (userData.social.medals[props.medalId])
                    setMedal({...medals[props.medalId], ...userData.social.medals[props.medalId]})
                else
                    setMedal({...medals[props.medalId], variant: 0})
            }
        }
    }, [props, userData.social, setMedal])

    return (
        <div
            ref={ref}
            className={'d-flex justify-content-center align-items-center rounded-circle medal medal-' + (props.reveal ? 'gray' : variantList[medal.variant])}
            style={{width: props.size + 'px', height: props.size + 'px', position: 'absolute', zIndex: 1250}}
        >
            <div className={'rounded-circle position-absolute medal medal-' + (props.reveal ? 'gray' : variantList[medal.variant])} style={{width:'95%', height: '95%', transform: 'rotate(-1deg)', borderWidth: '2px'}}/>
            <div className={'rounded-circle position-absolute medal medal-' + (props.reveal ? 'gray' : variantList[medal.variant])} style={{width:'82%', height: '82%', transform: 'rotate(-1deg)', borderWidth: '2px'}}/>
            <div className='my-text-dark' style={{zIndex: 'inherit'}}>
                <Image src={medal.icon} width={Math.round(2 * props.size / 5)} height={Math.round(2 * props.size / 5)} style={props.reveal || medal.dateAchieved ? {zIndex: 'inherit'} : {filter: (medal.tier > userData.social.tier ? 'blur(3px) ' : '') + 'invert(100%) grayscale(100%) contrast(100%) brightness(0%)'}} />
            </div>
        </div>
    )
})

export default Medal