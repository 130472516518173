import React, { useEffect, useState } from 'react'
import { Card } from 'react-bootstrap'
import { useAuth } from '../../../../contexts/authContext'
import { useTheme } from '../../../../contexts/themeContext'
import VideoDataCardBody from './videoDataCardBody'

const BestVideoCard = (props) => {
    const { userData } = useAuth()
    const {getCardStyle} = useTheme()

    const [video, setVideo] = useState()

    useEffect(() => {
        if (props.selected)
            setVideo(userData.channels.find(channel => channel.platform === props.selected.split(':')[0] && channel.handle === props.selected.split(':')[1]).bestVideo || null)
    }, [userData.channels, props.selected, setVideo])

    return (video &&
        <Card className={getCardStyle()} style={{height: '100%'}}>
            <Card.Header className='py-3'>
                <p className='m-0 fw-bold'>Best Video</p>
            </Card.Header>
            {props.selected ?
                <VideoDataCardBody data={video} />
            :
                <Card.Body>
                    <h5 className='text-center p-5'>Select a Channel from the left to view details</h5>
                </Card.Body>
            }
        </Card>
    )
}

export default BestVideoCard;