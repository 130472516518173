import React, { useState, useRef, useEffect } from 'react'
import { Container, Row, Col, Spinner, Form, Image } from 'react-bootstrap'

import Defaultlogo from '../../../../../assets/img/brands/top-brand.png'
import { useAuth } from '../../../../../contexts/authContext'

const LogoSection = ({ formData, setFormData, setLogoImage }) => {
    const { currentUser } = useAuth()
    const [loadingImage, setLoadingImage] = useState(false)
    const [currLogo, setCurrLogo] = useState()

    const logoInputRef = useRef(null)
    const handleLogoChange = (e) => {
        setLoadingImage(true)
        const file = e.target.files[0]
        if (file) {
            const reader = new FileReader()
            reader.onload = () => {
                setLogoImage(reader.result)
                setCurrLogo(reader.result)
                setFormData(prev => ({...prev, logoFile: file}))
            }
            reader.readAsDataURL(file)
        } else {
            setLogoImage(null)
            setCurrLogo(null)
            setFormData(prev => ({...prev, logoFile: null}))
        }
        setLoadingImage(false)
    }

    useEffect(() => {
        if (formData.logoImage) setCurrLogo(formData.logoImage)
        else if (!formData.logoFile) {
            setCurrLogo(Defaultlogo)
            logoInputRef.current.value = ''
        }
    }, [formData.logoImage, formData.logoFile, setCurrLogo])

    return (
        <Container fluid className='px-5'>
            <Row>
                <Col lg={1} className='d-flex flex-column justify-content-center align-items-center'>
                    { loadingImage ? <Spinner className='my-5' animation='border'/> : 
                    <div className='d-flex flex-row align-items-center' style={{height: '85px', width: '85px'}}>
                        <Image className='mt-3' src={currLogo || currentUser.photoURL || Defaultlogo} width={60} height={60}/>
                    </div>
                    }
                </Col>
                <Col lg={4} className='d-flex flex-column justify-content-center'>
                    <Form.Group className='text-start'>
                        <Form.Label className='ps-3 mb-0'>Logo Image File</Form.Label>
                        <Form.Control 
                            ref={logoInputRef}
                            name='logoImage'
                            type='file'
                            accept='image/*'
                            onChange={handleLogoChange}
                        />
                    </Form.Group>
                </Col>
                <Col className='d-flex flex-column justify-content-center'>
                    <Form.Group>
                        <Form.Label className='ps-3 mb-0'>Preferred Corner for logo (not guaranteed)</Form.Label>
                        <Form.Select
                            name='logoCorner'
                            value={formData.logoCorner || 'Top-Left'}
                            onChange={(e) => setFormData(prev => ({...prev, logoCorner: e.target.value}))}
                        >
                            <option value='Top-Left'>Top-Left</option>
                            <option value='Top-Right'>Top-Right</option>
                            <option value='Bottom-Left'>Bottom-Left</option>
                            <option value='Bottom-Right'>Bottom-Right</option>
                        </Form.Select>
                    </Form.Group>
                </Col>
            </Row>
        </Container>
    )
}

export default LogoSection