import React, { useState } from 'react'
import { Button, Col, Row } from 'react-bootstrap'
import { ChevronDoubleDown, ChevronDoubleUp } from 'react-bootstrap-icons'

export const keyStats = [
    {
        name: 'Tier',
        dataName: 'tier',
        description: 'Current social tier'
    },
    {
        name: 'Medals',
        dataName: 'medals',
        description: 'Number of medals achieved'
    },
    {
        name: 'Tokens',
        dataName: 'tokens',
        description: 'Number of tokens earned, not including spent tokens'
    },
    {
        name: 'Score',
        dataName: 'score',
        description: 'Number of tokens earned, including spent tokens'
    },
]

const KeyStatsView = ({socialData}) => {

    const [show, setShow] = useState(true)

    return (
        <div>
            <Row className='position-relative mx-4 mb-2 px-2 border-bottom border-start border-end border-2 rounded-bottom my-bg-secondary-light' style={{'--bs-bg-opacity': 0.6, top: (show ? '0' : '-86px'), zIndex: 1, transition: 'top 0.5s ease'}}>
                {keyStats.map(stat => (
                    <Col key={stat.name} className='d-flex justify-content-center'>
                        <KeyStatView value={stat.name === 'Medals' ? Object.keys(socialData.medals).length : socialData[stat.dataName]} name={stat.name} image={stat.image} />
                    </Col>
                ))}
                <Button onClick={() => setShow(!show)} className='border-0 bg-transparent m-0 p-0'>
                    {show ? <ChevronDoubleUp /> : <ChevronDoubleDown />}
                </Button>
            </Row>
        </div>
    )
}

export const SimpleStatView = ({socialData}) => {
    return (
        <Row className='d-flex flex-nowrap' style={{transform: 'scale(50%)'}}>
            {keyStats.map(stat => (
                <Col key={stat.name} className='d-flex justify-content-center'>
                    <KeyStatView value={stat.name === 'Medals' ? Object.keys(socialData.medals).length : socialData[stat.dataName]} name={stat.name} image={stat.image} />
                </Col>
            ))}
        </Row>
    )
}

const KeyStatView = ({value, name, image=null}) => {
    const size = '70px'

    return (
        <div className='mt-2 mx-2 rounded border border-3 border-dark' style={{transition: 'all 0.5s', ...(image ? {backgroundImage: `url("${image}")`, backgroundSize: size + ' ' + size} : {})}}>
            <div className='d-flex flex-column justify-content-center text-center rounded bg-transition my-bg-primary-light' style={{minWidth: size, height: size, '--bs-bg-opacity': (image ? 0.6 : 1), borderRadius: '13px'}}>
                <h6 className='mb-0 pt-1' style={{fontSize: '12px', fontStyle: 'italic'}}>{name}</h6>
                <h1 className='fw-bold '>{value}</h1>
            </div>
        </div>
    )
}

export default KeyStatsView