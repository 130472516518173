import React from 'react'
import { useTheme } from '../../../contexts/themeContext'
import { Container } from 'react-bootstrap'

const AdvertiserAgreement = () => {    
    return (
        <Container fluid classNameName='p-5'>
            {/* <object data={Agreement} width='100%' height='100%'>Fiable Advertiser Agreement</object> */}
            <AdvertiserAgreementHTML />
        </Container>
    )
}

export const AdvertiserAgreementHTML = () => {
    const {getTextInv} = useTheme()

    return (
        <div classNameName={'text-transition ' + getTextInv()}>
            <h4 className='fw-bold'>Fiable Advertiser Agreement</h4>
            <h6>Last Updated 08/28/2024</h6>
            
            <h4 className='fw-bold'>Parties</h4>
            <h6>Fiable: This Agreement is entered into by and between Fiable LLC, a Florida Limited Liability Company with its principal place of business located at 3131 NE 7th Ave Unit 2701, Miami, FL 33137, herein referred to as "Fiable," "we," "us," or "our."</h6>
            <h6>Advertiser: The other party to this Agreement is the advertiser, herein referred to as "Advertiser" or "you," who engages with the Fiable platform to utilize the services provided. The Advertiser agrees to the terms of this Agreement upon clicking the "I Agree" button during the registration or ad campaign initiation process on the Fiable platform. The Advertiser represents and warrants that they are lawfully able to enter into contracts and are the legitimate holder of any accounts used to receive services or compensation from either Fiable or our Advertising partners.</h6>
            <h6>Acceptance: By clicking "I Agree," you, the Advertiser, acknowledge that you have read, understood, and agree to be bound by the terms of this Agreement, including any documents incorporated herein by reference. You further agree that this click constitutes your electronic signature and expresses your intent to be bound by the terms of this Agreement as if you had physically signed a paper version of this Agreement.</h6>
            
            <h4 className='fw-bold'>Background</h4>
            <h6>Fiable, a leading advertising brokerage firm specializing in connecting advertisers with content creators, enters into this Agreement with the Advertiser, referred to as “The Advertiser,” to facilitate the seamless integration of advertisements into the Advertiser’s content. Fiable aims to provide advertisers with effective avenues for promoting their products or services through strategic partnerships with The Advertiser across various digital platforms. Through this agreement, Fiable seeks to leverage the unique creative talents and engaged audiences of the Advertiser to deliver impactful advertising campaigns that resonate with viewers.</h6>
            <h6>The Advertiser, renowned for their captivating storytelling, extensive reach, and loyal fan base, possesses the ability to create compelling and authentic content that aligns with the interests and preferences of their audience. By collaborating with Fiable, the Advertiser can monetize their content while maintaining the trust and engagement of their audience.</h6>
            <h6>This agreement establishes the framework for the partnership between Fiable and the Advertiser, outlining the roles, responsibilities, and expectations of each party. By working together, Fiable and the Advertiser aim to deliver successful advertising campaigns that drive brand awareness, engagement, and ultimately results for the advertisers.</h6>
            
            <h4 className='fw-bold'>Scope of Work</h4>
            <h6>The inclusion of a Link Placement, Badge Placement, Advertiser Shoutout, Read Advertisement, or Clip Advertisement will be specified in the campaign agreement between Fiable, the Advertiser, and the Creator. If an Advertiser requests any of the following advertising styles, it will be an integral part of the Creator’s responsibilities for that campaign. Note, advertising styles are subject to change.</h6>
            
            <h5 className='fw-bold'>Link Placement</h5>
            <h6>Link Integration: The Creator agrees to include provided advertiser links in the description section of their video content. These links should be prominently placed above the fold to ensure easy access for viewers.</h6>
            <h6>Frequency: The Creator will include the advertiser’s links in all relevant video descriptions during the campaign period specified by Fiable.</h6>
            
            <h5 className='fw-bold'>Badge Placement</h5>
            <h6>Logo Specifications: Fiable will provide the Creator with the Advertiser’s logo in the appropriate format, size, and resolution for incorporation into the video content. The Creator shall adhere to the specifications provided by Fiable for the placement and display of the Advertisement.</h6>
            <h6>Logo Positioning: The Creator shall position the Advertisement within the video frame as directed by Fiable, ensuring that it remains visible and prominently displayed for the minimum agreed duration of the video as specified in the Advertisement details on the Fiable platform.</h6>
            <h6>Logo Size and Proportion: The Creator shall ensure that the size and proportion of the Advertisement are maintained in accordance with the specifications provided by Fiable, without distorting, resizing, or altering the logo’s dimensions in any way. The Creator shall incorporate the Advertisement into the video content in a manner that occupies a percentage area of the video frame as specified in the agreement between the Advertiser and Fiable.</h6>
            
            <h5 className='fw-bold'>Advertiser Shoutout</h5>
            <h6>Script Provision: The Advertiser will provide a script for the shoutout. This script will be concise, typically consisting of a short sentence such as “Special thanks to our sponsor [Advertiser’s Name]; go check out their website/product/platform at [URL].”</h6>
            <h6>Length and Content: The length of the shoutout will also be specified by the Advertiser, ensuring it is brief and to the point. The typical format will be a single sentence that acknowledges the sponsor and directs viewers to their website, product, or platform.</h6>
            <h6>Delivery: The Creator will deliver the shoutout in their natural style to maintain authenticity and viewer engagement. The Creator should aim to seamlessly integrate the shoutout into their content, ensuring it feels natural and unobtrusive.</h6>
            
            <h5 className='fw-bold'>Read Advertisement</h5>
            <h6>Length and Content: The read advertisement will typically be longer than a shoutout, ranging from 30 to 60 seconds. The exact length and content will be specified by the advertiser.</h6>
            <h6>Delivery: The Creator is encouraged to deliver the ad read into their content at a juncture that ensures a smooth transition and does not disrupt the viewer experience. Ideal points for integration might include natural breaks in the content, such as transitions between segments or topics.</h6>
            
            <h5 className='fw-bold'>Clip Advertisement</h5>
            <h6>Content Provision: The advertiser will provide the video clip to be used in the advertisement. This clip will include any visual and audio elements required by the advertiser, including an ad read if specified.</h6>
            <h6>Seamless Integration: The Creator will integrate the clip advertisement into their video content at a strategic point that ensures a smooth transition and does not disrupt the viewer experience. Preferred placements include natural breaks, transitions between segments, or at the beginning or end of the video.</h6>
            
            <h4 className='fw-bold'>Payment Schedule</h4>
            <h6>Any payments due for services provided under this agreement and specific campaign agreements will be processed monthly. Payments will be issued by the 15th of the month following the month in which the campaign was completed. A detailed statement of earnings, including commissions deducted, will accompany each payment.</h6>
            <h6>Additional Expenses: Fiable will not reimburse any additional expenses incurred by the Creator unless explicitly agreed upon in writing prior to the expenditure. Any agreed-upon expenses must be documented and submitted for reimbursement within 30 days of the expenditure.</h6>
            <h6>Royalties or Residuals: The Creator is not entitled to any royalties or residuals unless explicitly stated in the specific campaign agreement.</h6>
            <h6>Revenue Sharing: Revenue sharing models, if applicable, will be outlined in the specific campaign agreement. Any changes to the revenue sharing terms will be communicated and agreed upon prior to the start of a new campaign.</h6>
            <h6>Advance Payments: Fiable does not provide advance payments for campaign participation. Compensation will be issued only after the completion of the campaign and the receipt of payment from the Advertiser.</h6>
            <h6>Late Payment Terms: If Fiable fails to issue payment by the 15th of the month following the campaign’s completion, the Creator may notify Fiable in writing. Fiable will address the delay and ensure payment is made promptly. Past due payments beyond 30 days from the due date may incur interest at a rate of 1.5% per month.</h6>
            <h6>Payment Method: Payments will be made via bank transfer from Fiable’s business bank account to the Creator’s designated bank account. The Creator must provide accurate bank account details and notify Fiable of any changes promptly.</h6>
            <h6>Tax Considerations: Fiable will issue IRS Form 1099-NEC or 1099-K to any Creator receiving over $600 in a calendar year as required by U.S. tax law. The Creator is responsible for providing a completed IRS Form W-9 to Fiable to facilitate this process. The Creator is responsible for any taxes due on the payments received.</h6>
            <h6>Payment Disputes: Any disputes regarding payment must be submitted in writing to Fiable within 30 days of receiving the payment. Fiable will review and address the dispute within 30 days of receipt. If a resolution is not reached, the dispute will follow the dispute resolution procedures outlined in Section 15 Governing Law and Dispute Resolution.</h6>
            
            <h4 className='fw-bold'>Creative Control and Approval</h4>
            <h6>Content Review: Fiable retains the right to review and approve the Creator’s content before it is published to ensure compliance with the campaign guidelines and the advertiser’s requirements. This review process will be specified in the campaign agreement.</h6>
            <h6>Revisions and Feedback: If the content does not meet the advertiser’s requirements or Fiable’s quality standards, the Creator will be provided with feedback and requested revisions. The Creator agrees to make the necessary changes to meet the specified requirements.</h6>
            <h6>Approval Timeline: The approval process will include specific timelines for content submission, review, and revisions to ensure timely publication of the content. The Creator will adhere to these timelines as specified in the campaign agreement.</h6>
            <h6>Content Ownership: The Creator retains ownership of their content. However, the advertiser may request limited usage rights for promotional purposes, such as sharing the content on their social media platforms or website. Any such usage rights will be agreed upon in writing.</h6>
            
            <h4 className='fw-bold'>Confidentiality</h4>
            <h6>Confidential Information: Both parties acknowledge that during the course of this Agreement, they may have access to confidential information, including but not limited to trade secrets, business plans, strategies, financial information, customer data, and proprietary technology (“Confidential Information”). Each party agrees to maintain the confidentiality of the other party’s Confidential Information and to use it solely for the purposes of this Agreement.</h6>
            <h6>Non-Disclosure: Neither party shall disclose any Confidential Information to any third party without the prior written consent of the other party, except as required by law or as necessary to fulfill the obligations of this Agreement.</h6>
            <h6>Return or Destruction: Upon termination of this Agreement, each party agrees to return or destroy any Confidential Information belonging to the other party, including any copies or derivatives thereof, within thirty (30) days of termination.</h6>
            
            <h4 className='fw-bold'>Term and Termination</h4>
            <h6>Term: This Agreement shall commence on the Effective Date and shall remain in effect until terminated by either party in accordance with the terms of this Agreement.</h6>
            <h6>Termination for Convenience: Either party may terminate this Agreement for convenience by providing thirty (30) days written notice to the other party.</h6>
            <h6>Termination for Cause: Either party may terminate this Agreement immediately upon written notice if the other party breaches any material term or condition of this Agreement and fails to cure such breach within ten (10) days after receiving written notice of the breach.</h6>
            <h6>Effect of Termination: Upon termination of this Agreement, all rights and obligations of the parties shall cease, except for those rights and obligations that by their nature or express terms survive termination, including but not limited to payment obligations, confidentiality, and indemnification.</h6>
            
            <h4 className='fw-bold'>Indemnification</h4>
            <h6>Advertiser’s Indemnity: The Advertiser agrees to indemnify, defend, and hold harmless Fiable, its officers, directors, employees, agents, and affiliates from and against any and all claims, liabilities, damages, losses, and expenses, including reasonable attorneys’ fees and costs, arising out of or in connection with (i) the Advertiser’s breach of this Agreement; (ii) any content or materials provided by the Advertiser; or (iii) the Advertiser’s use of the Fiable platform.</h6>
            <h6>Fiable’s Indemnity: Fiable agrees to indemnify, defend, and hold harmless the Advertiser, its officers, directors, employees, agents, and affiliates from and against any and all claims, liabilities, damages, losses, and expenses, including reasonable attorneys’ fees and costs, arising out of or in connection with (i) Fiable’s breach of this Agreement; or (ii) any content or materials provided by Fiable.</h6>
            <h6>Indemnification Procedure: The indemnified party shall promptly notify the indemnifying party of any claim for which indemnification is sought. The indemnifying party shall have the right to control the defense and settlement of such claim, provided that the indemnified party shall have the right to participate in the defense at its own expense. The indemnified party shall cooperate with the indemnifying party in the defense of any such claim.</h6>
            
            <h4 className='fw-bold'>Limitation of Liability</h4>
            <h6>Exclusion of Damages: In no event shall either party be liable to the other party for any indirect, incidental, consequential, special, or punitive damages, including but not limited to lost profits, loss of business, or loss of data, arising out of or in connection with this Agreement, even if such party has been advised of the possibility of such damages.</h6>
            <h6>Limitation of Liability: Each party’s total liability to the other party for any and all claims arising out of or in connection with this Agreement shall not exceed the total amount paid or payable by the Advertiser to Fiable under this Agreement during the twelve (12) months preceding the date of the claim.</h6>
            
            <h4 className='fw-bold'>Governing Law and Dispute Resolution</h4>
            <h6>Governing Law: This Agreement shall be governed by and construed in accordance with the laws of the State of Florida, without regard to its conflict of law principles.</h6>
            <h6>Dispute Resolution: Any disputes arising out of or in connection with this Agreement shall be resolved through good faith negotiations between the parties. If the parties are unable to resolve the dispute through negotiations, the dispute shall be resolved by binding arbitration in accordance with the rules of the American Arbitration Association. The arbitration shall take place in Miami, Florida, and the arbitration award may be entered in any court having jurisdiction thereof.</h6>
            <h6>Injunctive Relief: Notwithstanding the foregoing, either party may seek injunctive or other equitable relief in any court of competent jurisdiction to protect its intellectual property rights or Confidential Information.</h6>
            
            <h4 className='fw-bold'>Miscellaneous</h4>
            <h6>Entire Agreement: This Agreement, including any documents incorporated herein by reference, constitutes the entire agreement between the parties with respect to the subject matter hereof and supersedes all prior and contemporaneous understandings, agreements, representations, and warranties, both written and oral, with respect to such subject matter.</h6>
            <h6>Amendments: No amendment or modification of this Agreement shall be valid unless made in writing and signed by both parties.</h6>
            <h6>Waiver: No waiver of any term or condition of this Agreement shall be valid unless made in writing and signed by the party against whom the waiver is to be enforced. No waiver of any term or condition of this Agreement shall be construed as a waiver of any subsequent breach or failure of the same term or condition or as a waiver of any other term or condition of this Agreement.</h6>
            <h6>Severability: If any provision of this Agreement is held to be invalid or unenforceable, such provision shall be severed from this Agreement and the remaining provisions shall remain in full force and effect.</h6>
            <h6>Assignment: Neither party may assign or transfer this Agreement or any of its rights or obligations hereunder without the prior written consent of the other party, except that either party may assign this Agreement in connection with a merger, acquisition, or sale of all or substantially all of its assets.</h6>
            <h6>Force Majeure: Neither party shall be liable for any delay or failure to perform its obligations under this Agreement due to circumstances beyond its reasonable control, including but not limited to acts of God, war, terrorism, labor disputes, and governmental actions.</h6>
            <h6>Notices: All notices required or permitted under this Agreement shall be in writing and shall be deemed given when delivered in person, sent by email, or three (3) business days after being sent by certified or registered mail, return receipt requested, to the addresses set forth in the campaign agreement or such other addresses as either party may specify in writing.</h6>
        </div>
    )
}

export default AdvertiserAgreement