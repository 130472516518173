import React, { useCallback, useEffect, useState } from 'react'
import { Row, Col, Card } from 'react-bootstrap'
import { useAuth } from '../../../../contexts/authContext'
import { useTheme } from '../../../../contexts/themeContext'
import { BadgeAd, BadgeAdFill, Plus } from 'react-bootstrap-icons'
import { Timestamp } from 'firebase/firestore'

const AdCards = (props) => {
    const { userData, type } = useAuth()

    const [adCounts, setAdCounts] = useState({ 'active': 0, 'completed': 0, 'potential': 0 })

    const getAdData = useCallback(() => {
        const now = Timestamp.now()
        const potentialAds = type && userData.potentialAds ? userData.potentialAds.length : Object.values(userData.history || {}).filter(adData => adData.isPotential).length

        let activeAds = 0, completedAds = 0
        for ( const adData of Object.values(userData.history || {}) ) {
            if ( adData.isActive && now <= adData.endDate) {
                activeAds += 1
            } else if ( now > adData.endDate ) {
                completedAds += 1
            }
        }
        setAdCounts({ 'active': activeAds, 'completed': completedAds, 'potential': potentialAds })
    }, [type, userData.history, userData.potentialAds])

    useEffect(() => {
        if (!props.isDemo) {
            getAdData()
        }
    }, [getAdData, props.isDemo])

    return (
        <Row className='gx-2 mb-3'>
            <Col>
                <AdCard icon={<BadgeAdFill />} name='Active Ads' value={props.isDemo ? '3' : adCounts['active']} />
            </Col>
            <Col>
                <AdCard icon={<BadgeAd />} name='Completed Ads' value={props.isDemo ? 7 : adCounts['completed']} />
            </Col>
            <Col>
                <AdCard icon={<Plus />} name={(type ? 'Potential' : 'Saved') + ' Ads'} value={props.isDemo ? 4 : adCounts['potential']} />
            </Col>
        </Row>
    )
}

const AdCard = (props) => {
    const {getCardStyle, isSmallScreen} = useTheme()

    return (
        <Card className={getCardStyle()}>
            <Card.Body>
                <Row className='align-items-center flex-nowrap'>
                    <Col className='me-0' style={{flex: '2 2 75%'}}>
                        <div className='text-uppercase text-nowrap fw-bold mb-1 my-text-light'>
                            {props.name}
                        </div>
                        <h5 className='fw-bold mb-0 my-text-light'>
                            {props.value}
                        </h5>
                    </Col>
                    {isSmallScreen ? <></> :
                    <Col className='d-flex justify-content-end bs-icon bs-icon-lg my-text-light'>
                        {props.icon}
                    </Col>
                    }
                </Row>
            </Card.Body>
        </Card>
    )
}

export default AdCards;