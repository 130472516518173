import React, { useEffect, useState } from 'react'
import { Container, Row, Col } from 'react-bootstrap'
import { useTheme } from '../../../contexts/themeContext'
import { useAuth } from '../../../contexts/authContext'
import { Timestamp } from 'firebase/firestore'
import { useParams } from 'react-router-dom'
import PaginatedSection from './subcomponents/advertiser/paginatedSection'
import AdvertisementsCard from './subcomponents/advertisementsCard'
import AdvertisementDetailCard from './subcomponents/advertisementDetailCard'
import AdVisualizationsCard from './subcomponents/adVisualizationsCard'
import AdDeploymentDetails from './subcomponents/creator/adDeploymentDetailsCard'
import AdCards from './subcomponents/adCards'

const Advertisements = () => {
    const {type} = useAuth()

    return (
        <Container fluid className='p-2'>
            <AdCards />
            {type === 1 ? <CreatorView /> : <AdvertiserView />}
        </Container>
    )
}

const CreatorView = () => {
    const {userData} = useAuth()
    const {isSmallScreen} = useTheme()

    const params = useParams()
    const [selectedAd, setSelectedAd] = useState(params.adId)

    useEffect(() => {
        if (Object.keys(userData.history).length) setSelectedAd(Object.keys(userData.history)[0])
    }, [userData.history, setSelectedAd])

    return (
        <>
            <Row className='pb-3 gx-2'>
                <Col lg={4}><AdvertisementsCard selected={selectedAd} setSelected={setSelectedAd} /></Col>
                <Col lg={8}><AdvertisementDetailCard selected={selectedAd} /></Col>
            </Row>
            {!isSmallScreen && selectedAd && Object.keys(userData.history[selectedAd].progress).some(c1 => userData.channels.map(c2 => c2.platform + '-' + c2.handle).includes(c1)) ?
                <Row className='pb-3'>
                    <Col><AdVisualizationsCard selected={selectedAd} /></Col>
                </Row> 
            : <></>}
            {!isSmallScreen ? 
                <Row className='pb-3'>
                    <Col><AdDeploymentDetails selected={selectedAd} /></Col>
                </Row>
            : <></>}
        </>
    )
}

const AdvertiserView = () => {
    const {userData} = useAuth()

    return (
        <>
            <PaginatedSection
                adData={Object.entries(userData.history).filter(ad => ad[1].isActive && ad[1].startDate <= Timestamp.fromDate(new Date())).sort((a, b) => b[1].startDate - a[1].startDate).map(ad => ({[ad[0]]: ad[1]}))}
                title='Active'
                type={1}
            />
            <PaginatedSection
                adData={Object.entries(userData.history).filter(ad => !ad[1].isPotential && ad[1].startDate > Timestamp.fromDate(new Date())).sort((a, b) => b[1].startDate - a[1].startDate).map(ad => ({[ad[0]]: ad[1]}))}
                title='Scheduled'
                type={2}
            />
            <PaginatedSection
                adData={Object.entries(userData.history).filter(ad => ad[1].isPotential).sort((a, b) => b[1].startDate - a[1].startDate).map(ad => ({[ad[0]]: ad[1]}))}
                title='Saved'
                type={3}
            />
        </>
    )
}

export default Advertisements