import React, { useRef } from 'react'
import { Col, OverlayTrigger, Row, Tooltip } from 'react-bootstrap'
import { EyeFill, Link45deg, TagsFill } from 'react-bootstrap-icons'

import '../../achievements/subcomponents/medals/medals.css'

export const progressStats = [
    {
        name: 'Connections',
        dataName: 'connections',
        description: 'Number of social connections',
        icon: <Link45deg />
    },
    {
        name: 'Categories',
        dataName: 'buckets',
        description: 'Number of selected categories across all channels',
        icon: <TagsFill />
    },
    {
        name: 'Impressions',
        dataName: 'ppi',
        description: 'Average number of expected impressions across all channels',
        icon: <EyeFill />
    },
]

const ProgressStatsView = ({ socialData }) => {

    const getProgressStatAndVariant = (stat) => {
        let variant = 'gold'
        if (stat.dataName === 'connections') {
            const data = socialData.connections.length
            if (data < 50) variant = 'bronze'
            else if (data < 250) variant = 'silver'
            return {stat: data, variant: variant}
        }
        if (stat.dataName === 'buckets') {
            const data = socialData.buckets.length + socialData.subBuckets.length
            if (data < 10) variant = 'bronze'
            else if (data < 25) variant = 'silver'
            return {stat: data, variant: variant}
        } 
        if (stat.dataName === 'ppi') {
            const data = socialData.ppi
            if (data < 5000) variant = 'bronze'
            else if (data < 25000) variant = 'silver'
            return {stat: data, variant: variant}
        }
        else return 0
    }

    return (
        <Row className={'position-relative mx-2 pt-2 border border-2 rounded bg-transition my-bg-primary-light'} style={{zIndex: 2}}>
            {progressStats.map(stat => (
                <Col key={stat.name} className='d-flex justify-content-center'>
                        <ProgressStatView
                            stat={stat}
                            value={getProgressStatAndVariant(stat).stat}
                            variant={getProgressStatAndVariant(stat).variant}
                        />
                </Col>
            ))}
        </Row>
    )
}

const ProgressStatView = ({stat, value, variant}) => {
    const statRef = useRef()

    return (
        <div className='d-flex flex-column'>
                <div className={'d-flex justify-content-center align-items-center position-relative rounded-circle medal medal-' + variant} style={{width: '120px', height: '120px'}}>
                    <div className={'rounded-circle position-absolute medal medal-' + variant} style={{width:'95%', height: '95%', transform: 'rotate(-1deg)', borderWidth: '3px'}}/>
                    <div className={'rounded-circle position-absolute medal medal-' + variant} style={{width:'82%', height: '82%', transform: 'rotate(-1deg)', borderWidth: '3px'}}/>
                    <div style={{transform: 'scale(400%)', paddingBottom: '2px'}}>
                        {stat.icon}
                    </div>
                </div>
            <OverlayTrigger placement='bottom' overlay={<Tooltip id={'tooltip-' + stat.name} style={{fontSize: '12px'}}>{stat.description}</Tooltip>}>
                <div ref={statRef} className='text-center'>
                    <h3 className='fw-bold mb-0'>{value}</h3>
                    <h6 style={{fontSize: '14px', fontStyle: 'italic'}}>{stat.name}</h6>
                </div>
            </OverlayTrigger>
        </div>
    )
}

export default ProgressStatsView