import React from 'react'
import { useAuth } from '../../../../../contexts/authContext'
import AdTable from '../adTable'

const UserContentTable = () => {
    const { userData } = useAuth()
    const cols = ['Platform', 'Handle', 'Posted Date', 'Views', 'Likes', 'Comments', 'Type']
    const data = userData.channels.flatMap(channel => channel.lastNVideos.flatMap(vid => ({
        platform: channel.platform,
        handle: channel.handle,
        postDate: vid.publishedAt.toDate().toLocaleDateString(),
        views: Number(vid.viewCount).toLocaleString(),
        likes: vid.likeCount !== null ? Number(vid.likeCount).toLocaleString() : 'Unavailable',
        comments: vid.commentCount !== null ? Number(vid.commentCount).toLocaleString() : 'Unavailable',
        type: vid.type.split('_').join(' ')
    })))

    return (userData.channels &&
        <AdTable title='Recent Videos/Streams' tableCols={cols} data={data} bgTheme='primary' />
    )
}

export default UserContentTable