import React, { useState } from 'react'
import { Container, Row, Col } from 'react-bootstrap'
import ChannelBucketsCard from './subcomponents/channelBucketsCard'
import PotentialAdsCard from '../profile/subcomponents/creator/potentialAdsCard'
import ChannelsCard from './subcomponents/channelsCard'
import ChannelSnippetCard from './subcomponents/channelSnippetCard'
import ChannelDetailCard from './subcomponents/channelDetailCard'
import MostRecentVideoCard from './subcomponents/mostRecentVideoCard'
import AdVideosCard from './subcomponents/adVideosCard'
import BestVideoCard from './subcomponents/bestVideoCard'
import { useTheme } from '../../../contexts/themeContext'

const Channel = () => {
    const { isSmallScreen } = useTheme()

    const [selected, setSelected] = useState()

    return (
        <Container fluid className='p-2'>
            <Row>
                <Col><PotentialAdsCard /></Col>
            </Row>
            <Row className='mb-3 gx-2'>
                <Col lg={4}><ChannelsCard selected={selected} setSelected={setSelected} /></Col>
                <Col lg={8}><ChannelSnippetCard selected={selected} /></Col>
            </Row>
            <Row className='mb-3'>
                <Col><ChannelDetailCard selected={selected} /></Col>
            </Row>
            {isSmallScreen ? <></> : 
            <Row className={selected && 'mb-3'}>
                <Col><AdVideosCard selected={selected} /></Col>
            </Row>}
            {isSmallScreen ? <></> : 
            <Row>
                <Col lg={12} className={selected && 'mb-3'}><MostRecentVideoCard selected={selected} /></Col>
                <Col lg={12} className={selected && 'mb-3'}><BestVideoCard selected={selected} /></Col>
            </Row>}
            <Row>
                <Col><ChannelBucketsCard /></Col>
            </Row>
        </Container>
    )
}

export default Channel