import React from 'react'
import { useAuth } from '../../../contexts/authContext'
import { useTheme } from '../../../contexts/themeContext'
import MedalsDisplay from './subcomponents/medalsDisplay'
import { Col, Container, Row } from 'react-bootstrap'
import TokenShop from './subcomponents/tokenShop'
import PotentialAdsCard from '../profile/subcomponents/creator/potentialAdsCard'
import Leaderboard from './subcomponents/leaderboard'
import TokenExchange from './subcomponents/tokenExchange'

const Achievements = () => {
    const {userData} = useAuth()
    const {screenWidth} = useTheme()

    return (userData && userData.social && screenWidth < 1200 ? <Container fluid className='p-2'><MedalOnlyView /></Container> : 
        <Container fluid className='p-2'>
            <Row>
                <Col><PotentialAdsCard /></Col>
            </Row>
            <Row className='pb-3 gx-2'>
                <Col><MedalsDisplay /></Col>
                {screenWidth < 1200 ? <></> : <Col lg={3}><TokenExchange /></Col>}
            </Row>
            <Row className='pb-3'>
                <Col><TokenShop /></Col>
            </Row>
            <Row className='pb-3'>
                <Col><Leaderboard /></Col>
            </Row>
        </Container>
    )
}

const MedalOnlyView = () => {
    return (
        <MedalsDisplay />
    )
}

export default Achievements