import React, { useEffect, useState } from 'react'
import { useAuth } from '../../../../../contexts/authContext'
import AdTable from '../adTable'
import { useTheme } from '../../../../../contexts/themeContext'

const AdRecordsTable = () => {
    const { userData } = useAuth()
    const {screenWidth} = useTheme()

    const cols = ['Advertiser Name', 'Advertisement Name', 'Start Date', 'For Channel(s)', 'Required Imps.'].concat((screenWidth < 1200 ? [] : ['Current Imps', 'Status']))
    const [data, setData] = useState() 

    useEffect(() => {
        let tableData = Object.values(userData.history).map( ad => ({
            advertiserName: ad.advertiserName,
            advertisementName: ad.advertisementName,
            startDate: ad.startDate.toDate().toLocaleDateString(),
            forChannels: ad.channels.map(channel => channel.split('-').slice(1).join('-')).join(', '),
            requiredImps: ad.quota,
            currentImps: ad.channels.reduce((sum, curr) => sum + (ad.progress[curr] ? ad.progress[curr].impressions.at(-1) : 0), 0),
            status: (ad.isActive ? 'Active' : 'Complete')
        }))
        if (screenWidth < 1200) {
            tableData = tableData.map(({status, currentImps, ...rest}) => rest)
        }
        setData(tableData)
    }, [userData.history, screenWidth])

    return (
        data && <AdTable title='Advertisement History' tableCols={cols} data={data} bgTheme='secondary' />
    )
}

export default AdRecordsTable