import React, { useState, useRef, useEffect } from 'react'
import { Container, Row, Form, Spinner, Col } from 'react-bootstrap'

const ClipSection = ({ formData, setFormData, setClipVideo }) => {
    const [loadingClip, setLoadingClip] = useState(false)
    const [currClip, setCurrClip] = useState(formData.clipVideo)

    const clipInputRef = useRef(null)
    const handleClipChange = (e) => {
        setLoadingClip(true)
        const file = e.target.files[0]
        if (file) {
            const videoURL = URL.createObjectURL(file)
            setClipVideo(videoURL)
            setCurrClip(videoURL)
            setFormData(prev => ({...prev, clipFile: file}))
        } else {
            setClipVideo(null)
            setCurrClip(null)
            setFormData(prev => ({...prev, clipFile: null}))
        }
        setLoadingClip(false)
    }

    useEffect(() => {
        if (formData.clipVideo) setCurrClip(formData.clipVideo)
        else if (!formData.clipFile) {
            setCurrClip(null)
            clipInputRef.current.value = ''
        }
    }, [formData.clipVideo, formData.clipFile, setCurrClip])

    return (
        <Container fluid className='px-5'>
            <Row>
                <Col>
                    <Form.Label className='ps-3 mb-0'>Video Clip (optional)</Form.Label>
                    <Form.Control
                        ref={clipInputRef}
                        name='clipVideo'
                        type='file'
                        accept='video/*'
                        onChange={handleClipChange}
                    />
                </Col>
                <Col lg={8}>
                    <Form.Label className='ps-3 mb-0'>Clip Description</Form.Label>
                    <Form.Control
                        name='clipText'
                        as='textarea'
                        rows={1}
                        placeholder='Describe the clip and how you would like a creator to present it'
                        value={formData.clipText || ''}
                        onChange={(e) => setFormData(prev => ({...prev, clipText: e.target.value}))}
                        className='form-control-md'
                    />
                </Col>
            </Row>
            <Row className='mb-2'>
                {loadingClip ? <Spinner className='my-5' animation='border'/> : ( currClip && 
                    <div className='d-flex flex-row align-items-center justify-content-center pb-2'>
                        <video className='rounded-1 mt-3' height={300} controls>
                            <source src={currClip} />
                            Your Browser does not support embedded videos
                        </video>
                    </div>
                )}
            </Row>
        </Container>
    )
}

export default ClipSection