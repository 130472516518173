import React from "react";
import { Row } from "react-bootstrap";
import { useTheme } from "../../../../../contexts/themeContext";

import './fiawords.css'

const Fiawords = () => {
    const {getBG, getTextInv, isSmallScreen } = useTheme()
    const bigWordsFontSize = isSmallScreen ? '2rem' : '5rem'
    const smallWordsFontSize = isSmallScreen ? '1.5rem' : '2rem'
    const hoverColor = [
        '#000000',
        '#ACEB98',
        '#9139D0',
        '#25A9D9',
        '#0FFF95',
        '#C08EEB',
        '#ED7B84',
        '#94D2BD',
        '#0A9396',
        '#005F73',
        '#F92A82'
    ]

    return (
        <div id='fiawordsSection' className={'position-relative overflow-y-visible pt-0 bg-transition ' + getBG()} style={{opacity: 1}}>
            <div className='overflow-hidden h-100 w-100 position-absolute top-0' style={{zIndex: '-1'}}>
                <div className='rounded-circle position-absolute ' style={{animation: 'breathe 60s infinite', height: '600px', width:'600px', top: '70%', left: '0%', backdropFilter: 'brightness(105%)', opacity: '1'}}/>
                <div className='rounded-circle position-absolute ' style={{animation: 'breathe 60s infinite', height: '900px', width:'900px', top: '20%', left: '70%', backdropFilter: 'brightness(102.5%)', opacity: '1'}}/>
                <div className='rounded-circle position-absolute ' style={{animation: 'breathe 60s infinite', height: '500px', width:'500px', top: '20%', left: '0%', backdropFilter: 'brightness(97.5%)', opacity: '1'}}/>
                <div className='rounded-circle position-absolute ' style={{animation: 'breathe 60s infinite', height: '300px', width:'300px', top: '40%', left: '50%', backdropFilter: 'brightness(101.15%)', opacity: '1'}}/>
                <div className='rounded-circle position-absolute ' style={{animation: 'breathe 60s infinite', height: '400px', width:'400px', top: '50%', left: '0%', backdropFilter: 'brightness(99%)', opacity: '1'}}/>
                <div className='rounded-circle position-absolute ' style={{animation: 'breathe 60s infinite', height: '650px', width:'650px', top: '55%', left: '25%', backdropFilter: 'brightness(101%)', opacity: '1'}}/>
                <div className='rounded-circle position-absolute ' style={{animation: 'breathe 60s infinite', height: '900px', width:'900px', top: '60%', left: '60%', backdropFilter: 'brightness(98%)', opacity: '1'}}/>
                <div className='rounded-circle position-absolute ' style={{animation: 'breathe 60s infinite', height: '350px', width:'350px', top: '10%', left: '55%', backdropFilter: 'brightness(97.5%)', opacity: '1'}}/>
            </div>
            
            <Row className='d-flex flex-col text-center align-items-center overflow-x-visible'>
                <p className={'dela-gothic-one-regular fiawords overflow-visible ' + getTextInv()} style={{'--hover-color': isSmallScreen ? '#1C1C22' : hoverColor[1], fontSize: isSmallScreen ? '3rem' : '6rem', flex: '1 1 25%'}}>
                    FIABLE DOES
                </p>
            </Row>
            <Row className={'d-flex flex-col text-center align-items-center overflow-x-visible ' + getTextInv()}>
                <p className='dela-gothic-one-regular fiawords overflow-visible' style={{'--hover-color': hoverColor[2], fontSize: bigWordsFontSize, flex: '1 1 40%'}}>
                    REACH
                </p>
                <p className='fiawordsExtra arvo-bold text-no-wrap overflow-visible' style={{fontSize: smallWordsFontSize, flex: '1 1 100%'}}>
                    Access hundreds of devoted communities 
                </p>
            </Row>
            <Row className={'d-flex flex-col text-center align-items-center overflow-x-visible ' + getTextInv()}>
                <p className='dela-gothic-one-regular fiawords overflow-visible' style={{'--hover-color': hoverColor[3], fontSize: bigWordsFontSize, flex: '1 1 40%'}}>
                    CATEGORY-FIT
                </p>
                <p className='fiawordsExtra arvo-bold text-no-wrap overflow-visible' style={{'--hover-color': hoverColor[5], fontSize: smallWordsFontSize, flex: '1 1 100%'}}>
                    Granularity that hits all your categories
                </p>
            </Row>
            <Row className={'d-flex flex-col text-center align-items-center overflow-x-visible ' + getTextInv()}>
                <p className='dela-gothic-one-regular fiawords overflow-visible' style={{'--hover-color': hoverColor[4], fontSize: bigWordsFontSize, flex: '1 1 40%'}}>
                    LOWER COST
                </p>
                <p className='fiawordsExtra arvo-bold text-no-wrap overflow-visible' style={{'--hover-color': hoverColor[7], fontSize: smallWordsFontSize, flex: '1 1 100%'}}>
                    CPIs 50% the industry average 
                </p>
            </Row>
            <Row className={'d-flex flex-col text-center align-items-center overflow-x-visible ' + getTextInv()}>
                <p className='dela-gothic-one-regular fiawords overflow-visible' style={{'--hover-color': hoverColor[5], fontSize: bigWordsFontSize, flex: '1 1 40%'}}>
                    HIGHER ENGAGEMENT
                </p>
                <p className='fiawordsExtra arvo-bold text-no-wrap overflow-visible' style={{'--hover-color': hoverColor[9], fontSize: smallWordsFontSize, flex: '1 1 100%'}}>
                Passionate creators foster brand trust
                </p>
            </Row>
            <Row className={'d-flex flex-col text-center align-items-center overflow-x-visible ' + getTextInv()}>
                <p className='dela-gothic-one-regular fiawords overflow-visible' style={{'--hover-color': hoverColor[6], fontSize: bigWordsFontSize, flex: '1 1 40%'}}>
                    BRAND SAFETY
                </p>
                <p className='fiawordsExtra arvo-bold text-no-wrap overflow-visible' style={{'--hover-color': hoverColor[1], fontSize: smallWordsFontSize, flex: '1 1 100%'}}>
                    VeriFi (AI) Veraciously Verifies (and we double check!) 
                </p>
            </Row>
            <Row className={'d-flex flex-col text-center align-items-center overflow-x-visible ' + getTextInv()}>
                <p className='dela-gothic-one-regular fiawords overflow-visible' style={{'--hover-color': hoverColor[7], fontSize: bigWordsFontSize, flex: '1 1 40%'}}>
                    EASY ADVERTISING
                </p>
                <p className='fiawordsExtra arvo-bold text-no-wrap overflow-visible' style={{'--hover-color': hoverColor[1], fontSize: smallWordsFontSize, flex: '1 1 100%'}}>
                    Once your Ad is booked, we've got it from there!
                </p>
            </Row>
            <Row className={'d-flex flex-col text-center align-items-center overflow-x-visible ' + getTextInv()}>
                <p className='dela-gothic-one-regular fiawords overflow-visible' style={{'--hover-color': hoverColor[8], fontSize: bigWordsFontSize, flex: '1 1 40%'}}>
                    MEDIA DELIVERY
                </p>
                <p className='fiawordsExtra arvo-bold text-no-wrap overflow-visible' style={{'--hover-color': hoverColor[4], fontSize: smallWordsFontSize, flex: '1 1 100%'}}>
                    Upload your marketing kit once, distributed to your creators automatically
                </p>
            </Row>
            <Row className={'d-flex flex-col text-center align-items-center overflow-x-visible ' + getTextInv()}>
                <p className='dela-gothic-one-regular fiawords overflow-visible' style={{'--hover-color': hoverColor[9], fontSize: bigWordsFontSize, flex: '1 1 40%'}}>
                    PAYMENT
                </p>
                <p className='fiawordsExtra arvo-bold text-no-wrap overflow-visible' style={{'--hover-color': hoverColor[6], fontSize: smallWordsFontSize, flex: '1 1 100%'}}>
                    One payment, 1000s of creators, try for as little as $100
                </p>
            </Row>
            <Row className={'d-flex flex-col text-center align-items-center overflow-x-visible ' + getTextInv()}>
                <p className='dela-gothic-one-regular fiawords overflow-visible' style={{'--hover-color': hoverColor[10], fontSize: bigWordsFontSize, flex: '1 1 40%'}}>
                    MODERATION
                </p>
                <p className='fiawordsExtra arvo-bold text-no-wrap overflow-visible' style={{'--hover-color': hoverColor[8], fontSize: smallWordsFontSize, flex: '1 1 100%'}}>
                    We're there every step of the way
                </p>
            </Row>
        </div>
    )
}

export default Fiawords