import React, { useEffect, useState, useCallback } from 'react'
import PageHeader from '../../common/pageHeader'
import { useAuth } from '../../../contexts/authContext'
import { Card, Container, Row, Image, Col, Collapse, Button, Form } from 'react-bootstrap'
import { Timestamp } from 'firebase/firestore'
import { useTheme } from '../../../contexts/themeContext'
import { Link } from 'react-router-dom'

const Blog = () => {
    const { currentUser, getBlogPosts, uploadBlogImage, addBlogPost, setError } = useAuth()
    const { getThemeBGPlatform, getThemeBG, getTextInv, darkMode } = useTheme()

    const [ blogPosts, setBlogPosts ] = useState([])
    const [ loading, setLoading ] = useState(false)
    const [ openAdd, setOpenAdd ] = useState(false)
    const [ blogImage, setBlogImage ] = useState()

    const addNewBlogPost = async (e) => {
        e.preventDefault()
        setLoading(true)
        try {
            const formData = [...(new FormData(e.target)).entries()]
            let newPostData = {}
            newPostData.title = formData[0][1]
            newPostData.text = formData[2][1]
            newPostData.posterUID = currentUser.uid
            newPostData.posterName = currentUser.displayName ? currentUser.displayName : 'Anonymous'
            newPostData.postDate = Timestamp.now()
            newPostData.comments = []
            if (formData[1][1].name !== '') {
                newPostData.photoURL = await uploadBlogImage(formData[1][1])
            }

            const id = await addBlogPost(newPostData).then( docRef => docRef.id )
            setBlogPosts(prevPosts => [...prevPosts, {...newPostData, id: id}])
        } catch (err) {
            setError('Failed to Add Blog Post')
        } finally {
            document.getElementById('newBlogPostForm').reset()
            setBlogImage(null)
            setOpenAdd(false)
            setLoading(false)
        }
    }

    const handleBlogImageChange = (e) => {
        const file = e.target.files[0]
        if (file) {
            const reader = new FileReader()
            reader.onload = () => {
                setBlogImage(reader.result)
            }
            reader.readAsDataURL(file)
        }
    }

    const fetchBlogPosts = useCallback(async () => {
        const blogData = await getBlogPosts()
        let blogPostsArr = []
        blogData.forEach((doc) => {
            let docData = doc.data()
            docData.id = doc.id
            blogPostsArr.push(docData)
        })
        setBlogPosts(blogPostsArr)
    }, [getBlogPosts])

    useEffect(() => {
        setLoading(true)
        fetchBlogPosts()
        setLoading(false)
    }, [fetchBlogPosts])

    return (
        <section className='py-5' style={{minHeight: '100vh'}}>
            <PageHeader>
                <h1 className={'fw-bold arvo-bold ' + (darkMode ? 'my-text-primary-light' : 'my-text-dark')} style={{fontSize: '64px', transition: 'all 0.5s'}}>Blog</h1>
                <h3 className={'fw-bold fs-3 arvo-bold ' + (darkMode ? 'my-text-primary-light' : 'my-text-dark')} style={{transition: 'all 0.5s'}}>Explore Our Advertising Related Discussions and Posts</h3>
            </PageHeader>
            <Container className='pt-2 pb-4'>
                <div className='w-100 d-flex justify-content-center'>
                    <Button 
                        onClick={() => setOpenAdd(!openAdd)} 
                        disabled={!currentUser} 
                        className={'fw-bold fs-4 btn-md border-1 border-primary w-50 mb-4 shadow bg-gradient ' + getThemeBGPlatform('secondary') + ' ' + getTextInv()} 
                        style={{transition: 'color 0.5s, background 0.5s'}}
                    >
                        Add Post
                    </Button>
                </div>
                <Collapse in={openAdd}>
                    <div>
                        <Card className='bg-light p-3'>
                            <Form onSubmit={(e) => addNewBlogPost(e)} id='newBlogPostForm'>
                                <Row>
                                    <Col>
                                        <Form.Label className='text-dark ms-3'>
                                            What is Your Post About?
                                        </Form.Label>
                                        <Form.Control
                                            required
                                            type='text'
                                            name='blogTitle'
                                            placeholder='Title of your post'
                                            autoComplete='off'
                                        />
                                    </Col>
                                    <Col>
                                        <Form.Label className='text-dark ms-3'>
                                            Add an Image for Your Post (Optional)
                                        </Form.Label>
                                        <Form.Control
                                            type='file'
                                            name='blogImage'
                                            accept='image/*'
                                            onChange={handleBlogImageChange}
                                        />
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        <Form.Control
                                            required
                                            type='text'
                                            name='blogText'
                                            placeholder='Content...'
                                            as='textarea'
                                            rows={4}
                                            autoComplete='off'
                                            className='my-1'
                                        />
                                    </Col>
                                    { blogImage ? 
                                    <Col className='text-center p-2'>
                                        <Image src={blogImage} height={120} width={120}/>
                                    </Col>
                                    : <></>}
                                </Row>
                                <Row className='px-4 d-flex justify-content-center'>
                                    <Button 
                                        type='submit' 
                                        className={'btn-sm border-0 w-50 mt-2 ' + getThemeBG('primary') + ' ' + getTextInv()}
                                        style={{transition: 'color 0.5s, background 0.5s'}}
                                    >
                                        Upload
                                    </Button>
                                </Row>
                            </Form>
                        </Card>
                    </div>
                </Collapse>
                { loading ? 
                    <></>
                    :
                    blogPosts.sort(function(x, y){return y.postDate - x.postDate}).map((post, index) => (
                        <BlogPostLink data={post} index={index} key={index} refreshData={fetchBlogPosts} />
                    ))
                }
            </Container>
        </section>
    )
}

const BlogPostLink = (props) => {
    const { getThemeBGInv, getTextInv, getThemeBGPlatform } = useTheme()

    return (
        <Link to={'/blog/' + props.data.id} className='text-decoration-none'>
            <Card 
                className={'w-100 p-2 my-2 border-1 border-primary shadow ' + ( props.index % 2 ? getThemeBGInv('primary') : getThemeBGPlatform('secondary') ) + ' ' + getTextInv()} 
                style={{transition: 'background 0.5s, color 0.5s'}}
            >
                <Card.Body className='d-flex justify-content-between align-items-center' style={{flexDirection: props.index % 2 ? 'row-reverse' : 'row'}}>
                    <Col className='w-100 d-flex flex-column justify-content-between'>
                        <h1 className='fw-bold px-5 pt-2 text-center'>{props.data.title}</h1>
                        <p className='pt-5 pe-5 text-end'>By {props.data.posterName}</p>
                    </Col>
                    {props.data.photoURL && window.innerWidth > 750 ? <Image rounded src={props.data.photoURL} width={200} height={200}/> : <></> }
                </Card.Body>
            </Card>
        </Link>
    )
}

export default Blog