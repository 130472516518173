import React from 'react'
import './shiftingText.css'


const ShfitingWord = ( { word, number } ) => {
    const characters = word.split('')
    const animationSpeed = 6.75
    const letterDelay = 0.05

    return(
         <div  data-key={`${number}-${word}`} className={`align-items-center word word-${number} position-relative d-flex flex-row`} style={{overflow: 'nowrap hidden'}}>
            {characters.map((char, index) => (
                <p key={index} className={`text-center letter letter-${index}`} style={{transform: 'translateY(100%)', animation: `flow-${number} ${animationSpeed}s infinite ${index * letterDelay}s ease-in-out`}}>
                    {char}
                </p>
            ))}
        </div>
    )
}

const ShiftingText = ( { words, prefix } ) => {
    return (
        <div className='d-flex flex-row m-0 p-0 shiftingTextWrapper' style={{width: '40vw'}}>
            <p>{prefix}</p>
            <div className='word-wrapper d-inline-flex flex-col'>
                {words.map((word, index) => (
                    <div key={index} className={`word-row position-absolute overflow-hidden`}>
                        <ShfitingWord word={word} number={index}/>
                    </div>
                ))}
            </div>
        </div>
    )
}

export default ShiftingText