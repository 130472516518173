import React, { useState } from 'react'
import { Card, Form, Button } from 'react-bootstrap'
import { useAuth } from '../contexts/authContext'

const ResetPassword = () => {
    const { setError, setSuccess, sendPasswordResetLink } = useAuth()

    const [email, setEmail] = useState()
    const [isDisabled, setIsDisabled] = useState(false)

    const handleSubmit = async (e) => {
        setError('')
        setSuccess('')
        try {
            await sendPasswordResetLink(email)
            setIsDisabled(true)
            setSuccess('A password reset link was sent to the provided email')
        } catch ( err ) {
            if ( err.code === 'auth/user-not-found') {
                setError( 'Email not associated with existing Account' )
            } else {
                setError( 'Failed to send password reset email' )
            }
        }
        
    }

    return (
        <section className='d-flex justify-content-center align-items-center' style={{height:'75vh'}}>
            <Card>
                <Card.Header>
                    <Card.Title>
                        Reset Password
                    </Card.Title>
                </Card.Header>
                <Card.Body>
                    <Card.Text>Enter the email associated with your account. We will send you a link to reset your password</Card.Text>
                    <Form.Control required name='email' type='email' placeholder='Account Email' onChange={(e) => setEmail(e.target.value)} />
                    <div className='text-center'>
                        <Button onClick={handleSubmit} className='my-3' disabled={isDisabled}>
                            Send Reset Password Link
                        </Button>
                    </div>
                </Card.Body>
            </Card>
        </section>
    )
}

export default ResetPassword