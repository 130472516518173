import React , { useCallback, useEffect, useState } from 'react'
import { Row, Col, Card } from 'react-bootstrap'
import { useTheme } from '../../../../../contexts/themeContext'
import { BarChartLineFill, CalendarFill, CashStack } from 'react-bootstrap-icons'
import formatDollars from '../../../../../utils/formatDollars'
import { useAuth } from '../../../../../contexts/authContext'

 const EarningsCards = (props) => {
    const { userData, type, getCreatorEarnings} = useAuth()

    const [earnings, setEarnings] = useState()

    const updateEarnings = useCallback(async () => {
        const newEarnings = await getCreatorEarnings()
        setEarnings(newEarnings)
    }, [getCreatorEarnings])
    
    useEffect(() => {
        if (!props.isDemo) {
            if (type) {
                updateEarnings()
            }
        }
    }, [type, updateEarnings, userData.potentialAds, props.isDemo])

    return (
        <Row className='gx-2 mb-3'>
            <Col>
                <EarningCard icon={<CalendarFill />} name='Next Payment' value={props.isDemo ? formatDollars(458.86) : (!earnings ? '-' : formatDollars(earnings['next'].toLocaleString()))} />
            </Col>
            <Col>
                <EarningCard icon={<CashStack />} name='Total Payments' value={props.isDemo ? formatDollars(9874) : (!earnings ? '-' : formatDollars(earnings['total'].toLocaleString()))} />
            </Col>
            <Col>
                <EarningCard icon={<BarChartLineFill />} name='Potential Earnings' value={props.isDemo ? formatDollars(2438) : (!earnings ? '-' : formatDollars(earnings['potential'].toLocaleString()))} />
            </Col>
        </Row>
    )
}

const EarningCard = (props) => {
    const { getCardStyle, isSmallScreen } = useTheme()

    return (
        <Card className={getCardStyle('primary') + ' my-bg-primary-light'}>
            <Card.Body>
                <Row className='align-items-center flex-nowrap'>
                    {isSmallScreen ? <></> :
                    <Col className='d-flex justify-content-start bs-icon bs-icon-lg my-text-light'>
                        {props.icon}
                    </Col>
                    }
                    <Col className='text-end' style={{flex: '2 2 75%'}}>
                        <div className='text-uppercase text-nowrap fw-bold mb-1 text-nowrap my-text-light'>
                            {props.name}
                        </div>
                        <div className='fw-bold mb-0 me-2'>
                            <h5 className='fw-bold mb-0 fs-3 my-text-light'>
                                {props.value}
                            </h5>
                        </div>
                    </Col>
                </Row>
            </Card.Body>
        </Card>
    )
}

export default EarningsCards;