import React, { useState } from 'react'
import { Button, Card, Col, Collapse, Row, Image } from 'react-bootstrap'

const VideoDataCardBody = ({data}) => {
    const getRatingsString = (ratings) => {
        if (!Object.keys(ratings).length) {
            return 'No Ratings'
        }
        let result = ''
        for (let key of Object.keys(ratings)) {
            result += key.split('R').join(' r') + ': ' + ratings[key] + ', '
        }
        return result.substring(0, result.length - 2) //remove trailing , and space
    }

    return (
        <Card.Body>
            <Row>
                <Col md={8} className='pt-3 ps-3'>
                    <Row><h4 className='fw-bold'>{data.snippet.localized ? data.snippet.localized.title : data.snippet.title}</h4></Row>
                    <Row><h5>{data.snippet.localized ? (data.snippet.localized.description.substring(0, 125) + (data.snippet.localized.description.length > 125 ? '...' : '')) : (data.snippet.description ? data.snippet.description.substring(0, 125) + (data.snippet.description.length > 125 ? '...' : '') : 'No Video Description Found')}</h5></Row>
                    <Row><h6 className='fw-bold pt-1'>{data.snippet.publishedAt ? 'Video posted on ' + (new Date(data.snippet.publishedAt).toDateString()) : 'Video Post Date not Found'}</h6></Row>
                    <Row><h4 className='fw-bold p-2'>Engagement Statistics</h4></Row>
                    <Row className='text-center'>
                        <Col>
                            <Row><h6 className='fw-bold'>Views</h6></Row>
                            <Row><h5>{data.statistics.viewCount}</h5></Row>
                        </Col>
                        <Col>
                            <Row><h6 className='fw-bold'>Likes</h6></Row>
                            <Row><h5>{data.statistics.likeCount}</h5></Row>
                        </Col>
                        <Col>
                            <Row><h6 className='fw-bold'>Favorites</h6></Row>
                            <Row><h5>{data.statistics.favoriteCount || 0}</h5></Row>
                        </Col>
                        <Col>
                            <Row><h6 className='fw-bold'>Comments</h6></Row>
                            <Row><h5>{data.statistics.commentCount}</h5></Row>
                        </Col>
                    </Row>
                    <Row><h4 className='fw-bold p-2'>Content Details</h4></Row>
                    <Row className='text-center'>
                        <Col>
                            <Row><h6 className='fw-bold'>Length</h6></Row>
                            <Row><h5>{data.contentDetails.duration ? data.contentDetails.duration.substring(2).toLowerCase() : 'Duration not Found'}</h5></Row>
                        </Col>
                        <Col>
                            <Row><h6 className='fw-bold'>Resolution</h6></Row>
                            <Row><h5>{data.contentDetails.definition}</h5></Row>
                        </Col>
                        <Col>
                            <Row><h6 className='fw-bold'>Captions</h6></Row>
                            <Row><h5>{data.contentDetails.caption === 'false' ? 'Off' : 'On'}</h5></Row>
                        </Col>
                        <Col>
                            <Row><h6 className='fw-bold'>Ratings</h6></Row>
                            <Row><h5>{getRatingsString(data.contentDetails.contentRating)}</h5></Row>
                        </Col>
                    </Row>
                </Col>
                <Col md={4} className='d-flex justify-content-center align-items-center'>
                    {data.snippet.thumbnails && data.snippet.thumbnails.default ?
                        <Image fluid src={data.snippet.thumbnails.default.url} className='w-100 h-100' />
                    :
                        <h3 className='text-center py-3'>No Thumbnail Found</h3>
                    }
                </Col>
            </Row>
            <Row><Col className='ps-1'><h4 className='fw-bold p-2'>Content Topics</h4></Col></Row>
            <Row>
                <Col>
                    <Row><h6 className='fw-bold ps-4'>Topics</h6></Row>
                    <TextFoldRow string={data.topicDetails && data.topicDetails.topicCategories ? data.topicDetails.topicCategories.map(cat => cat.substring(cat.lastIndexOf('/') + 1).split('-').join(' ').split('_').join(' ')).join(', ') : 'No Topic Categories'} />
                </Col>
                <Col>
                <Row><h6 className='fw-bold ps-4'>Tags</h6></Row>
                    <TextFoldRow string={(data.snippet.tags ? data.snippet.tags.join(', ') : 'No Tags')} />
                </Col>
            </Row>
            
            
            
        </Card.Body>
    )
}

const TextFoldRow = (props) => {
    const [showMore, setShowMore] = useState(false)

    return (
        <Row>
            {props.string.split(', ').length > 5 ?
                <div className='ps-5'>
                    <h5 className='mb-0'>{props.string.split(', ').slice(0, 5).join(', ')}</h5>
                    <Collapse in={showMore}>
                        <h5>{props.string.split(', ').slice(5).join(', ')}</h5>
                    </Collapse>
                    <Button className='bg-transparent border-0 p-0 m-0 my-text-primary' onClick={() => setShowMore(!showMore)}>
                        {showMore ? '-' : '+'} {props.string.split(', ').length - 5} more
                    </Button>
                </div>
            :
                <h5 className='ps-5'>{props.string}</h5>
            }
        </Row>
    )
}

export default VideoDataCardBody