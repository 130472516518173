import { Navigate, useLocation } from 'react-router-dom';
import { useAuth } from '../contexts/authContext';
import { useLayoutEffect } from 'react';

const WithPrivateRoute = (props) => {
    const { currentUser } = useAuth()

    const { pathname } = useLocation()
    useLayoutEffect(() => window.scrollTo(0, 0), [pathname])

    if ( currentUser && props.isAllowed() ) {
        return props.children
    }

    return <Navigate to='/' />
}

export default WithPrivateRoute