import React, { useEffect, useState } from 'react'
import { useAuth } from '../../../../../contexts/authContext'
import { useTheme } from '../../../../../contexts/themeContext'
import { Button, Col, Modal, Row } from 'react-bootstrap'
import Medal, { medals, variantList } from './medals'

const MedalModal = ({show, setShow, medalId, medalData=null, customTransition=false, newlyEarned=false, onClose=null, isCurrentUser=true, medalReveal=false}) => {
    const {userData, setSocialData, setError, setSuccess, redeemMedal} = useAuth()
    const {darkMode, getBG, getTextInv, getButtonStyle} = useTheme()

    const [opacity, setOpacity] = useState(0)
    const [medal, setMedal] = useState({})

    const [isToggling, setIsToggling] = useState(false)
    const [isRedeeming, setIsRedeeming] = useState(false)

    const toggleHighlight = async () => {
        const newState = !userData.social.medals[medalId].highlight
        const numMedals = Object.values(userData.social.medals).reduce((sum, curr) => curr.highlight ? sum + 1 : sum, 0)
        if (newState && numMedals >= 6) {
            setError('Can\'t highlight more than six medals!')
            closeModal()
            return
        }
        if (!newState && numMedals === 1) {
            setError('Must have at least one medal highlighted!')
            closeModal()
            return
        }

        setIsToggling(true)
        try {
            await setSocialData({medals: {...userData.social.medals, [medalId]: {...userData.social.medals[medalId], highlight: newState}}})
            setSuccess(`Medal ${newState ? 'highlighted!' : 'un-highlighted!'}`)
        } catch (err) {
            setError('Couldn\'t toggle highlight state')
        } finally {
            closeModal()
        }
    }

    const redeemCurrentMedal = async () => {
        setIsRedeeming(true)
        try {
            const success = await redeemMedal(medalId)
            if (success) setSuccess('Claimed Medal!')
            else setError('Failed to claim medal, try again later')
        } catch (err) {
            setError('Failed to claim medal, try again later')
        } finally {
            setIsRedeeming(false)
            closeModal()
        }
    }

    const closeModal = () => {
        if (onClose) onClose()
        setShow(false)
    }

    useEffect(() => {
        // if rendering a medal associated with another user
        if (medalData) {
            setMedal({...medals[medalId], ...medalData})
        }
        else {
            // merge static medal data with user data
            if (userData.social && userData.social.medals) {
                if (userData.social.medals[medalId])
                    setMedal({...medals[medalId], ...userData.social.medals[medalId]})
                else
                    setMedal({...medals[medalId]})
            }
        }
    }, [medalId, medalData, userData.social, setMedal])

    return (
        <>
            <style dangerouslySetInnerHTML={{
                __html: `
                    .medalModal.fade .modal-dialog {
                        transform: translate(0, 0);
                        transition: opacity 0.5s;
                    }`
                }}
            />
            <Modal
                className={(customTransition ? 'medalModal fade ' : '') + getTextInv()}
                show={show}
                centered
                onHide={() => closeModal()}
                style={customTransition ? {opacity: opacity, transition: 'opacity 1s'} : {}}
                onShow={() => customTransition && setOpacity(1)}
                onExit={() => customTransition && setOpacity(0)}
            >
                <Modal.Header closeButton className={'border-0 text-center pb-2 mb-0 ' + getBG()}>
                    <h6>{newlyEarned ? 'You just earned a t' : 'T'}ier {medal.tier} {variantList[(medal.variant || 3)][0].toUpperCase() + variantList[medal.variant || 3].slice(1)} Medal!</h6>
                </Modal.Header>
                <Modal.Body className={'d-flex flex-column justify-content-center align-items-center rounded-bottom text-center py-0 ' + getBG()}>
                    {customTransition ?
                        <div className={'mt-1 rounded-circle border border-2 border-' + (darkMode ? 'light' : 'dark')} style={{width: '110px', height: '110px', backgroundColor: 'gray'}}></div>
                    :
                        <div className='m-2 w-100 d-flex justify-content-center ' style={{height: '120px'}}><Medal medalData={medal} size={120} reveal={medalReveal} /></div>
                    }
                    <h2 className='pt-1 mb-0'>{medal.name}</h2>
                    <h5 className='pt-1 m-0'>{medalReveal || medal.dateAchieved ? medal.desc : '?????'}</h5>
                    {newlyEarned ? <h6 className='pt-1 m-0'>Highlight it in your medal case in the Medals page!</h6> : <></>}
                    <Row className='py-1 d-flex justify-content-center flex-nowrap'>
                        <Col className='text-nowrap'>
                            <h6 className='mb-0 pt-2' style={{fontSize: '12px'}}>
                                {medal.dateAchieved ? `Achieved on ${medal.dateAchieved.toDate().toLocaleDateString()}` : 'Not Achieved'}
                            </h6>
                        </Col>
                        {isCurrentUser && medal.dateAchieved ? 
                            <Col className='text-nowrap'>
                                <Button className={'text-nowrap my-0 py-0 px-2 ' + getButtonStyle('sm')} style={{fontSize: '12px'}} onClick={toggleHighlight} disabled={isToggling}>
                                    {medal.highlight ? 'Unhighlight' : 'Highlight'} Medal
                                </Button>
                            </Col>
                        :<></>}
                        {medal.dateAchieved ? 
                            <Col className='text-nowrap'>
                                {medal.isRedeemed ? 
                                    <h6 className='mb-0 pt-2' style={{fontSize: '12px'}}>Claimed on {medal.dateRedeemed.toDate().toLocaleDateString()}</h6>
                                :
                                    (isCurrentUser ?
                                        <Button className={'text-nowrap my-0 py-0 px-2 ' + getButtonStyle('sm')} style={{fontSize: '12px'}} onClick={redeemCurrentMedal} disabled={isRedeeming}>
                                            Claim Medal
                                        </Button>
                                    :
                                        <h6 className='mb-0 pt-2' style={{fontSize: '12px'}}>Not Yet Claimed</h6>
                                    )
                                }
                            </Col>
                        :<></>}
                    </Row>
                </Modal.Body>
            </Modal>
        </>
    )
}

export default MedalModal