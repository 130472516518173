import React, { useEffect, useState } from 'react'
import { Row, Card, Button, Col } from 'react-bootstrap'
import { useAuth } from '../../../../contexts/authContext'
import { useTheme } from '../../../../contexts/themeContext'
import VideoDataCardBody from './videoDataCardBody'
import AdVerificationCardBody from './adVerificationCardBody'

const AdVideosCard = (props) => {
    const { userData } = useAuth()

    const {getThemeBG, getCardStyle} = useTheme()

    const [videoData, setVideoData] = useState()
    const [selectedAd, setSelectedAd] = useState()

    const getButtonBG = (currVideo) => {
        let theme = 'primary'
        if (currVideo[1].isVerifying) theme = 'warning'
        else if (currVideo[1].isVerified) theme = 'success'
        else theme = 'danger'
        return getThemeBG(theme)
    }

    useEffect(() => {
        if (props.selected) {
            const videos = userData.channels.find(channel => channel.platform === props.selected.split(':')[0] && channel.handle === props.selected.split(':')[1]).adContent || {}
            const adIds = Object.keys(videos).sort((a, b) => userData.history[b].startDate - userData.history[a].startDate)
            setVideoData(videos)
            setSelectedAd(adIds.length ? (selectedAd || adIds[0]) : null)
        }
    }, [userData.channels, userData.history, props.selected, selectedAd])

    return userData.channels.length ? (
        <Card className={getCardStyle()} style={{height: '100%'}}>
            <Card.Header className='py-3'>
                <p className='m-0 fw-bold'>Fiable-Advertised Videos</p>
            </Card.Header>
            {props.selected && selectedAd ?
                <Row>
                    {Object.entries(videoData).sort((a, b) => userData.history[b[0]].startDate - userData.history[a[0]].startDate).map((video) => (
                        <Col key={video[0]} className='text-center py-2'>
                            <Button 
                                className={'rounded-2 border-0 py-1 px-3 fw-bold fs-4 my-text-dark ' + getButtonBG(video)} 
                                style={{transition: 'all 0.5s', opacity: (video[0] === selectedAd ? 1 : 0.5)}} 
                                onClick={() => setSelectedAd(video[0])}
                            >
                                {userData.history[video[0]].advertisementName}
                            </Button>
                        </Col>
                    ))}
                    {videoData[selectedAd].videoId && videoData[selectedAd].videoData ? 
                        <VideoDataCardBody data={videoData[selectedAd].videoData} />
                    :
                        <AdVerificationCardBody data={videoData[selectedAd]} selected={props.selected} adId={selectedAd} setVideoData={setVideoData} />
                    }
                </Row>
            :
                <Card.Body>
                    <h3 className='text-center py-5'>No Fiable-Advertised videos associated with the selected channel.</h3>
                </Card.Body>
            }
        </Card>
    ) : (<></>)
}

export default AdVideosCard;