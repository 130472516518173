import React, { useCallback, useEffect, useState } from 'react'
import { useAuth } from '../../../../contexts/authContext'
import { useTheme } from '../../../../contexts/themeContext'
import { Card, Col, Row } from 'react-bootstrap'
import LineGraph from '../../dashboard/subcomponents/lineGraph'
import PieGraph from '../../dashboard/subcomponents/pieGraph'

const AdVisualizationsCard = (props) => {
    const {userData, type} = useAuth()
    const {getCardStyle} = useTheme()

    const [impressionLineData, setImpressionLineData] = useState([])
    const [earningsLineData, setEarningsLineData] = useState([])
    const [impressionDistData, setImpressionDistData] = useState([])

    const getImpressionLineData = useCallback(() => {
        const adProgress = userData.history[props.selected].progress
        let lineData = []
        if (type) {
            const userProgress = userData.history[props.selected].channels.reduce((sum, curr) => adProgress[curr] ? {...sum, [curr]: adProgress[curr]} : {...sum}, {})

            for (const progress of Object.values(userProgress)) {
                let postDate = progress.postDate.toDate()
                for (const impressions of progress.impressions) {
                    let postDateStr = postDate.toLocaleString('en-US', {month: 'short', day: 'numeric'})
                    if (lineData.find(point => point.name === postDateStr)) lineData.find(point => point.name === postDateStr).value += impressions
                    else lineData.push({name: postDateStr, value: impressions})
                    postDate.setDate(postDate.getDate() + 1)
                }
            }
        } else {
            for (const progress of Object.values(adProgress)) {
                let postDate = progress.postDate.toDate()
                for (const impressions of progress.impressions) {
                    let postDateStr = postDate.toLocaleString('en-US', {month: 'short', day: 'numeric'})
                    if (lineData.find(point => point.name === postDateStr)) lineData.find(point => point.name === postDateStr).value += impressions
                    else lineData.push({name: postDateStr, value: impressions})
                    postDate.setDate(postDate.getDate() + 1)
                }
            }
        }
        
        if (lineData.length) lineData.unshift({name: userData.history[props.selected].startDate.toDate().toLocaleString('en-US', {month: 'short', day: 'numeric'}), value: 0})

        setImpressionLineData(lineData)
        if (type) setEarningsLineData(lineData.map(point => ({name: point.name, value: Math.round((userData.history[props.selected].totalCost * Math.min(point.value / userData.history[props.selected].totalImpressions, 1) * 100)) / 100})))
    }, [userData.history, type, props.selected])

    const getImpressionDistribution = useCallback(() => {
        setImpressionDistData(Object.keys(userData.history[props.selected].progress).map(channel => ({name: channel.split('-').slice(1).join('-'), value: userData.history[props.selected].progress[channel].impressions.at(-1)})))
    }, [userData.history, props.selected])

    useEffect(() => {
        if (props.selected) {
            getImpressionLineData()
            if (!type) getImpressionDistribution()
        }
    }, [props.selected, type, getImpressionLineData, getImpressionDistribution])

    return (
        <Card className={getCardStyle()} style={{minHeight: '380px'}}>
            <Card.Header className='py-3'>
                <h6 className='fw-bold'>Advertisement Progress Visualizations</h6>
            </Card.Header>
            <Card.Body>
                {props.selected && props.selected.length ?
                    <Row>
                        <Col className='d-flex justify-content-center'>
                            <Card className={'m-3 w-100 ' + getCardStyle()} style={{minHeight: '400px'}}>
                                <Card.Header className='fw-bold'>
                                    Impressions to Date (Target Impressions: {type ? userData.history[props.selected].quota.toLocaleString() : userData.history[props.selected].totalImpressions.toLocaleString()})
                                </Card.Header>
                                <Card.Body className='d-flex justify-content-center align-items-center'>
                                    {impressionLineData.length ? 
                                        <LineGraph lineData={impressionLineData} />
                                    :
                                        <h3>No Impression Data yet</h3>
                                    }
                                </Card.Body>
                            </Card>
                        </Col>
                        <Col className='d-flex justify-content-center'>
                            <Card className={'m-3 w-100 ' + getCardStyle()} style={{minHeight: '400px'}}>
                                <Card.Header className='fw-bold'>
                                    {type ? 'Earnings to Date' : 'Impressions by Creator'}
                                </Card.Header>
                                <Card.Body className='d-flex justify-content-center align-items-center'>
                                    {(type && earningsLineData.length) || (!type && impressionDistData.length) ? 
                                        (type ? <LineGraph lineData={earningsLineData} monetary /> : <PieGraph pieData={impressionDistData} />)
                                    :
                                        <h3>No {type ? 'Earnings' : 'Impression'} Data yet</h3>
                                    }
                                </Card.Body>
                            </Card>
                        </Col>
                    </Row>
                :
                    <div className='fs-3 mt-5 pt-5 text-center'>Select an Advertisement above to view progress visualizations</div>
                }
            </Card.Body>
        </Card>
    )
}

export default AdVisualizationsCard