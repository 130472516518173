import React, { useState } from 'react'
import { Card, Col, Container, Row, Form, Button } from 'react-bootstrap'

import { useTheme } from '../../../../../../contexts/themeContext'
import { useAuth } from '../../../../../../contexts/authContext'
import AdGuideToolInDepth from './adGuideToolInDepth'

export const linkDesc = 'Product link in the video description with accompanying copy to direct traffic.'
export const logoDesc = 'Logo or promotional imagery superimposed in a non-intrusive way over video content.'
export const readDesc = 'Creators perform a personalized sales pitch to their audience.'
export const clipDesc = 'Creator shows a longer, more visual ad at the beginning of the content (or periodically on a stream) reviewing the advertiser, their product, and potentially even reading a supplied script.' 
export const productDesc = '(COMING SOON!) Have the product shipped to the Creator, and place it in the background of the content (only available if creator has a live cam).'

export const adTypes = [
    {name: 'Link', desc: linkDesc, dataName: 'link', cost: 0.3},
    {name: 'Logo', desc: logoDesc, dataName: 'logo', cost: 0.5},
    {name: 'Read', desc: readDesc, dataName: 'read', cost: 0.9}, 
    {name: 'Clip', desc: clipDesc, dataName: 'clip', cost: 1}, 
]

const AdGuideTool = ({hidePreferences=false, page, setPage=(() => true)}) => {
    const { userData, type, updateUserData } = useAuth()
    const {getTextInv, getCardStyle} = useTheme()

    const [loading, setLoading] = useState(false)
    const [updateMsg, setUpdateMsg] = useState()

    const updateAdTypes = async (e) => {
        e.preventDefault()
        setLoading(true)

        const data = ['link', ...((new FormData(e.target)).entries().map(entry => entry[0]))].sort((a,b) => a.localeCompare(b))
        if ( data.join('') === userData.adTypes.join('') ) {
            return
        }

        try {
            await updateUserData({adTypes: data})
            setUpdateMsg('Updated Ad Types')
        } catch (err) {
            setUpdateMsg('Failed to update Ad Types')
        } finally {
            setLoading(false)
        }
    }

    return (page && page !== 'Advertisement Types'  ? <AdGuideToolInDepth inDepthType={page}/> : 
        <Container fluid className='rounded-bottom d-flex flex-column justify-content-center arvo-regular pb-5' >
            <Row className={'text-center pb-1 ' + getTextInv()}>
                <h3 className='fw-bold mb-1'>Review each Fiable Ad Type{hidePreferences ? '' : ' and enable or disable your preferred types'}</h3>
                <h5 className='fw-bold'>Fiable offers three types of ads to incorporate into {type ? 'your' : "a creator's"} content.</h5>
                <p className='fs-5 text-decoration-underline'>Click on the cards for more information</p>
            </Row>
            <Row className='h-75 mt-3'>
                <Col>
                    <style dangerouslySetInnerHTML={{
                        __html: `
                        .adTypeCard:hover {
                            background-color: rgba(255, 184, 0) !important;
                            transition: background-color 0.5s;
                            cursor:pointer;
                        }`
                    }}/>
                    <Card onClick={() => {setPage('Link')}} className={'py-1 h-100 adTypeCard ' + getCardStyle()}>
                        <Card.Header className='border-0 pb-0'>
                            <Card.Title className='fs-3 fw-bold my-0 text-center text-decoration-underline'>Link</Card.Title>
                            <h6 className='mt-1 text-center text-decoration-underline'>Default offering included in every sponsorship</h6>
                        </Card.Header>
                        <Card.Body className='text-center d-flex align-items-center'>
                            <Card.Text className='fs-4 mt-2 fw-bold'>
                                {linkDesc}
                            </Card.Text>
                        </Card.Body>
                        <Card.Footer>
                            <Card.Text className='mb-2 arvo-regular-italic text-center'>
                                Links will be routed through Fiable's website to track key performance metrics.
                            </Card.Text>
                        </Card.Footer>
                    </Card>
                </Col>
                <Col>
                    <Card onClick={() => {setPage('Logo')}} className={'py-1 h-100 adTypeCard ' + getCardStyle()}>
                        <Card.Header className='border-0 pb-0'>
                            <Card.Title className='fs-3 fw-bold my-0 text-center text-decoration-underline'>Logo</Card.Title>
                        </Card.Header>
                        <Card.Body className='fs-4 mt-2 arvo-regular text-center d-flex flex-column justify-content-between align-items-center'>
                            <Card.Text className='fw-bold'>
                                {logoDesc}
                            </Card.Text>
                            <Card.Text className='mt-3 fs-6 fst-italic'>
                                This option is similar to standard banner ads.
                            </Card.Text>
                        </Card.Body>
                    </Card>
                </Col>
                <Col>
                    <Card onClick={() => {setPage('Ad Read')}} className={'h-100 adTypeCard ' + getCardStyle()}>
                        <Card.Header className='border-0 pb-0'>
                            <Card.Title className='fs-3 fw-bold my-0 text-center text-decoration-underline'>Ad Read</Card.Title>
                        </Card.Header>
                        <Card.Body className='fs-4 mt-2 arvo-regular text-center d-flex flex-column justify-content-between align-items-center'>
                            <Card.Text className='fw-bold'>   
                                {readDesc}
                            </Card.Text>
                            <Card.Text className='mt-3 fs-6 fst-italic'>
                                Ad reads less than 30 seconds are classified as a Shout Out.
                            </Card.Text>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
            {hidePreferences ? <></> : 
                <Row>
                    <Form onSubmit={updateAdTypes} className={'d-inline-flex ' + getTextInv()}>
                        <Row className='pt-1 d-flex justify-content-between'>
                            <Col className='d-flex text-nowrap align-items-center'>
                                {updateMsg ?
                                    <h5 className='fw-bold pt-2'>{updateMsg}</h5>
                                :
                                <Button type='submit' disabled={loading} className='my-bg-primary my-text-dark text-nowrap btn-md h-75'>Update Ad Types</Button>
                            }
                            </Col>
                            <Col className='d-flex text-nowrap'>
                                <Form.Switch defaultChecked={true} disabled label='Link' name='link' className='form-control-lg pe-0' />
                            </Col>
                            <Col className='d-flex text-nowrap'>
                                <Form.Switch defaultChecked={userData.adTypes.includes('logo')} label='Logo' name='logo' className='form-control-lg pe-0' />
                            </Col>
                            <Col className='d-flex text-nowrap'>
                                <Form.Switch defaultChecked={userData.adTypes.includes('read')} label='Ad Read' name='read' className='form-control-lg pe-0' />
                            </Col>
                            <Col className='d-flex text-nowrap'>
                                <Form.Switch defaultChecked={userData.adTypes.includes('clip')} label='Ad Clip' name='clip' className='form-control-lg pe-0' />
                            </Col>
                        </Row>
                    </Form>
                </Row>
            }
        </Container>
    )
}

export default AdGuideTool