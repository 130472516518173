import React, { useEffect, useState } from 'react'
import { Card, Button, Modal, Form, InputGroup, Row, Col, Spinner } from 'react-bootstrap'
import { useAuth } from '../../../../contexts/authContext'
import { useTheme } from '../../../../contexts/themeContext'
import { ArrowClockwise, Check, Link45deg, TrashFill, X } from 'react-bootstrap-icons'

const ChannelsCard = (props) => {
    const { userData, setError, setWarning, setSuccess, getChannelData, updateChannelData, earnMedal, setSocialData } = useAuth()
    const { getBG, getText, getCardStyle, getButtonStyle, getTextInv, isSmallScreen} = useTheme()

    const [isAdding, setIsAdding] = useState( false )
    const [loading, setLoading] = useState(false)
    const [currentPlatform, setCurrentPlatform] = useState('youtube')
    const [currentHandle, setCurrentHandle] = useState()
    const [token, setToken ] = useState('')

    const [showOauthModal, setShowOauthModal] = useState(false)

    const getYoutubeAuthUrl = (handle) => {
        return `https://accounts.google.com/o/oauth2/v2/auth?client_id=${process.env.REACT_APP_YOUTUBE_CLIENT_ID}&redirect_uri=${encodeURIComponent(window.location.href)}&response_type=token&scope=https://www.googleapis.com/auth/youtube.readonly&state=youtube-${handle}`
    }

    const getTwitchAuthUrl = (handle) => {
        return `https://id.twitch.tv/oauth2/authorize?client_id=${process.env.REACT_APP_TWITCH_CLIENT_ID}&redirect_uri=${encodeURIComponent(window.location.href)}&response_type=token&state=twitch-${handle}`
    }

    const getToken = () => {
        if ( document.location.hash && document.location.hash !== '') {
            let parsedHash = new URLSearchParams(window.location.hash.slice(1))
            if (parsedHash.get('access_token')) {
                return {'token': parsedHash.get('access_token'), 'state': parsedHash.get('state')}
            }
        } else {
            setShowOauthModal(true)
            return {token: '', state: ''}
        }
    }

    const addChannel = async (e) => {
        e.preventDefault()

        if ( !token.length && !userData.bypassOauth ) {
            setShowOauthModal(true)
            return
        }

        const handle = currentHandle[0] === '@' ? currentHandle.replace('@', '') : currentHandle
        const platform = currentPlatform
        if (userData.channels.find(channel => channel.handle === handle && channel.platform === platform)) {
            setWarning('You already added this channel!')
            setIsAdding( false )
            return
        }

        setError('')
        setSuccess('')
        setLoading(true)
        try {
            const channelDoc = (await getChannelData(platform + '-' + handle))
            if (channelDoc.data()) {
                setWarning('Someone already claimed this channel!')
                setLoading(false)
                setIsAdding(false)
                return
            }

            const response = await updateChannelData(platform, handle, token)
            if ( response === 200 ) {
                const channels = userData.channels.map(channel => channel.platform + '-' + channel.handle)
                await setSocialData({channels: channels})
                setSuccess('Channel Added')
                earnMedal(4, userData.socialCode, {variant: Math.min(channels.length, 3), value: channels.length})
            } else {
                setWarning('Could not find channel associated with @' + handle)
            }
        } catch( err ) {
            setError('Failed to add Channel')
        } finally {
            setIsAdding(false)
            setLoading(false)
            window.history.replaceState({}, document.title, window.location.href.split('#')[0])
            setToken('')
        }
    }

    useEffect(() => {
        if (document.location.hash && document.location.hash !== '') {
            const parsedHash = new URLSearchParams(window.location.hash.slice(1))
            if (parsedHash.get('access_token')) {
                setToken(parsedHash.get('access_token'))
                const state = parsedHash.get('state').split('-')
                setCurrentPlatform(state[0])
                setCurrentHandle(state[1])
                setIsAdding(true)
            }
        }
    }, [setToken, setCurrentPlatform, setIsAdding])

    return (
        <Card className={'pb-3 overflow-y-auto ' + getCardStyle()} style={{height: '100%', minHeight: '320px', display: (isSmallScreen ? 'none' : '')}}>
            <Card.Header className='py-3'>
                <h6 className={'fw-bold m-0 text-transition ' + getTextInv()}>{!userData.channels && !userData.channels.length ? 'Connect Your ' : ''}Channel</h6>
            </Card.Header>
            <Card.Body className='text-center px-0 pb-0'>
                {userData.channels.length ?
                    userData.channels.map((channel, index) => (
                        <ChannelRow platform={channel.platform} handle={channel.handle} lastRefreshed={channel.lastRefreshed} key={index} getToken={getToken} selected={props.selected} setSelected={props.setSelected} hideSelect={props.hideSelect} setPlatform={setCurrentPlatform}/>
                    ))
                :
                    <div className='d-flex h-100 align-items-center justify-content-center text-center overflow-x-hidden'>
                        <Form onSubmit={addChannel} id='addChannel' className='ms-2' style={{transition: 'opacity 0.5s ease-in-out', opacity: (isAdding ? 1 : 0)}}>
                            {isAdding && <>
                                <Row className='pb-3'><h5>Select Your Platform and Enter Your Handle</h5></Row>
                                <Row>
                                    <Col>
                                        <InputGroup size='sm' className='p-1'>
                                            <Form.Select name='platform' required disabled={token.length} onChange={(e) => setCurrentPlatform(e.target.value)} value={currentPlatform}>
                                                <option value='youtube'>YouTube</option>
                                                {/* NOT SUPPORTING TWITCH: <option value='twitch'>Twitch</option> */}
                                                <option disabled value='other'>More coming soon!</option>
                                            </Form.Select>
                                        </InputGroup>
                                        <InputGroup size='sm' className='p-1'>
                                            <InputGroup.Text className='rounded-start'>@</InputGroup.Text>
                                            <Form.Control name='handle' type='text' required placeholder='Platform Handle (No @ sign)' defaultValue={currentHandle} onChange={(e) => setCurrentHandle(e.target.value)} />
                                        </InputGroup>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col className='d-flex align-items-center justify-content-center'>
                                        {loading ? <Spinner className='pt-3' /> : <>
                                        <Button className='bg-transparent border-0 m-0 p-0 pe-5' onClick={() => setIsAdding(!isAdding)}>
                                            <div className={'bs-icon bs-icon-lg text-transition ' + getText()}>
                                                <X className='my-text-danger' style={{fontSize: '64px'}}/>
                                            </div>
                                        </Button>
                                        <Button className='bg-transparent border-0 m-0 p-0 ps-5' type='submit' disabled={!(currentPlatform && currentHandle)}>
                                            <div className={'bs-icon bs-icon-lg text-transition ' + getTextInv()}>
                                                {token && token.length ? <Check className='my-text-success' style={{fontSize: '64px'}} /> : <Link45deg className='my-text-success' style={{fontSize: '64px'}} />}
                                            </div>
                                        </Button>
                                        </>}
                                    </Col>
                                </Row>
                            </>}
                        </Form>
                        <Button 
                            className={'position-absolute ' + getButtonStyle('lg')}
                            style={{transition: 'opacity 0.35s ease-in-out, transform 0.35s ease-in-out', opacity: (isAdding ? 0 : 1), transform: 'scale(' + (isAdding ? 0.5 : 1) + ')', marginTop: '-11%'}}
                            onClick={() => setIsAdding(true)}
                            disabled={isAdding}
                        >
                            Link Your Channel!
                        </Button>
                    </div>
                }
                <Modal show={showOauthModal} onHide={() => setShowOauthModal(false)} className={getTextInv()}>
                    <Modal.Header closeButton className={getBG()}>Verify Channel</Modal.Header>
                    <Modal.Body className={'text-center rounded-bottom ' + getBG()}>
                        <p>To link a channel, you will first need to verify ownership of this youtube account and give Fiable permission to access your channel data through Google Authentication.</p>
                        <p>Click the link below and allow Fiable read-only access to your channel data on the next page. After doing this you will be redirected back here.</p>
                        <p>After being redirected, click the green checkmark to complete the verification process and link your channel.</p>
                        <p>Fiable pledges to use your data responsibly and will never disclose private information to any external party.</p>
                        <a className='my-text-primary fw-bold text-decoration-underline' href={currentPlatform === 'youtube' ? getYoutubeAuthUrl(currentHandle) : getTwitchAuthUrl(currentHandle)}>Redirect me to Google Authentication</a>
                    </Modal.Body>
                </Modal>
            </Card.Body>
        </Card>
    )
}

const ChannelRow = (props) => {
    const { userData, deletePlatform, updateChannelData, setError, setWarning, setSuccess, earnMedal } = useAuth()
    const { getBG, getText, getTextInv, getButtonThemeStyle } = useTheme()

    const [isRefreshing, setIsRefreshing] = useState(false)
    const [isDeleting, setIsDeleting] = useState(false)

    const refreshChannel = async () => {
        let authData, token
        let authenticationNecessary = false

        const diff = (new Date()) - props.lastRefreshed.toDate()
        if ( diff < (24 * 60 * 60 * 1000)) {
            setWarning('You can only refresh a channel once per day. Next refresh in ' + ( 24 - Math.round(diff / (1000 * 60 * 60) )) + ' hours.')
            return
        }

        if (authenticationNecessary) {
            authData = props.getToken()
            token = authData['token']
            if (!token.length) {
                return
            }
        }

        setIsRefreshing(true)
        try {
            const response = await updateChannelData(props.platform, props.handle, token)
            if ( response === 200 ) {
                setSuccess('Channel Data Refreshed')
            } else {
                setError('Failed to find Channel Data')
            }
            earnMedal(6)
        } catch( err ) {
            setError('Failed to refresh Channel Data')
        } finally {
            setIsRefreshing(false)
            window.history.replaceState({}, document.title, window.location.href.split('#')[0])
        }
    }

    const deleteChannel = async () => {
        if (userData.channels.length === 1) {
            setWarning('Cannot delete your only Platform!')
            setIsDeleting( false )
            return
        }

        try {
            if (!props.hideSelect) props.setSelected(null)
            await deletePlatform( props.platform, props.handle )
            setSuccess('Channel Deleted')
        } catch ( err ) {
            setError('Failed to delete Platform')
        } finally {
            setIsDeleting( false )
        }
    }

    useEffect(() => {
        if (!props.hideSelect) props.setSelected(props.platform + ':' + props.handle)
    })

    return (
        <div className='h-100 overflow-hidden d-flex flex-column justify-content-between'> {/*REMOVE WHEN MULTIPLE CHANNELS ALLOWED*/}
            <Row className='d-flex d-inline-flex flex-row w-100'>
                <Col className='d-flex justify-content-center'>
                    <Button 
                        className='mt-3 px-2 py-2 h-100 rounded-0 border-0 bg-transparent'
                        disabled={true} // not good for multiple channels
                        style={{opacity: 1}}
                        // onClick={() => props.setPlatform(props.platform) || props.setSelected(props.platform + ':' + props.handle)} (currently only one channel allowed, no point in click event)
                    >
                            <div className={'fw-bold text-center text-transition ' + getTextInv()} style={{fontSize: '1.6vw'}}>@{props.handle}</div>
                            <h4 className={'pt-2 fw-bold text-center text-transition ' + getTextInv()} style={{fontSize: '1.4vw'}}>Platform: {props.platform[0].toUpperCase() + props.platform.slice(1)}</h4>
                    </Button>
                </Col>
            </Row>
            {/* Remove the row tags here and the div tags when reverting to multiple channels */}
            <Row className='d-flex justify-content-between mb-3'>
                <Col className='d-flex justify-content-center ps-3'>
                    <Button className='bg-transparent border-0 m-3 p-0' onClick={() => setIsDeleting(true)} disabled={isDeleting || props.hideSelect}>
                        <div className={'bs-icon bs-icon-md text-transition ' + getText()}>
                            <TrashFill className='my-text-danger' style={{fontSize: '64px'}} />
                        </div>
                    </Button>
                </Col>
                <Col className='d-flex justify-content-center pe-3'>
                    <Button className='bg-transparent border-0 m-3 p-0' onClick={() => props.setPlatform(props.platform) || refreshChannel()} disabled={isRefreshing || props.hideSelect}>
                        <div className={'bs-icon bs-icon-md text-transition ' + getTextInv()}>
                            <ArrowClockwise style={{fontSize: '64px'}} />
                        </div>
                    </Button>
                </Col>
                <Modal size='md' show={isDeleting} onHide={() => setIsDeleting(false)}>
                    <Modal.Header closeButton className={getBG()}>
                        <Modal.Title className={'fs-5 ' + getTextInv()}>Are you sure you want to delete this channel?</Modal.Title>
                    </Modal.Header>
                    <Modal.Body className={getBG() + ' rounded-bottom d-flex justify-content-between'}>
                        <Button className={getButtonThemeStyle('sm', 'success')} onClick={() => setIsDeleting(false)}>No, nevermind</Button>
                        <Button className={getButtonThemeStyle('sm', 'danger')} onClick={deleteChannel}>Yes, delete</Button>
                    </Modal.Body>
                </Modal>
            </Row>
        </div>
    )
}

export default ChannelsCard