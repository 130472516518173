import React from 'react'
import { Button, Card, Container, Row } from 'react-bootstrap'
import { useTheme } from '../../../../../../contexts/themeContext'
import { useNavigate } from 'react-router-dom'

const ConnectChannelTool = () => {
    const {getCardStyle, getButtonStyle} = useTheme()

    const navigate = useNavigate()

    return (
        <Container fluid className='rounded-2 arvo-regular px-5 pb-1'>
            <Row className='text-center pb-1'>
                <h3 className='fw-bold'>Follow these steps to link your content channel</h3>
                <h5 className='arvo-regular-italic px-5 text-center'>Currently, only YouTube channels are supported, but support for Twitch, Instagram, TikTok, and more are coming soon!</h5>
            </Row>
            <Card className={'my-3 mx-2 p-3 rounded ' + getCardStyle('secondary')}>
                <h4 className='fw-bold'>Step 1: Navigate to the channel page or use the channel card on the dashboard</h4>
                <h6>Tip: look for the monitor icon on the nav bar, or click the menu icon in the top left</h6>
            </Card>
            <Card className={'my-3 mx-2 p-3 rounded ' + getCardStyle('primary')}>
                <h4 className='fw-bold'>Step 2: Click Link Your Channel, and enter the handle in the text input</h4>
                <h6>Tip: don't include the @, and make sure to click the link icon when done</h6>
            </Card>
            <Card className={'my-3 mx-2 p-3 rounded ' + getCardStyle('secondary')}>
                <h4 className='fw-bold'>Step 3: Verify channel ownership and let Fiable view your channel data</h4>
                <h6>Tip: after approving the view permissions, click the checkmark to link your channel to Fiable</h6>
            </Card>
            <Row className='d-flex justify-content-center text-center mt-5'>
                <Button className={getButtonStyle()} onClick={() => navigate('/channel')}>
                    Go to Channel Page Now!
                </Button>
            </Row>
        </Container>
    )
}

export default ConnectChannelTool