import React, { useCallback, useEffect, useState } from 'react'
import { Button, Card, Col, Row, Dropdown } from 'react-bootstrap'
import { useTheme } from '../../../../contexts/themeContext'
import { useAuth } from '../../../../contexts/authContext'
import { CartesianGrid, Line, LineChart, Tooltip, XAxis, YAxis } from 'recharts';
import { BarChartLineFill, ThreeDotsVertical } from 'react-bootstrap-icons'

const Leaderboard = () => {
    const {getCardStyle} = useTheme()

    return (
        <Card className={getCardStyle()}>
            <Card.Header className='d-inline-flex me-4'>
                <Col className='fs-5'>Leaderboard</Col>
                <Col className='text-end'><BarChartLineFill className='position-absolute fs-3'/></Col>
            </Card.Header>
            <Card.Body className='pe-1'>
                <Row className='w-100 gx-2'>
                    <Col>
                        <ScoreLineGraph />
                    </Col>
                    <Col lg={5}>
                        <Leaderboards />
                    </Col>
                </Row>
            </Card.Body>
        </Card>
    )
}

const Leaderboards = () => {
    const {userData, getSocialData} = useAuth()
    const {getCardStyle, getBG, getTextInv, darkMode} = useTheme()

    const leaderboards = ['Connections', 'Localized', 'Global']
    const [connectionsData, setConnectionsData] = useState([])
    const [currentLeaderboard, setCurrentLeaderboard] = useState(0)

    const getConnectionsData = useCallback(async () => {
        if (!(userData.social && userData.social.connections)) return

        const newConnectionsData = await Promise.all(userData.social.connections.map(code => {
            return new Promise((res, rej) => {
                getSocialData(code).then((data) => res({...data.data(), socialCode: code})).catch((err) => rej(err))
            })
        }))
        setConnectionsData([...newConnectionsData, {...userData.social, socialCode: userData.socialCode}])
    }, [setConnectionsData, getSocialData, userData.social, userData.socialCode])

    useEffect(() => {
        getConnectionsData()
    }, [getConnectionsData])

    return (
        <Card className={'h-100 ' + getCardStyle('primary')}>
            <Card.Header className='d-flex justify-content-center'>
                <Row className='w-100 d-inline-flex flex-nowrap'>
                    {leaderboards.map((name, index) => (
                        <Col key={name} className='d-flex flex-row justify-content-center'>
                            <Button
                                className={'border-0 fs-6 py-1 px-3 rounded-3 ' + getBG() + ' ' + getTextInv()}
                                style={{opacity: (currentLeaderboard === index ? 1 : 0.7), transition: 'all 0.5s'}}
                                onClick={() => setCurrentLeaderboard(index)}
                            >
                                {name}
                            </Button>
                        </Col>
                    ))}
                </Row>
            </Card.Header>
            <Card.Body className='px-2 mx-1'>
                <Row className={'text-center border-bottom border-2 border-' + (darkMode ? 'dark' : 'light')} style={{transition: 'border 0.5s'}}>
                    <Col className='text-start'>#</Col>
                    <Col>Social Name</Col>
                    <Col className='text-end'>Score</Col>
                </Row>
                {currentLeaderboard === 0 ? 
                    connectionsData.sort((a, b) => b.score - a.score ).map((conn, index) => (
                        <Row key={index} className={'text-center border-bottom border-2 border-' + (darkMode ? 'dark' : 'light') + ' ' + (conn.socialCode === userData.socialCode && (getBG() + ' my-text-' + (darkMode ? 'white' : 'dark')))} style={{transition: 'all 0.5s'}}>
                            <Col className='text-start'>{index + 1}.</Col>
                            <Col>{conn.socialCode}</Col>
                            <Col className='text-end'>{conn.score}&nbsp;</Col>
                        </Row>
                    ))
                :
                    <Row className='h-100 d-flex flex-column justify-content-center text-center fw-bold fst-italic fs-2 pb-4'>
                        {leaderboards[currentLeaderboard]} Leaderboard Data Unavailable
                    </Row>
                }
            </Card.Body>
        </Card>
    )
}

const ScoreLineGraph = () => {
    const {userData, getMedalValue} = useAuth()
    const {getBG, getCardStyle, getTextInv} = useTheme()

    const [type, setType] = useState(0)
    const [lineData, setLineData] = useState([])

    const updateLineData = useCallback(() => {
        const currentDate = new Date()

        let result = []
        if (type === 0) {
            let currentScore = Object.keys(userData.social.medals).filter(id => {
                const medalDate = userData.social.medals[id].dateAchieved.toDate()
                return medalDate.getUTCFullYear() < currentDate.getUTCFullYear() || medalDate.getMonth() < currentDate.getMonth()
            }).reduce((sum, curr) => sum + getMedalValue(curr), 0)

            const daysThisMonth = Array.from({ length: currentDate.getUTCDate() }, (_, i) => ({name: (i + 1), amount: 0}))
            result = daysThisMonth.map(day => {
                const medalsThisDay = Object.keys(userData.social.medals).filter(id => userData.social.medals[id].dateAchieved.toDate().getMonth() === currentDate.getMonth() && userData.social.medals[id].dateAchieved.toDate().getDate() === day.name)
                const sumMedalValue = medalsThisDay.reduce((sum, curr) => sum + getMedalValue(curr), 0)
                currentScore += sumMedalValue
                return {name: day.name, amount: currentScore}
            })
        } else {
            const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec']
            const monthValues = {}

            months.forEach( monthName => {
                monthValues[monthName] = 0
            })

            Object.keys(userData.social.medals).forEach(id => {
                const medal = userData.social.medals[id]
                const medalDate = medal.dateAchieved.toDate()
                const medalValue = getMedalValue(id)

                if (currentDate - medalDate <= 1000 * 60 * 60 * 24 * 365)
                    monthValues[months[medalDate.getMonth()]] += medalValue
            })

            const reorderedMonths = months.slice(currentDate.getMonth() + 1).concat(months.slice(0, currentDate.getMonth() + 1))
            let cumulativeScore = 0
            reorderedMonths.forEach(month => {
                cumulativeScore += monthValues[month]
                result.push({ name: month, amount: cumulativeScore })
            })

            if (type === 1) result = result.slice(11 - currentDate.getMonth(), 12)
        }

        if (userData.social.connections.length) {
            result[result.length - 1].amount += userData.social.connections.length
        }

        setLineData(result)
    }, [type, userData.social, getMedalValue])
    
    useEffect(() => {
        updateLineData()
    }, [updateLineData])

    return (
        <Card className={getCardStyle()}>
            <Card.Header className='d-flex justify-content-between align-items-center'>
                <h6 className={'fw-bold m-0 ' + getTextInv()} style={{transition: 'color 0.5s'}}>
                    Score over Time
                </h6>
                <Dropdown className='rounded bg-transparent'>
                    <Dropdown.Toggle className='dropdown-toggle-arrowless border-0 bg-transparent'>
                        <ThreeDotsVertical className={getTextInv()} style={{transition: 'color 0.5s'}} />
                    </Dropdown.Toggle>
                    <Dropdown.Menu className={'shadow ' + getBG()}>
                        <Dropdown.Item className={getTextInv()} onClick={() => setType(0)}>
                            This Month (default)
                        </Dropdown.Item>
                        <Dropdown.Item className={getTextInv()} onClick={() => setType(1)}>
                            Year to Date
                        </Dropdown.Item>
                        <Dropdown.Item className={getTextInv()} onClick={() => setType(2)}>
                            Past Year
                        </Dropdown.Item>
                    </Dropdown.Menu>
                </Dropdown>
            </Card.Header>
            <Card.Body className='d-flex justify-content-start align-items-center'>
                <LineChart width={550} height={300} data={lineData}>
                    <CartesianGrid strokeDashArray='2 2' />
                    <XAxis dataKey='name' />
                    <YAxis />
                    <Tooltip />
                    <Line type='monotone' dataKey='amount' stroke='#8884d8' activeDot={{ r: 6 }} strokeWidth={3} />
                </LineChart>
            </Card.Body>
        </Card>
    )
}

export default Leaderboard