import { createContext, useContext, useState, useEffect, useCallback } from "react";

import { createUserWithEmailAndPassword, signInWithEmailAndPassword, updateProfile, signOut, reauthenticateWithCredential, updatePassword, EmailAuthProvider, sendPasswordResetEmail, updateEmail } from 'firebase/auth'
import { getStorage, ref, uploadBytes, getDownloadURL, deleteObject, uploadBytesResumable } from 'firebase/storage'
import { doc, setDoc, getDoc, getDocs, deleteDoc, collection, arrayUnion, arrayRemove, addDoc, updateDoc, Timestamp, deleteField, query, where } from 'firebase/firestore'
import { auth, db, functions, httpsCallable } from '../config/firebase'
import axios from "axios";
import { medals } from "../Components/userHome/achievements/subcomponents/medals/medals";

// Auth init functions
const AuthContext = createContext()

export function useAuth() {
    return useContext( AuthContext )
}

export function AuthProvider( { children } ) {

    //---------- USER STATES ----------//
    const [ currentUser, setCurrentUser ] = useState()
    const [ userData, setUserData ] = useState({})

    // 0 for advertiser, 1 for creator. Please don't regret this
    const [ type, setType ] = useState(1)

    //---------- APP STATES ----------//
    const [ loading, setLoading ] = useState(true)
    const [ error, setErrorMsg ] = useState('')
    const [ warning, setWarningMsg ] = useState('')
    const [ success, setSuccessMsg ] = useState('')

    const [timeoutID, setTimeoutID] = useState()
    const setError = (msg) => {
        clearTimeout(timeoutID)
        setErrorMsg(msg)
        const id = setTimeout(() => setErrorMsg(''), 5000)
        setTimeoutID(id)
    }
    
    const setWarning = (msg) => {
        clearTimeout(timeoutID)
        setWarningMsg(msg)
        const id = setTimeout(() => setWarningMsg(''), 5000)
        setTimeoutID(id)
    }

    const setSuccess = (msg) => {
        clearTimeout(timeoutID)
        setSuccessMsg(msg)
        const id = setTimeout(() => setSuccessMsg(''), 5000)
        setTimeoutID(id)
    }

    //---------- CURRENT USER FUNCTIONS ----------//

    async function register( email, password, isCreator ) {
        const newUser = (await createUserWithEmailAndPassword( auth, email, password ))
        let newUserData = {
            firstTimeUser: true,
            creator: isCreator,
            nickname: '',
            name: ' ',
            history: {},
            adTypes: ['link'],
            paymentHistory: []
        }
        if (!isCreator) {
            newUserData.events = {}
        } else {
            newUserData.channels = []
        }

        const userRef = doc( db, 'users', newUser.user.uid )
        await setDoc( userRef, newUserData )
    }

    function sendPasswordResetLink( email ) {
        return sendPasswordResetEmail(auth, email)
    }
    
    function login( email, password ) {
        return signInWithEmailAndPassword( auth, email, password )
    }

    function reauthenticateUser( password ) {
        const credentials = EmailAuthProvider.credential(currentUser.email, password)
        return reauthenticateWithCredential( currentUser, credentials )
    }

    function updateUserEmail( newEmail ) {
        return updateEmail( currentUser, newEmail )
    }

    function updateUserPassword( newPassword ) {
        return updatePassword( currentUser, newPassword )
    }

    function updateUserProfile( profile ) {
        return updateProfile( currentUser, profile )
    }
    
    function logout() {
        setUserData({})
        setCurrentUser(null)
        return signOut(auth)
    }

    // returns the URL to the newly uploaded avatar
    async function uploadAvatar( file, uid ) {
        const storageRef = ref( getStorage(), 'profilePics/' + uid )
        await uploadBytes( storageRef , file)
        return getDownloadURL(storageRef)
    }

    //---------- USER DATA FUNCTIONS ----------//

    function getUserDataFromUID( uid ) {
        return getDoc( doc( db, 'users', uid) )
    }

    function setUserDataFromUID( uid, data ) {
        return setDoc( doc( db, 'users', uid ), data, {merge: true} )
    }

    function updateUserData( data ) {
        setUserData(prevData => ({...prevData, ...data}))
        return updateDoc( doc( db, 'users', currentUser.uid ), {...data} )
    }

    //set a user status to pending (pending application review)
    function setPending() {
        return updateUserData({ pending: true })
    }

    function getCreatorEarnings() {    
        let next = 0, total = 0, potential = 0, available = 0
        let nextList = [], totalList = [], potentialList = [], availableList = []
        const now = Timestamp.now()
        for ( const adId of Object.keys(userData.history) ) {
            const adData = userData.history[adId]

            const creatorChannels = userData.channels ? userData.channels.map(c => c.platform + '-' + c.handle) : []
            for (const channel of creatorChannels) {
                let keyData = {
                    text: (adData.advertiserName + ': ' + adData.advertisementName),
                    adId: adId,
                    channel: channel,
                    start: adData.startDate.toDate().toLocaleDateString(),
                    value: 0
                }
                if (!adData.progress[channel]) continue // no progress yet, no earnings

                keyData.postDate = adData.progress[channel].postDate.toDate().toLocaleDateString()
                keyData.value = Math.round((adData.totalCost * Math.min(adData.progress[channel].impressions.at(-1) / adData.totalImpressions, 1) * 100)) / 100
                if ( adData.isActive && now > adData.startDate && now < adData.endDate ) {
                    next += keyData.value
                    nextList.push(keyData)
                } else if ( now > adData.endDate ) {
                    total += keyData.value
                    totalList.push(keyData)
                    // check if it is available for withdrawal
                    if (!userData.paymentHistory) {
                        available += keyData.value
                        availableList.push(keyData)
                    } else if (!userData.paymentHistory.some(payment => payment.for.includes(adId) && payment.channels.includes(channel))) {
                        available += keyData.value
                        availableList.push(keyData)
                    }
                }
            }
        }
        nextList.sort((a, b) => a.start.localeCompare(b.start))
        totalList.sort((a, b) => a.start.localeCompare(b.start))

        for (const ad of (userData.potentialAds || [])) {
            potential += ad.potentialEarnings
            potentialList.push({text: (ad.advertiserName + ': ' + ad.advertisementName), value: ad.potentialEarnings, channel: ad.channel})
        }

        // idk if this is the right way to go about things...
        if (userData.firstTimeUser || userData.pending) {
            potential = 0
            potentialList = []
        }

        return {'next': next, 'total': total, 'potential': potential, 'available': available, 'nextList': nextList, 'totalList': totalList, 'potentialList': potentialList, 'availableList': availableList}
    }
    
    function getChannelData(channelId) {
        return getDoc( doc(db, 'channels', channelId) )
    }

    async function deleteChannel( platform, handle ) {
        setUserData( prevUserData => ({
            ...prevUserData,
            platforms: [...prevUserData.channels.filter(channel => channel.platform !== platform || channel.handle !== handle)]
        }))

        await deleteDoc( doc( db, 'channels', platform + '-' + handle ) )
        return updateDoc( doc( db, 'users', currentUser.uid ), { platforms: arrayRemove([platform + '-' + handle]) } )
    }

    async function updateChannelData(platform, handle, token = '') {
        let data
        if ( platform === 'youtube' ) {
            const channelData = await getYoutubeChannelData(handle)
            if (channelData === 404 || !channelData.id) return 404

            data = {
                id: channelData.id,
                country: channelData.snippet.country || 'US',
                description: channelData.snippet.description,
                thumbnail: channelData.snippet.thumbnails.default.url,
                publishedAt: Timestamp.fromDate(new Date(Date.parse(channelData.snippet.publishedAt))),
                subscriberCount: Number(channelData.statistics.subscriberCount),
                viewCount: Number(channelData.statistics.viewCount),
                videoCount: Number(channelData.statistics.videoCount),
                lastRefreshed: Timestamp.now()
            }

            if (Number(channelData.statistics.videoCount) !== 0) {
                const channelVideoData = await getYoutubeChannelRecentVideosData(channelData.id)
                if (channelVideoData === 401 || channelVideoData === 405) return 405

                const lastNVideos =  [...(channelVideoData.map(vid => ({...vid, type: 'youtube_video'})))].sort((a, b) => b.publishedAt.toDate() - a.publishedAt.toDate())
                data.lastNVideos = lastNVideos

                const mostRecentVideoData = await getYoutubeVideoData(lastNVideos[0].id)
                if (mostRecentVideoData === 401) return 405
                data.mostRecentVideo = mostRecentVideoData

                const bestVideoID = await getYoutubeChannelBestVideoID(channelData.id)
                if ( bestVideoID === 401 ) return 405

                const bestVideoData = await getYoutubeVideoData(bestVideoID)
                if (bestVideoData === 401) return 405
                data.bestVideo = bestVideoData
            }
        }
        // not supporting twitch
        // else if ( platform === 'twitch' ) {
        //     const verified = await verifyTwitchToken(token)
        //     if ( verified === 504 || verified === 505 ) {
        //         return 405 // issue authenticating user
        //     }

        //     const channelData = await getTwitchChannelData(token)
        //      if ( channelData === 404) {
        //         return 404 // not sure this can ever even happen idk man this API sucks balls
        //     } else {
        //         if ( channelData.display_name !== handle ) {
        //             return 404 // this could happen if a user validates an account but tries to sneak in a different handle in the input
        //         }

        //         let channelVideoData = await getTwitchChannelVideoData(token, channelData.id)
        //         if ( channelVideoData[0] !== 405 ) {
        //             channelVideoData[0].map(video => ({
        //                 type: 'twitch_vod',
        //                 viewCount: video.view_count,
        //                 publishedAt: Timestamp.fromDate(new Date(Date.parse(video.published_at))),

        //             }))
        //         } else {
        //             console.log('We aren\'t gonna have a problem, but you sure do ;)')
        //             channelVideoData[0] = []
        //             channelVideoData[1] = 0
        //         }

        //         let channelSubCount = await getTwitchChannelFollowerCount(token, channelData.id)
        //         if (channelSubCount < 0) {
        //             channelSubCount = 0
        //         }

        //         data = {
        //             id: channelData.id,
        //             country: 'US',
        //             description: channelData.description,
        //             thumbnail: channelData.profile_image_url,
        //             publishedAt: Timestamp.fromDate(new Date(Date.parse(channelData.created_at))),
        //             viewCount: channelData.view_count,
        //             subscriberCount: channelSubCount,
        //             videoCount: channelVideoData[1],
        //             lastNVideos: channelVideoData[0],
        //             lastRefreshed: Timestamp.fromDate(new Date())
        //         }
        //     }
        // } 
        else {
            return 300
        }

        if ( !data ) {
            return 404 //idk just putting this here to be safe
        }

        // add channel data to channels collection
        await setDoc( doc( db, 'channels', platform + '-' + handle), data, { merge: true })
        // update user data with new channel (if it is new)
        await updateDoc( doc( db, 'users', currentUser.uid ), { channels: arrayUnion(`${platform}-${handle}`) } )
        // Calculates this channels current PPI and updates the channel doc accordingly, very important!
        await updatePPI(platform + '-' + handle)

        setUserData( prevUserData => ({
            ...prevUserData,
            channels: [
                ...(prevUserData.channels && prevUserData.channels.length ? prevUserData.channels.filter(channel => channel.platform !== platform && channel.handle !== handle) : []),
                {...data, platform: platform, handle: handle}
            ],
        }))

        return 200
    }

    async function updateChannelAdContent(platform, handle, adId, data, skipFirebase=false) {
        const channelRef = doc(db, 'channels', platform + '-' + handle)
        const channel = userData.channels.find(channel => channel.platform === platform && channel.handle === handle)
        if (!skipFirebase) {
            if (!channel.adContent) {
                await updateDoc( channelRef, {adContent: {[adId]: data}})
            } else if (!channel.adContent[adId]) {
                await updateDoc( channelRef, {[`adContent.${adId}`]: data})
            } else {
                await updateDoc( channelRef, {[`adContent.${adId}`]: {...channel.adContent[adId], ...data}})
            }
        }
        setUserData((prevData) => ({
            ...prevData,
            channels: [...prevData.channels.filter(channel => channel.platform !== platform || channel.handle !== handle), {
                ...channel, 
                adContent: {
                    ...channel.adContent, 
                    [adId]: data
                }
            }]
        }))
    }

    const deleteAdVerification = (channel, adId, videoFilePath) => {
        const videoRef = ref(getStorage(), videoFilePath)
        try {
            deleteObject(videoRef)
        } catch (err) {}
        
        const reversed = channel.split('-').slice(1).join('-') + '-' + channel.split('-')[0]
        const logoRef = ref(getStorage(), `/${adId}/creatorVideos/found_logos/${reversed}_best_logo.png`)
        try {
            deleteObject(logoRef)
        } catch (err) {}

        setUserData(prevData => ({
            ...prevData,
            channels: [...prevData.channels.filter(c => c.platform + '-' + c.handle !== channel), {
                ...userData.channels.find(c => c.platform + '-' + c.handle === channel), 
                adContent: {
                    ...userData.channels.find(c => c.platform + '-' + c.handle === channel).adContent, 
                    [adId]: {isVerified: false}
                }
            }]
        }))

        return updateDoc(doc(db, 'channels', channel), {[`adContent.${adId}`]: {isVerified: false}})
    }

    function addCalendarEvent(dateString, data) {
        setUserData(prevData => ({
            ...prevData,
            events: {
                ...prevData.events,
                [dateString]: (prevData.events[dateString] ? [...prevData.events[dateString], data] : [{...data}])
            }
        }))

        if (userData.events[dateString]) return updateDoc( doc(db, 'users', currentUser.uid), {['events.' + dateString]: arrayUnion(data)})
        else return updateDoc( doc(db, 'users', currentUser.uid), {['events.' + dateString]: [{...data}]})
    }

    function deleteCalendarEvent(dateString, event) {
        setUserData(prevData => ({
            ...prevData,
            events: {
                ...prevData.events,
                [dateString]: (prevData.events[dateString].filter(evt => evt.eventName !== event.eventName))
            }
        }))

        if ( userData.events[dateString].length < 2 ) return updateDoc( doc( db, 'users', currentUser.uid ), {['events.' + dateString]: deleteField() })
        else return updateDoc( doc(db, 'users', currentUser.uid), {['events.' + dateString]: userData.events[dateString].filter(evt => evt.eventName !== event.eventName)})
    }

    function updateCalendarEvent(dateString, oldName, event) {
        setUserData(prevData => ({
            ...prevData,
            events: {
                ...prevData.events,
                [dateString]: [...(prevData.events[dateString].filter(evt => evt.eventName !== oldName)), event]
            }
        }))

        return updateDoc( doc(db, 'users', currentUser.uid), {['events.' + dateString]: [...userData.events[dateString].filter(evt => evt.eventName !== oldName), event]})
    }

    //---------- CHANNEL API FUNCTIONS ----------//

    async function getYoutubeChannelData(handle) {
        let response;
        const url = 'https://www.googleapis.com/youtube/v3/channels'
        try {
            const apiResponse = await axios.get( url, {
                params: {
                    part: 'snippet, statistics',
                    forHandle: handle,
                    key: process.env.REACT_APP_YOUTUBE_API_KEY,
                }
            })
            response = apiResponse.data.items[0]
        } catch (err) {
            response = 404
        } finally {
            return response
        }
    }

    async function getYoutubeChannelRecentVideosData(channelID, numVideos=5) {
        let response = [];
        const url = 'https://www.googleapis.com/youtube/v3/search'
        try {
            const apiResponse = await axios.get( url, {
                params: {
                    part: 'id',
                    channelId: channelID,
                    order: 'date',
                    maxResults: numVideos,
                    key: process.env.REACT_APP_YOUTUBE_API_KEY,
                }
            })
            for(const item of apiResponse.data.items) {
                if ( item.id.kind && item.id.kind.includes('video') ) {
                    const videoId = item.id.videoId
                    const videosUrl = 'https://www.googleapis.com/youtube/v3/videos'
                    try {
                        const videosResponse = await axios.get( videosUrl, {
                            params: {
                                part: 'statistics, snippet',
                                id: videoId,
                                key: process.env.REACT_APP_YOUTUBE_API_KEY
                            }
                        })
                        const resData = videosResponse.data.items[0]
                        const valuedData = {
                            commentCount: Number(resData.statistics.commentCount),
                            likeCount: Number(resData.statistics.likeCount),
                            viewCount: Number(resData.statistics.viewCount),
                            id: videoId,
                            publishedAt: Timestamp.fromDate(new Date(resData.snippet.publishedAt))
                        }
                        response.push(valuedData)
                    } catch (err) {
                        response = 405
                        return response
                    }
                }
            }
        } catch (err) {
            response = 401
        } finally {
            return response
        }
    }

    async function getYoutubeChannelBestVideoID(channelID) {
        const url = 'https://www.googleapis.com/youtube/v3/search'
        try {
            const response = await axios.get(url, {
                params: {
                    part: 'snippet',
                    channelId: channelID,
                    maxResults: 1,
                    order: 'viewCount',
                    key: process.env.REACT_APP_YOUTUBE_API_KEY

                }
            })

            return response.data.items[0].id.videoId
        } catch (err) {
            return 401
        }
    }

    // get the snippet, content details, statistics, and topic details data on a given video ID
    async function getYoutubeVideoData(videoID) {
        let response
        const url = 'https://www.googleapis.com/youtube/v3/videos'
        try {
            response = await axios.get(url, {
                params: {
                    part: 'snippet,contentDetails,statistics,topicDetails',
                    id: videoID,
                    key: process.env.REACT_APP_YOUTUBE_API_KEY
                }
            })
            response = response.data.items[0]
            response = {
                ...response,
                statistics: {
                    commentCount: Number(response.statistics.commentCount),
                    likeCount: Number(response.statistics.likeCount),
                    viewCount: Number(response.statistics.viewCount)
                }
            }
        } catch (err) {
            response = 401
        } finally {
            return response
        }
    }

    // Currently not supporting twitch
    // async function verifyTwitchToken(accessToken) {
    //     const verifyUrl = 'https://id.twitch.tv/oauth2/validate'
    //     try {
    //         const verifyResponse = await fetch( verifyUrl, {
    //             'headers': {
    //                 'Authorization': `OAuth ${accessToken}`
    //             }
    //         })
    //         if ( verifyResponse.status !== 200) {
    //             return 505
    //         }
    //     } catch( err ) {
    //         return 504
    //     } finally {
    //         return 200
    //     }
    // }

    // async function getTwitchChannelData(accessToken) {
    //     let response
    //     const url = 'https://api.twitch.tv/helix/users'
    //     try {
    //         const apiResponse = await fetch( url, {
    //             'headers': {
    //                 'Authorization': `Bearer ${accessToken}`,
    //                 'Client-Id': process.env.REACT_APP_TWITCH_CLIENT_ID,
    //             }
    //         }).then(res => res.json())
    //         response = apiResponse.data[0]
    //     } catch ( err ) {
    //         response = 404
    //     } finally {
    //         return response
    //     }
    // }

    // async function getTwitchChannelVideoData(accessToken, channelId) {
    //     let response = []
    //     let count = 0
    //     let hasNextPage = true
    //     const url = 'https://api.twitch.tv/helix/videos'
    //     let options = {
    //         'headers': {
    //             'Authorization': `Bearer ${accessToken}`,
    //             'Client-Id': process.env.REACT_APP_TWITCH_CLIENT_ID
    //         },
    //         'params': {
    //             'user_id': channelId,
    //             'first': 100,
    //         }
    //     }

    //     try {
    //         while (hasNextPage) {
    //             const apiResponse = await axios.get( url, options )
    //             if ( count === 0 ) {
    //                 response = apiResponse.data.slice(0, 5)
    //             }
    //             count += apiResponse.data.length

    //             if ( apiResponse.pagination.cursor ) {
    //                 options['params']['after'] = apiResponse.pagination.cursor
    //             } else {
    //                 hasNextPage = false
    //             }
    //         }
    //     } catch( err ) {
    //         response = 405
    //     } finally {
    //         return [response, count]
    //     }
    // }

    // async function getTwitchChannelFollowerCount(accessToken, channelId) {
    //     let response
    //     const url = 'https://api.twitch.tv/helix/channels/followers'
    //     try {
    //         const apiResponse = await axios.get( url, {
    //             'headers': {
    //                 'Authorization': `Bearer ${accessToken}`,
    //                 'Client-Id': process.env.REACT_APP_TWITCH_CLIENT_ID
    //             },
    //             params: {
    //                 'broadcaster_id': channelId
    //             }
    //         })
    //         response = apiResponse.data.total
    //     } catch ( err ) {
    //         response = -1
    //     } finally {
    //         return response
    //     }
    // }

    const uploadCreatorVideo = (videoFile, fileName, onProgress, onComplete) => {
        const storageRef = ref(getStorage(), fileName)
        const uploadTask = uploadBytesResumable(storageRef, videoFile)
        uploadTask.on('state_changed', (snapshot) => {
            const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100
            if (onProgress) {
                onProgress(progress)
            }
        }, (error) => {
            throw new Error("Upload Error: " + error.message)
        }, () => {
            getDownloadURL(uploadTask.snapshot.ref).then((url) => {
                if (onComplete) {
                    onComplete(url)
                }
            })
        })
    }

    // get average ppi across all creators channels
    const getCreatorAveragePPI = () => {
        if (!userData.channels || !userData.channels.length) return 0
        return Math.round(userData.channels.reduce((sum, curr) => curr.ppi + sum, 0) / userData.channels.length)
    }

    //---------- BUCKET FUNCTIONS ----------//

    function getBuckets() {
        return getDocs(collection(db, 'buckets'))
    }

    async function updateBuckets( bucketStates ) {
        const buckets = []
        const subBuckets = []
        for ( const bName of Object.keys( bucketStates )) {
            const [ bucketName, subBucketName ] = bName.split('/')
            const bucketRef = doc( db, 'buckets', bucketName)

            if ( !subBucketName ) {
                if ( bucketStates[bucketName] ) {
                    await updateDoc( bucketRef, { users: arrayUnion( currentUser.uid ) })
                    buckets.push(bucketName)
                }
                else {
                    await updateDoc( bucketRef, { users: arrayRemove( currentUser.uid ) })
                }
            }
            else {
                if ( bucketStates[bName] ) {
                    await updateDoc( bucketRef, { ['subBuckets.' + subBucketName]: arrayUnion( currentUser.uid ) })
                    subBuckets.push(subBucketName)
                }
                else {
                    await updateDoc( bucketRef, { ['subBuckets.' + subBucketName]: arrayRemove( currentUser.uid ) })
                }
            }
        }
        setUserData(prevData => ({...prevData, buckets: buckets, subBuckets: subBuckets}))
    }

    const getCreatorBuckets = useCallback(async (uid) => {
        const buckets = await getDocs(collection(db, 'buckets'))

        let userBuckets = []
        let subBuckets = []
        buckets.forEach(bucket => {
            const bucketData = bucket.data()
            if (bucketData.users.includes(uid)) userBuckets.push(bucket.id)
            Object.keys(bucketData.subBuckets).forEach(key => {
                if (bucketData.subBuckets[key].includes(uid)) subBuckets.push(key)
            })
        })
    return {'buckets': userBuckets, 'subBuckets': subBuckets}
    }, [])

    //---------- AD FUNCTIONS ----------//

    function getAdvertisementFromID( adId ) {
        return getDoc( doc( db, 'advertisements', adId ) )
    }

    // Add an ad to a creators history, effectively marking it as in progress for that creator
    async function acceptAd( adId, quota, channel ) {
        const adData = (await getDoc(doc(db, 'advertisements', adId))).data()
        const platform = channel.split('-')[0]
        const handle = channel.split('-').slice(1).join('-')

        const currDate = Timestamp.fromDate(new Date())
        let newAdData
        if (userData.history[adId]) {
            newAdData = {quota: userData.history[adId].quota + quota, channels: [...userData.history[adId].channels, channel]}
        } else {
            newAdData = {quota: quota, startDate: currDate, channels: [channel]}
        }

        const channelData = userData.channels.find(channel => channel.platform === platform && channel.handle === handle)
        setUserData( prevUserData => ({
            ...prevUserData,
            history: {
                ...prevUserData.history,
                [adId]: {...adData, ...newAdData}
            },
            channels: [...prevUserData.channels.filter(channel => channel.platform !== platform || channel.handle !== handle), {
                ...channelData, 
                adContent: {
                    ...channelData.adContent, 
                    [adId]: {isVerified: false}
                }
            }],
            potentialAds: prevUserData.potentialAds.filter(ad => adId !== ad.id || channel !== ad.channel)
        }))

        await updateDoc( doc( db, 'advertisements', adId ), { creators: arrayUnion(channel), potentialCreators: arrayRemove(channel) } )
        await updateDoc( doc( db, 'channels', channel ), {['adContent.' + adId]: {isVerified: false}})
        return updateDoc( doc( db, 'users', currentUser.uid ), { ['history.' + adId]: {...newAdData} } )
    }

    // prevent this ad from being shown to a creator
    function declineAd( adId, channel ) {
        setUserData( prevUserData => ({
            ...prevUserData,
            potentialAds: prevUserData.potentialAds.filter(ad => adId !== ad.id || channel !== ad.channel)
        }))

        return updateDoc( doc(db, 'advertisements', adId), { blacklist: arrayUnion(channel), potentialCreators: arrayRemove(channel) })
    }

    // if an adId is provided, this function will update the ad at that ad id instead of add a new one
    async function addAdvertisement(data, adId=null) {
        let logoImage, clipVideo, otherMedia

        if (data.logoImage && (data.logoImage instanceof Array)) {
            if (!adId || userData.history[adId].logoImage !== data.logoImage[1]) {
                logoImage = data.logoImage[0]
                delete data['logoImage']
            } else if (adId) {
                data.logoImage = data.logoImage[1]
            } else {
                delete data['logoImage']
            }
        }
        if (data.clipVideo && (data.clipVideo instanceof Array)) {
            if (!adId || userData.history[adId].clipVideo !== data.clipVideo[1]) {
                clipVideo = data.clipVideo[0]
                delete data['clipVideo']
            } else if (adId) {
                data.clipVideo = data.clipVideo[1]
            } else {
                delete data['clipVideo']
            }
        }
        if (data.otherMedia && (data.otherMedia instanceof Array)) {
            if (!adId || userData.history[adId].otherMedia !== data.otherMedia[1]) {
                otherMedia = data.otherMedia[0]
                delete data['otherMedia']
            } else if (adId) {
                data.otherMedia = data.otherMedia[1]
            } else {
                delete data['otherMedia']
            }
        }

        if (!adId) {
            adId = await addDoc( collection( db, 'advertisements' ), {...data, currentImpressions: 0} )
        } else {
            await updateDoc( doc( db, 'advertisements', adId ), {...data} )
            adId = {id: adId}
        }

        const adDataStorageLinks = {}
        if ( (logoImage && (logoImage instanceof File)) || (clipVideo && (clipVideo instanceof File)) || (otherMedia && (otherMedia instanceof File)) ) {
            const storage = getStorage()
            
            if ( logoImage ) {
                const storageRef = ref(storage, adId.id + `/logo/${data.advertisementName.replaceAll(' ', '_')}logo.${logoImage.name.split('.').pop()}`)
                await uploadBytes(storageRef, logoImage)
                adDataStorageLinks.logoImage = await getDownloadURL(storageRef)
            }
            if ( clipVideo ) {
                const storageRef = ref(storage, adId.id + `/clip/${data.advertisementName.replaceAll(' ', '_')}_clip.${clipVideo.name.split('.').pop()}`)
                await uploadBytes(storageRef, clipVideo)
                adDataStorageLinks.clipVideo = await getDownloadURL(storageRef)
            }
            if ( otherMedia ) {
                const storageRef = ref(storage, adId.id + '/misc/' + otherMedia.name)
                await uploadBytes(storageRef, otherMedia)
                adDataStorageLinks.otherMedia = await getDownloadURL(storageRef)
            }
            try {
                await updateDoc( doc( db, 'advertisements', adId.id ), {...adDataStorageLinks})
            } catch (err) {
                console.log(err)
            }
        }

        // const costInfo = await updateAdCost(adId.id) // EXTREMELY IMPORTANT

        setUserData(prevData => ({
            ...prevData,
            history: {
                ...prevData.history,
                [adId.id]: {...data, ...adDataStorageLinks}
            }
        }))
        await updateDoc( doc( db, 'users', currentUser.uid ), { ['history.' + adId.id]: {isActive: data.isActive, isPotential: data.isPotential, startDate: data.startDate} } )
        
        return adId.id
    }

    async function updateAdvertisement(adId, data) {
        return addAdvertisement(data, adId)
    }

    async function deleteAdvertisement(adId) {
        await deleteDoc( doc( db, 'advertisements', adId ) )

        try {
            if (userData.history[adId].logoImage) await deleteObject( ref( getStorage(), userData.history[adId].logoImage ) )
            if (userData.history[adId].clipVideo) await deleteObject( ref( getStorage(), userData.history[adId].clipVideo ) )
            if (userData.history[adId].otherMedia) await deleteObject( ref( getStorage(), userData.history[adId].otherMedia ) )
        } catch (err) {
            console.log(err)
        }

        const newUserHistory = { ...userData.history }
        delete newUserHistory[adId]

        if ( userData.history[adId].isPotential ) {
            setUserData(prevData => ({
                ...prevData, 
                history: newUserHistory
            }))

            return updateDoc( doc( db, 'users', currentUser.uid ), {['history.' + adId]: deleteField()})
        } else {
            const transactionData = {
                type: 4,
                amount: userData.history[adId].totalCost,
                date: Timestamp.fromDate(new Date()),
                for: adId
            }

            const startDate = new Date(userData.history[adId].startDate.seconds * 1000)
            const adDateString = `${startDate.getFullYear()}-${String(startDate.getMonth() + 1).padStart(2, '0')}-${String(startDate.getDate()).padStart(2, '0')}`
            await deleteCalendarEvent(adDateString, userData.events[adDateString].find(event => event.eventName === userData.history[adId].advertisementName))

            const endDate = new Date(userData.history[adId].endDate.seconds * 1000)
            const adEndDateString = `${endDate.getFullYear()}-${String(endDate.getMonth() + 1).padStart(2, '0')}-${String(endDate.getDate()).padStart(2, '0')}`
            await deleteCalendarEvent(adEndDateString, userData.events[adEndDateString].find(event => event.eventName === userData.history[adId].advertisementName))

            setUserData(prevData => ({
                ...prevData,
                history: newUserHistory,
                paymentHistory: [...prevData.paymentHistory, transactionData]
            }))

            return updateDoc( doc( db, 'users', currentUser.uid ), {['history.' + adId]: deleteField(), paymentHistory: arrayUnion( transactionData ) } )
        }
    }

    async function deactivateAdvertisement(adId) {
        await updateDoc( doc( db, 'advertisements', adId ), {isActive: false, isPotential: true, progress: deleteField() } )

        const transactionData = {
            type: 4,
            amount: userData.history[adId].totalCost,
            date: Timestamp.fromDate(new Date()),
            for: adId
        }

        try {
            const startDate = new Date(userData.history[adId].startDate.seconds * 1000)
            const adDateString = `${startDate.getFullYear()}-${String(Number(startDate.getMonth()) + 1).padStart(2, '0')}-${String(startDate.getDate()).padStart(2, '0')}`
            await deleteCalendarEvent(adDateString, userData.events[adDateString].find(event => event.eventName === userData.history[adId].advertisementName))

            const endDate = new Date(userData.history[adId].endDate.seconds * 1000)
            const adEndDateString = `${endDate.getFullYear()}-${String(endDate.getMonth() + 1).padStart(2, '0')}-${String(endDate.getDate()).padStart(2, '0')}`
            await deleteCalendarEvent(adEndDateString, userData.events[adEndDateString].find(event => event.eventName === userData.history[adId].advertisementName))
        } catch (err) {
            console.error('Failed to find associated ad events, ignoring')
        }
        

        const newAdHistoryData = {...userData.history[adId], isActive: false, isPotential: true}
        delete newAdHistoryData['progress']
        setUserData(prevData => ({
            ...prevData,
            history: {
                ...prevData.history,
                [adId]: newAdHistoryData
            },
            paymentHistory: [...prevData.paymentHistory, transactionData]
        }))

        return updateDoc( doc( db, 'users', currentUser.uid ), { ['history.' + adId]: {isActive: false, isPotential: true}, paymentHistory: arrayUnion( transactionData ) } )
    }

    async function bookAdvertisement(adId) {
        const transactionData = {
            type: 3,
            amount: userData.history[adId].totalCost,
            date: Timestamp.fromDate(new Date()),
            for: adId
        }

        let startDate = new Date(userData.history[adId].startDate.seconds * 1000)
        let endDate = new Date(userData.history[adId].endDate.seconds * 1000)

        // if ad was created days before being booked, update the start date to today and end date to a month from now
        if (startDate < new Date()) {
            const nextMonth = new Date()
            nextMonth.setMonth(nextMonth.getMonth() + 1)

            startDate = new Date()
            endDate = nextMonth
        }

        const startDateString = `${startDate.getFullYear()}-${String(startDate.getMonth() + 1).padStart(2, '0')}-${String(startDate.getDate()).padStart(2, '0')}`
        const startTimestamp = Timestamp.fromDate(startDate)

        const endDateString = `${endDate.getFullYear()}-${String(endDate.getMonth() + 1).padStart(2, '0')}-${String(endDate.getDate()).padStart(2, '0')}`
        const endTimestamp = Timestamp.fromDate(endDate)

        await updateDoc( doc( db, 'advertisements', adId ), {isActive: true, isPotential: false, startDate: startTimestamp, endDate: endTimestamp, progress: {}} )
        await updateDoc( doc( db, 'users', currentUser.uid ), {
            ['history.' + adId + '.isActive']: true, 
            ['history.' + adId + '.isPotential']: false, 
            ['history.' + adId + '.startDate']: startTimestamp, 
            paymentHistory: arrayUnion( transactionData ) 
        })

        setUserData(prevData => ({
            ...prevData,
            history: {
                ...prevData.history,
                [adId]: {
                    ...prevData.history[adId],
                    isActive: true,
                    isPotential: false,
                    progress: {}
                }
            },
            paymentHistory: [...prevData.paymentHistory, transactionData]
        }))
        

        const eventData = {eventType: 0, eventName: userData.history[adId].advertisementName, eventDesc: userData.history[adId].description, eventID: adId, eventTime: '00:00'}
        await addCalendarEvent(startDateString, eventData) // start of ad event
        await addCalendarEvent(endDateString, {...eventData, eventType: 1}) // end of ad event

        sendEmail(currentUser.email, 'Fiable: Your Ad is Booked!', `Thank you for booking the ad "${userData.history[adId].advertisementName}". We will begin distributing it to our creators and you will be able to view the results in you platform as soon as the first creator posts!`)
        sendEmail('fiable@fiable-solutions.com', 'An advertiser just booked an ad!', 'The ad had the following data: \n' + JSON.stringify(userData.history[adId]), false)
        return true
    }

    async function bookAllSavedAdvertisements() {
        for (const adId of Object.keys(userData.history).filter(ad => userData.history[ad].isPotential)) {
            await bookAdvertisement(adId)
        }
    }

    //---------- BLOG FUNCTIONS ----------//

    async function uploadBlogImage( file ) {
        const storageRef = ref( getStorage(), 'blogPics/' + file.name )
        await uploadBytes( storageRef, file )
        return getDownloadURL(storageRef)
    }

    async function getBlogPosts() {
        return await getDocs(collection(db, 'blogs'))
    }

    function getBlogPost(id) {
        return getDoc( doc( db, 'blogs', id ) )
    }

    function addBlogPost(data) {
        return addDoc(collection( db, 'blogs' ), data)
    }

    function updateBlogPost(data) {

    }

    async function deleteBlogPost(docID, imageURL=null) {
        if (imageURL) {
            await deleteObject( ref( getStorage(), imageURL ) )
        }

        return deleteDoc( doc( db, 'blogs', docID ) )
    }

    function deleteBlogPostComment( docID, comment ) {
        return updateDoc( doc(db, 'blogs', docID ), { comments: arrayRemove( comment )})
    }

    function addBlogPostComment( blogId, commentData) {
        return setDoc( doc( db, 'blogs', blogId ), { comments: arrayUnion(commentData) }, {merge: true} )
    }

    //---------- EMAIL FUNCTIONS ----------//

    async function sendVerificationCode( email ) {
        const code = (Math.round(Math.random() * 100000) + "").padEnd(6, "0")
        const data = {
            from: "Fiable <fiable@fiable-solutions.com>",
            to: [email],
            message: {
                subject: "Email Verification Code",
                text: 'Your Email Verification code is ' + code
            }
        }
        await addDoc( collection( db, 'mail' ), data )
        return code
    }

    function sendEmail( recipient, subject, body, isHtml ) {
        const data = {
            to: recipient,
            message: {
                subject: subject,
                [isHtml ? 'html' : 'text']: body
            }
        }
        return addDoc( collection( db, 'mail' ), data )
    }

    function subscribeToNewsletter( email ) {
        return updateDoc( doc( db, 'mail', 'newsletter' ), {emails: arrayUnion(email) }, {merge: true} )
    }

    //---------- PAYMENT FUNCTIONS ----------//

    // creates a stripe payment intent, and returns the client secret for that intent
    async function createPaymentIntent( amount, currency ) {
        const createPaymentIntentFunction = httpsCallable(functions, 'createPaymentIntent')
        const result = await createPaymentIntentFunction({ amount, currency })
        return result.data.clientSecret
    }

    //---------- ACHIEVEMENT FUNCTIONS ----------//

    // tracks new medals
    const [newMedal, setNewMedal] = useState(null)

    // helper to return the value of a token
    const getMedalValue = (medalId) => {
        const medal = medals[medalId]
        if (medal.payout) return medal.payout
        else {
            if (userData.social && userData.social.medals[medalId] && userData.social.medals[medalId].variant)
                return medal.tier * userData.social.medals[medalId].variant
            else return medal.tier * (medal.variant || 1)
        }
    }

    // adds a medal to a users social document
    const earnMedal = async (medalId, socialCode=userData.socialCode, medalData={}) => {
        if (!socialCode || !userData.social) return false
        
        const medal = medals[medalId]
        if (userData.social.tier < medal.tier) return false
        if (medal.type && userData.social.medals[medalId] && userData.social.medals[medalId].dateAchieved) return false
        else if (!medal.type && userData.social.medals[medalId] && userData.social.medals[medalId].variant >= medalData.variant) return false

        const medalValue = userData.social.score + getMedalValue(medalId)

        let userMedalData = {
            dateAchieved: medalData.dateAchieved || Timestamp.now(),
            isRedeemed: false,
            variant: (medalData.variant || medal.variant || 1),
            highlight: medalData.highlight || false
        }
        if (medalData && medalData.value) userMedalData.value = medalData.value

        await updateDoc( doc( db, 'social', socialCode ), {['medals.' + medalId]: {...userMedalData}, score: medalValue} )
        if (userData.social.tier >= medal.tier) setTimeout(() => setNewMedal(medalId), 1200)
        setUserData(prevData => ({...prevData, social: {...prevData.social, medals: {...prevData.social.medals, [medalId]: userMedalData}, score: medalValue}}))
        return true
    }

    // ONE TIME event to add medal value in tokens to user
    const redeemMedal = async (medalId, socialCode=userData.socialCode,) => {
        if (!socialCode || !userData.social || !userData.social.medals) return false
        if (!userData.social.medals[medalId] || userData.social.medals[medalId].isRedeemed) return false

        const medalValue = userData.social.tokens + getMedalValue(medalId)
        const newData = {isRedeemed: true, dateRedeemed: Timestamp.now()}
        await updateDoc( doc( db, 'social', socialCode ), {['medals.' + medalId]: {...userData.social.medals[medalId], ...newData}, tokens: medalValue})
        setUserData(prevData => ({...prevData, social: {...prevData.social, medals: {...prevData.social.medals, [medalId]: {...prevData.social.medals[medalId], ...newData}}, tokens: medalValue}}))
        return true
    }

    // ONE TIME event to redeem a list of medal ids and add tokens to user
    const redeemMedals = async (medalIds, socialCode=userData.socialCode) => {
        if (!socialCode || !userData.social || !userData.social.medals) return false
        
        let newTokens = userData.social.tokens
        const newData = {}
        for (const id of medalIds) {
            if (userData.social.medals[id] && !userData.social.medals[id].isRedeemed) {
                newTokens += getMedalValue(id)
                newData[id] = {...userData.social.medals[id], isRedeemed: true, dateRedeemed: Timestamp.now()}
            }
        }
        await updateDoc( doc( db, 'social', socialCode ), {medals: {...userData.social.medals, ...newData}, tokens: newTokens})
        setUserData(prevData => ({...prevData, social: {...prevData.social, medals: {...prevData.social.medals, ...newData}, tokens: newTokens}}))
        return true
    }

    //---------- SOCIAL FUNCTIONS ----------//

    // add a new social document with name social code and data
    const addSocialData = (data, socialCode) => {
        setUserData(prevData => ({...prevData, social: {...data}}))
        return setDoc(doc( db, 'social', socialCode ), {...data} )
    }

    // get the social data associated with socialCode
    const getSocialData = (socialCode = userData.socialCode) => {
        return getDoc( doc( db, 'social', socialCode ) )
    }

    // set the social data associated with socialCode
    const setSocialData = async (data, socialCode = userData.socialCode) => {
        if (!socialCode || !userData.social) return false

        await updateDoc( doc( db, 'social', socialCode ), {...data} )
        if (socialCode === userData.socialCode)
            setUserData(prevData => ({...prevData, social: {...prevData.social, ...data}}))
    }

    //---------- CLOUD FUNCTION WRAPPERS ----------//

    // returns an object containing totalCost (total ad cost), and receipt, a list of line items containing an amount and a description
    // each of these fields is also updated in the associated ad doc
    // async function updateAdCost(adId) {
    //     const updateAdCostFunction = httpsCallable(functions, 'getAdCost')
    //     const result = await updateAdCostFunction({ adId })
    //     return result.data
    // }

    // returns the ppi and calculation info for the current user on the given channel
    // also sets the ppi and ppiLastRefreshed fields in the creator's channel object
    async function updatePPI(channel) {
        const updatePPIFunction = httpsCallable(functions, 'calculatePPI')
        const result = await updatePPIFunction({ channelId: channel })
        return result.data
    }

    async function verifyCreatorAdVideo(platform, handle, adId, onComplete, onError) {
        const inputData = {channelId: platform + '-' + handle, adId: adId}
        const verifyVideoFunction = httpsCallable(functions, 'verifyAdVideo', {timeout: 540000})
        verifyVideoFunction(inputData).then(async (res) => {
            await updateChannelAdContent(platform, handle, adId, {...userData.channels.find(c => c.platform === platform && c.handle === handle).adContent[adId], ...res.data}, true)
            onComplete(res.data)
        }).catch((err) => {
            onError(err)
        })
    }

    //---------- LOAD DATA ON AUTH CHANGE ----------//

    // gets the potential ads for a creator, only called when page is first loaded
    const getPotentialAdsForCreator = useCallback(async (currData) => {
        const ads = await getDocs(query(collection(db, 'advertisements'), where('isActive', '==', true), where('potentialCreators', 'array-contains-any', currData.channels || [])))
        const potentials = []
        ads.forEach(async ad => {
            const adData = ad.data()
            const relevantChannels = adData.potentialCreators.filter(channel => currData.channels.includes(channel))
            for (const channel of relevantChannels) {
                if ( Timestamp.now() < adData.endDate ) {
                    const ppi = (await getDoc(doc(db, 'channels', channel))).data().ppi || 0
                    let potentialData = {...adData, id: ad.id, quota: ppi, channel: channel}
                    potentialData.potentialEarnings = Math.floor(adData.totalCost * Math.min(potentialData.quota / adData.totalImpressions, 1) * 100) / 100
                    potentials.push(potentialData)
                }
            }
        })
        return potentials
    }, [])

    // gets the ad data associated with the ad ids in a users history
    const getUserAdData = useCallback(async (currData) => {
        const history = currData && currData.history ? currData.history : {}
        for ( const adId of Object.keys(history) ) {
            const adData = (await getDoc( doc( db, 'advertisements', adId ) )).data()
            history[adId] = {...adData, ...history[adId]}
        }
        return history
    }, [])

    useEffect(() => {
        const subscribe = auth.onAuthStateChanged( async (user) => {
            setLoading(true)
            try {
                if ( user ) {
                    // first set current user
                    setCurrentUser( user )
                    // then get userData
                    let loadedUserData = (await getDoc( doc(db, 'users', user.uid ) )).data() || {}

                    // then get adData associated with user and merge it into user data
                    loadedUserData.history = (await getUserAdData(loadedUserData))

                    // then, if user is a creator, add potential ads data and set type
                    if ( loadedUserData.creator ) {
                        const buckets = await getCreatorBuckets(user.uid)
                        loadedUserData.buckets = buckets.buckets
                        loadedUserData.subBuckets = buckets.subBuckets
                        if (loadedUserData.socialCode) {
                            loadedUserData.social = (await getDoc(doc(db, 'social', loadedUserData.socialCode))).data()
                        }
                        if (loadedUserData.channels && loadedUserData.channels.length) {
                            loadedUserData.potentialAds = (await getPotentialAdsForCreator({...loadedUserData, uid: user.uid}))
                            loadedUserData.channels = (await Promise.all(loadedUserData.channels.map(async channel => {
                                const newData = (await getDoc(doc(db, 'channels', channel))).data()
                                return {...newData, platform: channel.split('-')[0], handle: channel.split('-').slice(1).join('-')}
                            })))
                        }
                        setType(1)
                    } else {
                        setType(0)
                    }
                    

                    // finally, set user data to the newly created object
                    setUserData(loadedUserData)
                }
            } catch (err) {
                setErrorMsg('Failed to fetch user data')
            } finally {
                setLoading( false )
            }
        })

        return subscribe
    }, [getUserAdData, getPotentialAdsForCreator, getCreatorBuckets, setErrorMsg])

    //---------- PROVIDER VALUES ----------//

    const value = {
        currentUser,
        userData,
        type,
        loading,
        setLoading,
        login,
        sendVerificationCode,
        sendEmail,
        register,
        sendPasswordResetLink,
        reauthenticateUser,
        updateUserEmail,
        updateUserPassword,
        deletePlatform: deleteChannel,
        getCreatorEarnings,
        error,
        setError,
        warning,
        setWarning,
        success,
        setSuccess,
        getUserDataFromUID,
        setUserDataFromUID,
        getAdvertisementFromID,
        addAdvertisement,
        updateAdvertisement,
        deleteAdvertisement,
        deactivateAdvertisement,
        getBuckets,
        getCreatorBuckets,
        updateBuckets,
        acceptAd,
        declineAd,
        updateUserProfile,
        addCalendarEvent,
        deleteCalendarEvent,
        updateCalendarEvent,
        bookAdvertisement,
        bookAllSavedAdvertisements,
        setPending,
        logout,
        uploadAvatar,
        uploadBlogImage,
        uploadCreatorVideo,
        updateUserData,
        getChannelData,
        updateChannelData,
        updateChannelAdContent,
        deleteAdVerification,
        getCreatorAveragePPI,
        newMedal,
        setNewMedal,
        getMedalValue,
        earnMedal,
        redeemMedal,
        redeemMedals,
        getSocialData,
        setSocialData,
        addSocialData,
        getBlogPosts,
        getBlogPost,
        addBlogPost,
        updateBlogPost,
        deleteBlogPost,
        deleteBlogPostComment,
        addBlogPostComment,
        subscribeToNewsletter,
        createPaymentIntent,
        verifyCreatorAdVideo,
    }

    return (
        <AuthContext.Provider value={value}>
            { !loading && children }
        </AuthContext.Provider>
    )
}