import React, { useEffect, useState } from 'react'
import { useTheme } from '../../../../../contexts/themeContext'
import { Button, Card, Col, Modal, Row } from 'react-bootstrap'
import { useNavigate } from 'react-router-dom'
import { useAuth } from '../../../../../contexts/authContext'
import AdSummary from '../adSummary'

const PaginatedSection = (props) => {
    const {getCardStyle, isSmallScreen, darkMode} = useTheme()

    const [selected, setSelected] = useState()
    const [refresh, setRefresh] = useState()

    useEffect(() => {
        if (props.adData && props.adData.length) {
            setSelected(Object.keys(props.adData[0])[0])
        }
    }, [props.adData, setSelected, refresh])

    return (
        <Card className={'mb-2 ' + getCardStyle()}>
            <Card.Header className='bg-transparent pb-0'>
                <Card.Title className={'fs-1 fw-bold ' + (isSmallScreen && 'text-center')}>{props.title} Advertisements</Card.Title>
            </Card.Header>
            <Card.Body className='p-1 m-0 pt-0'>
                {props.adData && selected ? 
                    <>
                    <Row>
                        {props.adData.map(ad => (
                            <Col key={Object.values(ad)[0].advertisementName} className='text-center py-1'>
                                <Button
                                    className={'rounded-2 border-0 py-1 px-3 fw-bold fs-4 my-text-dark my-bg-' + (selected === Object.keys(ad)[0] ? 'primary' : 'secondary')}
                                    style={{transition: 'all 0.5s', opacity: (Object.keys(ad)[0] === selected ? 1 : 0.5)}}
                                    onClick={() => setSelected(Object.keys(ad)[0])}
                                >
                                    {Object.values(ad)[0].advertisementName}
                                </Button>
                            </Col>
                        ))}
                    </Row>
                    <Row className={'m-1 p-2 mb-2 justify-content-center border border-2 rounded border-' + (!darkMode ? 'light' : 'dark')} style={{transition: 'border 0.5s'}}>
                        <PageAdSummary ad={selected} type={props.type} onChange={() => setRefresh(!refresh)} />
                    </Row>
                    </>
                :
                    <h1 className='text-center'>You currently have no {props.title} Ads</h1>
                }
            </Card.Body>
        </Card>
    )
}

const PageAdSummary = (props) => {
    const {userData, deleteAdvertisement, deactivateAdvertisement, setError, setSuccess} = useAuth()
    const { darkMode, getBG, getTextInv, isSmallScreen, getButtonThemeStyle } = useTheme()
    const navigate = useNavigate()
    
    const [showDelete, setShowDelete] = useState(false)
    const [showDeacc, setShowDeacc] = useState(false)

    const [loading, setLoading] = useState(false)

    const getButtonDisabled = () => {
        const disableDate = new Date(userData.history[props.ad].startDate.seconds * 1000)
        disableDate.setHours(23, 23, 59, 999)

        if ( props.type === 1 || props.type === 2 ) {
            if ( userData.history[props.ad].creators && userData.history[props.ad].creators.length ) return true
            else if ( new Date(userData.history[props.ad].startDate.seconds * 1000) > disableDate ) return true
            else return false
        } else return false
    }

    const deleteAd = async () => {
        setLoading(true)
        setError('')
        setSuccess('')

        try {
            await deleteAdvertisement(props.ad)
            setSuccess('Deleted Advertisement')
        } catch (err) {
            setError('Failed to delete Advertisement. Try again later')
        } finally {
            props.onChange() 
            setLoading(false)
            setShowDelete(false)
        }
    }

    const deactivateAd = async () => {
        setLoading(true)
        setError('')

        try {
            await deactivateAdvertisement(props.ad)
            setSuccess('Deactivated Advertisement')
        } catch (err) {
            console.error(err)
            setError('Failed to deactivate Advertisement')
        } finally {
            props.onChange()
            setLoading(false)
            setShowDeacc(false)
        }
    }

    return (
        <>
            {!loading && <AdSummary data={userData.history[props.ad]} />}
            {isSmallScreen ? <></> : <>
            <Row className={'mx-4 border-top border-' + (darkMode ? 'dark' : 'light' )} style={{transition: 'border 0.5s'}}>
                {/* disabling edit advertisement for now 
                <Col lg={3}>
                    {props.type === 3 &&
                        <Button className='w-100 mx-1 rounded-1 mt-2 text-nowrap border-0 my-bg-primary my-text-dark' onClick={() => navigate('/create-ad/adId=' + props.ad)}>
                            Edit Ad
                        </Button>
                    }
                </Col> */}
                <Col className='d-flex flex-row justify-content-end'>
                    <Button 
                        className={'me-3 w-25 mt-2 text-nowrap ' + getButtonThemeStyle('md', 'danger')} 
                        onClick={() => setShowDelete(true)} 
                        disabled={true || loading || getButtonDisabled()}
                    >
                        Delete Ad
                    </Button>
                    <Button 
                        className={'w-25 mt-2 text-nowrap ' + getButtonThemeStyle('md', (props.type === 3 ? 'success' : 'danger'))} 
                        onClick={() => props.type === 3 ? navigate('/payment/' + props.ad) : setShowDeacc(true)}
                        disabled={props.type !== 3 || loading || (props.type !== 3 && getButtonDisabled())}
                    >
                        {props.type === 3 ? 'Book Ad Now!' : 'Deactivate Ad'}
                    </Button>
                </Col>
            </Row>
            <Modal show={showDelete} onHide={() => setShowDelete(false)} className={getTextInv()}>
                <Modal.Header className={getBG()} closeButton>
                    Delete Advertisement
                </Modal.Header>
                <Modal.Body className={'rounded-bottom ' + getBG()}>
                    <Row className='text-center'><h4>Please Confirm Deletion of This Ad</h4></Row>
                    <Row className='pb-2'><small className='my-text-danger text-center'>This cannot be undone</small></Row>
                    <Row>
                        <Col><Button className={getButtonThemeStyle('md', 'success')} onClick={() => setShowDelete(false)}>No, don't delete</Button></Col>
                        <Col className='text-end'><Button className={getButtonThemeStyle('md', 'danger')} onClick={() => deleteAd()}>Yes, delete it</Button></Col>
                    </Row>
                </Modal.Body>
            </Modal>
            <Modal show={showDeacc} onHide={() => setShowDeacc(false)} className={getTextInv()}>
                <Modal.Header className={getBG()} closeButton>
                    Deactivate Advertisement
                </Modal.Header>
                <Modal.Body className={'rounded-bottom ' + getBG()}>
                    <Row className='text-center'><h4>Please Confirm Deactivation of This Ad</h4></Row>
                    <Row>
                        <Col><Button className={getButtonThemeStyle('md', 'success')} onClick={() => setShowDeacc(false)}>No, don't deactivate</Button></Col>
                        <Col className='text-end'><Button className={getButtonThemeStyle('md', 'danger')} onClick={() => deactivateAd()}>Yes, deactivate it</Button></Col>
                    </Row>
                </Modal.Body>
            </Modal>
            </>}
        </>
    )
}

export default PaginatedSection