import React, { useState } from 'react'
import { Container, Button, Row, Col, Card, Form } from 'react-bootstrap';
import { useTheme } from '../../contexts/themeContext';
import { useAuth } from '../../contexts/authContext';

const firstNewsletter = [
    "<h2>In a world inundated with advertisements vying for attention, standing out has become the ultimate challenge for brands and creators alike.</h2>",
    "<h3>Enter Fiable – the game-changer in digital advertising. Empowering Creators, Amplifying Brands.</h3>",
    "<h4>Fiable isn't just another advertising platform. It's a dynamic ecosystem where creators and advertisers converge to create compelling content that captivates audiences. By seamlessly connecting creators with advertisers, Fiable facilitates authentic collaborations that resonate with viewers.</h4>",
    "<h4>Unmatched Engagement, Unbeatable Value: One of Fiable's most impressive feats? Its ability to deliver unparalleled engagement at half the cost of traditional YouTube ads. Imagine reaching your target audience with double the engagement rate, all while saving on your advertising budget. With Fiable, it's not just a dream – it's a reality.</h4>", 
    "<h4>Fiable simplifies the advertising process, making it accessible to creators and advertisers of all sizes. Here's how it works:</h4>",
    "<h5> - Creator Collaboration: Creators sign up on Fiable and showcase their talents and audience demographics.</h5>", 
    "<h5> - Advertiser Connection: Advertisers browse through a diverse pool of creators and select the perfect match for their campaign.</h5>", 
    "<h5> - Content Creation: Creators work closely with advertisers to craft authentic, engaging content that resonates with their audience.</h5>", 
    "<h5> - Distribution: Once the content is ready, Fiable leverages its network to distribute the ad across multiple platforms, ensuring maximum visibility.</h5>", 
    "<h4>Why Fiable? Fiable isn't just about numbers – it's about meaningful connections. Here's why creators and advertisers are flocking to our platform: Authenticity: Say goodbye to intrusive ads that disrupt the viewing experience. Fiable prioritizes authenticity, creating content that feels natural and resonates with audiences.</h4>", 
    "<h5> - Cost-Effectiveness: With Fiable, advertisers can stretch their advertising budget further while enjoying higher engagement rates.</h5>", 
    "<h5> - Diverse Network: Whether you're a niche creator or a global brand, Fiable offers access to a diverse network of creators from all walks of life.</h5>", 
    "<h4>Join the Revolution Are you ready to revolutionize your digital advertising strategy? Join Fiable today and experience the future of advertising firsthand. Say goodbye to cookie-cutter ads and hello to authentic, engaging content that captivates audiences. At Fiable, we're not just reimagining advertising – we're reshaping it. Join us on this exciting journey and discover what's possible when creators and advertisers come together in perfect harmony. Fiable: Where Creativity Meets Connectivity.</h4>",
]

const NewsletterSignup = () => {
    const { subscribeToNewsletter, sendEmail, setError } = useAuth()
    const { getText, getThemeBGInv, getTextInv, darkMode, getThemeTextInv, isSmallScreen} = useTheme()

    const [subscribed, setSubscribed] = useState( false )
    const [loading, setLoading] = useState( false )

    const addToNewsletter = async (e) => {
        e.preventDefault()
        setLoading(true)

        try {
            const email = [...(new FormData(e.target)).entries()][0][1]
            const firstTimeSubscriberMessage = {
                subject: 'Thank you for subscribing to Fiable\'s Newsletter!',
                html: '<h1>Fiable: Transforming Ads, Empowering Creators</h1>' + firstNewsletter.join('')
            }
            await subscribeToNewsletter( email )
            await sendEmail(email, firstTimeSubscriberMessage.subject, firstTimeSubscriberMessage.html, true)
            setSubscribed(true)
        } catch (err) {
            setError('Failed to subscribe. Please check your email and try again.')
        } finally {
            setLoading(false)
        }
    }

    return (
        <Container fluid style={{width: (isSmallScreen ? '100%' : '80%')}}>
            <Row>
                <Card className={'py-3 border-1 border-primary shadow ' + (darkMode ? 'my-bg-secondary-darkmode' : 'my-bg-secondary-light')} style={{transition: 'background 0.5s'}}>
                    <Card.Body className='p-4'>
                        <Row>
                            <Col className='text-lg-start py-3 py-lg-1'>
                                <h4 className={'text-center mb-2 text-nowrap ' + getThemeTextInv('primary')} style={{transition: 'color 0.5s'}}>Subscribe to our newsletter!</h4>
                                {isSmallScreen ? <></> : <h2 className={'text-center ' + getTextInv()} style={{transition: 'color 0.5s'}}>Learn how to leverage Fiable to boost your digital marketing funnel</h2>}
                            </Col>
                            <Col className='d-flex justify-content-center align-items-center'>
                                { subscribed ? 
                                    <h5 className={'text-center px-5 fw-bold ' + getText()}>Thank you for Subscribing!</h5>
                                :
                                    <Form onSubmit={addToNewsletter} className='d-flex justify-content-center flex-wrap'>
                                        <Form.Control required type='email' name='subscribeEmail' placeholder='Your Email' className={'border-0 mb-2 ' + (darkMode ? 'my-text-light my-bg-secondary-darkmode-light' : '')}/>
                                        <Button 
                                            className={'border-2 shadow border-light m-2 w-100 my-text-dark ' + getThemeBGInv('primary')}
                                            style={{transition: 'background 0.5s, color 0.5s'}}
                                            type='submit'
                                            disabled={loading || subscribed}
                                        >
                                            Subscribe
                                        </Button>
                                    </Form>
                                }
                            </Col>
                        </Row>
                    </Card.Body>
                </Card>
            </Row>
        </Container>
    )
}

export default NewsletterSignup;