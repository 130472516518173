import React from 'react'
import { Container, Row, Form } from 'react-bootstrap'

const LinkSection = ({formData, setFormData}) => {
    return (
        <Container fluid className='px-5'>
            <Row>
                <Form.Group>
                <Form.Label className='ps-3 mb-0'>Advertiser Link</Form.Label>
                    <Form.Control
                        name='productLink'
                        required
                        type='text'
                        placeholder='Link to be placed in video descriptions (e.g. https:www.fiable-solutions.com/tracking?code=blank)'
                        value={formData.productLink || ''}
                        onChange={(e) => setFormData(prev => ({...prev, productLink: e.target.value}))}
                        className='form-control-md'
                    />
                </Form.Group>
            </Row>
        </Container>
    )
}

export default LinkSection