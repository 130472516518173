import React, { useState } from 'react'
import { useStripe, useElements, CardElement } from '@stripe/react-stripe-js'
import { useAuth } from '../../../../../contexts/authContext'
import { Button, Col, Container, Form, Row } from 'react-bootstrap'
import { useTheme } from '../../../../../contexts/themeContext'
import formatDollars from '../../../../../utils/formatDollars'

const CheckoutForm = (props) => {
    const {currentUser, userData, createPaymentIntent, bookAdvertisement, setError, setSuccess} = useAuth()
    const {darkMode, getButtonStyle} = useTheme()

    const stripe = useStripe()
    const elements = useElements()

    const [loading, setLoading] = useState(false)

    const bookAd = async () => {
        try {
            await bookAdvertisement(props.adId)
            setSuccess('Booked Advertisement!')
            setLoading(false)
            props.onSubmit()
        } catch (err) {
            setError('Failed to book ad, please try again later')
            setLoading(false)
            props.onError()
        }
    }

    const handleSubmit = async (e) => {
        e.preventDefault()
        setError('')
        setSuccess('')
        setLoading(true)

        let clientSecret
        const currency= 'usd'
        try {
            clientSecret = await createPaymentIntent(Math.round(userData.history[props.adId].totalCost * 100), currency)
        } catch (err) {
            setError("Failed to Access Payment Network. Try again later")
            setLoading(false)
            props.onError()
            return
        }

        const cardElement = elements.getElement(CardElement)
        const { error, paymentIntent } = await stripe.confirmCardPayment(clientSecret, {
            payment_method: {
                card: cardElement,
                billing_details: {
                    name: currentUser.displayName ? currentUser.displayName : currentUser.email
                }
            }
        })
        if (error) {
            setError("Failed to Checkout")
            setLoading(false)
            props.onError()
        } else if (paymentIntent.status === 'succeeded') {
            bookAd()
        } else {
            setError("Unkown error occurred at Checkout, check your payment details and try again later")
            setLoading(false)
            props.onError()
        }
    }

    return (
        <Container fluid>
            <Row className={'mb-3 border-top border-2 border-' + (darkMode ? 'light' : 'dark')}>
                <Col><h4 className='fw-bold'>Total Cost: </h4></Col>
                <Col className='text-end'><h4 className='fw-bold'>{formatDollars(userData.history[props.adId].totalCost)}</h4></Col>
            </Row>
            <Form onSubmit={handleSubmit}>
                <Form.Label className='fs-5'>Payment Information</Form.Label>
                <CardElement />
                <div className='mt-3 d-flex justify-content-center'>
                    <Button className={'py-1 ' + getButtonStyle('lg')} type='submit' disabled={props.disabled || loading || !stripe}>{loading ? 'Processing...' : 'Purchase Advertisement'}</Button>
                </div>
            </Form>
        </Container>
    )
}

export default CheckoutForm