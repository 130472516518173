import React, { useCallback, useEffect, useState } from 'react'
import { Card, Image, Row, Col } from 'react-bootstrap'
import KeyStatsView from './keyStats'
import ProgressStatsView from './progressStats'
import { useAuth } from '../../../../contexts/authContext'
import MedalCase from './medalCase'
import SocialDetails from './socialDetails'

const SocialPage = ({socialCode, loadedData=null}) => {
    const {userData, getSocialData} = useAuth()

    const [socialData, setSocialData] = useState()

    const getSocialCodeData = useCallback(async () => {
        if (userData.socialCode === socialCode) setSocialData(userData.social)
        else {
            const newData = await getSocialData(socialCode)
            setSocialData(newData.data())
        }
    }, [userData.social, userData.socialCode, getSocialData, setSocialData, socialCode])

    useEffect(() => {
        if (loadedData) setSocialData(loadedData)
        else getSocialCodeData()
    }, [getSocialCodeData, loadedData, setSocialData])

    return (socialData &&
        <Card id={socialCode} className='shadow border-2 my-text-dark' style={{background: (socialData.bgColor || '#ffffff')}}>
            <Card.Header className='mb-0 pb-0'>
                <Row>
                    <Col>
                        <div className='d-flex flex-nowrap text-start align-items-center my-bg-primary-light rounded pe-2 mb-2' style={{width: 'min-content'}}>
                            {socialData.photoURL ? 
                                <div style={{width: '80px', height: '80px'}}>
                                    <Image className='m-2' roundedCircle src={socialData.photoURL} style={{width: '60px', height: '60px'}} />
                                </div>
                            : <></>}
                            <div className='d-flex flex-column pt-1 ps-3 pe-2'>
                                <h4 className='fw-bold'>{socialCode}</h4>
                                <h6>Youtuber</h6>
                            </div>
                        </div>
                    </Col>
                    <Col className='text-nowrap d-flex justify-content-end h-100'>
                        <h6 className='rounded my-bg-primary-light px-3' style={{width: 'fit-content'}}>Fiable member since {socialData.creationDate.toDate().toLocaleDateString()}</h6>
                    </Col>
                </Row>
            </Card.Header>
            <Card.Body className='mt-0 pt-0'>
                <ProgressStatsView socialData={socialData} />
                <KeyStatsView socialData={socialData} />
                <Row>
                    <Col className='d-flex justify-content-center'>
                        <MedalCase medals={socialData.medals} isCurrentUser={userData.socialCode === socialCode} />
                    </Col>
                    <Col className='d-flex justify-content-center'>
                        <SocialDetails socialData={socialData} />
                    </Col>
                </Row>
            </Card.Body>
        </Card>
    )
}

export default SocialPage