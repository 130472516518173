import React, { useCallback, useEffect, useState } from 'react'
import { Button, Card, Col, Collapse, Container, Form, Modal, OverlayTrigger, Row, Spinner, Table, Tooltip } from 'react-bootstrap'

import { useTheme } from '../../../contexts/themeContext'
import { useNavigate, useParams } from 'react-router-dom'
import { useAuth } from '../../../contexts/authContext'
import { Timestamp } from 'firebase/firestore'
import DetailsSection from './subcomponents/sections/detailsSection' 
import CategoriesSection from './subcomponents/sections/categoriesSection'
import LinkSection from './subcomponents/sections/linkSection'
import LogoSection from './subcomponents/sections/logoSection'
import ReadSection from './subcomponents/sections/readSection'
import ClipSection from './subcomponents/sections/clipSection'
import { CaretDownFill, QuestionCircleFill } from 'react-bootstrap-icons'
import { adTypes } from '../profile/subcomponents/tools/creator/adGuideTool'

const CreateAd = () => {
    const { currentUser, userData, addAdvertisement, updateAdvertisement, getBuckets, setWarning, setError } = useAuth()
    const {getCardStyle, getButtonStyle} = useTheme()
    const navigate = useNavigate()

    const params = useParams().params
    let currId = params && params.includes('adId=') ? params.substring(params.indexOf('adId') + 5, params.indexOf('adId') + 25) : null
    const currData = params && params.includes('adId=') ? userData.history[currId] : {}

    const [formData, setFormData] = useState(currData)
    const [showLoadAdData, setShowLoadAdData] = useState(false)
    const [loading, setLoading] = useState(false)

    const [budget, setBudget] = useState('')
    const [totalImpressions, setTotalImpressions] = useState('')

    const [logoImage, setLogoImage] = useState(currData && currData.logoImage ? currData.logoImage : null)
    const [clipVideo, setClipVideo] = useState(currData && currData.clipVideo ? currData.clipVideo: null)

    const [bucketNames, setBucketNames] = useState([])

    const handleSubmit = async () => {
        if (budget < 100) {
            setWarning('Budget must be at least $100')
            return
        }
        if (!formData.advertiserName || !formData.advertisementName || !formData.startDate || !formData.platforms || !formData.description) {
            setWarning('Missing some initial details')
            return
        }

        if (!formData.buckets || !formData.buckets.length) {
            setWarning('No categories selected')
            return
        }

        try {
            new URL(formData.productLink)
        } catch (err) {
            setWarning('Invalid Ad Link! Use the format https://www.domain.com/page?parameters')
            return
        }

        const endDate = new Date(formData.startDate.toDateString())
        endDate.setMonth(endDate.getMonth() + 1)

        let adData = {
            advertiserName: formData.advertiserName,
            advertisementName: formData.advertisementName,
            startDate: Timestamp.fromDate(formData.startDate),
            endDate: Timestamp.fromDate(endDate),
            platforms: formData.platforms,
            description: formData.description,
            buckets: formData.buckets,
            productLink: formData.productLink,
            totalImpressions: totalImpressions,
            totalCost: budget,
        }
        if (formData.otherBuckets) adData.otherBuckets = formData.otherBuckets
        if (formData.logoImage) {
            adData.logoImage = formData.logoImage
            adData.logoCorner = formData.logoCorner
        }
        if (formData.clipVideo) {
            adData.clipVideo = formData.clipVideo
        }
        if (formData.logoFile) {
            adData.logoImage = [formData.logoFile, logoImage]
            adData.logoCorner = formData.logoCorner || 'Top-Left'
        }
        if (formData.readText) adData.readText = formData.readText
        if (formData.clipText) adData.clipText = formData.clipText
        if (formData.clipFile) adData.clipVideo = [formData.clipFile, clipVideo]

        const data = {...adData, isActive: false, isPotential: true, advertiserUID: currentUser.uid}
        try {
            setLoading(true)
            if (currId && currId.length) {
                await updateAdvertisement(currId, data)
            } else {
                currId = await addAdvertisement(data)
            }
            
            resetForm()
            setLoading(false)
            navigate('/payment/' + (currId ? currId : ''))
        } catch (err) {
            console.log(err)
            setError('Failed to save Advertisement')
            setLoading(false)
        }
    }

    const updateFormData = (adId) => {
        setFormData({...userData.history[adId], startDate: new Date()})
        setLogoImage(userData.history[adId].logoImage)
        setClipVideo(userData.history[adId].clipVideo)
        setBudget(userData.history[adId].totalCost)
        setTotalImpressions(userData.history[adId].totalImpressions)
        setShowLoadAdData(false)
    }

    const resetForm = () => {
        setFormData({advertiserName: userData.companyName, startDate: new Date(), platforms: ['youtube'], buckets: userData.buckets})
        setLogoImage(null)
        setClipVideo(null)
        setBudget('')
        setTotalImpressions('')
    }

    const fetchBucketData = useCallback(async () => {
        const bucketData = []
        await getBuckets().then(res => res.forEach(docSnap => {
            bucketData.push(docSnap.id)
        }));
        setBucketNames(bucketData)
    }, [getBuckets])

    useEffect(() => {
        fetchBucketData().then(() => {
            setFormData(prev => ({
                advertiserName: userData.companyName,
                startDate: new Date(),
                platforms: ['youtube'],
                buckets: userData.buckets,
                ...prev,
            }))
        })
    }, [fetchBucketData, setFormData, userData])

    return (
        <Container fluid className='p-2'>
            <Card className={getCardStyle()}>
                <Card.Header className='pb-0'>
                    <Row className='text-center'>
                        <h1 className='fw-bold mb-0'>Create An Ad!</h1>
                        <h6 className='mb-0'>Enter Advertisement details and upload any desired graphics</h6>
                    </Row>
                </Card.Header>
                <Card.Body>
                    <Row className='my-2'>
                        <Col className='d-flex justify-content-center'>
                            <Button className={getButtonStyle('sm')} disabled={!Object.keys(userData.history).length} onClick={() => setShowLoadAdData(true)}>Load Data from Previous Ad</Button>
                            <SelectPreviousAdData show={showLoadAdData} setShow={setShowLoadAdData} onSelect={(adId) => updateFormData(adId)} />
                        </Col>
                        <Col className='d-flex justify-content-center'>
                            <Button className={getButtonStyle('sm')} onClick={() => resetForm()}>Clear Data on Current Ad</Button>
                        </Col>
                    </Row>
                    <SectionCollapse
                        buttonText='Initial Details (Company name, ad name, start date, ad budget, target impressions)'
                        content={<DetailsSection formData={formData} setFormData={setFormData} />}
                        complete={formData.advertiserName && formData.advertisementName && formData.startDate && formData.platforms && formData.description}
                        step={1}
                    />
                    <SectionCollapse 
                        buttonText='Ad Categories (Defaults to previously selected categories)'
                        content={<CategoriesSection bucketNames={bucketNames} formData={formData} setFormData={setFormData} />}
                        complete={formData.buckets && formData.buckets.length}
                        step={2}
                    />
                    <SectionCollapse
                        buttonText='Advertisement Link (Add a link to your site, service, or product)'
                        content={<LinkSection formData={formData} setFormData={setFormData} />}
                        complete={!!formData.productLink}
                        step={3}
                    />
                    <SectionCollapse
                        buttonText='Advertisement Logo (Optional: Upload a Logo to be overlayed on Advertised Content)'
                        content={<LogoSection setLogoImage={setLogoImage} formData={formData} setFormData={setFormData} />}
                        complete={!!formData.logoFile || !!formData.logoImage}
                        step={4}
                    />
                    <SectionCollapse
                        buttonText='Advertisement Script (Optional: Enter a suggested script for shoutouts or ad reads)'
                        content={<ReadSection formData={formData} setFormData={setFormData} />}
                        complete={!!formData.readText}
                        step={5}
                    />
                    <SectionCollapse
                        buttonText='Advertisement Clip (Optional: Upload a Clip to be shown in Advertised Content)'
                        content={<ClipSection setClipVideo={setClipVideo} formData={formData} setFormData={setFormData} />}
                        complete={!!formData.clipText || !!formData.clipFile || !!formData.clipVideo}
                        step={6}
                    />
                    <BudgetAndImpressionsController formData={formData} budget={budget} totalImpressions={totalImpressions} setBudget={setBudget} setTotalImpressions={setTotalImpressions} />
                </Card.Body>
                <Card.Footer>
                    <Row className='px-5 m-2 pt-2 me-5 d-flex justify-content-center'>
                        {loading ? <Spinner /> : <Button className={getButtonStyle('lg')} onClick={handleSubmit}>Save Ad and go to Payment</Button>}
                    </Row>
                </Card.Footer>
            </Card>
        </Container>
    )
}

const SectionCollapse = ({buttonText, disabled, content, complete, step}) => {
    const {getTextInv} = useTheme()
    const [isOpen, setIsOpen] = useState(false)

    return (
        <Row className='ms-3'>
            <Col className='py-1'>
                <Button 
                    className={'d-inline-flex flex-nowrap text-start border-0 bg-transparent m-0 p-1 fs-4 ' + getTextInv()}
                    onClick={() => setIsOpen(!isOpen)}
                    disabled={disabled}
                >
                    <CaretDownFill className='mt-2' style={{transition: 'transform 0.2s', transform: `rotate(${(isOpen && !disabled) ? 0 : -90}deg)`}} />
                    {buttonText}
                </Button>
            </Col>
            <Col lg={1} className='d-flex flex-row me-2 ps-0 mb-0 border-dark border-start'>
                <div className={'position-relative m-2 d-flex justify-content-center align-items-center rounded-circle fw-bold border border-primary my-text-dark bg-transition my-bg-' + (complete ? 'success' : 'secondary')} style={{height: '30px', width: '30px', display: (disabled ? 'none' : ''), left: '-24px'}}>
                    {step}
                </div>
            </Col>
            <Collapse in={isOpen && !disabled}>
                <div>
                    {content}
                </div>
            </Collapse>
        </Row>
    )
}

const BudgetAndImpressionsController = ({formData, budget, totalImpressions, setBudget, setTotalImpressions}) => {
    const [currentCPM, setCurrentCPM] = useState(1)

    // type = 1 means updating budget, type = 2 means updating impressions
    const updatePrice = (type, value) => {
        if (type === 1) {
            setBudget(value || '')
            setTotalImpressions(Math.round((value * 0.945 / currentCPM) * 1000) || '')
        }
        if (type === 2) {
            setTotalImpressions(value || '')
            setBudget(Math.round(((value / 1000) * currentCPM) / 0.945) || '')
        }
    }

    useEffect(() => {
        let cpm = adTypes[0].cost * 10
        if (formData.productLink) cpm = adTypes.find(type => type.dataName === 'link').cost * 10
        if (formData.logoImage || formData.logoFile) cpm = adTypes.find(type => type.dataName === 'logo').cost * 10
        if (formData.readText) cpm = adTypes.find(type => type.dataName === 'read').cost * 10
        if (formData.clipVideo || formData.clipFile) cpm = adTypes.find(type => type.dataName === 'clip').cost * 10

        setBudget('')
        setTotalImpressions('')
        setCurrentCPM(cpm)
    }, [formData.productLink, formData.logoImage, formData.logoFile, formData.readText, formData.clipVideo, formData.clipFile, setCurrentCPM, setBudget, setTotalImpressions])

    return (
        <Row className='px-5 m-2 me-5'>
            <Col>
                <Form.Group className='text-start'>
                    <Form.Label className='ps-3 mb-0'>
                        Advertisement Budget
                        <sup>
                            <OverlayTrigger placement='right' overlay={<Tooltip id='tooltip-link'>Total Impressions is Calculated from inputted budget (including fees)</Tooltip>}>
                                <QuestionCircleFill className='ms-1'/>
                            </OverlayTrigger>
                        </sup>
                    </Form.Label>
                    <Form.Control
                        type='number'
                        min={100}
                        max={10000000}
                        name='amount'
                        id='amount'
                        placeholder='How much do you want to pay? (Minimum $100)'
                        value={budget}
                        onChange={(e) => updatePrice(1, Number(e.target.value))}
                        className='form-control-sm'
                    />
                </Form.Group>
            </Col>
            <Col lg={1} className='d-flex flex-column text-nowrap justify-content-end align-items-center fs-4 fw-bold'>
                - OR -
            </Col>
            <Col>
                <Form.Group className='text-start'>
                    <Form.Label className='ps-3 mb-0'>
                        Target Impressions
                        <sup>
                            <OverlayTrigger placement='right' overlay={<Tooltip id='tooltip-link'>Budget is Calculated from inputted impressions</Tooltip>}>
                                <QuestionCircleFill className='ms-1'/>
                            </OverlayTrigger>
                        </sup>
                    </Form.Label>
                    <Form.Control
                        type='number'
                        min={10000}
                        max={1000000000}
                        name='impressions'
                        id='impressions'
                        placeholder='How many impressions do you want?'
                        value={totalImpressions}
                        onChange={(e) => updatePrice(2, Number(e.target.value))}
                        className='form-control-sm'
                    />
                </Form.Group>
            </Col>
        </Row>
    )
}

const SelectPreviousAdData = ({show, setShow, onSelect}) => {
    const {userData} = useAuth()
    const {getBG, getTextInv, getButtonStyle} = useTheme()

    const [selected, setSelected] = useState()

    return (
        <Modal size='lg' show={show} onHide={() => setSelected(null) || setShow(false)}>
            <Modal.Header className={getBG() + ' ' + getTextInv()} closeButton>Select a previous Ad</Modal.Header>
            <Modal.Body className={getBG() + ' ' + getTextInv()}>
                <style dangerouslySetInnerHTML={{
                    __html: `
                        .hoverable:hover {
                            cursor: pointer;
                        }`
                    }}
                />
                <Table responsive className='mt-2' id='table' role='grid'>
                    <thead className='text-center'>
                        <tr>
                            <th style={{borderRadius: '12px 0 0 0'}}>Ad Name</th>
                            <th>Start Date</th>
                            <th>Ad Link</th>
                            <th>Has Logo?</th>
                            <th>Has Script?</th>
                            <th style={{borderRadius: '0 12px 0 0'}}>Has Clip?</th>
                        </tr>
                    </thead>
                    <tbody className='text-center'>
                        {Object.entries(userData.history).map(ad => (
                            <tr key={ad[0]} onClick={() => setSelected(ad[0])} className='hoverable'>
                                <td className={'bg-transition my-bg-' + (selected === ad[0] ? 'success' : 'transparent')}>{ad[1].advertisementName}</td>
                                <td className={'bg-transition my-bg-' + (selected === ad[0] ? 'success' : 'transparent')}>{ad[1].startDate.toDate().toLocaleDateString()}</td>
                                <td className={'bg-transition my-bg-' + (selected === ad[0] ? 'success' : 'transparent')}>{ad[1].productLink}</td>
                                <td className={'bg-transition my-bg-' + (selected === ad[0] ? 'success' : 'transparent')}>{ad[1].logoImage ? 'Yes' : 'No'}</td>
                                <td className={'bg-transition my-bg-' + (selected === ad[0] ? 'success' : 'transparent')}>{ad[1].readText ? 'Yes' : 'No'}</td>
                                <td className={'bg-transition my-bg-' + (selected === ad[0] ? 'success' : 'transparent')}>{ad[1].clipVideo || ad[1].clipText ? 'Yes' : 'No'}</td>
                            </tr>
                        ))}
                    </tbody>
                    <tfoot className='text-center'>
                        <tr>
                            <th style={{borderRadius: '0 0 0 12px'}}>Ad Name</th>
                            <th>Start Date</th>
                            <th>Ad Link</th>
                            <th>Has Logo?</th>
                            <th>Has Script?</th>
                            <th style={{borderRadius: '0 0 12px 0'}}>Has Clip?</th>
                        </tr>
                    </tfoot>
                </Table>
            </Modal.Body>
            <Modal.Footer className={getBG() + ' d-flex justify-content-center ' + getTextInv()}>
                <Button className={getButtonStyle('sm')} onClick={() => onSelect(selected) || setSelected(null)} disabled={!selected}>
                    Load Ad Data
                </Button>
            </Modal.Footer>
        </Modal>
    )
}

export default CreateAd