import React, { useEffect, useState } from 'react'
import { Button, Card, ProgressBar } from 'react-bootstrap'
import { useAuth } from '../../../../contexts/authContext'
import { useTheme } from '../../../../contexts/themeContext'

const AdvertisementsCard = (props) => {
    const { userData, type } = useAuth()
    const { isSmallScreen, getCardStyle } = useTheme()

    return (
        <Card className={'pb-3 overflow-y-auto ' + getCardStyle() + ' ' + (isSmallScreen && 'mb-3')} style={{height: isSmallScreen ? 'max-content' : '380px'}}>
            <Card.Header className='py-3'>
                <h6 className='fw-bold'>Active Advertisements</h6>
            </Card.Header>
            <Card.Body className='px-0'>
                {Object.values(userData.history).filter(ad => ad.isActive).length ? 
                    Object.keys(userData.history).filter(id => userData.history[id].isActive).sort((a, b) => userData.history[b].startDate - userData.history[a].startDate).map((adId, index) => (
                        props.hideSelect ?
                            <AdvertisementProgressBar id={adId} key={index} hideSelect/>
                        :
                            <AdvertisementProgressBar id={adId} key={index} selected={props.selected} setSelected={props.setSelected}/>
                    ))
                : 
                    <div className='h-75 d-flex justify-content-center align-items-center fs-3'>{type && !userData.channels.length ? 'Link a Channel to Receive Ads!' : 'No Active Advertisements'}</div>
                }
            </Card.Body>
        </Card>
    )
}

const AdvertisementProgressBar = (props) => {
    const { userData, type } = useAuth()
    const { getText, getTextInv, isSmallScreen } = useTheme()

    const [currentImpressions, setCurrentImpressions] = useState(0)
    const [percent, setPercent] = useState(0)

    const barColors = [
        '#8B0000', // Dark Red
        '#FF0000', // Red
        '#FF4500', // Dark Orange
        '#FFA500', // Orange
        '#FFCC00', // Yellow-Orange
        '#FFD700', // Yellow
        '#ADFF2F', // Lime
        '#90EE90', // Light Green
        '#32CD32', // Medium Green
        '#008000'  // Green
    ]

    const getImpressionsString = () => {
        if (props.id && userData.history[props.id]) {
            if (type) {
                if (userData.channels.adContent && userData.channels.adContent[props.id] && userData.channels.adContent[props.id].videoId)
                    return `Impressions: ${currentImpressions} of ${userData.history[props.id].quota}`
                else return 'Awaiting Video Verification'
            } else {
                if (userData.history[props.id].creators && userData.history[props.id].creators.length)
                    return `Impressions: ${currentImpressions} of ${userData.history[props.id].totalImpressions}`
                else return 'Awaiting Creator Assignment'
            }
        }
    }

    useEffect(() => {
        const adData = userData.history[props.id]
        let progress = 0
        if (type === 1) {
            for (const channel of adData.channels) {
                if (adData.progress[channel]) {
                    progress += adData.progress[channel].impressions.at(-1)
                }
            }
            setPercent(Math.round(100 * progress / Math.max(adData.quota, 1)))
        } else {
            progress = adData.currentImpressions || 0
            setPercent(Math.round(100 * progress / Math.max(adData.totalImpressions, 1)))
        }
        setCurrentImpressions(progress)
    }, [props.id, userData.history, userData.channels, type, setCurrentImpressions, setPercent])

    return (
        userData.history[props.id].isActive && (
            <Button 
                className={'m-0 p-0 px-2 rounded-0 border-0 w-100 ' + (props.selected === props.id ? 'my-bg-success' : 'bg-transparent')}
                disabled={props.hideSelect}
                onClick={() => !props.hideSelect && props.setSelected(props.id)}
                style={{opacity: 1}}
            >
                <div className='d-flex justify-content-between'>
                    <h4 className={'small fw-bold text-transition ' + (props.selected === props.id ? getText() : getTextInv())}>{userData.history[props.id].advertiserName}: {userData.history[props.id].advertisementName}</h4>
                    {isSmallScreen ? <></> : <h4 className={'small fw-bold text-transition ' + (props.selected === props.id ? getText() : getTextInv())}>{getImpressionsString()}</h4>}
                </div>
                <style dangerouslySetInnerHTML={{
                    __html: `
                        .customProgressBG .progress-bar {
                            background-color: ${barColors[Math.min(Math.floor((percent / 100) * (barColors.length - 1)), barColors.length - 1)]};
                        }`
                    }}
                />
                <ProgressBar className='customProgressBG border border-dark mb-3' now={percent < 7 ? 7 : percent} label={`${percent}%`} striped animated />
            </Button>
        )
    )
}

export default AdvertisementsCard;