import React from 'react'
import { PieChart, Pie, Tooltip, Legend, Cell } from 'recharts';

const PieGraph = ({pieData=[], width=400, height=400, radius=150}) => {
    const colors = [
        "#FF5733",  // Vibrant orange
        "#33FF57",  // Bright green
        "#3357FF",  // Strong blue
        "#FF33A1",  // Vivid pink
        "#FFD133",  // Sunny yellow
        "#33FFF2",  // Light turquoise
        "#9933FF",  // Deep purple
        "#FF3380",  // Bold magenta
        "#33FF99",  // Fresh mint green
        "#FF6F33"   // Warm coral
    ]

    return (
        <PieChart width={width} height={height}>
            <Pie data={pieData} outerRadius={radius} dataKey='value'>
                {pieData.map((_, index) => (
                    <Cell key={`cell-${index}`} fill={colors[index % colors.length]}/>
                ))}
            </Pie>
            <Tooltip />
            <Legend />
        </PieChart>
    ) 
}

export default PieGraph