import React, { useState } from 'react'
import { Container, Row, Form, Button, Card } from 'react-bootstrap'
import { useTheme } from '../../../../../../contexts/themeContext'
import { useAuth } from '../../../../../../contexts/authContext'

const ConsumerProfileTool = ({bucketNames}) => {
    const {userData, updateBuckets, updateUserData, setError, type} = useAuth()
    const {getTextInv, getCardStyle, getButtonStyle} = useTheme()

    const [loading, setLoading] = useState(false)
    const [submitted, setSubmitted] = useState(false)

    const updateUserBuckets = (e) => {
        e.preventDefault()
        setLoading(true)
        const data = [...(new FormData(e.target).entries())]

        let buckets = []
        let otherBuckets = ''
        for (const entry of data) {
            if (entry[0].includes('-')) buckets.push(entry[0].split('-')[2])
            else otherBuckets = entry[1]
        }
        
        try {
            if ( type === 1 ) {
                const bucketStates = {}
                bucketNames.forEach(bucket => {
                    bucketStates[bucket] = buckets.includes(bucket)
                })

                updateBuckets(bucketStates)
                updateUserData({otherBuckets: otherBuckets})
            } else {
                updateUserData({buckets: buckets, otherBuckets: otherBuckets})
            }
            setSubmitted(true)
        } catch (err) {
            setError('Failed to update buckets')
        } finally {
            setLoading(false)
        }
    }

    return (
        <Container fluid>
            <Row className={'text-center pb-1 ' + getTextInv()}>
                <h3 className='arvo-regular mb-4'>Select your {type === 1 ? 'Content Categories' : 'Category Preferences for Advertisements'}, then save your selections</h3>
            </Row>
            <Form onSubmit={(e) => updateUserBuckets(e)}>
                <Row className={'d-flex flex-row flex-nowrap '}>
                    <Card className={'m-2 rounded ' + getCardStyle('secondary')} style={{flex: '1 1 25%'}}>
                        {bucketNames.slice(0, Math.ceil(bucketNames.length / 4)).map(bucket => (
                            <Form.Check name={'bucket-check-' + bucket} key={bucket} label={bucket} className='form-control-lg' defaultChecked={userData.buckets && userData.buckets.includes(bucket)} />
                        ))}
                    </Card>
                    <Card className={'m-2 rounded ' + getCardStyle('primary')} style={{flex: '1 1 25%'}}>
                        {bucketNames.slice(Math.ceil(bucketNames.length / 4), Math.ceil(2 * bucketNames.length / 4)).map(bucket => (
                            <Form.Check name={'bucket-check-' + bucket} key={bucket} label={bucket} className='form-control-lg' defaultChecked={userData.buckets && userData.buckets.includes(bucket)} />
                        ))}
                    </Card>
                    <Card className={'m-2 rounded ' + getCardStyle('secondary')} style={{flex: '1 1 25%'}}>
                        {bucketNames.slice(Math.ceil(2 * bucketNames.length / 4), Math.ceil(3 * bucketNames.length / 4)).map(bucket => (
                            <Form.Check name={'bucket-check-' + bucket} key={bucket} label={bucket} className='form-control-lg' defaultChecked={userData.buckets && userData.buckets.includes(bucket)} />
                        ))}
                    </Card>
                    <Card className={'m-2 rounded ' + getCardStyle('primary')} style={{flex: '1 1 25%'}}>
                        {bucketNames.slice(Math.ceil(3 * bucketNames.length / 4), bucketNames.length).map(bucket => (
                            <Form.Check name={'bucket-check-' + bucket} key={bucket} label={bucket} className='form-control-lg' defaultChecked={userData.buckets && userData.buckets.includes(bucket)} />
                        ))}
                    </Card>
                </Row>
                <Row className='pt-2'>
                    <Form.Group>
                        <Form.Label className='ms-3 mb-0'>Additional Categories</Form.Label>
                        <Form.Control name='otherBuckets' placeholder='Separate multiple with a comma' defaultValue={userData.otherBuckets} />
                    </Form.Group>
                </Row>
                <Row className='d-flex justify-content-center text-center pt-3 pb-0'>
                    <Button type='submit' className={'w-25 ' + getButtonStyle()} disabled={loading}>Save Selections</Button>
                    {submitted ? <h6 className={'pt-1 mb-0 ' + getTextInv()}>Selections Saved!</h6> : <br />}
                </Row>
            </Form>
        </Container>
    )
}

export default ConsumerProfileTool