import React, { useState }  from 'react'
import { Button, Col, Form, InputGroup, Modal, OverlayTrigger, Row, Tooltip } from 'react-bootstrap'
import { QuestionCircleFill } from 'react-bootstrap-icons'
import { useAuth } from '../../../../../contexts/authContext'
import { useTheme } from '../../../../../contexts/themeContext'
import { adTypes } from '../tools/creator/adGuideTool'
import { CreatorAgreementHTML } from '../../../../policyPages/creatorAgreement/creatorAgreement'

const CreatorApplicationModal = (props) => {
    const { currentUser, updateUserData, sendEmail, setSuccess, setError, earnMedal } = useAuth()
    const {getTextInv, getThemeBGPlatform, getButtonStyle} = useTheme()

    const [currentPage, setCurrentPage] = useState(0)
    const [isValidated, setIsValidated] = useState(false)
    const [isSubmitting, setIsSubmitting] = useState(false)

    const submitApplication = async (e) => {
        e.preventDefault()
        if (!e.target.checkValidity()) {
            e.stopPropagation()
            setIsValidated(true)
            return
        } else setIsSubmitting(true)

        const data = [...(new FormData(e.target)).entries()]

        let emailRecipient = 'fiable@fiable-solutions.com'
        let emailSubject = 'A Creator just filled out the onboarding Application!'
        let emailString = '<h1>A new Creator wants to join our network. Details below: </h1><br/>'

        let adTypes = []
        let userData = {adTypes: ['link'], pending: 'true'}
        let excessData = {}

        emailString += ('<h2>Creator UID: ' + currentUser.uid + '</h2><br/>')
        for ( const field of data ) {
            if ( field[0].includes('adType')) {
                userData['adTypes'] = [...userData['adTypes'], field[0].substring(field[0].lastIndexOf('-') + 1)]
                adTypes.push(field[0].substring(field[0].lastIndexOf('-') + 1))
            }
            else {
                if ( field[0] === 'name') {
                    userData['name'] = field[1]
                } else if ( field[0].includes('handle') && field[1].length) {
                    userData['primaryPlatform'] = field[0].split('-')[0]
                }else {
                    excessData[field[0]] = field[1]
                }
                emailString += '<h3>' + (field[0] + ': ' + field[1]) + '</h3><br/>'
            }
        }
        emailString += ('<h3>Creator is willing to use these ad types: ' + (adTypes.length ? adTypes.join(', ') : 'None') + '</h3><br/>')

        setError('')
        setSuccess('')
        try {
            await sendEmail( emailRecipient, emailSubject, emailString, true)
            await updateUserData({applicationData: excessData, pending: true, firstTimeUser: false})
            await earnMedal(3)
            setSuccess('Your Application has been submitted! We will reach out to you shortly.')
        } catch( err ) {
            setError('Failed to submit application, try again later')
        } finally {
            if (document.getElementById('creatorApplication')) document.getElementById('creatorApplication').reset()
            setIsSubmitting(false)
            props.setPending(true)
            props.setShow(false)
        }
    }

    function getMarginLeft( row ) {
        if ( row >= currentPage ) {
            return '0%'
        } else {
            return '-100%'
        }
    }

    function getMarginRight( row ) {
        if ( row <= currentPage ) {
            return '0%'
        } else {
            return '-100%'
        }
    }

    let pages = [<ApplicationPage1 />, <ApplicationPage2 />, <ApplicationPage3 adTypes={adTypes} />,  <ApplicationPage4 isValid={isValidated} isSubmitting={isSubmitting} />]

    return (
        <Modal size='xl' show={props.show} onHide={() => props.setShow(false)} className={'back-blur ' + getTextInv()}>
            <Modal.Header className={getThemeBGPlatform('secondary')} closeButton>
                <Modal.Title>
                    Creator Activation (Page {currentPage + 1} of {pages.length})
                </Modal.Title>
            </Modal.Header>
            <Modal.Body className={'mb-0 pb-0 pt-0 ' + getThemeBGPlatform('secondary')}>
                <Form id='creatorApplication' noValidate validated={isValidated} onSubmit={submitApplication}>
                    <Row className='overflow-hidden m-0' style={{width: '100%'}}>
                        {pages.map((page, index) => (
                            <Col key={index} style={{marginLeft: getMarginLeft(index), marginRight: getMarginRight(index), transition: 'all 0.3s ease'}}>
                            {page}
                        </Col>
                        ))}
                    </Row>
                </Form>
            </Modal.Body>
            <Modal.Footer className={'p-1 ' + getThemeBGPlatform('secondary')}>
                <Row className='d-flex d-inline-flex justify-content-between w-100'>
                    <Col>
                        <Button 
                            className={'me-5 ' + getButtonStyle()} 
                            disabled={!currentPage} 
                            onClick={() => setCurrentPage( prevPage => prevPage - 1)}
                        >
                            {'<'} Previous 
                        </Button>
                    </Col>
                    <Col className='text-end'>
                        <Button 
                            className={'ms-5 ' + getButtonStyle()} 
                            disabled={currentPage === pages.length - 1} 
                            onClick={() => setCurrentPage( prevPage => prevPage + 1)}
                        >
                            Next {'>'}
                        </Button>
                    </Col>
                </Row>
            </Modal.Footer>
        </Modal>
    )
}

const ApplicationPage1 = () => {
    return (
        <>
            <Row className='pt-2 text-center fw-bold'><h3 className='fw-bold'>Initial Details</h3></Row>
            <Row className='d-flex justify-content-between'>
                <Col className='d-flex flex-column flex-wrap'>
                    <InputGroup size='sm' className='mt-2'>
                        <Form.Label className='pe-2 fw-bold w-100'>Name</Form.Label>
                        <Form.Control
                            required
                            name='name'
                            type='text'
                            placeholder='Full Name'
                            className='rounded'
                        />
                    </InputGroup>
                </Col>
            </Row>
            <Row>
                <Col className='d-flex flex-column text-nowrap pt-3'>
                    <InputGroup size='sm'>
                        <Form.Label className='pt-2 pe-2 fw-bold w-100'>Youtube Handle</Form.Label>
                        <InputGroup.Text className='rounded-start'>@</InputGroup.Text>
                        <Form.Control
                            required
                            name='youtube-handle'
                            type='text'
                            placeholder='Required'
                        />
                    </InputGroup>
                </Col>
            </Row>
            <Row>
                <Col>
                    <Form.Label className='fw-bold pt-4'>Describe Your Channel!</Form.Label>
                    <Form.Control
                        required
                        name='CreatorPitch'
                        type='text'
                        as='textarea'
                        rows={4}
                        placeholder='Gaming Content, focusing on lets plays and guides'
                        className='m-1 pt-3 form-control-md w-100'
                    />
                </Col>
            </Row>
            {/* <Row className='pt-3'>
                <Col className='d-inline-flex text-nowrap'>
                    <InputGroup size='sm'>
                    <Form.Label className='pt-2 pe-3 fw-bold'>Twitch Handle&nbsp;</Form.Label>
                        <InputGroup.Text className='rounded-start'>@</InputGroup.Text>
                        <Form.Control
                            required={!ytHandle}
                            onChange={(e) => setTwHandle(e.target.value)}
                            name='twitch-handle'
                            type='text'
                            placeholder='Leave blank if inapplicable'
                        />
                    </InputGroup>
                </Col>
            </Row>
            */}
        </>
    )
}

const ApplicationPage2 = () => {
    return ( 
        <>
            <Row className='pt-2 text-center fw-bold'><h3 className='fw-bold'>Creator Details</h3></Row>
            <Row>
                <Col>
                    <Form.Label className='fw-bold'>What are your goals as a Creator?</Form.Label>
                    <Form.Control
                        required
                        name='CreatorMotivation'
                        type='text'
                        as='textarea'
                        rows={4}
                        placeholder='Make more money, grow my channel, connect with Advertisers...'
                        className='m-1 form-control-md w-100'
                    />
                </Col>
            </Row>
            <Row>
                <Col>
                    <Form.Label className='fw-bold'>What kind of Companies would you work with?</Form.Label>
                    <Form.Control
                        required
                        name='CreatorCompanies'
                        type='text'
                        as='textarea'
                        rows={4}
                        placeholder='I would only work with ethical companies selling a good product'
                        className='m-1 form-control-md w-100'
                    />
                </Col>
            </Row>
        </>
    )
}

const ApplicationPage3 = (props) => {
    return (
        <>
            <Row className='pt-2 text-center fw-bold'><h3 className='fw-bold'>Advertising Details</h3></Row>
            <Row>
                <Col>
                    <Form.Label className='fw-bold pe-2'>What are your preferred Fiable Advertisement types?</Form.Label>
                    <div className='text-center'>
                        {props.adTypes.map( (type, index) => (
                            <div key={index} className='d-inline-flex'>
                                <Form.Check
                                    name={'check-adType-' + type.dataName}
                                    type='checkbox'
                                    label={type.name}
                                    disabled={!index}
                                    defaultChecked={!index}
                                />
                                <OverlayTrigger placement='bottom' overlay={<Tooltip id={'tooltip-' + type.name}>{type.name === 'Read' ? type.desc.replace('Creators p', 'P').replace('their', 'your') : (type.name === 'Clip' ? type.desc.replace('Creator shows', 'Show') : type.desc)}</Tooltip>}><QuestionCircleFill className='mt-1 ms-1 me-3'/></OverlayTrigger>
                            </div>
                        ))}
                    </div>
                </Col>
            </Row>
            <Row className='pt-2'>
                <Col>
                    <Form.Label className='fw-bold mb-1'>Are you currently making money on your platform(s)?</Form.Label>
                    <Form.Control
                        name='CurrentRevenues'
                        type='text'
                        as='textarea'
                        rows='2'
                        placeholder='If so, how? Leave blank if not'
                        className='m-1 form-control-md'
                    />
                </Col>
            </Row>
            <Row className='pt-2'>
                <Col>
                    <Form.Label className='fw-bold mb-1'>Do you currently have any other advertising deals?</Form.Label>
                    <Form.Control
                        name='CurrentAdDeals'
                        type='text'
                        as='textarea'
                        rows='2'
                        placeholder='Leave blank if none'
                        className='m-1 form-control-md'
                    />
                </Col>
            </Row>
        </>
    )
}

const ApplicationPage4 = (props) => {
    const { getButtonStyle, getTextInv, darkMode } = useTheme()

    return (
        <>
            <Row>
                <Col className={'mt-1 rounded-start border border-2 border-' + (darkMode ? 'light' : 'dark')} style={{height: '300px', overflowY: 'scroll'}}>
                    <CreatorAgreementHTML />
                </Col>
            </Row>
            <Row>
                <Col className='d-inline-flex'>
                    <Form.Check
                        required
                        id='creatorAcknowledgement'
                        className='my-1'
                    />
                    <Form.Label className='pt-1 ps-2'>I agree to the Creator Agreement (<a href={'/creator-agreement'} target='_blank' rel='noreferrer' className='my-text-primary'>View Externally</a>)</Form.Label>
                </Col>
            </Row>
            <Row>
                <Col className='text-center'>
                    <Button
                        className={'mb-1 ' + getButtonStyle('lg')}
                        disabled={props.isSubmitting}
                        type='submit'
                    >
                        Submit Application
                    </Button>
                    { props.isValid ?
                        <p className='my-text-danger'>Please review and fill out all fields.</p>
                    :
                        <p className={getTextInv()}>Submissions are reviewed daily!</p>
                    }
                </Col>
            </Row>
        </>
    )
}

export default CreatorApplicationModal