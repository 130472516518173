import React, { useEffect, useState } from 'react'
import { useAuth } from '../../../../../contexts/authContext'
import AdTable from '../adTable'
import formatDollars from '../../../../../utils/formatDollars'
import { useTheme } from '../../../../../contexts/themeContext'

const AdRevenuesTable = () => {
    const { userData } = useAuth()
    const {screenWidth} = useTheme()

    const cols = ['Advertisement Name', 'Start Date', 'Current Revs', 'Available Revs', 'Total Revs'].concat(screenWidth < 1200 ? [] : ['Remaining Imps'])
    const [data, setData] = useState()

    useEffect(() => {
        let tableData = Object.values(userData.history).filter(ad => ad.isActive).map( ad => {
            const currentImpressions = ad.channels.reduce((sum, curr) => sum + (ad.progress[curr] ? ad.progress[curr].impressions.at(-1) : 0), 0)
            const currRevs = Math.round((ad.totalCost * (currentImpressions / ad.totalImpressions ) * 100)) / 100
            const totalRevs = Math.round((ad.totalCost * (ad.quota / ad.totalImpressions ) * 100)) / 100
            return {
                advertisementName: ad.advertisementName,
                startDate: ad.startDate.toDate().toLocaleDateString(),
                currentRevs: formatDollars(currRevs),
                avalableRevs: formatDollars(totalRevs - currRevs),
                totalRevs: formatDollars(totalRevs),
                remainingImps: ad.quota - currentImpressions
            }
        })
        if (screenWidth < 1200) {
            tableData = tableData.map(({remainingImps, ...rest}) => rest)
        }
        setData(tableData)
    }, [userData.history, screenWidth])

    return (
        data && <AdTable title='Advertisement Revenues (Active Ads)' tableCols={cols} data={data} bgTheme='secondary' />
    )
}

export default AdRevenuesTable