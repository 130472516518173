import React from 'react'
import { Card, Col, Container, Row } from 'react-bootstrap'
import { useTheme } from '../../../contexts/themeContext'
import { useAuth } from '../../../contexts/authContext'
import AddSocialCodeForm from './subcomponents/addSocialCodeForm'
import SocialPage from './subcomponents/socialPage'
import ConnectionsCarousel from './subcomponents/connectionsCarousel'

const Social = () => {
    const {userData} = useAuth()
    const {getCardStyle} = useTheme()

    return (
        <Container fluid className='p-2'>
            <Row className='pb-3'>
                <Col>
                    <Card className={getCardStyle()}>
                        <Card.Header className='text-center rounded-top pb-0'>
                            <h3 className='fw-bold'>Welcome to The SociAble Network!</h3>
                            <h6>Here, you can add new connections, view your social page, and see your friends' pages!</h6>
                        </Card.Header>
                        <Card.Body className='text-center py-0'>
                            <Row>
                                <AddSocialCodeForm />
                            </Row>
                        </Card.Body>
                        <Card.Footer className='text-center pt-0'>
                            <h5>{userData.social ? 'When you add a connection, you can both see each others social pages. See yours below!' : 'After adding your social name, you can view your social page below!'}</h5>
                        </Card.Footer>
                    </Card>
                </Col>
            </Row>
            {userData.social ? 
                <Row className='pb-3'>
                    <Col>
                        <SocialPage socialCode={userData.socialCode} />
                    </Col>
                </Row>
            :<></>}
            {userData.social ? 
                <Row>
                    <Col>
                        <ConnectionsCarousel />
                    </Col>
                </Row>
            :<></>}
        </Container>
    )
}

export default Social