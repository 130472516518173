import React from 'react';
import ReactDOM from 'react-dom/client';

import './assets/bootstrap/css/bootstrap.min.css'
import './assets/css/Stats-icons.css'

import App from './App';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <App />
);
