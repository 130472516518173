import React from 'react'
import { Card, Row, Col } from 'react-bootstrap'
import MedalView from '../../achievements/subcomponents/medals/medalView'
import { useTheme } from '../../../../contexts/themeContext'

const MedalCase = ({medals, isCurrentUser=true}) => {
    const {darkMode} = useTheme()

    const highlightedMedals = Object.entries(medals).filter(medal => medal[1].highlight).map(medal => ([medal[0], {...medal[1]}]))
    const size = 80

    return (
        <Card className={'shadow h-100 w-75 border border-4 border-primary' + (darkMode ? '' : '-light')} style={{transition: 'border 0.5s', minWidth: 4*size + 10}}>
            <Card.Body>
                <Row className='mb-3'>
                    {[0, 1, 2].map(i => (
                        <Col key={i} className='position-relative d-flex justify-content-center' style={{height: size + 'px', zIndex: 0}}>
                            {highlightedMedals[i] ?
                                <MedalView medalId={highlightedMedals[i][0]} medalData={highlightedMedals[i][1]} size={size} clickable={false} isCurrentUser={isCurrentUser} />
                            :
                                <div className='rounded-circle' style={{width: size + 'px', height: size + 'px', backgroundColor: 'gray'}}></div>
                            }
                        </Col>
                    ))}
                </Row>
                <Row>
                    {[3, 4, 5].map(i => (
                        <Col key={i} className='d-flex justify-content-center' style={{height: size + 'px'}}>
                            {highlightedMedals[i] ?
                                <MedalView medalId={highlightedMedals[i][0]} medalData={highlightedMedals[i][1]} size={size} clickable={false} />
                            :
                                <div className='rounded-circle' style={{width: size + 'px', height: size + 'px', backgroundColor: 'gray'}}></div>
                            }
                        </Col>
                    ))}
                </Row>
            </Card.Body>
        </Card>
    )
}

export default MedalCase