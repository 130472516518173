import React from 'react'

import { Container } from 'react-bootstrap'
import { useTheme } from '../../../contexts/themeContext'

const PrivacyPolicy = () => {
    const { getBG } = useTheme()

    return (
        <Container fluid className={'px-0 mx-0 ' + getBG()} style={{paddingTop: '85px', transition: 'background 0.5s'}}>
            {/* <object data={policy} width='100%' height='900'>Fiable Privacy Policy</object> */}
            <PrivacyPolicyHTML />
        </Container>
    )
}

const PrivacyPolicyHTML = () => {
    const {getTextInv} = useTheme()
    
    return (
        <div className={'p-5 text-transition ' + getTextInv()}>
            <h1 className='fw-bold'>Privacy Policy</h1>
            <p><strong>Last Updated: 08/28/2024</strong></p>

            <h2 className='fw-bold'>1. Scope and Updates to this Privacy Policy</h2>
            <p>This Privacy Policy describes how Fiable Inc. dba Fiable (“Fiable,” “we,” “us,” “our”) uses, shares, and protects information we may collect through www.fi-able.com and the platform (“Platform”). This privacy policy applies to business representatives and prospective influencers on our Platform to learn more about Fiable’s services and does not cover the collection, usage, and sharing of information in connection with your application or acceptance into the Fiable creator network (“Program”). If you are a creator or an advertiser applying for participation to or actively participating in our Program, the processing of your information in connection with the Program shall be governed by our commercial agreements with you.</p>

            <h2 className='fw-bold'>2. Collection of Information</h2>
            <p>We may collect, store, and use the following kinds of information that you either provide to us directly through your interaction with the Platform, personal information collected automatically when you interact with our Program, or information from other sources such as third-party services and organizations, as described below:</p>
            <ul>
                <li><strong>Personal Information You provide to Us directly.</strong>
                    <ul>
                        <li><strong>Contact Form.</strong> By completing the contact form on our website (including your name, email address, phone number, location, company, social media accounts and/or website, and any information you enter in the “Message” field).</li>
                        <li><strong>Book a Demo.</strong> By completing the form on our website (including your name, email address, phone number, company name, phone number, and/or any information you enter in the “How Would a Demo Benefit You?” field).</li>
                        <li><strong>Email Notifications.</strong> By subscribing to our email notifications and/or newsletters (including your name and email address).</li>
                        <li><strong>Other Communications.</strong> By corresponding with us via email, phone, or otherwise.</li>
                        <li><strong>Conferences, Trade Shows, and Other Events.</strong> We may collect personal information from individuals when we attend or host conferences, trade shows, and/or other events.</li>
                        <li><strong>Negotiations, Business Relationships.</strong> By entering into or negotiating to enter into a business relationship with us (including your name, address, email address, phone number, social media accounts and/or website, account name, nickname, information about preferences, information from your agent or manager if applicable, start and end date of agreements made, bank details, and impressions on your social media accounts relating to marketing posts (including the amount of views, engagements) you have agreed to provide us with.)</li>
                    </ul>
                </li>
                <li><strong>Personal Information collected automatically from interaction with the Program.</strong>
                    <ul>
                        <li><strong>Internet and other network activity.</strong> Your IP address and non-personal identification information about you whenever you interact with the Platform, including your operating system, browser type, device or session IDs, and information regarding your system configuration, network, and connected hardware (computer, mobile device).</li>
                    </ul>
                </li>
                <li><strong>Personal information collected from third parties.</strong> We work closely with third parties, including business partners, clients, vendors, and consultants, and may receive information about you from them.</li>
            </ul>

            <h2 className='fw-bold'>3. Use of Information We Collect</h2>
            <p>We use information we collect from you to fulfill the obligations of our contract with you and to provide our services, including:</p>
            <ul>
                <li>Contacting you and delivering the information you've requested, which includes our newsletters, blogs, and program marketing materials.</li>
                <li>Addressing applications and queries from potential retail and influencer partners.</li>
                <li>Performing internal analysis to comprehend our audience and enhance our Platform and Program marketing strategies.</li>
                <li>Offering marketing materials about our Program via digital advertising, which you can agree to or opt out of, based on your location.</li>
                <li>Enhancing the security of our Platform, analyzing how it's used, and managing its operation.</li>
                <li>Identifying and mitigating risks related to fraud, spam, and other harmful online activities on our Site, as well as bolstering our security defenses.</li>
                <li>Upholding and defending our legal rights in potential disputes with third parties; and</li>
                <li>Ensuring we abide by all relevant legal and regulatory requirements, as well as the directives of judicial authorities in each region where we operate.</li>
            </ul>

            <h2 className='fw-bold'>4. Disclosure of Information</h2>
            <p>We may disclose information about you and your usage of our Platform as follows:</p>
            <ul>
                <li><strong>Disclosure to Our Service Providers.</strong> We may share all categories of your collected personal information as necessary with our third-party service providers. This includes service providers that provide us with IT support, hosting, payment processing, customer service, and other services.</li>
                <li><strong>Disclosure to Digital Advertising Providers.</strong> To provide us with advertising and marketing services, we partner with certain third parties that may systematically collect certain identifier information and internet and network activity information about your use of the Platform, such as your browsing history, IP address, pages viewed, and links clicked, so that they can use this information on our behalf to serve you ads for our Platform on other sites. These third parties may also use this collected information for their own business purposes and in relation to their other clients, governed by their own terms and privacy policy. You may have opt-in or opt-out rights regarding the collection, use, and sharing of your personal information for cross-context behavioral advertising or targeted advertising, depending on the data protection laws applicable to your use of the Platform in your location.</li>
                <li><strong>Disclosure for Legal Purposes.</strong> We may access, preserve, and disclose any information we store associated with you to external parties if we, in good faith, believe doing so is required or appropriate to: comply with law enforcement or national security requests and legal process, such as a court order or subpoena; to enforce our terms and conditions or other policies applicable to our Platform; and to protect the rights, property, life, health, security, and safety of Fiable or any third party.</li>
                <li><strong>Disclosures in Relation to Business Asset Transfers.</strong> We may share the information we collect, including personal information, with any third party in connection with, or during confidential negotiations of, any merger, acquisition, reorganization, or liquidation, foreclosure, or repossession, receivership, or any other type of acquisition of all or any portion of Company assets, conveyance, or transfer of all or a portion of our business to another company as permitted by law and/or contract.</li>
            </ul>

            <h2 className='fw-bold'>5. Your Privacy Choices and Rights</h2>
            <p>In accordance with applicable law, you have the right to: know about the personal information we process, and to access, correct, delete, and object to or limit the sale, sharing, or other processing of your personal information by opting out, or to engage an agent to exercise these rights on your behalf, as well as a right not to be discriminated against for exercising your privacy rights and to complain to the applicable authorities in the event your privacy rights are not respected.</p>
            <p>If you would like to exercise any of these rights, please contact us according to the “Contact Us” section below. We will respond to all qualified requests we receive from individuals wishing to exercise their rights in accordance with currently applicable privacy or data protection laws.</p>

            <h2 className='fw-bold'>6. Other Notices – Information Security, International Transfer, Retention of Personal Information, Cookies, Third Party Services, and Features and Analytics</h2>
            <ul>
                <li><strong>Information Security.</strong> We implement suitable technical and administrative safeguards tailored to the level of risk associated with the personal data we handle. These safeguards encompass a range of physical, digital, and procedural strategies to safeguard the integrity, accessibility, and confidentiality of data. We consistently assess our security protocols to ensure they provide adequate protection. However, while we strive to protect your information, we cannot guarantee its absolute security against potential loss or unauthorized access.</li>
                <li><strong>International Transfer.</strong> We may move, process, and keep your personal information in various locations globally, including the United States and other nations that might have different data protection regulations than those in your country. We commit to protecting your personal data in accordance with relevant legal standards.</li>
                <li><strong>Retention of Personal Information.</strong> We will keep your information only for the time needed to achieve the reasons it was gathered, including meeting any legal, accounting, or reporting obligations. Should there be a complaint or if we think there might be legal action related to our relationship with you, we may hold onto your personal data for a longer duration. The basis for deciding how long to retain data includes the type of data, the reasons it is held, how sensitive the data is, the possible risk of damage from unauthorized access or sharing, and any legal, regulatory, tax, accounting, or other relevant obligations.</li>
                <li><strong>Cookies.</strong> Our website employs cookies to improve your user experience. Cookies are stored on your computer by your web browser for record-keeping and sometimes to monitor your activities. You have the option to configure your browser to block cookies or to notify you when cookies are sent. However, disabling cookies might affect the functionality of certain parts of our website.</li>
                <li><strong>Third Party Services and Features.</strong> Our website includes links to sites, services, and platforms owned and operated by third parties, as well as social media profiles that offer interactive features. These features, such as "liking" or "sharing" content on social media, may involve interaction with third-party services. Linking to these third-party services does not imply our endorsement or association with these third parties. We have no control over and assume no responsibility for the content or practices of these third-party services. It's important to review their privacy policies, as they may collect and use your information independently.</li>
            </ul>
            <p>The collection, storage, and use of information by third parties through our website or their own services and features are governed by their own privacy policies and practices. This includes how they share information with us, the choices you have on their platforms, and where the information is stored. We are not liable for third-party practices and suggest consulting their privacy policies and terms of use for more information.</p>

            <h2 className='fw-bold'>7. Third Party APIs</h2>
            <p>Fiable offers functionality that enables you to view analytics for content published on YouTube, achieved through the integration of the YouTube API. In utilizing this service, Fiable commits to adhering strictly to the Google API Services User Data Policy, including the Limited Use requirements, for the use and transfer of information received from Google APIs. By engaging with our YouTube analytics feature, you consent to the sharing of necessary data under these guidelines. We ensure all third-party partners, like YouTube, uphold strong data protection standards. We encourage users to review relevant third-party privacy policies to understand their data use. For adjustments to your data sharing preferences or inquiries, please reach out to us directly by email to legal@fi-able.com. Note that changes to these preferences may impact the service's functionality, and any updates to our practices will be promptly reflected in our privacy policy.</p>

            <h2 className='fw-bold'>8. Children</h2>
            <p>The Platform is not directed to people under 18 (or any other age as required by local law). If a parent or guardian becomes aware that his or her child has provided us with personally identifiable information without their consent, he or she should contact us as described in “Contact Us” below. If we become aware that a child under 18 has provided us with personally identifiable information, we will remove such information from our files immediately.</p>

            <h2 className='fw-bold'>9. Changes to Our Privacy Policy</h2>
            <p>Any changes or updates to this Privacy Policy will be posted on the Platform. Please return frequently to see any changes or updates to our Privacy Policy. In the event of a material change, we will provide you with an additional notice via email communication.</p>

            <h2 className='fw-bold'>10. Contact Us</h2>
            <p>Fiable is the controller of the personal information we process under this Privacy Policy. Questions, comments, and requests regarding this Privacy Policy are welcomed and should be directed by email to <a href="mailto:legal@fi-able.com">legal@fi-able.com</a> or by mail to Fiable Inc. dba Fiable, 3131 NE 7th Ave, Miami, Apt. 2701, FL 33137 ATTN: Fiable Legal.</p>
        </div>
    )
}

export default PrivacyPolicy