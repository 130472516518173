import React, { useState, useEffect } from 'react'
import { Card, Container, Row, Col, Image, Carousel } from 'react-bootstrap';

import { useTheme } from '../../../../../contexts/themeContext';
import useIntersectionObserver from '../../../../../utils/useIntersectionObserver';

import AdHate from '../../../../../assets/img/adHate/adHate.png'
import CaveMan from '../../../../../assets/img/adHate/caveMan.png'
import Lock from '../../../../../assets/img/adHate/lock.png'
import GraphUp from '../../../../../assets/img/adHate/graphUp.png'
import CreatorGraph from '../../../../../assets/img/adHate/creatorGraph.png'

const DetailsCardDeck = () => {
    const { darkMode, getThemeTextMain, getThemeBGMain, getThemeBG, getThemeBGInv, getThemeTextInv, isSmallScreen, screenHeight } = useTheme()
    const [scrollOffset, setScrollOffset] = useState(0)
    const [isActive, setIsActive] = useState(true)
    const [waypoint, setWaypoint] = useState(0)
    const [hasBeenViewed, setHasBeenViewed] = useState(false)
    const [firstViewScrollY, setFirstViewScrollY] = useState()
    const scrollStep = 192
    const maxScroll = scrollStep * 5
    const animationSpeed = 120
    const cardBack = darkMode ? '#42414e' : '#e8d8cd'
    const waypointStep = (screenHeight * 4) / 6

    const qualityCardImg = (
        <Image src={CreatorGraph} alt="a graph representing Fiable's Creator Network" style={{maxWidth: '140%', maxHeight: '90%', transformOrigin: 'center center'}}/>
    )
    const prerollsCardImg = (
        <Image src={AdHate} alt="negative comments about prerolls" className='rounded-4' style={{maxWidth: '90%', maxHeight: '80%'}}/>
    )
    const simplicityCardImg = (
        <Image src={CaveMan} alt='a man reclining on a beach chair' style={{transformOrigin: 'center center', transform: 'scale(150%)', maxWidth: '90%'}}/>
    )
    const safetyCardImg = (
        <Image src={Lock} alt='a pad lock' style={{maxWidth: '50%', maxHeight: '60%', transformOrigin: 'center center', transform: 'rotate(5deg)'}}/>
    )
    const kpiCardImg = (
        <Image src={GraphUp} alt='an upwards trending graph' style={{ maxWidth: '60%', maxHeight: '90%', transformOrigin: 'center center', transform: 'scale(150%)'}}/>
    )

    const cardDetails1 = [
        {
            title: "Quality and Quantity",
            body: "We facilitate brand sponsorships for all creators regardless of size, allowing unprecedented degrees of freedom for your marketing campaign",
            bg: cardBack,
            text: getThemeTextMain('primary'),
            bg2: getThemeBGMain('primary'),
            img: qualityCardImg
        },
        {
            title: "Brand Safety",
            body: "Our custom VeriFi AI simplifies the creator vetting process and we personally veriFi all creators in our network meet your specific brand safety requirements",
            bg: cardBack,
            text: getThemeTextInv('info'),
            bg2: getThemeBGInv('info'),
            img: safetyCardImg
        },
        {
            title: "Amplify KPIs",
            body: "Our proprietary system drastically lowers all costs of advertising while quality creator brand advocates boost interaction",
            bg: cardBack,
            text: getThemeTextInv('primary'),
            bg2: getThemeBGInv('primary'),
            img: kpiCardImg
        },
        {
            title: "It's so Easy",
            body: "Sign-up and Market your brand in 10 minutes or less.\n We manage all the hassle of due dilengence, marketing material delivery, and payments",
            bg: cardBack,
            text: getThemeTextMain('danger'),
            bg2: getThemeBG('danger'),
            img: simplicityCardImg
        },
        {
            title: "Problematic Prerolls",
            body: "Audiences are becoming disenchanted with forceful injections of Advertisements,\n and resort to using Ad-Blockers,\n cheating creators and hurting your campaigns ",
            bg: cardBack,
            text: getThemeTextInv('success'),
            bg2: getThemeBGInv('success'),
            img: prerollsCardImg
        },
    ]

    const [refTop, isIntersectingTop] = useIntersectionObserver({
        root: null, 
        threshold: 0, 
      })

    const [refBottom, isIntersectingBottom] = useIntersectionObserver({
        root: null, 
        threshold: 0, 
    })  
   
    const isActiveTimer = () => {
        setIsActive(true)
        
        setTimeout(() => {
            setIsActive(false)
        }, 10)
    }

    useEffect(() => {
        const handleSetWaypoint = (direction) => {
            if (direction === 'down') {
                if ((waypoint + 1) > 6) {
                    return 6
                } else {
                    return (waypoint + 1)
                }
            } else if (direction === 'up') {
                if ((waypoint - 1) < 1) {
                    return 1
                } else {
                    return (waypoint - 1)
                }
            }
        }
        if (!hasBeenViewed && isIntersectingTop && isIntersectingBottom) {
            setHasBeenViewed(true)
            setFirstViewScrollY((refBottom.current.getBoundingClientRect().top))
            setIsActive(false)
        }

        const handleScroll = () => {
            if (((isIntersectingTop || isIntersectingBottom) && !isActive)) {
                const cardRow = document.getElementById('detail-cards-row')
                const position = window.scrollY
                if (cardRow && (position > firstViewScrollY + waypointStep * waypoint) && isIntersectingBottom) {
                    setWaypoint(handleSetWaypoint('down'))
                    const newScrollOffset = scrollOffset - scrollStep
                    setScrollOffset((newScrollOffset < (-1 * maxScroll) ? (-1 * maxScroll) : newScrollOffset))
                    isActiveTimer()
                } else if (cardRow && (position < firstViewScrollY + waypointStep * (waypoint - 1)) && isIntersectingTop) {
                    setWaypoint(handleSetWaypoint('up'))
                    const newScrollOffset = scrollOffset + scrollStep
                    setScrollOffset(newScrollOffset > 0 ? 0 : newScrollOffset)
                    isActiveTimer()
                }
            }
            if(((isIntersectingBottom && !isIntersectingTop) && !isActive)) {
                setScrollOffset(-1 * maxScroll)
            }
            if(((!isIntersectingBottom && isIntersectingTop) && !isActive)) {
                setScrollOffset(0)
            }
        }
        window.addEventListener('scroll', handleScroll)

        return () => {
            window.removeEventListener('scroll', handleScroll)
        }
        
      
    }, [refBottom, scrollOffset , isIntersectingTop, isIntersectingBottom, maxScroll, isActive, hasBeenViewed, firstViewScrollY, waypoint, waypointStep])

    return (
        <div id='detailsCardDeckParent' className={(isSmallScreen ? '' : 'pb-5 mb-5 ') + (!darkMode ? '' : 'my-bg-dark')} style={{scrollSnapType: 'y mandatory',  height: (isSmallScreen ? '800px' : screenHeight * 4), transition: 'background 0.5s'}}>
            <Container fluid id='detail-cards-container' className={'sticky-top overflow-y-visible back-blur ' + (isSmallScreen ? 'py-0' : 'py-5')} style={{transition: 'background 0.5s', height: (isSmallScreen ? '' : '80vh')}}>
                <div ref={refTop} className='position-absolute start-50 ' style={{height: '1%', top: '10%'}}/>
                { isSmallScreen ?
                <div id={'detail-cards-row'} className='mt-5 w-100' style={{height: 'max-content', transition: 'transform 0.5s'}}>
                    <CardSliderMobile cards={ cardDetails1 }/>
                </div>
                :
                <Row id={'detail-cards-row'} className='mt-5' style={{transition: 'transform 0.5s'}}>
                    <CardSlider scrollOffset={scrollOffset} scrollStep={scrollStep} cards={ cardDetails1 } time={animationSpeed}/>
                </Row>
                }
                <div ref={refBottom} className='position-absolute start-50' style={{height: '1%', bottom: '-10%'}}/>
            </Container>
        </div>
    )
}

const CardSlider = (props) => {
    const { darkMode } = useTheme()
    const scrollStep = props.scrollStep

    const handleTranslate = (scrollOffset, index) => {
        const offset = scrollOffset + scrollStep * index
        if (offset < 0 ) {
            return 'translate(0, -8%) scale(0.9) '
        } else if (offset > (scrollStep * index)) {
            return `translateX(${scrollStep * index}vw)`
        } else {
            return `translateX(${offset}vw)`
        }
    }

    return (
        <div className='d-flex justify-content-center' syle={{scrollBehavior: 'smooth', placeItems: 'center center'}}>
                { props.cards.map((card, index) => (
                    <Card className={'shadow m-0 position-absolute rounded-5'} style={{
                        transform: handleTranslate(props.scrollOffset, index),
                        transition: 'transform 0.75s ease, background 0.5s',
                        backgroundColor: card.bg ,
                        width: '90vw',
                        height: '75vh'
                        }}
                    key={index}>
                        <Row className='h-100'>
                            <Col sm={12} lg={6} className='h-100'>
                                <div className='h-100 text-center align-content-center px-4 py-1 px-md-5 text-wrap rounded-5'>
                                    <p  className={'arvo-bold-italic mb-4 fs-1 ' + card.text} style={{transition: 'color 0.5s'}}>
                                        {card.title}
                                    </p>
                                    <p className={'text-center arvo-regular mb-3 fs-3 ' + (darkMode ? 'my-text-primary-light' : 'my-text-light')} style={{transition: 'color 0.5s'}}>
                                        {card.body}
                                    </p>
                                </div>
                            </Col>
                            <Col sm={12} lg={6}>
                                <div className={'h-100 justify-content-center align-items-center d-flex rounded-5 w-100 overflow-hidden bg-transition ' + card.bg2}>
                                    {card.img}
                                </div>
                            </Col>
                        </Row>
                    </Card>
                ))}
        </div>
    )
}

const CardSliderMobile = (props) => {
    const { darkMode } = useTheme()

    return (
        <div className='d-flex flex-column flex-wrap align-items-center w-100 h-100'>
            <Carousel>
                { props.cards.map((card, index) => (
                    <Carousel.Item key={`mobile-detailCard-${index}`}>
                        <div 
                            className='shadow rounded-5'
                            style={{
                                transition: 'transform 0.75s ease, background 0.5s',
                                backgroundColor: card.bg ,
                                height: '700px'
                            }}
                            key={index}
                        >
                            <div className=' d-flex flex-column overflow-hidden rounded-bottom h-100'>
                                <div className='' style={{flex: '1 1 50%'}}>
                                    <div className='h-100 text-center align-content-center px-4 py-1 px-md-5 rounded-5' style={{textWrap: 'pretty'}}>
                                        <p  className={'arvo-bold-italic mb-4 fs-1 ' + card.text} style={{transition: 'color 0.5s'}}>
                                            {card.title}
                                        </p>
                                        <p className={'text-center arvo-regular mb-3 fs-4 ' + (darkMode ? 'my-text-primary-light' : 'my-text-light')} style={{transition: 'color 0.5s'}}>
                                            {card.body}
                                        </p>
                                    </div>
                                </div>
                                <div className='rounded-bottom d-flex' style={{flex: '1 1 50%'}}>
                                    <div className={'h-100 justify-content-center align-items-center d-flex rounded-bottom w-100 overflow-hidden bg-transition ' + card.bg2}>
                                        {card.img}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Carousel.Item>
                ))}
            </Carousel>
        </div>

    )
}

export default DetailsCardDeck;