import React, { useCallback, useEffect, useState } from 'react'
import { Row, Col, Card, Form } from 'react-bootstrap'
import { useAuth } from '../../../../contexts/authContext'
import { useTheme } from '../../../../contexts/themeContext'

const AdvertisementDetailCard = (props) => {
    const { userData, type } = useAuth()
    const {getCardStyle, getTextInv, isSmallScreen, getFormStyle} = useTheme()

    const [cardData, setCardData] = useState({})

    const getCardData = useCallback(() => {
        const adData = userData.history[props.selected]
        let newData = {}
        newData.advertiserName = adData.advertiserName
        newData.advertisementName = adData.advertisementName

        const target = type ? adData.quota : adData.totalImpressions
        let current = 0
        if (type) {
            for (const channel of adData.channels) {
                if (adData.progress[channel]) {
                    current += adData.progress[channel].impressions.at(-1)
                    newData.engagementRate = (adData.progress[channel].engagementRate * 100).toLocaleString() + '%'
                    newData.postDate = adData.progress[channel].postDate.toDate().toLocaleDateString()
                }
            }
        } else {
            current = adData.currentImpressions
            newData.postDate = Object.values(adData.progress).sort((a, b) => a.postDate - b.postDate).at(-1)?.postDate.toDate().toLocaleDateString() // FIRST USE OF ?. MY LIFE WILL NEVER BE THE SAME AGAIN
            newData.engagementRate = (Object.values(adData.progress).reduce((sum, curr) => sum + curr.engagementRate, 0) / Math.max(Object.keys(adData.progress).length, 1) * 100).toLocaleString() + '%'
        }
        newData.targetImpressions = target.toLocaleString()
        newData.currentImpressions = current.toLocaleString()
        newData.remainingImpressions = Math.max(target - current, 0).toLocaleString()

        newData.startDate = userData.history[props.selected].startDate.toDate().toLocaleDateString()
        newData.numOtherCreators = (userData.history[props.selected].creators || []).length.toLocaleString()

        setCardData(newData)
    }, [userData.history, type, props.selected])

    useEffect(() => {
        if (props.selected) getCardData()
    }, [props.selected, getCardData])

    return (
        <Card className={getCardStyle()} style={{height: isSmallScreen ? 'max-content' : '380px', transition: 'background 0.5s, color 0.5s'}}>
            <Card.Header className='py-3'>
                <p className='m-0 fw-bold'>Advertisement Progress</p>
            </Card.Header>
            <Card.Body>
                {props.selected ? <>
                    <Row>
                        <Col>
                            <Form.Group className='mb-3'>
                                <Form.Label className='ps-3'>Advertiser Name</Form.Label>
                                <Form.Control className={getFormStyle() + ' ' + getTextInv()} type='text' disabled defaultValue={cardData.advertiserName} />
                            </Form.Group>
                        </Col>
                        <Col>
                            <Form.Group className='mb-3'>
                                <Form.Label className='ps-3'>Advertisement Name</Form.Label>
                                <Form.Control className={getFormStyle() + ' ' + getTextInv()} type='text' disabled defaultValue={cardData.advertisementName} />
                            </Form.Group>
                        </Col>
                        <Col>
                            <Form.Group className='mb-3'>
                                <Form.Label className='ps-3'>Creators on Ad</Form.Label>
                                <Form.Control className={getFormStyle() + ' ' + getTextInv()} type='text' disabled defaultValue={cardData.numOtherCreators} />
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <Form.Group className='mb-3'>
                                <Form.Label className='ps-3'>Target Impressions</Form.Label>
                                <Form.Control className={getFormStyle() + ' ' + getTextInv()} type='text' disabled defaultValue={cardData.targetImpressions} />
                            </Form.Group>
                        </Col>
                        <Col>
                            <Form.Group className='mb-3'>
                                <Form.Label className='ps-3'>Current Impressions</Form.Label>
                                <Form.Control className={getFormStyle() + ' ' + getTextInv()} type='text' disabled defaultValue={cardData.currentImpressions} />
                            </Form.Group>
                        </Col>
                        <Col>
                            <Form.Group className='mb-3'>
                                <Form.Label className='ps-3'>Remaining Impressions</Form.Label>
                                <Form.Control className={getFormStyle() + ' ' + getTextInv()} type='text' disabled defaultValue={cardData.remainingImpressions} />
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <Form.Group className='mb-3'>
                                <Form.Label className='ps-3'>Ad {type ? 'Acceptance' : 'Start'} Date</Form.Label>
                                <Form.Control className={getFormStyle() + ' ' + getTextInv()} type='text' disabled defaultValue={cardData.startDate} />
                            </Form.Group>
                        </Col>
                        <Col>
                            <Form.Group className='mb-3'>
                                <Form.Label className='ps-3'>{type ? 'Ad Video Posted On' : 'Last Ad Posted On'}</Form.Label>
                                <Form.Control className={getFormStyle() + ' ' + getTextInv()} type='text' disabled value={cardData.postDate || 'Not Posted Yet'} onChange={() => true} />
                            </Form.Group>
                        </Col>
                        <Col>
                            <Form.Group className='mb-3'>
                                <Form.Label className='ps-3'>Engagement Rate</Form.Label>
                                <Form.Control className={getFormStyle() + ' ' + getTextInv()} type='text' disabled value={cardData.engagementRate || 'Unavailable'} onChange={() => true} />
                            </Form.Group>
                        </Col>
                    </Row>
                    </>
                :
                    <div className='h-75 d-flex align-items-center justify-content-center fs-3'>{Object.keys(cardData).length ? 'Select an Advertisement from the list to view progress details' : 'No Active Advertisements'}</div>
                }
            </Card.Body>
        </Card>
)
}

export default AdvertisementDetailCard;