import React, { useEffect, useMemo, useState } from 'react'
import { useLocation, useParams } from 'react-router-dom'

import ReactGA from 'react-ga4'
import { Container } from 'react-bootstrap'

const RedirectPage = () => {
    const location = useLocation()
    const urlParams = useMemo(() => new URLSearchParams(location.search), [location])
    const params = useParams()

    const [link, setLink] = useState('')
    const [redirected, setRedirected] = useState(false)

    useEffect(() => {
        if (params.link === 'null' || !urlParams.get('link')) {
            setRedirected(true)
            return
        }

        window.dataLayer = window.dataLayer || []
        if (urlParams.get('referrer')) {
            window.dataLayer.push({
                event: 'page_redirect',
                triggerName: `${urlParams.get('referrer')} Trigger`
            })
        }

        ReactGA.send({hitType: 'pageview', page: location.pathname + location.search, title: 'redirect-view'})

        let newLink = decodeURIComponent(urlParams.get('link'))
        if (!newLink.includes('https://')) newLink = 'https://' + newLink

        window.location.href = newLink
        setLink(newLink)

    }, [urlParams, params, location])

    return (
        <Container fluid className='text-center' style={{paddingTop: '85px', height: '82vh'}}>
            <h1 className='text-center p-5 my-text-dark'>{redirected ? 'Failed to Redirect.' : 'Redirecting...'}</h1>
            <a href={link} className='my-text-primary'>Not Redirected? Click here</a>
        </Container>
    )
}

export default RedirectPage