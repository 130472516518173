import React, { useEffect, useState } from 'react'
import { useTheme } from '../../../../contexts/themeContext'
import { Button, Card, Col, Modal, Row } from 'react-bootstrap'
import { useAuth } from '../../../../contexts/authContext'
import { ArrowDown, PiggyBank, StarFill } from 'react-bootstrap-icons'

import './tokenSpinner.css'
import { medals } from './medals/medals'

const TokenExchange = () => {
    const {userData} = useAuth()
    const {getCardStyle, getThemeBG} = useTheme()

    return (
        <Card className={'h-100 ' + getCardStyle('primary')}>
            <Card.Header className='d-inline-flex text-center justify-content-end'>
                <Row className='d-flex flex-column align-items-start w-100 me-1'>
                    <h3 className='fw-bold fst-italic mb-0'>Tokens</h3>
                    <TokenSlotMachine tokens={userData.social.tokens} />
                </Row>
                <PiggyBank className='ms-2 position-absolute fs-2'/>
            </Card.Header>
            <Card.Body className='d-flex flex-column align-items-center pt-0'>
                {Array.from({ length: userData.social.tier }, (_, i) => (i + 1)).map(tier => (
                    <TokenExchangeTier key={tier} tier={tier} />
                ))}
            </Card.Body>
            <Card.Footer className='d-flex justify-content-center'>
                <Button
                    className={'position-relative d-inline-flex flex-nowrap justify-content-center text-center border-0 rounded-3 px-1 my-text-dark bg-transition ' + getThemeBG('primary')}
                    disabled={true}
                >
                    <StarFill className='my-text-success fs-4 me-1 pb-1' />
                    <h6 className='fw-bold mb-0 text-nowrap' style={{paddingTop: '1px'}}>Prestige (50 Tokens)</h6>
                    <StarFill className='my-text-success fs-4 ms-1 pb-1' />
                </Button>
            </Card.Footer>
        </Card>
    )
}

const TokenSlotMachine = ({tokens}) => {
    const extraDigits = 2
    const tokenString = String(tokens).padStart(String(tokens).length + extraDigits, '0')
    const digitCount = tokenString.length
    const [digits, setDigits] = useState(Array.from({ length: digitCount }, () => Math.floor(Math.random() * 10)))
    
    useEffect(() => {
        // Spin digits with random numbers
        const updateDigit = (digit, index) => {
            setTimeout(() => {
                setDigits((prevDigits) => {
                    const newDigits = [...prevDigits]
                    newDigits[index] = digit
                    return newDigits
                })
            }, index * 200)
        }

        const interval = setInterval(() => {
            setDigits(Array.from({ length: digitCount }, () => Math.floor(Math.random() * 10)))
        }, 100)

        // Stop spinning each digit with a cascading delay
        setTimeout(() => {
            clearInterval(interval) 
            Array.from(tokenString, Number).forEach((digit, index) => updateDigit(digit, index))
        }, 1000)
        
        return () => clearInterval(interval)
    }, [tokens, setDigits, digitCount, tokenString])
    
    return (
        <div className='token-spinner'>
        {digits.map((digit, index) => (
            <div key={index} className='digit'>
                <div className='digit-inner' data-digit={digit}></div>
            </div>
        ))}
        </div>
    );
}

const TokenExchangeTier = ({tier}) => {
    const {userData, redeemMedals, setSuccess, setError, setSocialData} = useAuth()
    const {getButtonStyle, darkMode, getThemeBG, getBG, getTextInv, getButtonThemeStyle} = useTheme()

    const [userTier, setUserTier] = useState(1)
    const [canTierUp, setCanTierUp] = useState(false)
    const [redeemableMedals, setRedeemableMedals] = useState([])
    const [isRedeeming, setIsRedeeming] = useState(false)
    const [isTieringUp, setIsTieringUp] = useState(false)

    const [showTierUpConfirm, setShowTierUpConfirm] = useState(false)

    const redeemAll = async () => {
        setIsRedeeming(true)

        try {
            await redeemMedals(redeemableMedals.map(m => m.id))
            setSuccess('All medals claimed!')
        } catch (err) {
            setError('Failed to claim all medals, try claiming individually')
        } finally {
            setIsRedeeming(false)
        }
    }

    const tierUp = async () => {
        setIsTieringUp(true)
        try {
            await setSocialData({tier: userData.social.tier + 1, tokens: userData.social.tokens - (tier * 10)})
            setSuccess('Tiered up!')
        } catch (err) {
            setError('Failed to tier up')
        } finally {
            setIsTieringUp(false)
        }
    }

    useEffect(() => {
        setUserTier(userData.social.tier)
        const currentTierMedals = Object.entries(medals).filter(m => m[1].tier === tier).map(m => ({...m[1], ...(userData.social.medals[m[0]] || {}), id: m[0]}))
        setCanTierUp(currentTierMedals.reduce((sum, curr) => sum && curr.dateAchieved, true) && userData.social.tokens >= userData.social.tier * 10)
        setRedeemableMedals(currentTierMedals.filter(m => m.dateAchieved && !m.isRedeemed))
    }, [setUserTier, setCanTierUp, setRedeemableMedals, userData.social, tier])

    return (
        <Row className={'w-100 d-flex flex-column flex-nowrap justify-content-center align-items-center mt-1 ' + (canTierUp || userTier !== tier ? 'border-bottom border-2 border-' + (darkMode ? 'white' : 'dark') : '')} style={{height: '154px', transition: 'border 0.5s'}}>
            <div className='position-relative d-flex flex-column justify-content-center text-center' style={{top: (tier === userTier ? '15px' : '43px')}}>
                {tier === userTier ?
                    (canTierUp ?
                        <h5 className='m-0 py-1 fst-italic'>All Medals Claimed</h5>
                    :
                        <h5 className='m-0 py-1 fst-italic'>{redeemableMedals.length} Available Medal{redeemableMedals.length === 1 ? '' : 's'}</h5>
                    )
                :
                    <h2 className='fw-bold fst-italic'>Tier Complete!</h2>
                }
                <Button className={'text-nowrap ' + getButtonStyle('lg')} onClick={redeemAll} disabled={isRedeeming || !redeemableMedals.length} style={{opacity: (tier === userTier ? '' : 0)}}>
                    Claim All!
                </Button>
            </div>
            {tier === userTier ?
                <Button
                    className={'position-relative d-inline-flex flex-nowrap justify-content-center text-center border-0 rounded-3 px-1 my-text-dark bg-transition ' + getThemeBG('primary')}
                    style={{top: '30px'}}
                    onClick={() => setShowTierUpConfirm(true)}
                    disabled={!canTierUp || isTieringUp}
                >
                    <ArrowDown className='my-text-success fs-4' />
                    <h6 className='fw-bold mb-0 text-nowrap' style={{paddingTop: '1px'}}>Tier Up! ({tier * 10} Tokens)</h6>
                    <ArrowDown className='my-text-success fs-4' />
                </Button>
            :<></>}
            <Modal show={showTierUpConfirm} onHide={() => setShowTierUpConfirm(false)}>
                <Modal.Header closeButton className={getBG() + ' ' + getTextInv()}>Confirm Tier Up</Modal.Header>
                <Modal.Body className={'d-flex flex-column text-center rounded-bottom ' + getBG() + ' ' + getTextInv()}>
                    <h3>Are you sure you want to do this?</h3>
                    <h5>- This cannot be undone</h5>
                    <h5>- This costs {tier * 10} tokens</h5>
                    <Row className='d-inline-flex'>
                        <Col className='text-start'><Button className={getButtonThemeStyle('md', 'danger')} onClick={() => setShowTierUpConfirm(false)}>No, I'm not ready</Button></Col>
                        <Col className='text-end'><Button className={getButtonThemeStyle('md', 'success')} onClick={() => tierUp().then(() => setShowTierUpConfirm(false))}>Yes, Let's go!</Button></Col>
                    </Row>
                </Modal.Body>
            </Modal>
        </Row>
    )
}

export default TokenExchange