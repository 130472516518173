import React, { useEffect, useState } from 'react'
import { Row, Col, Card, Image } from 'react-bootstrap'
import { useAuth } from '../../../../contexts/authContext'
import { useTheme } from '../../../../contexts/themeContext'

const ChannelSnippetCard = (props) => {
    const { userData } = useAuth()

    const {isSmallScreen, getCardStyle} = useTheme()

    const [channel, setChannel] = useState()

    useEffect(() => {
        if (props.selected)
            setChannel(userData.channels.find(channel => channel.platform === props.selected.split(':')[0] && channel.handle === props.selected.split(':')[1]) || {})
    }, [userData.channels, props.selected, setChannel])

    return (
        <Card className={getCardStyle()} style={{height: '100%', transition: 'background 0.5s, color 0.5s'}}>
            <Card.Header className='py-3'>
                <p className='m-0 fw-bold'>Channel Snippet</p>
            </Card.Header>
            <Card.Body>
                {props.selected && channel ? <>
                    <Row>
                        <Col lg={4} className='text-center'><Image height={200} width={200} roundedCircle src={channel.thumbnail} /></Col>
                        <Col lg={8} className={'pt-3 d-flex flex-column justify-content-center ' + (isSmallScreen && 'text-center')}>
                            <Row>
                                <h1 className='fw-bold' style={{fontSize: '2vw'}}>@{props.selected.split(':')[1]}</h1>
                            </Row>
                            <Row className='pt-2'>
                                <h1 style={{fontSize: '1.8vw'}}>Started on {channel.publishedAt ? channel.publishedAt.toDate().toLocaleDateString() : 'Unknown'}</h1>
                            </Row>
                            <Row>
                                <h1 style={{fontSize: '1.8vw'}}>Based in {channel.country || 'Unknown'}</h1>
                            </Row>
                        </Col>
                    </Row>
                    <Row className='pt-2'>
                        <p className='text-center'>{channel.description && channel.description.length ? channel.description : 'Channel does not have a description.'}</p>
                    </Row>
                    </>
                :
                    <h3 className='text-center py-5 my-4'>{userData.channels.length ? 'Select a Channel from the left to view details' : 'Link Your Channel to view details'}</h3>
                }
            </Card.Body>
        </Card>
    )
}

export default ChannelSnippetCard;