import React from 'react'
import { Container } from 'react-bootstrap'
import { useTheme } from '../../../contexts/themeContext'

const CreatorAgreement = () => {    
    return (
        <Container fluid className='p-5'>
            {/* <object data={Agreement} width='100%' height='100%'>Fiable Creator Agreement</object> */}
            <CreatorAgreementHTML />
        </Container>
    )
}

export const CreatorAgreementHTML = () => {
    const {getTextInv} = useTheme()
    
    return (
        <div className={'text-transition ' + getTextInv()}>
        
        <h4 className='fw-bold'>Creator Agreement</h4>
        <h6>Last Updated 08/28/2024</h6>
        
        <h4 className='fw-bold'>Parties</h4>
        <h6>
        Fiable: This Agreement is entered into by and between Fiable LLC, a Florida Limited Liability Company with its principal place of business located at 3131 NE 7th Ave Unit 2701, Miami FL 33137, herein referred to as "Fiable," "we," "us," or "our."
        </h6>
        <h6>
        Creator: The other party to this Agreement is the advertiser, herein referred to as "Creator" or "you," who engages with the Fiable platform to utilize the services provided. The Creator agrees to the terms of this Agreement upon clicking the "I Agree" button during the registration or ad campaign initiation process on the Fiable platform. The Creator represents and warrants that they are lawfully able to enter into contracts and are the legitimate holder of any accounts used to receive services or compensation from either Fiable or our Advertising partners.
        </h6>
        <h6>
        Acceptance: By clicking "I Agree," you, the Creator, acknowledge that you have read, understood, and agree to be bound by the terms of this Agreement, including any documents incorporated herein by reference. You further agree that this click constitutes your electronic signature and expresses your intent to be bound by the terms of this Agreement as if you had physically signed a paper version of this Agreement.
        </h6>
        
        <h4 className='fw-bold'>Background</h4>
        <h6>
        Fiable, a leading advertising brokerage firm specializing in connecting advertisers with content creators, enters into this Agreement with the Creator referred to as “The Creator” to facilitate the seamless integration of advertisements into the Creator’s content.
        </h6>
        <h6>
        Fiable aims to provide advertisers with effective avenues for promoting their products or services through strategic partnerships with The Creator across various digital platforms. Through this agreement, Fiable seeks to leverage the unique creative talents and engaged audiences of the Creator to deliver impactful advertising campaigns that resonate with viewers.
        </h6>
        <h6>
        The Creator, renowned for their captivating storytelling, extensive reach, and loyal fan base, possesses the ability to create compelling and authentic content that aligns with the interests and preferences of their audience. By collaborating with Fiable, the Creator can monetize their content while maintaining the trust and engagement of their audience.
        </h6>
        <h6>
        This agreement establishes the framework for the partnership between Fiable and the Creator, outlining the roles, responsibilities, and expectations of each party. By working together, Fiable and the Creator aim to deliver successful advertising campaigns that drive brand awareness, engagement, and ultimately results for the advertisers.
        </h6>
        
        <h4 className='fw-bold'>Scope of Work</h4>
        <h5 className='fw-bold'>Link Placement</h5>
        <h6>
        Link Integration: The Creator agrees to include provided advertiser links in the description section of their video content. These links should be prominently placed above the fold to ensure easy access for viewers.
        </h6>
        <h6>
        Frequency: The Creator will include the advertiser’s links in all relevant video descriptions during the campaign period specified by Fiable.
        </h6>
        
        <h5 className='fw-bold'>Badge Placement</h5>
        <h6>
        Logo Specifications: Fiable will provide the Creator with the Advertiser’s logo in the appropriate format, size, and resolution for incorporation into the video content. The Creator shall adhere to the specifications provided by Fiable for the placement and display of the Advertisement.
        </h6>
        <h6>
        Logo Positioning: The Creator shall position the Advertisement within the video frame as directed by Fiable, ensuring that it remains visible and prominently displayed for the minimum agreed duration of the video as specified in the Advertisement details on the Fiable platform.
        </h6>
        <h6>
        Logo Size and Proportion: The Creator shall ensure that the size and proportion of the Advertisement are maintained in accordance with the specifications provided by Fiable, without distorting, resizing, or altering the logo’s dimensions in any way. The Creator shall incorporate the Advertisement into the video content in a manner that occupies a percentage area of the video frame as specified in the agreement between the Advertiser and Fiable.
        </h6>
        <h6>
        Percentage of Area: The specific percentage of the area of the video that the Advertisement should occupy will be agreed upon by Fiable and the Advertiser for each specific campaign. The Creator shall adhere to these guidelines when positioning and displaying advertisements within the video content. Fiable ensures the size of the badge will not exceed 10% of the video frame.
        </h6>
        <h6>
        Aspect Ratio: The Creator shall maintain the original aspect ratio of the logo to ensure proportional scaling of the Advertisement within the designated area of the video frame. Any adjustments to the logo’s dimensions must preserve the original aspect ratio provided by Fiable.
        </h6>
        
        <h5 className='fw-bold'>Advertiser Shoutout</h5>
        <h6>
        Script Provision: The Advertiser will provide a script for the shoutout. This script will be concise, typically consisting of a short sentence such as “Special thanks to our sponsor [Advertiser’s Name], go check out their website/product/platform at [URL].”
        </h6>
        <h6>
        Length and Content: The length of the shoutout will also be specified by the Advertiser, ensuring it is brief and to the point. The typical format will be a single sentence that acknowledges the sponsor and directs viewers to their website, product, or platform.
        </h6>
        <h6>
        Delivery: The Creator will deliver the shoutout in their natural style to maintain authenticity and viewer engagement. The Creator should aim to seamlessly integrate the shoutout into their content, ensuring it feels natural and unobtrusive.
        </h6>
        
        <h5 className='fw-bold'>Read Advertisement</h5>
        <h6>
        Length and Content: The read advertisement will typically be longer than a shoutout, ranging from 30 to 60 seconds. The exact length and content will be specified by the advertiser.
        </h6>
        <h6>
        Delivery: The Creator is encouraged to deliver the ad read into their content at a juncture that ensures a smooth transition and does not disrupt the viewer experience. Ideal points for integration might include natural breaks in the content, such as transitions between segments or topics.
        </h6>
        
        <h5 className='fw-bold'>Clip Advertisement</h5>
        <h6>
        Content Provision: The advertiser will provide the video clip to be used in the advertisement. This clip will include any visual and audio elements required by the advertiser, including an ad read if specified.
        </h6>
        <h6>
        Seamless Integration: The Creator will integrate the clip advertisement into their video content at a strategic point that ensures a smooth transition and does not disrupt the viewer experience. Preferred placements include natural breaks, transitions between segments, or at the beginning or end of the video.
        </h6>
        <h6>
        Length and Content: the length of the clip advertisement will be specified by the advertiser, typically ranging from 30 to 120 seconds. The clip may include an ad read or additional commentary by the Creator to enhance the message.
        </h6>
        <h6>
        Ad Read Integration: If the clip advertisement includes an ad read, the Creator is responsible for delivering the ad read as part of the integration. The ad read should be presented in the Creator’s natural style to maintain authenticity and engagement with the audience.
        </h6>
        <h6>
        Quality Standards: The clip advertisement must meet the same quality standards as the Creator’s regular content. This includes high resolution (minimum 720p), clear audio, and professional editing to ensure a seamless viewing experience.
        </h6>
        
        <h4 className='fw-bold'>Compliance and Disclosure</h4>
        <h6>
        Legal Compliance: The Creator shall comply with all applicable laws, regulations, and guidelines governing advertising, endorsements, and sponsored content, including but not limited to the FTC guidelines on disclosure of material connections or YouTube terms of service.
        </h6>
        <h6>
        Disclosure Requirements: The Creator shall clearly disclose any material connections with advertisers or sponsors in accordance with FTC guidelines. Such disclosures shall be made in a clear, prominent, and unambiguous manner that is easily noticeable and understandable to viewers.
        </h6>
        <h6>
        Transparency and Authenticity: The Creator shall ensure that any sponsored content or advertisements integrated into their content maintain transparency and authenticity. All advertising shall be clearly identified as such and should not mislead or deceive viewers regarding the nature of the content or the relationship between the Creator and the Advertiser.
        </h6>
        <h6>
        Endorsement Guidelines: The Creator shall adhere to any specific endorsement guidelines provided by Fiable or the Advertiser, including requirements for brand messaging, tone, and branding integration.
        </h6>
        <h6>
        Review and Approval: Prior to publishing or distributing content featuring advertisements, the Creator shall provide Fiable and the advertiser with the opportunity to review and approve the content to ensure compliance with all legal and contractual requirements.
        </h6>
        <h6>
        Record-Keeping: The Creator shall maintain accurate records of all sponsored content and advertising placements, including documentation of disclosures, agreements, and communications with Advertisers or Sponsors.
        </h6>
        <h6>
        Indemnification: The Creator shall indemnify and hold harmless Fiable and the advertiser from any claims, liabilities, damages, or expenses arising from the Creator’s failure to comply with the compliance and disclosure requirements set forth in this section.
        </h6>
        
        <h4 className='fw-bold'>Performance Metrics and Reporting</h4>
        <h6>
        Performance Tracking: Fiable shall provide the Creator with tools and systems to track the performance of advertisements integrated into their content. These tools may include unique tracking links, codes, or analytics platforms.
        </h6>
        <h6>
        Reporting Requirements: The Creator shall regularly report the performance of advertisements to Fiable, including metrics such as click-through rates, views, engagement, and conversions. The frequency and format of these reports shall be specified by Fiable.
        </h6>
        <h6>
        Confidentiality of Metrics: The Creator agrees to maintain the confidentiality of performance metrics and reporting data provided by Fiable or the advertiser. Such data shall only be used for the purpose of evaluating and optimizing the performance of advertisements.
        </h6>
        <h6>
        Optimization and Adjustments: Based on the performance metrics and reporting data, Fiable may request the Creator to make adjustments to the placement, delivery, or content of advertisements to optimize their effectiveness. The Creator shall make reasonable efforts to implement such adjustments in a timely manner.
        </h6>
        <h6>
        Performance Benchmarks: Fiable and the advertiser may establish performance benchmarks or targets for advertising campaigns. The Creator shall make reasonable efforts to meet or exceed these benchmarks and shall work collaboratively with Fiable to achieve the desired results.
        </h6>
        <h6>
        Transparency and Accountability: The Creator shall maintain transparency and accountability in reporting performance metrics and shall not engage in any fraudulent or deceptive practices, including but not limited to inflating metrics, generating fake engagement, or misrepresenting performance data.
        </h6>
        <h6>
        Non-Performance: If the Creator consistently fails to meet performance benchmarks or reporting requirements, Fiable may, at its discretion, terminate the agreement and cease any further collaboration with the Creator.
        </h6>
        
        <h4 className='fw-bold'>Compensation</h4>
        <h5 className='fw-bold'>Payment Terms</h5>
        <h6>
        Payment Structure: The compensation structure for the Creator’s services shall be determined based on the specific advertising campaigns and agreed upon by Fiable and the Creator. This may include flat fees, revenue share, performance-based payments, or other agreed-upon methods.
        </h6>
        <h6>
        Payment Schedule: Fiable shall provide the Creator with a payment schedule outlining the frequency and timing of payments. Payments may be made on a monthly, bi-weekly, or per-campaign basis as agreed upon by both parties.
        </h6>
        <h6>
        Payment Method: The Creator shall provide Fiable with the necessary payment details, including bank account information or preferred payment platform, to facilitate timely and accurate payments. Fiable shall make payments through the agreed-upon method, ensuring compliance with all applicable regulations.
        </h6>
        <h6>
        Invoice Submission: The Creator shall submit invoices for their services as per the agreed-upon payment schedule. Invoices should include detailed information about the services provided, including campaign details, performance metrics, and any additional agreed-upon deliverables.
        </h6>
        <h6>
        Payment Disputes: In the event of any payment disputes, Fiable and the Creator shall work together in good faith to resolve the issue promptly. The Creator shall provide any necessary documentation or clarification to support their payment claims.
        </h6>
        <h6>
        Late Payments: If Fiable fails to make payments within the agreed-upon timeframe, the Creator may notify Fiable of the delay and request immediate payment. Fiable shall make reasonable efforts to rectify any payment delays and ensure timely payments moving forward.
        </h6>
        
        <h5 className='fw-bold'>Performance-Based Compensation</h5>
        <h6>
        Performance Metrics: Performance-based compensation shall be tied to specific metrics and performance benchmarks agreed upon by Fiable and the Creator. These metrics may include click-through rates, conversions, views, engagement, or other relevant KPIs.
        </h6>
        <h6>
        Incentive Structure: Fiable shall provide the Creator with an incentive structure that outlines the performance thresholds and corresponding compensation levels. The Creator shall be eligible for additional compensation or bonuses based on their ability to meet or exceed the specified performance benchmarks.
        </h6>
        <h6>
        Regular Reviews: Fiable and the Creator shall conduct regular reviews to assess the performance of advertising campaigns and evaluate the Creator’s eligibility for performance-based compensation. These reviews shall be based on the agreed-upon metrics and benchmarks.
        </h6>
        <h6>
        Transparency and Reporting: The Creator shall provide Fiable with accurate and transparent performance reports to facilitate the evaluation of performance-based compensation. Any discrepancies or inaccuracies in reporting may impact the Creator’s eligibility for performance-based payments.
        </h6>
        
        <h5 className='fw-bold'>Revenue Share</h5>
        <h6>
        Revenue Share Model: Fiable and the Creator may agree to a revenue share model where the Creator receives a percentage of the revenue generated from the advertising campaigns. The specific percentage and terms of the revenue share shall be outlined in the agreement between Fiable and the Creator.
        </h6>
        <h6>
        Revenue Tracking: Fiable shall provide the Creator with access to revenue tracking tools and systems to monitor the revenue generated from the advertising campaigns. The Creator shall have visibility into the revenue data and be able to track their share of the earnings.
        </h6>
        <h6>
        Payment Schedule: Fiable shall provide the Creator with a payment schedule for revenue share payments, outlining the frequency and timing of disbursements. Payments may be made on a monthly, quarterly, or per-campaign basis as agreed upon by both parties.
        </h6>
        <h6>
        Revenue Reporting: Fiable shall provide the Creator with regular revenue reports detailing the revenue generated from the advertising campaigns and the Creator’s share. The reports shall include relevant metrics, performance data, and any additional information required for transparency.
        </h6>
        <h6>
        Discrepancies and Adjustments: In the event of any discrepancies or errors in revenue reporting, Fiable and the Creator shall work together in good faith to resolve the issue promptly. Any necessary adjustments to revenue share payments shall be made based on the accurate revenue data.
        </h6>
        <h6>
        Termination of Revenue Share: If either party terminates the agreement, Fiable shall continue to pay the Creator their share of the revenue generated from campaigns that were active prior to termination. The payment terms and schedule for post-termination revenue share shall be outlined in the termination clause of the agreement.
        </h6>
        
        <h4 className='fw-bold'>Termination and Renewal</h4>
        <h6>
        Termination by Fiable: Fiable may terminate this Agreement at any time with or without cause by providing written notice to the Creator. Termination shall be effective immediately upon delivery of the notice unless otherwise specified.
        </h6>
        <h6>
        Termination by Creator: The Creator may terminate this Agreement at any time with or without cause by providing written notice to Fiable. Termination shall be effective immediately upon delivery of the notice unless otherwise specified.
        </h6>
        <h6>
        Effect of Termination: Upon termination, the Creator shall cease all use of Fiable’s services and remove any advertisements, links, or badges associated with Fiable or its advertisers from their content. The Creator shall also return any confidential information or materials provided by Fiable.
        </h6>
        <h6>
        Post-Termination Payments: Fiable shall pay the Creator for any services rendered and approved expenses incurred up to the effective date of termination. Post-termination payments shall be made in accordance with the payment terms specified in this Agreement.
        </h6>
        <h6>
        Renewal: This Agreement may be renewed by mutual written consent of both parties. Renewal terms, including any changes to compensation, scope of work, or other provisions, shall be agreed upon and documented in writing.
        </h6>
        
        <h4 className='fw-bold'>Confidentiality</h4>
        <h6>
        Confidential Information: During the course of this Agreement, the Creator may receive or have access to confidential information belonging to Fiable or its advertisers. Confidential information includes but is not limited to business plans, marketing strategies, performance metrics, financial data, and any other proprietary information.
        </h6>
        <h6>
        Non-Disclosure: The Creator agrees to keep all confidential information strictly confidential and shall not disclose, share, or use such information for any purpose other than fulfilling their obligations under this Agreement. The Creator shall take all necessary precautions to protect the confidentiality of the information.
        </h6>
        <h6>
        Return of Confidential Information: Upon termination of this Agreement, the Creator shall return or destroy all confidential information provided by Fiable or its advertisers. The Creator shall certify in writing that all confidential information has been returned or destroyed.
        </h6>
        <h6>
        Survival of Confidentiality Obligations: The confidentiality obligations of the Creator shall survive the termination of this Agreement. The Creator shall continue to protect and maintain the confidentiality of all confidential information received during the course of the Agreement.
        </h6>
        
        <h4 className='fw-bold'>Indemnification</h4>
        <h6>
        Indemnification by Creator: The Creator agrees to indemnify, defend, and hold harmless Fiable, its affiliates, officers, directors, employees, and agents from and against any and all claims, liabilities, damages, losses, and expenses, including reasonable attorneys’ fees, arising out of or in connection with (a) the Creator’s breach of this Agreement, (b) the Creator’s violation of any applicable laws or regulations, (c) the Creator’s negligence or willful misconduct, or (d) any third-party claims related to the Creator’s content or services.
        </h6>
        <h6>
        Indemnification by Fiable: Fiable agrees to indemnify, defend, and hold harmless the Creator from and against any and all claims, liabilities, damages, losses, and expenses, including reasonable attorneys’ fees, arising out of or in connection with (a) Fiable’s breach of this Agreement, (b) Fiable’s violation of any applicable laws or regulations, or (c) Fiable’s negligence or willful misconduct.
        </h6>
        <h6>
        Notification of Claims: The indemnified party shall promptly notify the indemnifying party of any claims or potential claims subject to indemnification under this Agreement. The indemnifying party shall have the right to assume the defense and control of any such claims, with the indemnified party’s cooperation.
        </h6>
        
        <h4 className='fw-bold'>Limitation of Liability</h4>
        <h6>
        Liability Cap: To the maximum extent permitted by law, the total liability of Fiable to the Creator for any claims arising out of or in connection with this Agreement, whether in contract, tort, or otherwise, shall not exceed the total amount of compensation paid to the Creator under this Agreement.
        </h6>
        <h6>
        Exclusion of Certain Damages: In no event shall Fiable be liable to the Creator for any indirect, incidental, consequential, special, or punitive damages, including but not limited to lost profits, even if Fiable has been advised of the possibility of such damages.
        </h6>
        <h6>
        Basis of Bargain: The limitation of liability and exclusion of certain damages set forth in this section are fundamental elements of the basis of the bargain between Fiable and the Creator. The parties acknowledge that Fiable would not be able to provide the services described in this Agreement on an economic basis without such limitations.
        </h6>
        
        <h4 className='fw-bold'>Miscellaneous</h4>
        <h6>
        Governing Law: This Agreement shall be governed by and construed in accordance with the laws of the State of Florida, without regard to its conflict of law principles.
        </h6>
        <h6>
        Entire Agreement: This Agreement constitutes the entire agreement between Fiable and the Creator and supersedes all prior and contemporaneous understandings, agreements, representations, and warranties, whether written or oral, with respect to the subject matter hereof.
        </h6>
        <h6>
        Amendment: This Agreement may be amended or modified only by a written instrument signed by both parties.
        </h6>
        <h6>
        Waiver: No waiver of any provision of this Agreement shall be effective unless in writing and signed by the party against whom the waiver is sought to be enforced. No waiver shall be deemed a continuing waiver or a waiver of any other provision.
        </h6>
        <h6>
        Severability: If any provision of this Agreement is held to be invalid or unenforceable, the remaining provisions shall continue in full force and effect. The invalid or unenforceable provision shall be interpreted and replaced with a valid and enforceable provision that most closely approximates the intent of the original provision.
        </h6>
        <h6>
        Assignment: The Creator may not assign or transfer any rights or obligations under this Agreement without the prior written consent of Fiable. Fiable may assign or transfer its rights and obligations under this Agreement without the Creator’s consent.
        </h6>
        <h6>
        Notices: All notices, requests, consents, claims, demands, waivers, and other communications under this Agreement shall be in writing and shall be deemed to have been duly given (a) when delivered by hand, (b) when sent by a nationally recognized overnight courier, or (c) when sent by email, with confirmation of receipt.
        </h6>
        
        </div>
    )
}

export default CreatorAgreement