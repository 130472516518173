import React, { useCallback, useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { useAuth } from '../../../contexts/authContext'
import { Card, Button, Collapse, Row, Container, Image, Form, Modal} from 'react-bootstrap'
import { Check, X, TrashFill } from 'react-bootstrap-icons'
import { Timestamp } from 'firebase/firestore'
import { useTheme } from '../../../contexts/themeContext'

const BlogPage = () => {
    const { currentUser, getBlogPost, addBlogPostComment, deleteBlogPost, deleteBlogPostComment, setError } = useAuth()
    const { darkMode, getTextInv, getButtonThemeStyle, getBG } = useTheme()
    const id = useParams().id
    const navigate = useNavigate()

    const [loading, setLoading] = useState(true)
    const [data, setData] = useState()
    const [open, setOpen] = useState(false)
    const [currentComment, setCurrentComment ] = useState()
    const [comments, setComments] = useState()
    const [showConfirmDelete, setShowConfirmDelete] = useState( false )

    const addComment = (e) => {
        e.preventDefault()
        try {
            const comment = [...(new FormData(e.target)).entries()][0][1]
            const userName = currentUser.displayName ? currentUser.displayName : 'Anonymous'
            const time = Timestamp.now()
            const commentData = {text: comment, posterUID: currentUser.uid, posterName: userName, postDate: time}
            addBlogPostComment(id, commentData)
            setComments([...comments, commentData])
            document.getElementById('commentForm').reset()
        } catch (err) {
            setError('Failed to add comment')
        }
    }

    const deletePost = async () => {
        setError('')
        setShowConfirmDelete( false )
        try {
            if ( !currentComment ) {
                if (data.photoURL) await deleteBlogPost(id, data.photoURL)
                else await deleteBlogPost(id)
                
                navigate('/blog')
            }
            else {
                await deleteBlogPostComment( id, currentComment )
                setComments(comments.filter(comment => !comment.postDate.isEqual(currentComment.postDate)))
                setCurrentComment(null)
            }
        } catch (err) {
            setError('Failed to delete Post')
        }
    }

    const fetchData = useCallback(async () => {
        try {
            const blogData = await getBlogPost(id)
            setData(blogData.data())
            setComments(blogData.data().comments)
        } catch (err) {
            setError('Couldn\'t find blog post')
            navigate('/blog')
        } finally {
            setLoading(false)
        }
    }, [getBlogPost, id, setError, navigate])

    useEffect(()  => {
        fetchData()
    }, [fetchData])

    return (
        !loading &&
        <Container style={{paddingTop: '85px'}}>
            <Card className={'bg-transparent shadow py-3 px-5 border-' + (darkMode ? 'light' : 'dark') + ' ' + getTextInv()} style={{transition: 'color 0.5s, border 0.5s'}}>
                <Card.Header className={'bg-transparent border-2 d-flex justify-content-between align-items-center text-center border-' + (darkMode ? 'light' : 'dark')} style={{transition: 'border 0.5s'}}>
                    <h1 className='fw-bold'>{data.title}</h1>
                    {data.photoURL && window.innerWidth > 750 ? <Image rounded src={data.photoURL} width={200} height={200} /> : <></> }
                </Card.Header>
                <Card.Body>
                    <Card.Text>
                        {data.text}
                    </Card.Text>
                </Card.Body>
                <Card.Footer className='p-0 m-0 mt-1 mb-1 bg-transparent border-top border-dark border-2 d-flex d-inline-flex align-items-center justify-content-between'>
                    <div className='d-inline-flex m-0'>
                        { currentUser && data.posterUID === currentUser.uid &&
                            <Button onClick={() => { setShowConfirmDelete(true) || setCurrentComment(null) } } className={'btn-sm border-0 bg-transparent py-0 my-0 pe-2 ' + getTextInv()} style={{transition: 'color 0.5s'}}>
                                <TrashFill />
                            </Button>
                        }
                        <div>Posted on {data.postDate.toDate().toLocaleDateString()} by {data.posterName}</div>
                    </div>
                    <Button 
                        onClick={() => setOpen(!open)} 
                        className={'bg-transparent mt-0 pt-0 border-0 ' + getTextInv()} 
                        style={{transition: 'color 0.5s'}}
                    >
                        {!open ? 'Show' : 'Hide'} Comments
                    </Button>
                </Card.Footer>
                <Collapse in={open}>
                    <div>
                        <Form className='d-flex' onSubmit={addComment} id='commentForm'>
                            <Form.Control 
                                required
                                type='text' 
                                name='comment' 
                                id='comment'
                                autoComplete='off'
                                placeholder={ currentUser ? 'Add a Comment'  : 'Log in to Comment'}
                                className='rounded border-0 p-1'
                                disabled={!currentUser}
                            />
                            <Button type='submit' className='btn-sm border-0 bg-transparent' disabled={!loading && !currentUser}>
                                <Check className='bs-icon bs-icon-md my-text-primary'/>
                            </Button>
                            <Button type='reset' className='btn-sm border-0 bg-transparent' disabled={!loading && !currentUser}>
                                <X className='bs-icon bs-icon-md my-text-primary'/>
                            </Button>

                        </Form>
                        { !loading && comments.length ?
                            comments.map((comment, index) => (
                                <Row key={index} className='m-1 p-2'>
                                    <h5 
                                        className={'fw-bold border-top border-' + (!darkMode ? 'dark' : 'white') + ' ' + getTextInv()} 
                                        style={{transition: 'color 0.5s, border 0.5s'}}
                                    >
                                        {comment.posterName}
                                    </h5>
                                    <p className={getTextInv()} style={{transition: 'color 0.5s'}}>
                                        {comment.text}
                                    </p>
                                    <div className={'d-inline-flex border-bottom border-' + (!darkMode ? 'dark' : 'white') + ' ' + getTextInv()} style={{transition: 'color 0.5s, border 0.5s'}}>
                                        { currentUser && comment.posterUID === currentUser.uid &&
                                            <Button onClick={() => {setShowConfirmDelete(true) || setCurrentComment(comment)} } className={'btn-sm border-0 bg-transparent py-0 my-0 pe-2 ' + getTextInv()} style={{transition: 'color 0.5s'}}>
                                                <TrashFill />
                                            </Button>
                                        }
                                        <small>
                                            Posted on {comment.postDate.toDate().toLocaleDateString()}
                                        </small>
                                    </div>
                                </Row>
                            ))
                        :
                            <h3 className='fw-bold py-2 text-center'>No Comments Yet...</h3>
                        }
                    </div>
                </Collapse>
                <Modal size='md' show={showConfirmDelete} onHide={() => {setShowConfirmDelete(false) || setCurrentComment(null)}}>
                    <Modal.Header closeButton className={getBG() + ' ' + getTextInv()}>
                        Are you sure you want to delete?
                    </Modal.Header>
                    <Modal.Body className={getBG() + ' rounded-bottom d-flex justify-content-between'}>
                        <Button className={getButtonThemeStyle('sm', 'danger')} onClick={deletePost}>Yes, delete</Button>
                        <Button className={getButtonThemeStyle('sm', 'success')} onClick={() => {setShowConfirmDelete(false) || setCurrentComment(null)}}>No, nevermind</Button>
                    </Modal.Body>
                </Modal>
            </Card>
        </Container>
    )
}

export default BlogPage