import React from 'react'
import { Card } from 'react-bootstrap'
import { useTheme } from '../../../../contexts/themeContext'
import { useAuth } from '../../../../contexts/authContext'

import { medals } from './medals/medals'
import TierDisplay from './tierDisplay'

const MedalsDisplay = () => {
    const { userData } = useAuth()
    const { getCardStyle } = useTheme()

    return (
        <Card className={'p-3 ' + getCardStyle()}>
            <Card.Header className='d-flex flex-column text-center p-0'>
                <h1 className='fw-bold mb-0'>Medal Progress</h1>
                <h5 className='fst-italic'>Track Your Progression With Fiable!</h5>
            </Card.Header>
            <Card.Body className='pt-0'>
                {Object.values(medals).reduce((tiers, curr) => tiers.includes(curr.tier) ? tiers : (!tiers.push(curr.tier) || tiers), []).map(tier => (
                    <TierDisplay key={tier} tier={tier} userTier={userData.social ? userData.social.tier : 1} />
                ))}
            </Card.Body>
        </Card>
    )
}

export default MedalsDisplay