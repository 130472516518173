import React from 'react'
import { Container, Row, Form } from 'react-bootstrap'

const ReadSection = ({ formData, setFormData }) => {
    return (
        <Container fluid className='px-5'>
            <Row>
                <Form.Label className='ps-3 mb-0'>Ad Read Text</Form.Label>
                <Form.Control
                    name='readText'
                    as='textarea'
                    rows={3}
                    placeholder='10 seconds to 2 minutes of talking (approx. 20-250 words)'
                    value={formData.readText || ''} className='form-control-md'
                    onChange={(e) => setFormData(prev => ({...prev, readText: e.target.value}))}
                />
            </Row>
        </Container>
    )
}

export default ReadSection