import React, { useState, useEffect } from 'react'
import { Card, Col, Button, Modal, Row, Form } from 'react-bootstrap'
import { AwardFill, BookmarkPlusFill, Cart4, ChatLeftTextFill, PaletteFill } from 'react-bootstrap-icons'
import { useTheme } from '../../../../contexts/themeContext'
import { useAuth } from '../../../../contexts/authContext'
import { medals } from './medals/medals'
import MedalModal from './medals/medalModal'

const shopItems = [
    {
        name: 'Sub-Category Slot',
        description: 'Add an extra Sub-Category slot to your account',
        icon: <BookmarkPlusFill className='my-text-danger-dark' />,
        amount: 1,
        id: 1,
    },
    { // unimplemented
        name: 'Medal Reveal',
        description: 'Reveal a random medal requirement',
        icon: <AwardFill className='my-text-info-dark' />,
        amount: 3,
        id: 2,
    },
    {
        name: 'Social Page Change',
        description: 'Set the Public Background Color of Your Social Page',
        icon: <PaletteFill className='my-text-danger-light' />,
        amount: 10,
        id: 3,
    },
    { // unimplemented
        name: 'Video Transcript',
        description: 'Upload any video and receive a timestamped transcript',
        icon: <ChatLeftTextFill className='my-text-success-dark' />,
        amount: 20,
        id: 4,
    },
]

const TokenShop = () => {
    const {getCardStyle} = useTheme()

    return (
        <Card className={getCardStyle('primary')} style={{transition: 'all 0.5s'}}>
            <Card.Header className='d-inline-flex me-4'>
                <Col className='fs-5'>Token Shop</Col>
                <Col className='text-end'><Cart4 className='position-absolute fs-3'/></Col>
            </Card.Header>
            <Card.Body className='mx-0 d-flex justify-content-between align-items-center'>
                {shopItems.map((item, index) => (
                    <ShopItem shopItem={item} key={index} />
                ))}
            </Card.Body>
            <Card.Footer className='pt-2 text-center fs-3 fw-bold'>
                More Shop Items Coming Soon!
            </Card.Footer>
        </Card>
    )
}

const ShopItem = ({shopItem}) => {
    const {userData, updateUserData, setSocialData, setSuccess, setError, setWarning } = useAuth()
    const {getCardStyle, getButtonStyle, getButtonThemeStyle, getBG, getTextInv} = useTheme()

    const [itemData, setItemData] = useState(shopItem)
    const [showPurchaseConfirm, setShowPurchaseConfirm] = useState(false)
    const [isPurchasing, setIsPurchasing] = useState(false)

    const [showColorSelect, setShowColorSelect] = useState(false)
    const [medalRevealId, setMedalRevealId] = useState(false)

    const purchaseSCSlot = async () => {
        if (userData.subBucketSlots >= 50) {
            setWarning("Maximum of 50 slots")
        }

        try {
            await updateUserData({subBucketSlots: (userData.subBucketSlots || 10) + 1})
            await setSocialData({tokens: userData.social.tokens - shopItems.filter(item => item.id === 1)[0].amount})
            setSuccess("Added One Slot!")
        } catch (err) {
            setError("Failed to add Sub-Category Slot")
        }
    }

    const purchaseMedalReveal = async () => {
        const remainingMedals = Object.keys(medals).filter(id => !userData.social.medals[id] || !userData.social.medals[id].dateAchieved)
        if (remainingMedals.length === 0) {
            setWarning("Already earned all medals!")
            return
        }

        try {
            await setSocialData({tokens: userData.social.tokens - shopItems.filter(item => item.id === 2)[0].amount})
            setMedalRevealId(remainingMedals[Math.floor(Math.random() * remainingMedals.length)])
        } catch (err) {
            setError("Unable to reveal a medal")
        }
    }

    const purchaseBGChange = async () => {
        setShowColorSelect(true)
    }

    const purchaseItem = async () => {
        // not ready for video transcript
        if (itemData.id === 4) {
            setWarning('Currently unavailable for purchase (try again soon!)')
            return
        }

        setIsPurchasing(true)

        if (itemData.id === 1) await purchaseSCSlot()
        else if (itemData.id === 2) await purchaseMedalReveal()
        else if (itemData.id === 3) purchaseBGChange()
        
        setIsPurchasing(false)
    }

    useEffect(() => {
        let currentValue = ''
        if (shopItem.id === 1) currentValue = 'You Currently Have ' + (userData.subBucketSlots || 10) + ' Slots'
        else if (shopItem.id === 2) currentValue = 'Revealed Upon Purchase'
        else if (shopItem.id === 3) currentValue = (userData.social.bgColor ? 'Current Color is ' + userData.social.bgColor : 'Default Color is White')
        else if (shopItem.id === 4) currentValue = 'Transcript Created for Next Ad'
        else currentValue = 'No Additional Information'

        if (currentValue.length)
            setItemData(prev => ({...prev, currentValue: currentValue}))
    }, [shopItem, setItemData, userData.social.bgColor, userData.subBucketSlots])

    return (
        <Card className={getCardStyle()} style={{width: '250px', height: '200px', transition: 'all 0.5s'}}>
            <Card.Header className='text-center fs-4 fw-bold p-0'>{itemData.name}</Card.Header>
            <Card.Body className='p-0 d-flex flex-column align-items-center'>
                <div className='position-relative' style={{fontSize: '36px', top: '-8px'}}>{itemData.icon}</div>
                <h6 className='text-center mx-1 p-0 fw-bold'>{itemData.description}</h6>
                <h6 className='m-0 mb-1 fst-italic'>{itemData.currentValue}</h6>
                <Button className={'m-0 py-1 w-100 ' + getButtonStyle()} onClick={() => setShowPurchaseConfirm(true)} disabled={isPurchasing || userData.social.tokens < itemData.amount}>
                    Purchase ({itemData.amount} token{itemData.amount === 1 ? '' : 's'})
                </Button>
            </Card.Body>
            <Modal show={showPurchaseConfirm} onHide={() => setShowPurchaseConfirm(false)}>
                <Modal.Header closeButton className={getBG() + ' ' + getTextInv()}>Confirm Purchase</Modal.Header>
                <Modal.Body className={'d-flex flex-column text-center rounded-bottom ' + getBG() + ' ' + getTextInv()}>
                    <h3>Are you sure you want to purchase a {itemData.name}?</h3>
                    <h5>- This cannot be undone</h5>
                    <h5>- This costs {itemData.amount} tokens</h5>
                    <Row className='d-inline-flex'>
                        <Col className='text-start'><Button className={getButtonThemeStyle('md', 'danger')} onClick={() => setShowPurchaseConfirm(false)}>No, I'm not ready</Button></Col>
                        <Col className='text-end'><Button className={getButtonThemeStyle('md', 'success')} onClick={() => purchaseItem().then(() => setShowPurchaseConfirm(false))}>Yes, Let's go!</Button></Col>
                    </Row>
                </Modal.Body>
            </Modal>
            <ColorSelectModal show={showColorSelect} setShow={setShowColorSelect} />
            <MedalModal show={!!medalRevealId} setShow={() => setMedalRevealId} onClose={() => setMedalRevealId(null)} medalId={medalRevealId} medalReveal />
        </Card>
    )
}

const ColorSelectModal = ({show, setShow}) => {
    const {userData, setSocialData, setError, setSuccess} = useAuth()
    const {getBG, getTextInv} = useTheme()

    const [selectedColor, setSelectedColor] = useState('')
    const [loading, setLoading] = useState(false)

    const updateBGColor = async () => {
        setLoading(true)
        try {
            await setSocialData({bgColor: selectedColor, tokens: userData.social.tokens - shopItems.filter(item => item.id === 3)[0].amount})
            setSuccess('Background Color Updated! Go View it in Profile')
        } catch (err) {
            setError('Failed to Update Color')
        } finally {
            setLoading(false)
            setShow(false)
        }
    }

    return (
        <Modal show={show} onHide={() => setShow(false)}>
            <Modal.Header closeButton className={getBG() + ' ' + getTextInv()}>Select Your New Background Color!</Modal.Header>
            <Modal.Body className={'d-flex flex-column align-items-center justify-content-center text-center rounded-bottom ' + getBG() + ' ' + getTextInv()}>
                <h4>Select a Color from the picker below, then confirm it.</h4>
                <Form.Control
                    type="color"
                    onChange={(e) => setSelectedColor(e.target.value)}
                    title="Choose your color"
                    className='m-5'
                    style={{transform: 'scale(200%)'}}
                />
                <Row className='d-inline-flex'>
                    <Col className='text-end'><Button className='border-0 my-bg-success' onClick={updateBGColor} disabled={!selectedColor.length || loading}>Confirm Color</Button></Col>
                </Row>
            </Modal.Body>
        </Modal>
    )
}

export default TokenShop