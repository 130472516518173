import { useState, useEffect, useRef } from 'react'

const useIntersectionObserver = (options) => {
  const [isIntersecting, setIsIntersecting] = useState(false)
  const ref = useRef(null)

  useEffect(() => {
    const observer = new IntersectionObserver(([entry]) => {
      setIsIntersecting(entry.isIntersecting)
    }, options)

    if (ref.current) {
      observer.observe(ref.current)
    }

    return (() => observer.disconnect())
  }, [ref, isIntersecting, options])

  return [ref, isIntersecting]
};

export default useIntersectionObserver
