import React, { useCallback, useEffect, useState } from 'react'
import { Card, Badge, Row, Col, Button, Collapse, Form, Spinner } from 'react-bootstrap'
import { useAuth } from '../../../../contexts/authContext'
import { useTheme } from '../../../../contexts/themeContext'
import { BagHeartFill, BookFill, Brilliance, CameraReelsFill, CashStack, Controller, CpuFill, Dribbble, EggFried, EmojiLaughing, Gitlab, Hammer, HouseFill, LuggageFill, Magic, MusicNoteBeamed, PaletteFill, PeopleFill, PersonWalking, TvFill } from 'react-bootstrap-icons'

const ChannelBucketsCard = () => {
    const { currentUser, userData, getBuckets, updateBuckets, setError, setSuccess, setSocialData, earnMedal } = useAuth()
    const { getThemeBG, getTextInv, getBG, getButtonStyle, isSmallScreen, getCardStyle} = useTheme()

    const [ bucketData, setBucketData ] = useState([])
    const [ userBuckets, setUserBuckets ] = useState([])
    const [ userSubBuckets, setUserSubBuckets ] = useState([])
    const [ loading, setLoading ] = useState( true )

    const [ showEditCategories, setShowEditCategories ] = useState( false )
    const [ bucketCheckStates, setBucketCheckStates ] = useState({})

    const handleBucketCheckChange = (e) => {
        const { name, checked } = e.target
        const plainName = name.slice(name.lastIndexOf('_') + 1, name.length)
        setBucketCheckStates(prevStates => ({...prevStates, [plainName]: checked}))
    }

    const submitChanges = async (e) => {
        setError('')
        setSuccess('')
        setLoading(true)

        const numBuckets = Object.entries(bucketCheckStates).reduce((sum, state) => state[1] && state[0].split('/').length === 1 ? sum + 1 : sum, 0)
        const numSubBuckets = Object.entries(bucketCheckStates).reduce((sum, state) => state[1] && state[0].split('/').length === 2 ? sum + 1 : sum, 0)

        if (numBuckets > 10 || numSubBuckets > 50) {
            setError("Maximum of 10 categories and 50 sub categories")
            setLoading(false)
            return
        }
        if (numSubBuckets > (userData.subBucketSlots || 10)) {
            setError("Not enough sub-category slots. Buy more in the token shop!")
            setLoading(false)
            return
        }

        try {
            await updateBuckets(bucketCheckStates)
            await fetchBucketData()
            await setSocialData({buckets: userBuckets || [], subBuckets: userSubBuckets || []})
            setSuccess( 'Categories successfully updated!')
            const value = userBuckets.length + userSubBuckets.length
            const variant = value < 10 ? 1 : (value < 25 ? 2 : 3)
            earnMedal(5, undefined, {value: value, variant: variant})
        } catch (err) {
            setError('Failed to Update Categories')
        } finally {
            setShowEditCategories(false)
            setLoading(false)
        }
    }

    const fetchBucketData = useCallback( async () => {
        const data = await getBuckets()
        let bucketDataArr = []
        data.forEach((doc) => {
            let docData = doc.data()
            docData['id'] = doc.id
            bucketDataArr.push(docData)
        });
        setBucketData( bucketDataArr )
        setUserBuckets(bucketDataArr.filter( bucket => bucket.users.includes(currentUser.uid)).map( bucket => bucket.id ).sort((a, b) => a.localeCompare(b)))
        setUserSubBuckets(bucketDataArr.flatMap( bucket => Object.keys(bucket.subBuckets).flatMap( sub => bucket.subBuckets[sub].includes(currentUser.uid) ? [sub] : [])).sort((a, b) => a.localeCompare(b)))
        setLoading( false )
    }, [getBuckets, currentUser.uid])

    useEffect(() => {
        fetchBucketData()
    }, [fetchBucketData])

    return (
        <Card className={getCardStyle()}>
            <Card.Header className='py-2 fw-bold'>
                Content Categories
            </Card.Header>
            <Card.Body>
                <Row className='pb-2'>
                    <h4 className='pb-2'>Primary Categories: </h4>
                    <div className={(!isSmallScreen ? 'd-inline-block text-center' : 'd-flex flex-column')}>
                        {loading ? 
                            <Spinner />
                        :
                            userBuckets.length ? <>
                                { userBuckets.map( name => (
                                    <BucketBadge name={name} showIcon className='mx-3 my-1 fs-5 my-bg-success' key={name}/>
                                ))}</>
                            :
                                <h3>No categories...yet. Click Edit Categories to add your Content Categories!</h3>
                            }
                    </div>
                </Row>
                <Row className='pb-2'>
                    <h5 className='pb-2'>Additional Categories: </h5>
                    <div className={(!isSmallScreen ? 'd-inline-block text-center' : 'd-flex flex-column')}>
                        { loading ? 
                            <Spinner />
                        :
                            userSubBuckets.length ? <>
                                {userSubBuckets.map( name => (
                                    <BucketBadge name={name} className='my-1 mx-2 fs-6 my-bg-warning' key={name}/>
                                ))}</>
                            :
                            <h4>Select some additional categories from the sub-categories in the checkbox list below!</h4>
                        }
                    </div>
                </Row>
            </Card.Body>
            <Card.Footer className='bg-transparent text-center p-0'>
                <Collapse in={!loading && showEditCategories} className='mb-2'>
                    <div>
                        {bucketData.map( (_, index) => (
                            index % 5 === 0 && (
                                <Row key={index} className='ps-0 '>
                                    { bucketData.slice( index, index + 5).map( (bucket, index) => (
                                        <Col key={'sub_col_' + index}>
                                            <Card className={'mb-2 pe-3 ' + getBG()} style={{transition: 'background 0.5s'}}>
                                                <Card.Header className='m-0 p-0 ps-3 ms-2 mb-1'>
                                                    <Row className='ps-0 px-4'>
                                                        <BucketBadge 
                                                            showIcon
                                                            name={bucket.id} 
                                                            className='mx-2 my-1 fs-5 my-bg-success'
                                                            check={
                                                                <Form.Check
                                                                    name={'check_' + bucket.id}
                                                                    type='checkbox'
                                                                    defaultChecked={userBuckets.includes(bucket.id)}
                                                                    className='ms-2'
                                                                    style={{marginTop: '-3px'}}
                                                                    onChange={handleBucketCheckChange}
                                                                />
                                                            }
                                                        />
                                                    </Row>
                                                </Card.Header>
                                                <Card.Body className='m-0 p-0 ms-2 ps-1 mb-1'>
                                                    { Object.keys(bucket.subBuckets).sort((a, b) => a.localeCompare(b)).map( (subName, index) => (
                                                        <Row className='me-0 pe-0' key={'sub_row_' + index}>
                                                            <BucketBadge 
                                                                name={subName}
                                                                className='mx-2 mt-1 my-bg-warning fs-6'
                                                                check={
                                                                    <Form.Check
                                                                        name={'check_' + bucket.id + '/' + subName}
                                                                        type='checkbox'
                                                                        defaultChecked={userSubBuckets.includes(subName)}
                                                                        className='ms-2'
                                                                        style={{marginTop: '-3px'}}
                                                                        onChange={handleBucketCheckChange}
                                                                    />
                                                                }
                                                            />
                                                        </Row>
                                                    ))}
                                                </Card.Body>
                                            </Card>
                                        </Col>
                                    ))}
                                </Row>
                            )
                        ))}
                        <Row className='d-flex justify-content-center p-3'>
                            <Button
                                className={'w-25 btn-sm fw-bold ' + getThemeBG('info') + ' ' + getTextInv()}
                                style={{ transition: 'background 0.5s, color 0.5s'}}
                                onClick={submitChanges}
                            >
                                Submit Changes
                            </Button>
                        </Row>
                        <p>NOTE: New Categories are being added frequently. Check back here often to keep your content categories up to date!</p>
                    </div>
                </Collapse>
                <Button
                    className={'mb-3 ' + getButtonStyle()}
                    style={{transition: 'background 0.5s, color 0.5s', display: isSmallScreen ? 'none' : ''}}
                    onClick={() => setShowEditCategories(!showEditCategories)}
                    disabled={loading}
                >
                    {(showEditCategories ? 'Hide' : 'Edit') + ' Categories'}
                </Button>
            </Card.Footer>
        </Card>
    )
}

export const BucketBadge = (props) => {
    const bucketIconMap = {
        Entertainment: <CameraReelsFill />,
        Education: <BookFill />,
        Lifestyle: <PersonWalking />,
        Gaming: <Controller />,
        Music: <MusicNoteBeamed />,
        Technology: <CpuFill />,
        Comedy: <EmojiLaughing />,
        Beauty: <BagHeartFill />,
        Sports: <Dribbble />,
        News: <TvFill />,
        Futuristic: <Magic />,
        Food: <EggFried />,
        Travel: <LuggageFill />, 
        Wellness: <Brilliance />, 
        Crafts: <Hammer />,
        Business: <CashStack />,
        Home: <HouseFill />,
        Art: <PaletteFill />, 
        Family: <PeopleFill />, 
        Animals: <Gitlab />
    }

    return (
        <Badge className={'d-flex d-inline-flex justify-content-between my-text-dark ' + props.className} style={{transition: 'color 0.5s, background 0.5s'}}>
            {props.showIcon && bucketIconMap[props.name]}
            <div className={props.showIcon ? 'ps-2' : ''}>{props.name}</div>
            {props.check}
        </Badge>
    )
}

export default ChannelBucketsCard;