import React from 'react'
import { Container } from 'react-bootstrap'
import ContactUsCard from './subcomponents/contactUsCard';
import PageHeader from '../../common/pageHeader';
import BookDemo from '../../common/bookDemo';
import { useTheme } from '../../../contexts/themeContext';

const ContactUs = () => {
    const {darkMode} = useTheme()
    return (
        <section className='py-5' style={{minHeight: '100vh'}}>
            <PageHeader>
                <h1 className={'fw-bold arvo-bold ' + (darkMode ? 'my-text-primary-light' : 'my-text-dark')} style={{fontSize: '64px', transition: 'all 0.5s'}}>Contact us</h1>
                <h3 className={'fw-bold arvo-bold ' + (darkMode ? 'my-text-primary-light' : 'my-text-dark')} style={{transition: 'all 0.5s'}}>Reach out to us here</h3>
            </PageHeader>
            <Container className='pt-2 pb-4'>
                <ContactUsCard />
                <BookDemo />
            </Container>
        </section>
    )
}

export default ContactUs;