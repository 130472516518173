import { React } from 'react'

import { useAuth } from '../../contexts/authContext'
import { Alert } from 'react-bootstrap'

export default function WarningMessage() {
    const { warning, setWarning } = useAuth()

    return (
        warning && (
            <section className='py-2 mx-3 position-fixed w-75 text-center top-0' style={{transition: 'all 0.5s ease-in-out', zIndex: 5000}}>
                <Alert variant='warning' className='mb-0' dismissible onClose={() => setWarning('')} >
                    Warning: {warning}
                </Alert>
            </section>
        )
    )
}