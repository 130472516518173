import React from 'react'
import { Image } from 'react-bootstrap'
import logo from '../../assets/img/favicon.ico'
import { Link } from 'react-router-dom'
import { useTheme } from '../../contexts/themeContext'

const Brand = (props) => {
    const { getTextInv } = useTheme()
    return (
        <Link to='/' className='d-inline-flex text-decoration-none' style={{'marginTop': '6px'}}>
            <Image src={logo} height={25} width={25} />&nbsp;
            <h5 className={'arvo-bold-italic ' + (props.toggleColor ? getTextInv() : 'my-text-dark')} style={{transition: 'color 0.5s'}}>Fiable</h5>
        </Link>
    )
}

export default Brand