import { React } from 'react'

import { useAuth } from '../../contexts/authContext'
import { Alert } from 'react-bootstrap'

export default function SuccessMessage() {
    const { success, setSuccess } = useAuth()

    return (
        success && (
            <section className='py-2 mx-3 position-fixed text-center top-0' style={{transition: 'all 0.5s ease-in-out', zIndex: 5000, width: 'fit-content'}}>
                <Alert variant='success' className='mb-0 ' dismissible onClose={() => setSuccess('')}>
                    Success: {success}
                </Alert>
            </section>
        )
    )
}