import React, { useState } from 'react'
import { Badge, Button, Card, Col, Container, Form, Modal, Row } from 'react-bootstrap'
import dayjs from 'dayjs'
import Weekday from 'dayjs/plugin/weekday'
import WeekOfYear from 'dayjs/plugin/weekOfYear'
import { useTheme } from '../../../contexts/themeContext'

import './calendarStyles.css'
import { CheckCircleFill, PencilSquare, PlusCircleFill, TrashFill } from 'react-bootstrap-icons'
import { Link } from 'react-router-dom'
import { useAuth } from '../../../contexts/authContext'
import AdSummary from '../advertisements/subcomponents/adSummary'

dayjs.extend(Weekday)
dayjs.extend(WeekOfYear)

const AdCalendar = () => {
    const {userData} = useAuth()
    const {getTextInv} = useTheme()

    const [yearAndMonth, setYearAndMonth] = useState([new Date().getFullYear(), new Date().getMonth() + 1])


    return (
        <Container fluid className='p-2'>
            <Card className={'bg-transparent ' + getTextInv()} style={{transition: 'color 0.5s'}}>
                <Card.Body className='m-0 p-0'>
                    <Calendar yearAndMonth={yearAndMonth} setYearAndMonth={setYearAndMonth} renderDay={day => <CalDate day={day} events={userData.events ? userData.events[day.dateString] : []} />} />
                </Card.Body>
            </Card>
        </Container>
    )
}

const Calendar = (props) => {
    const {getButtonStyle} = useTheme()

    const [year, month] = props.yearAndMonth

    let currentMonthDays = createDaysForCurrentMonth(year, month)
    let pastMonthDays = createDaysForPastMonth(year, month, currentMonthDays)
    let nextMonthDays = createDaysForNextMonth(year, month, currentMonthDays)

    let calendarDays = [...pastMonthDays, ...currentMonthDays, ...nextMonthDays]

    const goToPrevMonth = () => {
        let nextYear = year
        let nextMonth = month - 1
        if ( nextMonth === 0 ) {
            nextMonth = 12
            nextYear = year - 1
        }
        props.setYearAndMonth([nextYear, nextMonth])
    }

    const goToNextMonth = () => {
        let nextYear = year
        let nextMonth = month + 1
        if ( nextMonth === 13 ) {
            nextMonth = 1
            nextYear = year + 1
        }
        props.setYearAndMonth([nextYear, nextMonth])
    }

    const goToCurrMonth = () => {
        props.setYearAndMonth([new Date().getFullYear(), new Date().getMonth() + 1])
    }

    const onMonthSelect = (e) => {
        let nextMonth = parseInt(e.target.value, 10)
        props.setYearAndMonth([year, nextMonth])
    }

    const onYearSelect = (e) => {
        let nextYear = parseInt(e.target.value, 10)
        props.setYearAndMonth([nextYear, month])
    }

    return (
        <Container fluid>
            <Row className='d-flex flex-row'>
                <Col>
                    <Form.Select value={month} onChange={onMonthSelect} className='border-2'>
                        {getMonthDropdownOptions().map(({label, value}) => <option value={value} key={value}>{label}</option>)}
                    </Form.Select>
                </Col>
                <Col>
                    <Form.Select value={year} onChange={onYearSelect} className='border-2'>
                        {getYearDropdownOptions().map(({label, value}) => <option value={value} key={value}>{label}</option>)}
                    </Form.Select>
                </Col>
                <Col className='d-flex align-items-center justify-content-between'>
                    <Button className={'rounded-1 fs-6 ' + getButtonStyle('xsm')} onClick={goToPrevMonth}>{'<'} Prev&nbsp;</Button>
                    <Button className={'rounded-1 fs-6 ' + getButtonStyle('xsm')} disabled={month === new Date().getMonth() + 1 && year === new Date().getFullYear()} onClick={goToCurrMonth}>Go to Current Month</Button>
                    <Button className={'rounded-1 fs-6 ' + getButtonStyle('xsm')} onClick={goToNextMonth}>&nbsp;Next {'>'}</Button>
                </Col>
            </Row>
            <Row>
                {daysOfWeek.map(day => (
                    <Col key={day} className='text-center'>
                        {day}
                    </Col>
                ))}
            </Row>
            <div className='days-grid'>
                {calendarDays.map(day => (
                    <Col key={day.dateString}>
                        {props.renderDay(day)}
                    </Col>
                ))}
            </div>
        </Container>
    )
}



const CalDate = (props) => {
    const [hovered, setHovered] = useState(false)
    const [showModal, setShowModal] = useState(false)

    const dateObj = new Date(props.day.dateString.split('-'))
    const isPast = Date.parse(props.day.dateString + 'T23:59:59') < new Date()

    return (
        <Card 
            className={'rounded-0 my-text-dark ' + (isPast ? 'my-bg-light' : (hovered ? 'my-bg-light' : 'my-bg-white'))} 
            style={{transition: 'background 0.2s', height: '190px'}}
            onMouseEnter={() => !isPast && setHovered(true)}
            onMouseLeave={() => !isPast && setHovered(false)}
        >
            <Card.Header className='mb-0 pb-0'>
                <h5 className={'text-end fw-bold ' + (props.day.isCurrentMonth ? 'my-text-dark' : 'my-text-light')}>
                    {props.day.dayOfMonth}
                </h5>
            </Card.Header>
            <Card.Body className='my-0 py-0'>
                {props.events ? props.events.sort((a,b) => a.eventTime.localeCompare(b.eventTime)).map((event, index) => (<CalEvent event={event} setHovered={setHovered} date={props.day.dateString} key={index} />)) : <></>}
                <div className='mt-1 justify-content-center' style={{display: (hovered ? 'flex' : 'none')}}>
                    <Button className='bg-transparent border-0 m-0 p-0' style={{position: 'absolute'}} onClick={() => setHovered(false) || setShowModal(true)}>
                        <PlusCircleFill className='fs-1 my-text-primary' style={{fontWeight: 'bolder'}}/>
                    </Button>
                </div>
            </Card.Body>
            <CalDateModal show={showModal} setShow={setShowModal} date={dateObj} />
        </Card>
    )
}

const CalEvent = (props) => {
    const {userData, deleteCalendarEvent, updateCalendarEvent, setError} = useAuth()
    const {getBG, getTextInv, getButtonStyle} = useTheme()

    const [showModal, setShowModal] = useState(false)

    const [isEditing, setIsEditing] = useState(false)
    const [name, setName] = useState(props.event.eventName)
    const [desc, setDesc] = useState(props.event.eventDesc)

    const typeBGs = ['my-bg-success', 'my-bg-danger', 'my-bg-primary']
    const localTime = () => {
        let timeList = props.event.eventTime.split(':')
        if ( Number(timeList[0]) % 12 !== 0 ) {
            timeList[0] = String(Number(timeList[0]) % 12)
        } else {
            timeList[0] = '12'
        }
        return timeList.join(':')
    }

    const badgeString = (props.event.eventType === 2 ? localTime() + ': ' : '') + props.event.eventName + (props.event.eventType === 0 ? ' - start' : (props.event.eventType === 1 ? ' - end' : ''))

    const deleteEvent = async () => {
        setError('')
        
        try {
            await deleteCalendarEvent(props.date, props.event)
        } catch (err) {
            setError('Failed to delete event')
        } finally {
            setShowModal(false)
        }
    }

    const updateEvent = async () => {
        setError('')

        if (userData.events[props.date].map(event => event.eventName).includes(name) && name !== props.event.eventName) {
            setError('Duplicate event names on the same day are not allowed')
            setShowModal(false)
            return
        }

        try {
            await updateCalendarEvent(props.date, props.event.eventName, {...props.event, eventName: name, eventDesc: desc})
        } catch (err) {
            setError('Failed to update event')
        } finally {
            setIsEditing(false)
            setShowModal(false)
        }
    }

    return (
        <Row className='text-center my-1'>
            <Button className='w-100 bg-transparent border-0 m-0 p-0' onClick={() => props.setHovered(false) || setShowModal(true)}>
                <Badge className={'w-100 fs-6 my-text-dark text-wrap ' + typeBGs[props.event.eventType]}>
                    {badgeString}
                </Badge>
            </Button>
            <Modal size={props.event.eventType === 2 ? 'md' : 'xl'} show={showModal} onHide={() => setShowModal(false)} className={getTextInv()}>
                <Modal.Header className={getBG()} closeButton>
                    <Modal.Title>
                        {props.event.eventID ? 'Advertisement' : 'Event'} on {new Date(Date.parse(props.date + 'T00:00:00')).toDateString()}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body className={'rounded-bottom ' + getBG()}>
                    {props.event.eventType === 2 ? 
                        <Form>
                            <Row><h2 className='fw-bold'>Event Title:</h2></Row>
                            <Row>
                                {isEditing ? 
                                    <Form.Control required={isEditing} type='text' value={name} onChange={e => setName(e.target.value)} />
                                :
                                    <h2 className='text-center'>{props.event.eventName}</h2>
                                }
                            </Row>
                            <Row className='pt-3'><h4 className='fw-bold'>Event Description:</h4></Row>
                            <Row>
                                {isEditing ? 
                                    <Form.Control required={isEditing} as='textarea' rows={4} value={desc} onChange={e => setDesc(e.target.value)} />
                                : 
                                    <h4 className='text-center'>{props.event.eventDesc}</h4>
                                }
                            </Row>
                            <Row className='pt-3 d-flex flex-row justify-content-between m-2 px-4'>
                                <Col className='text-start'><Button className={getButtonStyle('md')} onClick={deleteEvent}><TrashFill /></Button></Col>
                                <Col className='text-end'><Button className={getButtonStyle('md')} onClick={() => isEditing ? updateEvent() : setIsEditing(true)}>{isEditing ? <CheckCircleFill /> : <PencilSquare />}</Button></Col>
                            </Row>
                        </Form>
                    :
                        <AdSummary data={userData.history[props.event.eventID]} />
                    }
                </Modal.Body>
            </Modal>
        </Row>
    )
}

const CalDateModal = (props) => {
    const {userData, addCalendarEvent, setError} = useAuth()
    const { getBG, getTextInv, getButtonStyle } = useTheme()

    const [calEventType, setCalEventType] = useState('0')
    const dateString = `${props.date.getFullYear()}-${String(props.date.getMonth() + 1).padStart(2, '0')}-${String(props.date.getDate()).padStart(2, '0')}`

    const addEvent = async (e) => {
        e.preventDefault()
        setError('')

        const data = [...new FormData(e.target).entries()]
        const eventData = {eventType: Number(data[0][1]), eventName: data[1][1], eventTime: data[2][1], eventDesc: data[3][1]}

        if (userData.events[dateString] && userData.events[dateString].map(event => event.eventName).includes(eventData.eventName)) {
            setError('Duplicate event names on the same day are not allowed')
            props.setShow(false)
            return
        }

        try {
            await addCalendarEvent(dateString, eventData)
        } catch (err) {
            setError('Failed to add event.')
        } finally {
            document.getElementById(dateString + '-event-form').reset()
            props.setShow(false)
        }
    }

    return (
        <Modal show={props.show} onHide={() => props.setShow(false)} className={getTextInv()}>
            <Modal.Header closeButton className={getBG()}>
                <Modal.Title>
                    Add event on {props.date.toDateString()}
                </Modal.Title>
            </Modal.Header>
            <Modal.Body className={'rounded-bottom text-center pb-0 ' + getBG()}>
                <Form className='m-2 mt-0' onSubmit={addEvent} id={dateString + '-event-form'}>
                    <Form.Group className='text-start'>
                        <Form.Label className='ps-3'>Select an Event Type</Form.Label>
                        <Form.Select name='eventType' value={calEventType} onChange={(e) => setCalEventType(e.target.value)}>
                            <option value={'0'}>Scheduled Advertisement</option>
                            <option value={'2'}>Miscellaneous Event</option>
                        </Form.Select>
                    </Form.Group>
                    {calEventType === '0' &&
                        <Link to={'/create-ad/date=' + dateString} role='button' className='m-3 btn btn-md my-bg-primary'>Book Ad on This Date</Link>
                    }
                    {calEventType === '2' &&
                        <>
                            <Form.Group className='text-start pt-3'>
                                <Row>
                                    <Col>
                                        <Form.Label>Event Name</Form.Label>
                                        <Form.Control required={calEventType === 2} type='text' name='eventName' placeholder='Name of your event' />
                                    </Col>
                                    <Col>
                                        <Form.Label>Event Time</Form.Label>
                                        <Form.Control 
                                            type='time' 
                                            name='eventTime' 
                                            defaultValue={`${String(new Date().getHours()).padStart(2, '0')}:${String(new Date().getMinutes()).padStart(2, '0')}`}
                                            min={`${String(new Date().getHours()).padStart(2, '0')}:${String(new Date().getMinutes()).padStart(2, '0')}`}
                                        />
                                    </Col>
                                </Row>
                            </Form.Group>
                            <Form.Group className='text-start pt-3'>
                                <Form.Label>Event Description</Form.Label>
                                <Form.Control required={calEventType === 2} as='textarea' rows={4} name='eventDesc' placeholder='Describe your event' />
                            </Form.Group>
                            <Button className={'mt-3 py-1 ' + getButtonStyle('lg')} type='submit'>Add Event</Button>
                        </>
                    }
                </Form>
            </Modal.Body>
        </Modal>
    )
}

// HELPERS
const daysOfWeek = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday']

const getYearDropdownOptions = () => {
    let minYear = new Date().getFullYear() - 4
    let maxYear = new Date().getFullYear() + 5

    return Array.from({length: maxYear - minYear + 1}, (_, index) => ({label: `${minYear + index}`, value: minYear + index}))
}

const getMonthDropdownOptions = () => {
    return Array.from({length: 12}, (_, index) => ({value: index + 1, label: dayjs().month(index).format('MMMM')}))
}

const getNumberOfDaysInMonth = (year, month) => {
    return dayjs(`${year}-${month}-01`).daysInMonth()
}

const createDaysForCurrentMonth = (year, month) => {
    return [...Array(getNumberOfDaysInMonth(year, month))].map((_, index) => ({
        dateString: dayjs(`${year}-${month}-${index + 1}`).format('YYYY-MM-DD'),
        dayOfMonth: index + 1,
        isCurrentMonth: true
    }))
}

const createDaysForPastMonth = (year, month, currentMonthDays) => {
    const firstDayOfTheMonthWeekday = getWeekday(currentMonthDays[0].dateString)
    const previousMonth = dayjs(`${year}-${month}-01`).subtract(1, 'month')
    const visibleNumberOfDaysFromPreviousMonth = firstDayOfTheMonthWeekday
    const previousMonthLastMondayDayOfMonth = dayjs(currentMonthDays[0].dateString).subtract(visibleNumberOfDaysFromPreviousMonth, 'day').date()
    return [...Array(visibleNumberOfDaysFromPreviousMonth)].map((_, index) => ({
        dateString: dayjs(`${previousMonth.year()}-${previousMonth.month() + 1}-${previousMonthLastMondayDayOfMonth + index}`).format('YYYY-MM-DD'),
        dayOfMonth: previousMonthLastMondayDayOfMonth + index,
        isCurrentMonth: false,
        isPreviousMonth: true
    }))
}

const createDaysForNextMonth = (year, month, currentMonthDays) => {
    const lastDayOfTheMonthWeekday = getWeekday(`${year}-${month}-${currentMonthDays.length}`)
    const nextMonth = dayjs(`${year}-${month}-01`).add(1, 'month')
    const visibleNumberOfDaysFromNextMonth = 6 - lastDayOfTheMonthWeekday
    return [...Array(visibleNumberOfDaysFromNextMonth)].map((_, index) => ({
        dateString: dayjs(`${nextMonth.year()}-${nextMonth.month() + 1}-${index + 1}`).format('YYYY-MM-DD'),
        dayOfMonth: index + 1,
        isCurrentMonth: false,
        isNextMonth: true
    }))
}

const getWeekday = (dateString) => {
    return dayjs(dateString).weekday()
}

export default AdCalendar