import React, { useEffect, useState } from 'react'
import { useAuth } from '../../../../../contexts/authContext'
import MedalModal from './medalModal'

const NewMedalModal = () => {
    const {userData, type, newMedal, setNewMedal} = useAuth()
    
    const [showModal, setShowModal] = useState(false)

    useEffect(() => {
        if ((type && newMedal) || newMedal === 0) {
            setShowModal(true)
            setTimeout(() => {
                setShowModal(false)
                setNewMedal(null)
            }, 10000)
        } else {
            setShowModal(false)
        }
    }, [type, newMedal, setShowModal, setNewMedal, userData.social])

    return (
        <div id='newMedalModal'>
            <MedalModal show={showModal} setShow={setShowModal} newlyEarned medalId={newMedal} onClose={() => setNewMedal(null)} />
        </div>
    )
}

export default NewMedalModal