import React from 'react'
import { Card, Col, Container, Row, Button } from 'react-bootstrap'
import { PersonArmsUp, MegaphoneFill,  EmojiSunglassesFill, PauseFill, Fullscreen, PersonFill, ChatRightHeartFill, ChatRight} from 'react-bootstrap-icons'
import { useNavigate } from 'react-router-dom'
import { useTheme } from '../../../../../../contexts/themeContext'
import { useAuth } from '../../../../../../contexts/authContext'


const AdGuideToolInDepth = (props) => {
    const {type} = useAuth()
    const navigate = useNavigate()

    const pages = {
        Link: {video: <LinkVideoPlayer />, sideCards: <LinkSideCards type={type} />},
        Logo: {video: <LogoVideoPlayer />, sideCards: <LogoSideCards type={type} />},
        'Ad Read': {video: <AdReadVideoPlayer />, sideCards: <AdReadSideCards type={type} />}
    }

    return (
        <Container>
            <Row className='my-bg-dark h-100 arvo-regular fs-1 rounded-3' >
                <Col lg={7} className='h-100 d-flex flex-column'>
                    <Row lg={12} className='p-3 d-flex justify-content-center align-content-center'>
                        {pages[props.inDepthType] ? pages[props.inDepthType].video : pages['Link'].video}
                    </Row>
                    <Row>
                        <Row className='ps-4 d-flex align-items-center'>
                            <h4 className='my-text-white'>Your Ad Read is in this video</h4>
                        </Row>
                        <Row className='d-flex align-items-center'>
                            <Col className='mt-3 d-flex'>
                                <Col className='ps-3 d-flex justify-content-center align-items-center'>
                                    <div className='rounded-circle my-bg-secondary-light d-flex justify-content-center align-items-center' style={{width: '100px', height: '100px'}}>
                                        <EmojiSunglassesFill style={{fill: 'orange', fontSize: '70px'}} />
                                    </div>
                                </Col>
                                <Col className='ps-3 pt-2'>
                                    <h3 className='my-text-white text-nowrap'>A Fiable Creator</h3>
                                    <h5 className='my-text-white text-nowrap'>is plugging {type ? 'a' : 'your'} brand</h5>
                                </Col>
                            </Col>
                            <Col className='d-flex justify-content-end align-items-center'>
                                <Button className='rounded-pill border-2 border-white btn-lg ref' style={{backgroundColor: '#221C40'}} onClick={() => (type ? navigate('/channel') : navigate('/create-ad'))}>
                                    {type ? 'View Channel' : 'Book Now!'}
                                </Button>
                            </Col>
                        </Row>
                    </Row>
                    <Row className='d-flex p-4'>
                        <Card style={{backgroundColor: '#221C40'}}>
                            <Card.Header className='m-0 p-0'>
                                <h6>Description</h6>
                            </Card.Header>
                            <Card.Body className='m-0 p-0'>
                                <p className='fs-6'>Click here for the coolest thing ever: <strong className='my-text-primary'>{type ? 'Ad' : 'Your'} Link</strong></p>
                            </Card.Body>
                        </Card>
                    </Row>
                </Col>
                <Col lg={5} className='pt-3 d-flex flex-column align-items-center justify-content-between my-text-white'>
                    {pages[props.inDepthType] ? pages[props.inDepthType].sideCards : pages['Link'].sideCards}
                </Col>
            </Row>
        </Container>
    )
}

const AdReadSideCards = ({type}) => {
    return(<>
        <div>
            <Card className='mb-3 w-100' style={{backgroundColor: '#221C40'}}>
                <Card.Header className='bg-transparent rounded-3'>
                    <h5 className='text-center'>Shout Outs</h5>
                </Card.Header>
                <Card.Body>
                    <div className='me-2 d-flex flex-row'>
                        <ul className='me-3 fs-6' style={{flex: '3'}}>
                            <li>30 seconds or less</li>
                            <li>Optional video assets</li>
                            <li>Less Intrusive</li>
                        </ul>
                        <div className='ms-2 rounded-3 d-flex flex-column text-center' style={{flex: '2', backgroundColor: '#352E5C', height: 'max-content'}}>
                            <div className='mt-2 rounded-3' style={{height: 'max-content'}}>
                                <h6 className='m-0 p-0' style={{textWrap: 'pretty', wordWrap: 'normal'}}>Starting CPM:</h6>
                            </div>
                            <div className='rounded-3 m-3' style={{backgroundColor: '#352E5C'}}>
                                <p className='m-0 p-0 text-center fs-2 text-nowrap'>$9</p>
                            </div>
                        </div>
                    </div>
                </Card.Body>
            </Card>
            <Card className='mb-3' style={{backgroundColor: '#221C40'}}>
                <Card.Header className='bg-transparent' >
                    <h5 className='text-center'>Extended Ad Reads</h5>
                </Card.Header>
                <Card.Body>
                    <div className='me-2 d-flex flex-row'>
                        <ul className='me-3 fs-6' style={{flex: '3'}}>
                            <li>30 seconds to 2 minutes</li>
                            <li>Including video assets is {type ? 'common' : 'strongly recommended'}</li>
                            <li>High Value</li>
                        </ul>
                        <div className='ms-2 rounded-3 d-flex flex-column text-center' style={{flex: '2', backgroundColor: '#352E5C', height: 'max-content'}}>
                            <div className='mt-2 rounded-3' style={{height: 'max-content'}}>
                                <h6 className='m-0 p-0' style={{textWrap: 'pretty', wordWrap: 'normal'}}>Starting CPM:</h6>
                            </div>
                            <div className='rounded-3 m-3' style={{backgroundColor: '#352E5C'}}>
                                <p className='m-0 p-0 text-center fs-2 text-nowrap'>$10</p>
                            </div>
                        </div>
                    </div>
                </Card.Body>
            </Card>
        </div>
        <Card className='mb-3 border-2 border-white' style={{backgroundColor: '#221C40'}}>
            <Card.Header className='bg-transparent'>
                <h3 className='text-center'>How it works...</h3>
            </Card.Header>
            <Card.Body>
                <ul className='me-3 fs-6' style={{flex: '3'}}>
                    <li>{type ? 'Advertisers' : 'You'} provide a script that {type ? 'you' : 'the creator'} will use to create a custom commercial in their content.</li>
                    <li>{type ? 'Advertisers may o' : 'O'}ptionally include marketing assets such as video content to be placed in the video while the creator is preforming the ad read</li>
                    <li>{type ? 'Ad' : 'Your'} link is provided in the description of the video</li>
                </ul>
            </Card.Body>
        </Card>
    </>)
}

const LinkSideCards = ({type}) => {
    return(<>
        <Card className='mb-3 w-100' style={{backgroundColor: '#221C40'}}>
            <Card.Header className='bg-transparent' >
                <h5 className='text-center'>Link</h5>
            </Card.Header>
            <Card.Body>
                <div className='me-2 d-flex flex-row'>
                    <ul className='me-3 fs-6' style={{flex: '3'}}>
                        <li>Link in the description</li>
                        <li>Tracking</li>
                        <li>Default Inclusion</li>
                    </ul>
                    <div className='ms-2 rounded-3 d-flex flex-column text-center' style={{flex: '2', backgroundColor: '#352E5C', height: 'max-content'}}>
                        <div className='mt-2 rounded-3' style={{height: 'max-content'}}>
                            <h6 className='m-0 p-0' style={{textWrap: 'pretty', wordWrap: 'normal'}}>Starting CPM:</h6>
                        </div>
                        <div className='rounded-3 m-3' style={{backgroundColor: '#352E5C'}}>
                            <p className='m-0 p-0 text-center fs-2 text-nowrap'>$3</p>
                        </div>
                    </div>
                </div>
            </Card.Body>
        </Card>
        <Card className='mb-3 border-2 border-white' style={{backgroundColor: '#221C40'}}>
            <Card.Header className='bg-transparent'>
                <h3 className='text-center'>How it works...</h3>
            </Card.Header>
            <Card.Body>
                <ul className='me-3 fs-6' style={{flex: '3'}}>
                    <li>{type ? 'Advertisers' : 'You'} provide a link to where you want direct campaign traffic</li>
                    <li>{type ? 'Advertisers' : 'We'} add an additional supporting copy to accompany the link in the description</li>
                    <li>We automatically deliver the link and supporting copy to {type ? 'you' : 'the creator'}</li>
                    <li>Click-throughs are tracked per individual creator</li>
                    <li>This option is included by default in all creator sponsorships</li>
                </ul>
            </Card.Body>
        </Card>
    </>)
}

const LogoSideCards = ({type}) => {
    return(<>
        <Card className='mb-3 w-100' style={{backgroundColor: '#221C40'}}>
            <Card.Header className='bg-transparent' >
                <h5 className='text-center'>Logo</h5>
            </Card.Header>
            <Card.Body>
                <div className='me-2 d-flex flex-row'>
                    <ul className='me-3 fs-6' style={{flex: '3'}}>
                        <li>Brand Logo</li>
                        <li>Promotional Imagery</li>
                        <li>Can be animated</li>
                        <li>Minimal Intrusiveness</li>
                    </ul>
                    <div className='ms-2 rounded-3 d-flex flex-column text-center' style={{flex: '2', backgroundColor: '#352E5C', height: 'max-content'}}>
                        <div className='mt-2 rounded-3' style={{height: 'max-content'}}>
                            <h6 className='m-0 p-0' style={{textWrap: 'pretty', wordWrap: 'normal'}}>Starting CPM:</h6>
                        </div>
                        <div className='rounded-3 m-3' style={{backgroundColor: '#352E5C'}}>
                            <p className='m-0 p-0 text-center fs-2 text-nowrap'>$5</p>
                        </div>
                    </div>
                </div>
            </Card.Body>
        </Card>
        <Card className='mb-3 border-2 border-white' style={{backgroundColor: '#221C40'}}>
            <Card.Header className='bg-transparent'>
                <h3 className='text-center'>How it works...</h3>
            </Card.Header>
            <Card.Body>
                <ul className='me-3 fs-6' style={{flex: '3'}}>
                    <li>{type ? 'Advertisers' : 'You'} provide your brand's logo or promotional images</li>
                    <li>{type ? 'You' : 'The creator'} will place the logo/imagery in their video</li>
                    <li>{type ? 'The Advertiser' : 'You'} can combine this option with a short Ad read to provide context to the reader</li>
                    <li>The link is provided in the description of the video</li>
                </ul>
            </Card.Body>
        </Card>
    </>)
}

const AdReadVideoPlayer = () => {
    const {isSmallScreen} = useTheme()

    return (
        <div className='rounded-5 d-flex flex-row my-text-white' style={{backgroundColor: '#221C40', height: '50vh'}}>
            <div className='pb-4 d-flex justify-content-start align-items-end position-relative' style={{width: '40%', flex: '1 1 0'}}>
                <PersonArmsUp style={{fontSize: '35vh'}} />
                <MegaphoneFill className='position-relative' style={{fontSize: '10vh', fill: 'orange', bottom: '55%', right: '25%', transform: 'rotate(-20deg)', visibility: (isSmallScreen ? 'hidden' : 'visible')}} />
                <PauseFill className='position-absolute ms-2' style={{bottom: '0%'}} />
                <div className='position-absolute my-bg-danger rounded-pill' style={{height: '5px', width: '100%', bottom: '10%', left: '3%'}} />
            </div>
            <div className={'d-flex justify-content-start align-items-center position-relative ' + (isSmallScreen ? 'fs-3' : 'fs-6')} style={{flex: '3 0 0'}}>
                <div className='py-5'>
                    <ol className='my-5'>
                        <li><strong className='my-text-primary'>Authenticity and Trust{isSmallScreen ? '' : ':'}</strong> {isSmallScreen ? '' : ' Listeners are more likely to trust recommendations coming directly from a familiar voice they respect and admire.'}</li>
                        <li><strong className='my-text-primary'>Engagement{isSmallScreen ? '' : ':'}</strong> {isSmallScreen ? '' : ' More engaging and memorable than pre-produced ads. Capture listeners\' attention and drive action.'}</li>
                        <li><strong className='my-text-primary'>Flexibility{isSmallScreen ? '' : ':'}</strong> {isSmallScreen ? '' : ' Tailored to fit seamlessly with the creators tone and audience, natural.'}</li>
                    </ol>
                </div>
                <Fullscreen className='position-absolute' style={{right: '4%', bottom: '2%', fontSize: '1.6rem'}} />
                <div className='position-absolute my-bg-secondary-light rounded-pill d-flex justify-content-start align-items-center' style={{height: '5px', width: '100%', bottom: '10%', left: '0%'}}>
                    <div className='my-bg-white rounded-circle' style={{height: '10px', width: '10px'}} />
                </div>
            </div>
        </div>
    )
}

const LinkVideoPlayer = () => {
    return (
        <div className='rounded-5 d-flex flex-row my-text-white' style={{backgroundColor: '#221C40', height: '50vh'}}>
            <div className='pb-4 d-flex justify-content-center align-items-end position-relative w-100'>
                <PersonFill style={{fontSize: '35vh'}} />
                <div className='position-absolute' style={{top: '5%', left: '5%'}}>
                    <ChatRightHeartFill className='' style={{fontSize: '4em', fill: 'orange', top: '12%', left: '5%'}} />
                </div>
                <PauseFill className='position-absolute ms-2' style={{bottom: '0%', left: '0%'}} />
                
                <div className='position-absolute my-bg-secondary-light rounded-pill d-flex justify-content-start align-items-center' style={{height: '5px', width: '100%', bottom: '10%', left: '0%'}}>
                    <div className='position-absolute my-bg-danger rounded-pill d-flex justify-content-end align-items-center' style={{height: '5px', width: '60%', bottom: '0%', left: '0%'}}>
                        <div className='my-bg-white rounded-circle' style={{height: '10px', width: '10px'}} />
                    </div>
                </div>
                <Fullscreen className='position-absolute' style={{right: '4%', bottom: '2%', fontSize: '1.6rem'}} />
            </div>
        </div>
    )
}

const LogoVideoPlayer = () => {
    return (
        <div className='rounded-5 d-flex flex-row my-text-white position-relative' style={{backgroundColor: '#221C40', height: '50vh'}}>
            <div className='pb-4 d-flex justify-content-center align-items-end position-relative w-100'>
                <PersonFill style={{fontSize: '35vh'}} />
                <ChatRight className='position-absolute' style={{fontSize: '25vh', fill: 'white', top: '5%', left: '0%'}} />
                <PauseFill className='position-absolute ms-2' style={{bottom: '0%', left: '0%'}} />
                <div className='position-absolute fs-6 fw-bold my-text-primary d-flex justify-content-start align-items-center' style={{top: '12%', left: '0%'}}>
                    <ol>
                        <li>Cost Effective</li>
                        <li>Brand Awareness</li>
                        <li>Less Intrusive</li>
                    </ol>
                </div>
                <div className='position-absolute my-bg-secondary-light rounded-pill d-flex justify-content-start align-items-center' style={{height: '5px', width: '100%', bottom: '10%', left: '0%'}}>
                    <div className='position-absolute my-bg-danger rounded-pill d-flex justify-content-end align-items-center' style={{height: '5px', width: '60%', bottom: '0%', left: '0%'}}>
                        <div className='my-bg-white rounded-circle' style={{height: '10px', width: '10px'}} />
                    </div>
                </div>
                <Fullscreen className='position-absolute' style={{right: '4%', bottom: '2%', fontSize: '1.6rem'}} />
            </div>
            <div className='position-absolute rounded-circle border-3 border-black d-flex justify-content-center align-items-center my-bg-white' style={{height:'150px', width: '150px', top: '0%', right: '0%'}}>
                <div className='rounded-circle border-3 border-black d-flex justify-content-center align-items-center' style={{backgroundColor: '#221C40', height:'130px', width: '130px'}}>
                    <p className='fs-2 my-text-white'>Logo</p>
                </div>
            </div>
        </div>
    )
}

export default AdGuideToolInDepth