import React, { useState } from 'react'
import { Row, Col, Button, Card, Form, Modal, Collapse } from 'react-bootstrap'
import { useAuth } from '../../../../contexts/authContext'
import { useTheme } from '../../../../contexts/themeContext'

const UserSettingsCard = (props) => {
    const { currentUser, userData, type, updateUserProfile, setError, setSuccess, updateUserData, setSocialData, earnMedal } = useAuth()
    const { getTextInv, getCardStyle, getButtonStyle, getFormStyle } = useTheme()

    const [showChangePassModal, setShowChangePassModal] = useState(false)
    const [showChangeEmailModal, setShowChangeEmailModal] = useState(false)
    const [currentEmail, setCurrentEmail] = useState(currentUser.email)
    const [loading, setLoading] = useState(false)

    async function submitForm(e) {
        e.preventDefault()
        setLoading(true)
        setSuccess('')
        setError('')

        const data = [...(new FormData(e.target)).entries()]
        const newUsername = data[0][1]
        const newNickname = data[3][1]
        const newName = data[1][1] + ' ' + data[2][1]

        let newUserData = {}
        newUserData.nickname = newNickname
        newUserData.name = newName
        if ( type ) newUserData.primaryPlatform = data[4][1]
        else newUserData.companyName = data[4][1]
    
        try {
            if (newUsername !== currentUser.displayName) {
                await updateUserProfile( {displayName: newUsername} )
                props.setUsername(newUsername)
                setSuccess('Updated Profile')
            }

            if ( newNickname !== userData.nickname ) {
                props.setNickname(newNickname)
            }

            await updateUserData( newUserData )
            await setSocialData( {...newUserData, username: newUsername} )
            setSuccess('Updated Profile')

            if (currentUser.displayName && userData.nickname && userData.name) earnMedal(1)
        } catch (e) {
            setError('Failed to Update Profile')
        } finally {
            setLoading(false)
        }
    }

    return (
        <Card className={getCardStyle()} style={{transition: 'all 0.5s'}}>
            <Card.Header className='pt-2 pb-1 fw-bold'>
                User Settings
            </Card.Header>
            <Card.Body>
                <Form onSubmit={submitForm}>
                    <Row>
                        <Col>
                            <Form.Group className='mb-2 '>
                                <Form.Label>Username</Form.Label>
                                <Form.Control className={getFormStyle() + ' ' + getTextInv()} style={{transition: 'background 0.5s'}} type='text' name='userUsername' defaultValue={currentUser.displayName} placeholder='Username'/>
                            </Form.Group>
                        </Col>
                        <Col>
                            <Form.Group className='mb-2'>
                                <Form.Label>Email</Form.Label>
                                <Form.Control className={getFormStyle() + ' ' + getTextInv()} style={{transition: 'background 0.5s'}} type='email' name='userEmail' disabled defaultValue={currentEmail} placeholder='email'/>
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <Form.Group className='mb-2'>
                                <Form.Label>First Name</Form.Label>
                                <Form.Control className={getFormStyle() + ' ' + getTextInv()} style={{transition: 'background 0.5s'}} type='text' name='firstName' defaultValue={(userData.name && userData.name.split(' ')[0]) || null} placeholder='First Name' />
                            </Form.Group>
                        </Col>
                        <Col>
                            <Form.Group className='mb-2'>
                                <Form.Label>Last Name</Form.Label>
                                <Form.Control className={getFormStyle() + ' ' + getTextInv()} style={{transition: 'background 0.5s'}} type='text' name='firstName' defaultValue={(userData.name && userData.name.includes(' ') && userData.name.split(' ')[1]) || null} placeholder='Last Name' />
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <Form.Group className='mb-2'>
                                <Form.Label>Nickname</Form.Label>
                                <Form.Control className={getFormStyle() + ' ' + getTextInv()} style={{transition: 'background 0.5s'}} type='text' name='userNickname' defaultValue={userData.nickname} placeholder='Add a Nickname!'/>
                            </Form.Group>
                        </Col>
                        <Col>
                            {type === 1 ?
                                <Form.Group className='mb-2'>
                                    <Form.Label>Primary Creator Platform</Form.Label>
                                    <Form.Select className={getFormStyle() + ' ' + getTextInv()} style={{transition: 'background 0.5s'}} name='userPlatform' defaultValue={userData.primaryPlatform}>
                                        <option value=''>Select a Platform</option>
                                        <option value='youtube'>Youtube</option>
                                        <option value='twitch'>Twitch</option>
                                        <option value='other'>Other</option>
                                    </Form.Select>       
                                </Form.Group>
                            :
                                <Form.Group className='mb-2'>
                                    <Form.Label>Company Name</Form.Label>
                                    <Form.Control className={getFormStyle() + ' ' + getTextInv()} style={{transition: 'background 0.5s'}} type='text' name='companyName' defaultValue={userData.companyName} placeholder={'Your Company\'s Name'} />
                                </Form.Group>
                            }
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <Button disabled={loading} className={'m-2 ' + getButtonStyle('sm')} type='submit'>
                                Save Changes
                            </Button>
                        </Col>
                        <Col className='d-flex text-nowrap justify-content-end'>
                            <Button className={'m-2 ' + getButtonStyle('sm')} onClick={() => setShowChangeEmailModal(true)}>
                                Change Email
                            </Button>
                            <Button className={'m-2 ' + getButtonStyle('sm')} onClick={() => setShowChangePassModal(true)}>
                                Change Password
                            </Button>
                        </Col>
                    </Row>
                </Form>
                <ChangeEmailModal show={showChangeEmailModal} setShow={setShowChangeEmailModal} setEmail={setCurrentEmail}/>
                <ChangePasswordModal show={showChangePassModal} setShow={setShowChangePassModal} />
            </Card.Body>
        </Card>
    )
}

const ChangeEmailModal = (props) => {
    const { sendVerificationCode, updateUserEmail, reauthenticateUser, setError, setSuccess } = useAuth()
    const { getBG, getTextInv, getThemeBGInv, getText } = useTheme()

    const [loading, setLoading] = useState(false)
    const [isAuthenticated, setIsAuthenticated] = useState(false)
    const [verificationCode, setVerificationCode] = useState()

    function handleSubmit(e) {
        e.preventDefault()
        setLoading(true)
        const data = [...(new FormData(e.target)).entries()]
        if ( data[2][1].length ) {
            setError('')
            if ( data[2][1] === verificationCode ) {
                updateUserEmail( data[1][1] ).then(() => setSuccess('Updated Email') || props.setEmail(data[1][1])).catch((err) => setError('Failed to Update Email'))
                closeModal()
            } else {
                setError('Incorrect code')
                closeModal()
            }
        } else if ( data[1][1].length ) {
            setError('')
            sendVerificationCode(data[1][1]).then(res => setVerificationCode(res)).catch(err => closeModal() || setError('Failed to send verification email'))
        } else {
            setError('')
            reauthenticateUser( data[0][1] ).then(() => setIsAuthenticated(true)).catch(() => closeModal() || setError('Failed to Re-Authenticate'))
        }
        setLoading(false)
    }

    function closeModal() {
        setIsAuthenticated(false)
        setVerificationCode(null)
        setLoading(false)
        props.setShow(false)
    }

    return (
        <Modal show={props.show} onHide={closeModal}>
            <Modal.Header closeButton className={'border-0 ' + getBG()}>
                <Modal.Title className={getTextInv()}>
                    Change Email
                </Modal.Title>
            </Modal.Header>
            <Modal.Body className={'text-center rounded-bottom ' + getBG()}>
                <h5 className={getTextInv()}>Are you sure you want to change your email?</h5>
                <p className={getTextInv()}>Please enter your password to continue</p>
                <Form onSubmit={handleSubmit} className='mt-1'>
                    <Form.Group className='form-white mb-4' controlId='pass'>
                        <Form.Control
                            name='pass'
                            type='password'
                            placeholder='Enter your Password'
                            required
                            readOnly={isAuthenticated}
                            className='form-control-lg'
                        />
                    </Form.Group>
                    <Collapse in={isAuthenticated}>
                        <Form.Group className='form-white mb-4' controlId='newEmail'>
                            <Form.Control
                                name='newEmail'
                                type='email'
                                placeholder='New Email'
                                required={isAuthenticated}
                                readOnly={!!verificationCode}
                                className='form-control-lg'
                            />
                        </Form.Group>
                    </Collapse>
                    <Collapse in={!!verificationCode}>
                        <Form.Group className='form-white mb-4' controlId='verificationCode'>
                            <Form.Control
                                name='verificationCode'
                                type='text'
                                placeholder='Enter the code sent to the new email'
                                required={!!verificationCode}
                                className='form-control-lg'
                            />
                        </Form.Group>
                    </Collapse>
                    <Row className='text-center px-2'>
                        <Button className={'btn-lg px-5 ' + getThemeBGInv('primary') + ' ' + getText()} disabled={loading} type='submit'>
                            {loading ? 'Submitting...' : 'Submit' }
                        </Button>
                    </Row>
                </Form>
            </Modal.Body>
        </Modal>
    )
}

const ChangePasswordModal = (props) => {
    const { reauthenticateUser, updateUserPassword, setError, setSuccess } = useAuth()
    const { getBG, getTextInv, getThemeBGInv, getText } = useTheme()

    const [loading, setLoading] = useState(false)
    const [isAuthenticated, setIsAuthenticated] = useState(false)

    function handleSubmit(e) {
        e.preventDefault()
        setLoading(true)
        const data = [...(new FormData(e.target)).entries()]
        const pass = data[0][1]
        if ( data[1][1].length > 0) {
            setError('')
            const newPass = data[1][1]
            updateUserPassword( newPass ).then(() => {
                setSuccess('Updated Password')
            }).catch((e) => {
                setError('Failed to Update Password')
            })
            closeModal()
            setLoading(false)
        }
        else {
            setError('')
            reauthenticateUser( pass ).then(() => {
                setIsAuthenticated(true)
            }).catch(() => {
                closeModal()
                setError('Failed to Re-Authenticate')
            })
            setLoading(false)
        }
    }

    function closeModal() {
        setIsAuthenticated(false)
        props.setShow(false)
    }

    return (
        <Modal show={props.show} onHide={closeModal}>
            <Modal.Header closeButton className={'border-0 ' + getBG()}>
                <Modal.Title className={getTextInv()}>
                    Change Password
                </Modal.Title>
            </Modal.Header>
            <Modal.Body className={'text-center rounded-bottom ' + getBG()}>
                <h5 className={getTextInv()}>Are you sure you want to change your password?</h5>
                <p className={getTextInv()}>Please enter your password to continue</p>
                <Form onSubmit={handleSubmit} className='mt-1'>
                    <Form.Group className='form-white mb-4' controlId='oldPassword'>
                        <Form.Control 
                            name='oldPassword'
                            type='password'
                            placeholder='Password'
                            required
                            readOnly={isAuthenticated}
                            className='form-control-lg'
                        />
                    </Form.Group>
                    <Collapse in={isAuthenticated}>
                        <Form.Group className='form-white mb-4' controlId='newPassword'>
                            <Form.Control
                                name='newPassword'
                                type='password'
                                placeholder='New Password'
                                required={isAuthenticated}
                                className='form-control-lg'
                            />
                        </Form.Group>
                    </Collapse>
                    <Row className='text-center px-2'>
                        <Button className={'btn-lg px-5 ' + getThemeBGInv('primary') + ' ' + getText()} disabled={loading} type='submit'>
                            {loading ? 'Submitting...' : 'Submit' }
                        </Button>
                    </Row>
                </Form>
            </Modal.Body>
        </Modal>
    )
}

export default UserSettingsCard;