import React, { useEffect, useState, useCallback, useRef } from "react"
import ShiftingText from "./shiftingText"
import { useTheme } from "../../../../../contexts/themeContext"
import { ChatRightDotsFill, EyeFill, HandThumbsUpFill, HeartFill, ReplyFill, ShareFill } from "react-bootstrap-icons"

import './heroSection.css'

const HeroSection = () => {
    const { darkMode, getThemeTextInv, isSmallScreen } = useTheme()

    const iconRef = useRef(null)
    const [icon, setIcon] = useState({icon: <></>, position: { top: '0%', left: '0%' }})

    const updateIcon = useCallback(() => {
        const icons = [<HandThumbsUpFill />, <ReplyFill />, <ChatRightDotsFill />, <HeartFill />, <EyeFill />, <ShareFill />]
        
        const newLeft = Math.round(Math.random() * 100)
        const newTop = Math.round(Math.random() * 100)
        if (((newLeft <= 95 && newLeft >= 70) || (newLeft >= 5 && newLeft <= 30)) && newTop <= 95 && newTop >= 5) {
            setIcon({
                icon: icons[Math.floor(Math.random() * icons.length)],
                position: {
                    top: newTop + '%',
                    left: newLeft + (newLeft < 50 ? (-40) : 30) + '%'
                }
            })
            if (iconRef.current) iconRef.current.style.display = ''
        } else {
            setIcon({
                icon: <></>,
                position: {
                    top: 0 + '%',
                    left: 0 + '%'
                }
            })
            if (iconRef.current) iconRef.current.style.display = 'none'
        }

        if (iconRef.current) {
            iconRef.current.style.animation = 'none'
            iconRef.current.style.animation = 'emojiPop 1.5s infinite ease-in-out'
        }
    }, [setIcon])

    useEffect(() => {
        const intervalId = setInterval(updateIcon, 1500)
        
        return () => clearInterval(intervalId)
    }, [updateIcon])

    return (
        <div className={'d-flex fw-bold pt-0 pb-5 my-5 justify-content-start position-relative ' + (darkMode ? 'my-text-primary-light' : 'my-text-dark')} style={{fontSize: isSmallScreen ? '3rem ': '4.5rem', transition: 'color 0.5s', height: 'fit-content', left: '0%'}}>
            <div className={'arvo-regular-italic text-start ps-0 py-0 mb-5 d-flex flex-column align-items-start ' + (isSmallScreen ? 'w-100' : 'w-50')} style={{position: 'relative', lineHeight: '1'}}>
                <div style={{paddingLeft: isSmallScreen ? '5%' : '', lineHeight: '1'}}>
                    <ShiftingText prefix={'In'} words={['-Content',  'stant', 'teresting', 'spiring']}/>
                    <p className="m-0 p-0" >  
                        Advertising<br/> for
                    </p>
                </div>
                <div style={{fontSize: isSmallScreen ? '3rem ': '7rem', paddingBottom: '50%', paddingLeft: isSmallScreen ? '5%' : ''}}>
                    <ShiftingText words={['Brands', 'Businesses', 'Start-Ups', 'Enterprises']} />
                </div>
                <p className={'arvo-regular text-center ' + (isSmallScreen ? 'text-wrap w-100' : 'text-nowrap') + ' ' + (darkMode ? 'my-text-secondary' : 'my-text-dark')} style={{fontSize: '2rem'}}>
                        Creator Marketing made easy and efficient
                </p>
            </div>
            { isSmallScreen ? <></> :
            <div 
                ref={iconRef}
                className={'position-absolute emojiPop ' + getThemeTextInv('primary')}
                style={{
                    fontSize: '5rem',
                    top: icon.position.top,
                    left: icon.position.left,
                }}
            >
                {icon.icon}
            </div>
            }
        </div>
    )
}

export default HeroSection