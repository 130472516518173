import React, { useState } from 'react'
import { Card, Col, Dropdown } from 'react-bootstrap';
import { useTheme } from '../../../../../contexts/themeContext';
import { ThreeDotsVertical } from 'react-bootstrap-icons';
import { useAuth } from '../../../../../contexts/authContext';
import PieGraph from '../pieGraph';

const AdDistPieChart = () => {
    const {userData} = useAuth()
    const { getBG, getCardStyle, getTextInv } = useTheme()

    // type dictates which data to display in the pie chart. 0 displays all, 1 displays current, 2 displays past
    const [type, setType] = useState(0)

    const getPieData = () => {
        let result = []

        Object.values(userData.history).forEach( ad => {
            let adRevenue = Math.round((ad.totalCost * (ad.currentImpressions / ad.totalImpressions ) * 100)) / 100
            result.push({
                name: (ad.advertiserName + ' - ' + ad.advertisementName), 
                value: adRevenue, 
                type: (ad.isActive ? 1 : 2)
            })
        })

        result = result.filter( ad => ( ad['value'] !== 0 ))
        return result
    }

    return (
        <Col>
            <Card className={getCardStyle()}>
                <Card.Header className='d-flex justify-content-between align-items-center'>
                    <h6 className='fw-bold m-0'>
                        Earnings by Advertisement
                    </h6>
                    <Dropdown className='rounded bg-transparent'>
                        <Dropdown.Toggle className='dropdown-toggle-arrowless border-0 bg-transparent'>
                            <ThreeDotsVertical />
                        </Dropdown.Toggle>
                        <Dropdown.Menu className={'shadow ' + getBG()}>
                            <Dropdown.Item className={getTextInv()} onClick={() => setType(0)}>
                                All (default)
                            </Dropdown.Item>
                            <Dropdown.Item className={getTextInv()} onClick={() => setType(1)}>
                                Current
                            </Dropdown.Item>
                            <Dropdown.Item className={getTextInv()} onClick={() => setType(2)}>
                                Completed
                            </Dropdown.Item>
                        </Dropdown.Menu>
                    </Dropdown>
                </Card.Header>
                <Card.Body className='d-flex justify-content-center align-items-center my-3' style={{height: '400px'}}>
                    {getPieData().length ? 
                        <PieGraph pieData={getPieData().filter( data => (!type || data['type'] === type))}/>
                    :
                        <h1 className='text-center'>No Advertisement Earnings</h1>
                    }
                </Card.Body>
            </Card>
        </Col>
    )
}

export default AdDistPieChart