import React from 'react'
import { Card, Container, Row } from 'react-bootstrap'
import { useTheme } from '../../contexts/themeContext'
import { Link } from 'react-router-dom'

const BookDemo = (props) => {
    const { darkMode, getThemeBG, getThemeBGInv, getThemeText, isSmallScreen } = useTheme()

    return (
        <Container id={'bookDemoContainer'} fluid style={{width: (isSmallScreen ? '100%' : '80%'), transition: 'opacity 0.5s'}}>
            <Row>
                <Card className={'border-0 ' + getThemeBGInv('primary')} style={{transition: 'background 0.5s'}}>
                    <Card.Body className='d-flex justify-content-between align-items-center' style={{transition: 'background 0.5s'}}>
                        <div className='my-4'>
                            <h4 className='my-text-dark ' style={{transition: 'color 0.5s'}}>Book A Demo</h4>
                            <h2 className={'fw-bold ' + getThemeText('primary')} style={{transition: 'color 0.5s'}}>Magnify your Brand in 10 minutes</h2>
                        </div>
                        <div>
                            <Link
                                to='/demo' 
                                className={'btn btn-lg border-0 border-dark shadow ' + (darkMode ? 'my-text-primary-light' : 'my-text-dark') + ' ' + getThemeBG('primary')} 
                                style={{transition: 'color 0.5s, background 0.5s', transform: isSmallScreen ? (props.onMainPage ? 'scale(100%)' : 'scale(80%)') : (props.onMainPage ? 'scale(200%)' : 'scale(130%) translateX(-20%)')}} 
                                role='button'
                            >
                                Try Now!
                            </Link>
                        </div>
                    </Card.Body>
                </Card>
            </Row>
        </Container>
    )
}

export default BookDemo