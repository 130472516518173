import React from 'react';
import { Container } from 'react-bootstrap';
import { Instagram, Linkedin, TwitterX } from 'react-bootstrap-icons';
import Brand from '../common/brand';
import { useTheme } from '../../contexts/themeContext';
import { Link } from 'react-router-dom';

const Footer = () => {
    const { getBG, getTextInv } = useTheme()

    return (
        <footer className={getBG()} style={{transition: 'background 0.5s'}}>
            <Container className={'p-3 d-flex justify-content-between ' + getTextInv()} style={{transition: 'color 0.5s'}}>
                <div className='pt-1'>
                    <Brand toggleColor />
                    <p className='py-1'>Copyright © 2024 Fiable</p>
                </div>
                <div>
                    <div className='d-flex justify-content-end'>
                        <a target='_blank' rel='noreferrer' href='https://x.com/fiablesolutions' className='text-decoration-none'>
                            <TwitterX className='bs-icon-sm mx-2 my-text-primary' />
                        </a>
                        <a target='_blank' rel='noreferrer' href='https://www.instagram.com/fiablesolutions/' className='text-decoration-none'>
                            <Instagram className='bs-icon-sm mx-2 my-text-primary' />
                        </a>
                        <a target='_blank' rel='noreferrer' href='https://www.linkedin.com/company/fiable-solutions1' className='text-decoration-none'>
                            <Linkedin className='bs-icon-sm mx-2 my-text-primary' />                        
                        </a>

                    </div>
                    <div className='mt-2 text-end'>
                        <Link to='/terms' className='me-2 text-decoration-none my-text-primary'>Terms of Service</Link>
                        <Link to='/privacy' className='ms-2 text-decoration-none my-text-primary'>Privacy Policy</Link>
                    </div>
                </div>
            </Container>
        </footer>
    )
}

export default Footer;