import React, { useCallback, useEffect, useState } from 'react'
import { Button, Card, Col, Modal, Row } from 'react-bootstrap'
import { useAuth } from '../../../../contexts/authContext'
import { useTheme } from '../../../../contexts/themeContext'
import SocialSnippet from './socialSnippet'
import SocialPage from './socialPage'

const ConnectionsCarousel = () => {
    const {userData, getSocialData, earnMedal} = useAuth()
    const {getCardStyle} = useTheme()

    const [connections, setConnections] = useState([])

    const getConnectionsData = useCallback(async () => {
        if (!userData.social || !userData.social.connections) return
        const newConnectionsData = await Promise.all(userData.social.connections.map(code => (
            new Promise((res, rej) => {
                getSocialData(code).then((data) => res({...data.data(), socialCode: code})).catch((err) => rej(err))
            }))
        ))
        setConnections(newConnectionsData)
    }, [getSocialData, userData.social])

    useEffect(() => {
        if (userData.social && Object.keys(userData.social.medals).length === 0)
            earnMedal(0, userData.socialCode, {dateAchieved: userData.social.creationDate, isRedeemed: false, highlight: true})
        getConnectionsData()
    }, [getConnectionsData, earnMedal, userData.social, userData.socialCode])

    return (
        <Card className={getCardStyle()} style={{width: '100%'}}>
            <Card.Body className='text-center'>
                <h4 className='fw-bold'>Your Connections</h4>
                <Row className='ms-1 mb-2 d-flex flex-nowrap overflow-x-scroll' style={{overflowY: 'hidden', width: 0, minWidth: '100%'}}>
                    {connections.length ? 
                        connections.map(connection => <ConnectionCard key={connection.socialCode} connection={connection} />)
                    :
                        <h4 className='my-3'>No Connections yet, add some above!</h4>
                    }
                </Row>
            </Card.Body>
        </Card>
    )
}

const ConnectionCard = ({connection}) => {
    const {getBG} = useTheme()
    const [showDetails, setShowDetails] = useState(false)

    return (
        <Col className='d-flex justify-content-center p-2'>
            <Button className='bg-transparent border-0 m-0 p-0' onClick={() => setShowDetails(true)}>
                <SocialSnippet socialData={connection} />
            </Button>
            <Modal size='xl' className='border-0' show={showDetails} onHide={() => setShowDetails(false)}>
                <Modal.Body className={'rounded p-0 m-0 ' + getBG()}>
                    <SocialPage socialCode={connection.socialCode} loadedData={connection} />
                </Modal.Body>
            </Modal>
        </Col>
    )
}

export default ConnectionsCarousel