import React, { useState } from 'react'
import { Col, Container, Image, Row } from 'react-bootstrap'
import { useAuth } from '../../../../contexts/authContext'
import { BucketBadge } from '../../channel/subcomponents/channelBucketsCard'
import { useTheme } from '../../../../contexts/themeContext'
import formatDollars from '../../../../utils/formatDollars'

const AdSummary = ({ data }) => {
    const { darkMode, getThemeBG, getTextInv, isSmallScreen }= useTheme()

    const [showAltVideoText, setShowAltVideoText] = useState(false)

    return (isSmallScreen ?
        <MobileView data={data} />
        :
        <Container fluid>
            <Row>
                <Col>
                    <h3 className='fw-bold'>{data.advertiserName}: {data.advertisementName}</h3>
                </Col>
                <Col className='text-end'>
                    <h3 className='fw-bold'>{data.startDate.toDate().toLocaleDateString()} - {data.endDate.toDate().toLocaleDateString()}</h3>
                </Col>
            </Row>
            <Row className='text-center pt-1'>
                <Col>
                    <h5 className='fw-bold'>Target Platforms:</h5><h5>{data.platforms.join(', ')}</h5>
                </Col>
                <Col>
                    <h5 className='fw-bold'>Target Impressions:</h5><h5>{data.totalImpressions}</h5>
                </Col>
            </Row>
            <Row className={'pt-2 border-top border-2 border-' + (!darkMode ? 'light' : 'dark')} style={{transition: 'border 0.5s'}}>
                <Col className='w-50'>
                    <Row><h4 className='fw-bold'>Advertisement Description:</h4></Row>
                    <Row><h5>{data.description}</h5></Row>
                </Col>
                <Col>
                    <Row className='pt-2'><h4 className='fw-bold'>Advertisement Categories:</h4></Row>
                    <Row className='p-1'>
                        {data.buckets.map(bucket => <BucketBadge showIcon className={'m-1 w-25 ' + getThemeBG('primary') + ' ' + getTextInv()} name={bucket} key={bucket} />)}
                    </Row>
                    {data.otherBuckets ? <>
                        <Row className='pt-2'><h4 className='fw-bold'>Other Categories: </h4></Row>
                        <Row><h5>{data.otherBuckets.join(',')}</h5></Row></> 
                    : 
                        <></>
                    }
                </Col>
            </Row>
            <Row className='pt-2'>
                <Col md={3}><h4 className='fw-bold'>Product Link:</h4></Col>
                <Col style={{wordWrap: 'break-word'}}><h5>{data.productLink}</h5></Col>
            </Row>
            {data.logoImage ? 
                <Row className='d-flex flex-row align-items-center'>
                    <Col md={3}><h4 className='fw-bold'>Logo: </h4></Col>
                    <Col><Image src={data.logoImage} height={60} /></Col>
                </Row> 
            : <></>}
            {data.readText ? <Row><Col md={3}><h4 className='fw-bold'>Ad Script:</h4></Col><Col style={{wordWrap: 'break-word'}}><h5>{data.readText}</h5></Col></Row> : <></>}
            {data.clipVideo ? 
                <>
                    <Row className='w-100'>
                        <Col md={3}>
                            <h4 className='fw-bold'>Clip Description:</h4>
                        </Col>
                        <Col style={{wordWrap: 'break-word'}}>
                            <h5 className='ms-2 text-center'>{data.clipText || 'None Provided'}</h5>
                        </Col>
                    </Row>
                    {data.clipVideo && 
                        <Row>
                            <Col md={3} className='d-flex align-items-center'>
                                <h4 className='fw-bold'>Clip Video:</h4>
                            </Col>
                            <Col className='d-flex justify-content-center pb-2'>
                                {showAltVideoText ?
                                    <h5>Video was provided, but cannot currently be displayed.</h5>
                                :
                                    <video className='rounded-1' height={300} controls onError={() => setShowAltVideoText(true)}>
                                        <source src={data.clipVideo} />
                                        Your Browser does not support embedded videos.
                                    </video>
                                }
                            </Col>
                        </Row>
                    }
                </> 
            : 
                <></>
            }
            <Row className='w-100 text-center pt-2'>
                <h2 className='fw-bold'>{data.totalCost ? 'Total Cost: ' + formatDollars(data.totalCost) : 'Book or Save Ad to view final price.'}</h2> 
            </Row>
        </Container>
    )
}

const MobileView = ({data}) => {
    const { userData } = useAuth()
    const { getThemeBG, getTextInv } = useTheme()

    return (
        <Container fluid className='border border-2 rounded-2 py-1'>
            <Row>
                <h4 className='fw-bold text-center'>{data.advertiserName || userData.companyName || 'Advertisement'}: {data.advertisementName}</h4>
            </Row>
            <Row>
                <h6 className='text-center'>{data.startDate.toDate().toLocaleDateString()} - {data.endDate.toDate().toLocaleDateString()}</h6>
            </Row>
            <Row className='text-center pt-1'>
                <Col>
                    <h6 className='fw-bold'>Target Platforms:</h6><h6>{data.platforms.join(', ')}</h6>
                </Col>
                <Col>
                    <h6 className='fw-bold'>Target Impressions:</h6><h6>{data.totalImpressions}</h6>
                </Col>
            </Row>
            <Row className='pt-2'><h6 className='fw-bold text-center'>Advertisement Categories:</h6></Row>
            <Row className='p-1'>{data.buckets.map(bucket => <BucketBadge className={'p-1 my-1 w-50 ' + getThemeBG('primary') + ' ' + getTextInv()} name={bucket} key={bucket} />)}</Row>
            {data.otherBuckets ? <>
                <Row className='pt-2'><h6 className='fw-bold text-center'>Other Categories: </h6></Row>
                <Row><h6>{data.otherBuckets.join(',')}</h6></Row></> 
            : 
                <></>
            }
            <Row className='w-100 text-center py-2'>
                <h6 className='fw-bold'>{'Total Cost: ' + formatDollars(data.totalCost || 0)}</h6> 
            </Row>
        </Container>
    )
}

export default AdSummary