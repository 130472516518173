const generateDiceBearAvataaars = (seed) => `https://api.dicebear.com/7.x/avataaars-neutral/svg?seed=${seed}`;
const generateDiceBearShapes = (seed) => `https://api.dicebear.com/9.x/shapes/svg?seed=${seed}`;

export const generateAvatar = (type) => {
    if (type === 1) {
        return generateDiceBearAvataaars(Math.random());
    }
    else {
        return generateDiceBearShapes(Math.random());
    }
};