import React, { useState } from 'react'
import { Row, Col, Card, Button, Modal, Collapse } from 'react-bootstrap'
import { useAuth } from '../../../../../contexts/authContext'
import { useTheme } from '../../../../../contexts/themeContext'
import { CheckSquareFill, XSquareFill } from 'react-bootstrap-icons'
import formatDollars from '../../../../../utils/formatDollars'

const PotentialAdsCard = () => {
    const { userData } = useAuth()
    const { getTextInv, getThemeBGPlatform, isSmallScreen } = useTheme()

    const [ showMore, setShowMore ] = useState(false)

    return (
        !isSmallScreen && userData.potentialAds && userData.potentialAds.length && !userData.firstTimeUser && !userData.pending ?
        <Card className={'mb-3 shadow ' + getTextInv() + ' ' + getThemeBGPlatform('secondary')} style={{transition: 'color 0.5s, background 0.5s'}}>
            <Card.Header>
                <h3 className='fw-bold'>You have {userData.potentialAds.length} new potential advertisement{userData.potentialAds.length !== 1 && 's'}{userData.potentialAds.length ? '!' : '.'}</h3>
            </Card.Header>
            <Card.Body className='ms-0 px-0'>
                <Row className='ps-2'>
                    <Col>Advertiser Name</Col>
                    <Col>Advertisement Name</Col>
                    <Col>Required Impressions</Col>
                    <Col>Potential Earnings</Col>
                    <Col>Ad Posted Date</Col>
                    <Col>Decline/Accept</Col>
                </Row>
                { userData.potentialAds.slice(0,3).map( (ad, index) => (
                    <PotentialAdRow ad={ad} key={index} />
                ))}
                { userData.potentialAds.length > 3 && <>
                    <Collapse in={showMore}>
                        <div>
                            { userData.potentialAds.slice(3, userData.potentialAds.length).map( (ad, index) => (
                                <PotentialAdRow ad={ad} key={index} isLast={index === userData.potentialAds.length - 4} />
                            ))}
                        </div>
                    </Collapse>
                    <Button className='border-0 btn-sm bg-transparent' onClick={() => setShowMore(!showMore)}>
                        + show {userData.potentialAds.length - 3} {showMore ? 'less' : 'more'}
                    </Button>
                </>}
            </Card.Body>
        </Card>
        :
        <></>
    )
}

const PotentialAdRow = (props) => {
    const { acceptAd, declineAd, setSuccess, setError } = useAuth()
    const { darkMode, getTextInv, getThemeBGPlatform, getButtonThemeStyle } = useTheme()

    const [show, setShow] = useState(false)
    const [accept, setAccept] = useState(true)

    const startDate = props.ad.startDate.toDate()
    const completionDate = new Date(startDate)

    const showAccept = () => {
        setAccept(true)
        setShow(true)
    }

    const showDecline = () => {
        setAccept(false)
        setShow(true)
    }

    const handleDecision = async () => {
        setError('')
        setSuccess('')
        try {
            if ( accept ) {
                await acceptAd(props.ad.id, props.ad.quota, props.ad.channel)
                setSuccess('An email will be sent to you with further details!')
            }
            else {
                await declineAd(props.ad.id, props.ad.channel)
                setSuccess('You will no longer be offered this Advertisement')
            }
        } catch (err) {
            setError('Failed to process your decision')
        } finally {
            setShow(false)
        }
    }

    return (
        <Row className='ps-4' >
            <div className={'border-top border-2 mt-2 border-' + (darkMode ? 'dark' : 'white')} style={{transition: 'border 0.5s', width: '98%'}}/>
            <Col className='pt-2'>{props.ad.advertiserName}</Col>
            <Col className='pt-2'>{props.ad.advertisementName}</Col>
            <Col className='pt-2'>{props.ad.quota}</Col>
            <Col className='pt-2'>{formatDollars(props.ad.potentialEarnings)}</Col>
            <Col className='pt-2'>{startDate.toLocaleDateString()}</Col>
            <Col>
                <Button className='btn-sm bg-transparent border-0 p-0 m-0' onClick={() => showDecline()}>
                    <XSquareFill className='my-text-danger fs-2 mt-1 me-3'/>
                </Button>
                <Button className='btn-sm bg-transparent rounded-0 border-0 p-0 m-0' onClick={() => showAccept()}>
                    <CheckSquareFill className='my-text-success fs-2 mt-1 ms-4'/>
                </Button>
            </Col>
            <Modal show={show} onHide={() => setShow(false)} className={'modal-lg ' + getTextInv()}>
                <Modal.Header className={'fs-5 fw-bold ' + getThemeBGPlatform('secondary')} closeButton>
                    {props.ad.advertiserName}: {props.ad.advertisementName}
                </Modal.Header>
                <Modal.Body className={'my-0 py-0 ' + getThemeBGPlatform('secondary')}>
                    <ul>
                        <li>{props.ad.description}</li>
                        <li>Advertisement was requested on {startDate.toLocaleDateString()}, with a targeted completion date of {(completionDate.setMonth(startDate.getMonth() + 1)) && completionDate.toLocaleDateString()}</li>
                        <li>Your required impressions for this advertisement would be {props.ad.quota} impressions</li>
                        <li>Your potential earnings for this advertisement would be {formatDollars(props.ad.potentialEarnings)}</li>
                    </ul>
                    <Row className='d-flex justify-content-center mt-3 mb-0 pb-0 fs-5 fw-bold'>Are you sure you want to {accept ? 'accept' : 'decline'} this Advertisement?</Row>
                </Modal.Body>
                <Modal.Footer className={'d-flex justify-content-between my-0 py-0 ' + getThemeBGPlatform('secondary')}>
                    <Button className={getButtonThemeStyle('md', 'danger')} onClick={() => setShow(false)}>No, I'm not sure yet</Button>
                    <Button className={getButtonThemeStyle('md', 'success')} onClick={() => handleDecision()}>{accept ? "Yes, let's get started!" : "Yes, I want to decline"}</Button>
                </Modal.Footer>
            </Modal>
        </Row>
    )
}

export default PotentialAdsCard;