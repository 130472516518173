import React, {useEffect, useState} from "react";
import { useTheme } from "../../../../../contexts/themeContext";
import AtAGlance from "../../../../userHome/profile/subcomponents/atAGlance";

const ProductDemo = () => {
    const {darkMode, isSmallScreen} = useTheme()
    const [userTypeSwitch, setUserTypeSwitch] = useState(false)
    const [isHovering, setIsHovering] = useState(false)

    const getHoverColor = () => {
        if (isHovering) {
            if (userTypeSwitch) {
                return 'my-bg-info-dark'
            } else if (!userTypeSwitch) {
                return 'my-bg-primary'
            } else {
                return ''
            }
        } else {
            return 'my-bg-primary-light'
        }
    }

    useEffect(() => {
        const displaySwitch = document.getElementById('switchButton')
        if (displaySwitch){
            if (userTypeSwitch === true) {
                displaySwitch.style.transform = 'translateX(0)'
            } else {
                displaySwitch.style.transform = 'translateX(-100%)'
            }
        }
        
    },[userTypeSwitch])

    return (
        <>
        {isSmallScreen ? <>
            <div className='d-flex align-content-center justify-content-center my-3'>
                <div className={'d-flex rounded-pill w-25 my-bg-' + (userTypeSwitch ? 'primary' : 'info-dark')} style={{transition: 'background 0.2s', justifyContent: 'end'}}>
                    <div id='switchButton' onMouseEnter={() => {setIsHovering(true)}} onMouseLeave={() => {setIsHovering(false)}} onClick={() => setUserTypeSwitch(!userTypeSwitch) || setIsHovering(false)} 
                        className={'btn btn-xsm rounded-pill w-50 my-text-dark border-3 border-dark ' + getHoverColor()}  
                        style={{transition: 'transform 0.2s, background 0.3s', height:'50px'}}>

                        <p className='d-flex align-items-center justify-content-center arvo-bold fs-2 text-center text-nowrap overflow-hidden'>
                            {userTypeSwitch ? 'C' : 'M'}
                        </p>
                    </div>
                </div>
            </div>
            <AtAGlance isDemo={true} viewType={userTypeSwitch ? 1 : 0}/> 
            </>
            : 
            <div id='atAGlanceDemoParent' className={'d-flex justify-content-center mx-0 p-5 mb-5 my-bg-' + (!darkMode ? '' : 'secondary-darkmode-dark')} style={{ scrollSnapStop: 'always', position: 'relative', transition: 'background 0.5s'}}>
                <div className='px-5' style={{width: '90%'}}>
                    <h1 className={'arvo-bold text-center fw-bold pb-1 ' + (userTypeSwitch ? 'my-text-primary' : 'my-text-info-dark')} style={{fontSize: '4.2rem'}} >
                        Key Metrics 
                    </h1>
                    <h3 className={'arvo-bold text-center fw-bold pb-2 fs-1 ' + (userTypeSwitch ? 'my-text-primary' : 'my-text-info-dark')}>
                        At Your Fingertips                    
                    </h3>
                    <div className='d-flex align-content-center justify-content-center my-3'>
                        <div className={'d-flex rounded-pill w-25 my-bg-' + (userTypeSwitch ? 'primary' : 'info-dark')} style={{transition: 'background 0.2s', justifyContent: 'end'}}>
                            <div id='switchButton' onMouseEnter={() => {setIsHovering(true)}} onMouseLeave={() => {setIsHovering(false)}} onClick={() => {setUserTypeSwitch(!userTypeSwitch); setIsHovering(false)}} 
                                className={'btn btn-xsm rounded-pill w-50 align-content-center justify-content-center my-text-dark border-3 border-dark ' + getHoverColor()}  
                                style={{transition: 'transform 0.2s, background 0.3s', height:'50px'}}>

                                <p className='d-flex align-items-center justify-content-center position-relative arvo-bold fs-2 text-center text-nowrap h-100'>
                                    {userTypeSwitch ? 'C' : 'M'}
                                </p>
                            </div>
                        </div>
                    </div>
                    <AtAGlance isDemo={true} viewType={userTypeSwitch ? 1 : 0}/>
                </div>
            </div>}
            </>
    )
}

export default ProductDemo