import React, { useEffect, useState } from 'react'
import { Row, Col, Card } from 'react-bootstrap'
import { useAuth } from '../../../../contexts/authContext'
import { useTheme } from '../../../../contexts/themeContext'

const ChannelDetailCard = (props) => {
    const { userData } = useAuth()

    const {isSmallScreen, getCardStyle} = useTheme()

    const [channel, setChannel] = useState({})

    useEffect(() => {
        if (props.selected)
            setChannel(userData.channels.find(channel => channel.platform === props.selected.split(':')[0] && channel.handle === props.selected.split(':')[1]) || {})
    }, [userData.channels, props.selected, setChannel])

    return (
        <Card className={getCardStyle()} style={{minHeight: (isSmallScreen ? 'fit-content' : '300px')}}>
            <Card.Header className='py-3'>
                <p className='m-0 fw-bold'>Channel Detail</p>
            </Card.Header>
            {props.selected ?
                <Card.Body>
                    <Row className='pb-2'><h3 className='fw-bold'>Video Engagement Statistics</h3></Row>
                    <Row className='text-center'>
                        <Col>
                            <Row><h5 className='fw-bold'>Total Views</h5></Row>
                            <Row><h4>{Number(channel.viewCount).toLocaleString()}</h4></Row>
                        </Col>
                        <Col>
                            <Row><h5 className='fw-bold'>Average Views</h5></Row>
                            <Row><h4>{channel.videoCount ? Math.round(channel.viewCount / channel.videoCount).toLocaleString() : 0}</h4></Row>
                        </Col>
                        <Col>
                            <Row><h5 className='fw-bold text-nowrap'>Average Views on last 5 Videos</h5></Row>
                            <Row><h4>{channel.lastNVideos && channel.lastNVideos.length ? Math.round(channel.lastNVideos.reduce((acc, obj) => acc + Number(obj.viewCount), 0) / channel.lastNVideos.length).toLocaleString() : 0}</h4></Row>
                        </Col>
                        <Col>
                            <Row><h5 className='fw-bold text-nowrap'>Projected Views on Next Video</h5></Row>
                            <Row><h4>{channel.ppi ? channel.ppi.toLocaleString() : 'Unavailable'}</h4></Row>
                        </Col>
                    </Row>
                    <Row className='pt-3 pb-2'><h3 className='fw-bold'>Channel Statistics</h3></Row>
                    <Row className='text-center'>
                        <Col>
                            <Row><h5 className='fw-bold'>Subscriber Count</h5></Row>
                            <Row><h4>{Number(channel.subscriberCount).toLocaleString()}</h4></Row>
                        </Col>
                        <Col>
                            <Row><h5 className='fw-bold'>Total Videos</h5></Row>
                            <Row><h4>{Number(channel.videoCount).toLocaleString()}</h4></Row>
                        </Col>
                        <Col>
                            <Row><h5 className='fw-bold text-nowrap'>Videos With Fiable Supplied Ad</h5></Row>
                            <Row><h4>{channel.adContent ? Object.keys(channel.adContent).length : 0}</h4></Row>
                        </Col>
                        <Col>
                            <Row><h5 className='fw-bold'>Fiable Channel Grade</h5></Row>
                            <Row><h4>A (Excellent)</h4></Row>
                        </Col>
                    </Row>
                </Card.Body>
            :
                <Card.Body>
                    <h3 className='text-center py-5 my-3'>{userData.channels.length ? 'Select a Channel above to view details' : 'Link Your Channel to view details'}</h3>
                </Card.Body>
            }
            
        </Card>
    )
}

export default ChannelDetailCard