import React, { useEffect, useState } from 'react'
import { Clipboard2Data, Display, List, Person, Wallet2, FileEarmarkSpreadsheet, BoxArrowLeft, Calendar2Check, FilePlus, BadgeAd, Trophy, People } from 'react-bootstrap-icons'
import { Link, useNavigate } from 'react-router-dom'
import { useAuth } from '../../contexts/authContext'
import { useTheme } from '../../contexts/themeContext'
import { Button, Col, Collapse, Row  } from 'react-bootstrap'
import ColorThemeToggle from '../header/colorThemeToggle'

const SideNavbar = () => {
    const { currentUser, userData, type, logout, setError } = useAuth()
    const { darkMode, getTextInv, isSmallScreen } = useTheme()
    const navigate = useNavigate()
    const color = darkMode ? 'dark' : 'secondary-light'
    const [isOpen, setIsOpen] = useState(!isSmallScreen)

    // type 0 link is for advertiser, type 1 is for creator, type 2 for both, type 3 dont show on mobile, type 4 dont show at all
    const links = ([
        {path: '/dashboard', name: 'Dashboard', icon: <Clipboard2Data />, type: 2},
        {path: '/channel', name: 'Channel', icon: <Display />, type: 1}, 
        {path: '/advertisements', name: 'Advertisements', icon: <BadgeAd />, type: 2},
        {path: '/create-ad', name: 'Create an Ad', icon: <FilePlus />, type: (isSmallScreen ? 3 : 0)},
        {path: '/payment', name: 'Payment', icon: <Wallet2 />, type: 2},
        {path: '/calendar', name: 'Calendar', icon: <Calendar2Check />, type: (isSmallScreen ? 3 : 0)},
        {path: '/social', name: 'Social', icon: <People />, type: (isSmallScreen ? 3 : 1)},
        {path: '/profile', name: 'Profile', icon: <Person />, type: 2}, 
        {path: '/history', name: 'History', icon: <FileEarmarkSpreadsheet />, type: (isSmallScreen ? 3 : 2)},
        {path: '/achievements', name: 'Medals', icon: <Trophy />, type: (userData && userData.socialCode && !isSmallScreen ? 1 : 4)},
    ].filter(link => link.type === type || link.type === 2))

    async function handleLogout() {
        try {
            window.scrollTo(0, 0)
            navigate('/')
            await logout()
        } catch {
            setError('Failed to log out')
        }
    }

    useEffect(() => {
        if (isSmallScreen) setIsOpen(false)
    }, [isSmallScreen])

    return (
        <div className={'bg-transition my-bg-' + color} style={{display: 'flex', height: '100vh', width: (isOpen ? '188px' : '48px'), transition: 'all 0.5s ease'}}>
            <Row className='d-flex flex-row' style={{position: 'fixed'}}>
                <Col className={'d-flex flex-column pt-2 pe-0 bg-transition my-bg-' + color} style={{minHeight: '100vh', width: '50px'}}>
                    {isSmallScreen ? <></> :
                    <Button className={'bg-transparent border-0 m-0 p-0 rounded-0 text-transition ' + getTextInv()} onClick={() => setIsOpen(!isOpen)}>
                        <div className='mt-3 bs-icon bs-icon-md my-text-primary'>
                            <List className='fs-5' style={{marginLeft: '-4px'}} />
                        </div>
                    </Button>}
                    <div className='pt-1'>
                        {links.map((link, index) => (
                            <div style={{borderLeft: (window.location.pathname === link.path && '8px solid var(--my-primary)'), transition: 'all 0.3s'}} key={index}>
                                <div style={{transition: 'all 0.3s', marginLeft: '-4px'}}>
                                    <NavBarLink link={link} />
                                </div>
                            </div>
                        ))}
                    </div>
                    <div className='h-100 d-flex flex-column justify-content-end' style={{marginLeft: '-4px'}}>
                        <ColorThemeToggle />
                        <Button className={'bg-transparent border-0 m-0 p-0 text-transition ' + getTextInv()} onClick={handleLogout}>
                            <div className='bs-icon bs-icon-md my-text-primary text-nowrap overflow-hidden'>
                                <BoxArrowLeft className='fs-5' style={{marginLeft: '4px'}} />
                            </div>
                        </Button>
                    </div>
                </Col>
                <Col className={'d-flex flex-column pt-4 pe-2 ps-0 bg-transition my-bg-' + color} style={{minHeight: '100vh'}}>
                    <Collapse dimension='width' in={isOpen} style={{transition: 'all 0.5s ease'}}>
                        <div>
                            <div 
                                className={'fw-bold text-transition ' + getTextInv()} 
                                style={{width: '122px', paddingTop: '12px', paddingBottom: '11px', whiteSpace: 'nowrap', textOverflow: 'ellipsis', overflow: 'hidden'}}
                            >
                                {currentUser.displayName ? currentUser.displayName : 'User Home'}
                            </div>
                            <div className='pt-1'>
                                {links.map((link, index) => (
                                    <div key={index}>
                                        <NavBarLink showText link={link} />
                                    </div>
                                ))}
                            </div>
                            {/* <div className='h-100 d-flex flex-column justify-content-end'>
                                <Button className='bg-transparent m-0 p-0 border-0' onClick={handleLogout} style={{height: '82%'}}>
                                    <div>
                                        <p className={'fw-bold pt-1 ' + getTextInv()} style={{transition: 'color 0.5s'}}>Log Out</p>
                                    </div>
                                </Button>
                            </div> */}
                        </div>
                    </Collapse>
                </Col>
            </Row>
        </div>
    )
}

const NavBarLink = (props) => {
    const {getTextInv} = useTheme()

    return (
        <Link to={props.link.path}>
            <div className={'d-flex flex-row align-items-center text-nowrap text-transition ' + getTextInv() + ' ' + (props.showText ? 'fw-bold ps-2': 'bs-icon bs-icon-md fs-5')} style={{height: '50px', fontSize: (props.showText && '14px')}}>
                {props.showText ? props.link.name : props.link.icon}
            </div>
        </Link>
    )
}

export default SideNavbar