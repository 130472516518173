import React, { useCallback, useEffect, useRef, useState } from 'react'
import { useTheme } from '../../../../../contexts/themeContext'
import { Button } from 'react-bootstrap'
import Medal from './medals'
import MedalModal from './medalModal'

const MedalView = ({ medalId, medalData=null, size, clickable=true, isCurrentUser=true }) => {
    const {screenWidth, screenHeight} = useTheme()

    const medalRef = useRef()
    const [showMedalDetails, setShowMedalDetails] = useState(false)

    const triggerMedalAnimation = useCallback(() => {
        const rect = medalRef.current.getBoundingClientRect()
        if (showMedalDetails) {
            const translateX = Math.floor(screenWidth / 2) - (rect.width + rect.left + (size > 100 ? size - 97 : 0))
            const translateY = Math.floor(screenHeight / 2) - (rect.height + rect.top + (size > 100 ? size - 110 : 0))

            medalRef.current.style.transform = `translate(${translateX - (115 - size)}px, ${translateY - (100 - size)}px) rotateY(360deg)`
            medalRef.current.style.width = '120px'
            medalRef.current.style.height = '120px'
            medalRef.current.style.zIndex = 1500
        } else {
            medalRef.current.style.transform = `translate(-50%, -20%)`
            medalRef.current.style.width = size + 'px'
            medalRef.current.style.height = size + 'px'
            medalRef.current.style.zIndex = 0
        }

        setTimeout(() => {
            if (medalRef.current) medalRef.current.style.transition = 'all 0.5s linear'
        }, 100)
    }, [showMedalDetails, medalRef, screenWidth, screenHeight, size])

    useEffect(() => {
        // update medal styles and apply animation if requested
        if (medalRef.current)
            triggerMedalAnimation()
    }, [medalRef, triggerMedalAnimation])

    return (
        <div>
            <Button className='border-0 bg-transparent m-0 p-0' onClick={() => setShowMedalDetails(true)} disabled={!clickable} style={{opacity: 1}}>
                <Medal medalId={medalId} medalData={medalData} size={size} ref={medalRef} />
            </Button>
            <MedalModal medalId={medalId} medalData={medalData} show={showMedalDetails} setShow={setShowMedalDetails} customTransition isCurrentUser={isCurrentUser} />
        </div>
    )
}

export default MedalView