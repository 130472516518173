import React, { useState } from 'react'
import { Card, Row, Col, Button } from 'react-bootstrap'
import { useTheme } from '../../../../../contexts/themeContext'
import formatDollars from '../../../../../utils/formatDollars'
import { useAuth } from '../../../../../contexts/authContext'

const PaymentDetails = (props) => {
    const {userData, currentUser, updateUserData, sendEmail, setError, setSuccess} = useAuth()
    const {getButtonStyle, getCardStyle} = useTheme()

    const [loading, setLoading] = useState(false)
    const [awaitingWithdrawal, setAwaitingWithdrawal] = useState(userData.awaitingWithdrawal || false)

    const requestWithdrawal = async () => {
        setLoading(true)
        try {
            await updateUserData({awaitingWithdrawal: true})
            await sendEmail('fiable@fiable-solutions.com', 'A Creator requested a withdrawal', `Creator ${currentUser.uid} just requested a withdrawal for ${formatDollars(props.available)}.`)
            setSuccess('Withdrawal requested! We will process your request in 3-5 business days.')
        } catch (err) {
            setError('Failed to request withdrawal. Try again soon')
        } finally {
            setAwaitingWithdrawal(true)
            setLoading(false)
        }
    }

    return (
        <Card className={getCardStyle()}>
            <Card.Header>
                <Card.Title className='fw-bold'>
                    Payment Details
                </Card.Title>
            </Card.Header>
            <Card.Body>
                <Row>
                    <Col lg={8}>
                        <Card className={'h-100 text-center ' + getCardStyle()}>
                            <Card.Header className='border-0'>
                                <Card.Title>Transfer Details</Card.Title>
                            </Card.Header>
                            <Card.Body className='pb-0 mb-0'>
                                <h2><sup>*</sup>{userData.awaitingWithdrawal ? 'Awaiting' : 'Avaliable to'} Transfer:</h2>
                                <h1 className='mb-4'>{formatDollars(props.available)}</h1>
                            </Card.Body>
                            <Card.Footer>
                                <small><sup>*</sup>Fiable reserves the right to deny any withdrawal request. Valid reasons for denial include, incorrect calculation of account balance, improper validation of services rendered, improper advertisement implementation, or invalid creator account details.</small>
                            </Card.Footer>
                        </Card>
                    </Col>
                    <Col lg={4} className='py-3 d-flex justify-content-center'>
                        <Button disabled={props.available < 25 || awaitingWithdrawal || loading} className={getButtonStyle('lg')} onClick={() => requestWithdrawal()}>
                            {awaitingWithdrawal ? 'Processing Withdrawal' : 'Request Withdrawal'}
                        </Button>
                    </Col>
                </Row>
            </Card.Body>
        </Card>
    )
}

export default PaymentDetails