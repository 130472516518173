import React from 'react';
import { Row } from 'react-bootstrap'
import LogIn from './login';
import { useTheme } from '../../contexts/themeContext';
import { Link } from 'react-router-dom'
import ColorThemeToggle from './colorThemeToggle';


const SimpleHeader = () => {
    const { darkMode, isSmallScreen } = useTheme()

    return (
        <div className='px-4 py-0 m-0 position-absolute bg-transparent top-0 w-100' style={{transition: 'background 0.5s', display: (isSmallScreen ? 'none' : '')}}>
            <Row className='d-inline-flex flex-row flex-nowrap w-100'>
                <div className={'align-items-center arvo-bold-italic ' + (darkMode ? 'my-text-primary-light' : 'my-text-dark')} style={{fontSize: '3rem', transition: 'color 0.5s', flex: '1 1 10%'}}>
                    <Link to='/' className='d-inline-flex align-items-center' >
                        <p className={(darkMode ? 'my-text-primary-light' : 'my-text-dark')} style={{transition: 'color 0.5s'}}>
                            Fiable
                        </p>
                    </Link>
                </div>
                <div className='d-inline-flex justify-content-start align-items-center text-center' style={{width:'80%', flex: '1 1 85%'}}>
                    <div className={'mt-3 arvo-regular align-items-center arvo-regular d-flex justify-content-end ' + (darkMode ? 'my-text-primary-light' : 'my-text-dark')} style={{fontSize: '1.2rem', transition: 'color 0.5s', flex: '1 1 15%'}}>
                        <Link to='/solutions' className='d-inline-flex' >
                            <p className={(darkMode ? 'my-text-primary-light' : 'my-text-dark')} style={{transition: 'color 0.5s'}}>
                                Solutions
                            </p>
                        </Link>
                    </div>
                    <div className={'mt-3 arvo-regular align-items-center arvo-regular d-flex justify-content-end ' + (darkMode ? 'my-text-primary-light' : 'my-text-dark')} style={{fontSize: '1.2rem', transition: 'color 0.5s', flex: '1 1 15%'}}>
                        <Link to='/about' className='d-inline-flex' >
                            <p className={(darkMode ? 'my-text-primary-light' : 'my-text-dark')} style={{transition: 'color 0.5s'}}>
                                About
                            </p>
                        </Link>
                    </div>
                    <div className={'mt-3 arvo-regular align-items-center arvo-regular d-flex justify-content-end ' + (darkMode ? 'my-text-primary-light' : 'my-text-dark')} style={{fontSize: '1.2rem', transition: 'color 0.5s', flex: '1 1 13%'}}>
                        <Link to='/blog' className='d-inline-flex ' >
                            <p className={(darkMode ? 'my-text-primary-light' : 'my-text-dark')} style={{transition: 'color 0.5s'}}>
                                Blog
                            </p>
                        </Link>
                    </div>
                    <div className={'mt-3 arvo-regular align-items-end arvo-regular d-flex justify-content-end ' + (darkMode ? 'my-text-primary-light' : 'my-text-dark')} style={{fontSize: '1.2rem', transition: 'color 0.5s', flex: '1 1 17%', verticalAlign: 'middle'}}>
                        <Link to='/contact' className='d-inline-flex' >
                            <p className={(darkMode ? 'my-text-primary-light' : 'my-text-dark')} style={{transition: 'color 0.5s'}}>
                                Contact Us
                            </p>
                        </Link>
                    </div>
                    <div className='arvo-regular d-flex justify-content-end ' style={{flex: '1 1 20%'}}>
                        <Link
                            to='/demo' 
                            className={'shadow arvo-bold btn btn-sm btn-link ref-button border-2 ' + (darkMode ? 'my-text-primary-light' : 'my-text-dark')} 
                            style={{transition: 'all 0.5s', transform: 'scale(100%)', opacity: '1', borderColor: (darkMode ? '#FFEECD' : '#1C1C22')}} 
                            role='button'
                        >
                            Book Demo
                        </Link>
                    </div>
                    <div className='justify-content-end d-flex ms-3' style={{flex: '1 1 20%'}}>
                        <LogIn type={0} />
                        {isSmallScreen ? <div className='pb-2'><ColorThemeToggle /></div> : <ColorThemeToggle />}
                    </div>
                </div>
            </Row>
        </div>
    )
}





export default SimpleHeader