import React from 'react'
import { Card, Image } from 'react-bootstrap'
import { useTheme } from '../../../../contexts/themeContext'

const SocialSnippet = ({ socialData }) => {
    const {getCardStyle} = useTheme()
    
    return (
        <Card className={'d-flex text-center justify-content-center align-items-center my-3 p-0 ' + getCardStyle()} style={{width: '250px', height: '250px'}}>
            <Card.Body style={{width: 'inherit', height: 'inherit'}}>
                <Image width={100} height={100} roundedCircle src={socialData.photoURL} className='mt-3' />
                <h4 className='fw-bold'>{socialData.socialCode.length > 12 ? socialData.socialCode.slice(0, 12) + '...' : socialData.socialCode}</h4>
                <h5>Member since {socialData.creationDate.toDate().toLocaleDateString()}</h5>
            </Card.Body>
        </Card>
    )
}

export default SocialSnippet