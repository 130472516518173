import React from 'react'
import { Card, Col, Row } from 'react-bootstrap'
import { useTheme } from '../../../../../contexts/themeContext'
import formatDollars from '../../../../../utils/formatDollars'

const EarningsCard = (props) => {
    const {getCardStyle} = useTheme()
    
    return (
        <Card className={'h-100 ' + getCardStyle()} style={{transition: 'background 0.5s, color 0.5s'}}>
            <Card.Header className='border-0'>
                <Card.Title className='text-end text-wrap'>
                    <h3>{props.title}</h3>
                </Card.Title>
            </Card.Header>
            <Card.Body>
                {props.rows.length ? 
                    <table className='w-100'>
                        <tbody>
                            {props.rows.map((row, index) => (
                                <tr key={index}>
                                    <td className='text-start pb-1'>{row.start ? <small>Started on {row.start}</small> : <></>}<h5>{row.text}</h5></td>
                                    <td className='text-end'><h5 className='fw-bold'>{formatDollars(row.value)}</h5></td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                :
                    <h3 className='my-3 text-center'>No {props.title.split(' ')[0]} Data</h3>
                }
            </Card.Body>
            <Card.Footer>
                <Row>
                    <Col className='d-flex align-items-center'><h5 className='fw-bold'>{props.footer}</h5></Col>
                    <Col><h3 className='text-end'>{formatDollars(props.footerValue)}</h3></Col>
                </Row>
            </Card.Footer>
        </Card>
    )
}

export default EarningsCard