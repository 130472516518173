import React, { useCallback, useEffect, useState } from 'react'
import { useAuth } from '../../../../../contexts/authContext'
import AdTable from '../adTable'
import { useTheme } from '../../../../../contexts/themeContext'

const CreatorsWorkedWithTable = () => {
    const { userData, getChannelData } = useAuth()
    const {screenWidth} = useTheme()

    const [loading, setLoading] = useState(true)
    const [creatorData, setCreatorData] = useState([])

    const cols = ['Creator Name', 'Platform', 'Most Recent Ad', 'Times Worked With', 'Followers', 'Average Views'].concat((screenWidth < 1200 ? [] : ['Ad Types Used', 'Grade']))

    const fetchCreatorData = useCallback(async () => {
        let activeData = Object.keys(userData.history)
        activeData = activeData.filter(ad => !userData.history[ad].isPotential)
        activeData = activeData.filter(ad => userData.history[ad].creators && userData.history[ad].creators.length)
        activeData = activeData.sort((a, b) => userData.history[b].startDate - userData.history[a].startDate)

        const result = []
        for ( const ad of activeData) {
            for (const channel of userData.history[ad].creators) {
                if (!result.map(val => val.platform + '-' + val.handle).includes( channel )) {
                    const currChannel = await getChannelData(channel)
                    const [platform, handle] = [currChannel.id.split('-')[0], currChannel.id.split('-').slice(1).join('-')]
                    const currData = currChannel.data() || {}
                    let tableData = {
                        handle: handle,
                        platform: platform,
                        mostRecentAd: currData.adContent && currData.adContent[ad] ? userData.history[ad].advertisementName : 'Not Found',
                        timesWorkedWith: 1,
                        followers: currData.subscriberCount ? currData.subscriberCount : 0,
                        avgViews: currData.viewCount && currData.videoCount ? Math.round(currData.viewCount / (currData.videoCount > 0 ? currData.videoCount : 1)) : 0,
                    }
                    if (screenWidth > 1200) {
                        tableData.adTypes = (currData.adTypes ? currData.adTypes.join(', ') : 'Link')
                        tableData.grade = 'A (great)'
                    }
                    result.push(tableData)
                } else {
                    result.find(data => data.platform + '-' + data.handle === channel).timesWorkedWith += 1
                }
            }
        }
        setCreatorData(result)
    }, [userData, getChannelData, screenWidth])

    useEffect(() => {
        fetchCreatorData()
        setLoading(false)
    }, [fetchCreatorData, setLoading])

    return (
        !loading && <AdTable title='Creators Worked With' tableCols={cols} data={creatorData} bgTheme='primary' />
    )
}

export default CreatorsWorkedWithTable