import React from 'react';
import { CartesianGrid, Line, LineChart, Tooltip, XAxis, YAxis } from 'recharts';
import formatDollars from '../../../../utils/formatDollars';

const LineGraph = ({lineData=[], width=475, height=400, lineColor='#8884d8', monetary=false}) => {
    return (
        <LineChart width={width} height={height} data={lineData}>
            <CartesianGrid strokeDashArray='2 2' />
            <XAxis dataKey='name' />
            <YAxis />
            {monetary ? <Tooltip formatter={(value, _) => formatDollars(value)} /> : <Tooltip />}
            <Line type='monotone' dataKey='value' stroke={lineColor} activeDot={{ r: 6 }} strokeWidth={3} />
        </LineChart>
    )
}

export default LineGraph