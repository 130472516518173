import React, { useState } from 'react'
import { Button, Col, Container, Image, Row, Modal, Card } from 'react-bootstrap'

import img1 from '../../../assets/img/products/collaboration.jpg'
import img2 from '../../../assets/img/products/digital_ads.jpg'
import img3 from '../../../assets/img/products/data_analysis.jpg'
import img4 from '../../../assets/img/products/handshake.jpg'

import networkImg from '../../../assets/img/products/creatorNetwork1.jpg'
import productImg from '../../../assets/img/products/digitalProductPlacement1.jpg'
import insightsImg from '../../../assets/img/products/realTimeInsights1.jpg'
import synergiesImg from '../../../assets/img/products/creativeSynergies.jpg'

import PageHeader from '../../common/pageHeader'
import BookDemo from '../../common/bookDemo'
import { useTheme } from '../../../contexts/themeContext'

export const creatorNetworkSummary = "We harness the power of our extensive network of content creators to amplify your brand's message with innovative advertising. Through strategic partnerships and meticulous targeting, we seamlessly integrate your advertisements into the content of like-minded creators, ensuring maximum exposure to your target audience across various platforms. With our innovative approach, your brand gains unparalleled visibility and engagement, driving measurable results and fostering long-term brand loyalty."
const creatorNetworkTitle = "Creator Network"
export const creatorNetworkShortSummary = "Fiable leverages its extensive network of content creators to seamlessly integrate advertisements into like-minded creators' content, ensuring maximum exposure and fostering long-term brand loyalty."
const creatorNetworkDescription = "Through Fiable's Creator Network, we strategically partner with a diverse array of content creators whose audiences align with your target demographic, seamlessly integrating your brand's advertisements into their content. Our meticulous targeting ensures that your message reaches the right audience at the right time, across various platforms including YouTube, Twitch, and more. By harnessing the authentic influence of these creators, we amplify your brand's message, driving unparalleled visibility, engagement, and ultimately, fostering long-term brand loyalty among your audience. This innovative approach not only maximizes the impact of your advertising campaigns but also establishes meaningful connections between your brand and your target audience, leading to sustainable growth in the digital landscape."

export const digitalAdsSummary = "We redefine advertising through our cutting-edge digital advertising. Say goodbye to intrusive interruptions and hello to seamless integration. Our solution strategically places your digital ads within the backdrop of online content, ensuring subtle yet effective brand exposure that seamlessly blends with the viewer's experience. By seamlessly integrating your brand into the fabric of the content, we captivate audiences while maximizing brand visibility and engagement without disrupting or interrupting the viewer's journey."
const digitalAdsTitle = "Digital Advertising"
export const digitalAdsShortSummary = "Fiable's Digital Advertising seamlessly integrates brand advertisements into online content, captivating audiences and maximizing brand visibility without disrupting the viewer's experience."
const digitalAdsDescription = "Fiable's Digital Advertising revolutionizes traditional advertising methods by seamlessly integrating brand advertisements into the backdrop of online content. Through meticulous placement and strategic alignment with the content's context, we ensure that your brand receives subtle yet effective exposure that seamlessly blends with the viewer's experience. By seamlessly integrating your brand into the fabric of the content, we captivate audiences and maximize brand visibility and engagement without disrupting the viewer's journey. This innovative approach not only enhances brand recall and recognition but also fosters positive associations between your brand and the content, leading to increased audience engagement and loyalty over time. With Fiable's digital product placement service, say goodbye to intrusive interruptions and hello to a new era of seamless brand integration in the digital landscape."

export const insightsSummary = "We empower advertisers and creators alike with our real-time advertising insights platform. Gain invaluable access to comprehensive data and analytics that provide actionable insights into campaign performance and audience engagement. Our innovative platform offers a dynamic dashboard where advertisers can make data-driven decisions in real-time. Creators also benefit from valuable insights, enabling them to tailor content to better resonate with their audience and maximize revenue opportunities."
const insightsTitle = "Real-Time Insights"
export const insightsShortSummary = "Fiable's Real-Time Insights empowers advertisers and creators with comprehensive data and analytics, enabling them to make informed, data-driven decisions to optimize campaign performance and audience engagement."
const insightsDescription = "Fiable's Real-Time Insights serves as a cornerstone for advertisers and creators, providing invaluable access to comprehensive data and analytics that offer actionable insights into campaign performance and audience engagement. Through our innovative platform, advertisers gain access to a dynamic dashboard where they can monitor campaign metrics in real-time and make informed decisions to optimize performance and maximize ROA. Similarly, creators benefit from valuable insights that enable them to tailor their content to better resonate with their audience and capitalize on revenue opportunities. By leveraging these real-time insights, both advertisers and creators can stay agile, adapt to evolving trends, and drive meaningful results in today's dynamic digital landscape. With Fiable's real-time insights service, unlock the power of data-driven decision-making to drive success and growth in your advertising endeavors."

export const synergiesSummary = "We foster creative synergies between advertisers and content creators through our AI-driven matchmaking service. Our innovative technology analyzes audience demographics, interests, and behaviors to identify the perfect alignment between advertisers and creators whose audiences represent high-value potential customers. By leveraging this intelligent matchmaking process, advertisers can tap into the authenticity and influence of content creators to effectively reach and resonate with their target audience."
const synergiesTitle = "Creative Synergies"
export const synergiesShortSummary = "Fiable's Creative Synergies utilizes AI-driven matchmaking to foster collaborations between advertisers and content creators, enabling targeted engagement with high-value potential customers."
const synergiesDescription = "Fiable's Creative Synergies are at the forefront of forging dynamic collaborations between advertisers and content creators, leveraging innovative AI-driven matchmaking technology to identify perfect alignments between brands and creators whose audiences represent high-value potential customers. Through meticulous analysis of audience demographics, interests, and behaviors, our intelligent matchmaking process ensures that advertisers can tap into the authenticity and influence of content creators to effectively reach and resonate with their target audience. By facilitating these creative synergies, Fiable empowers brands to amplify their message, drive engagement, and cultivate long-lasting connections with their audience, ultimately leading to increased brand loyalty and sustainable growth in the digital realm. With Fiable's Creative Synergies service, unlock the potential for meaningful collaborations that propel your advertising efforts to new heights of success."

const Solutions = () => {
    const { darkMode, getThemeTextInv } = useTheme()

    const [show1, setShow1] = useState(false)
    const [show2, setShow2] = useState(false)
    const [show3, setShow3] = useState(false)
    const [show4, setShow4] = useState(false)

    return (
        <section className='py-5'>
            <PageHeader>
                <h1 className={'fw-bold arvo-bold ' + (darkMode ? 'my-text-primary-light' : 'my-text-dark')} style={{fontSize: '64px'}}>Our Solutions</h1>
                <h3 className={'fw-bold arvo-bold-italic ' + getThemeTextInv('primary')}>What we can do for you</h3>
            </PageHeader>
            <Container fluid className='pt-2 pb-4 px-5'>
                <Row md={2} className={'py-2 my-1 border rounded border-' + (darkMode ? 'light' : 'dark')}>
                    {window.innerWidth < 750 ? <></> : 
                    <Col className='text-center'>
                        <Image fluid rounded className='shadow' src={networkImg}/>
                    </Col>
                    }
                    <Col className='d-flex justify-content-center align-items-center mb-5'>
                        <SolutionCard title={creatorNetworkTitle} text={creatorNetworkSummary} setShow={setShow1} />
                        <SolutionModal show={show1} setShow={setShow1} title={creatorNetworkTitle} summary={creatorNetworkShortSummary} description={creatorNetworkDescription} img={img1} />
                    </Col>
                </Row>
                <Row md={2} className='py-2 my-1 '>
                    <Col className='d-flex justify-content-center align-items-center '>
                        <SolutionCard title={synergiesTitle} text={synergiesSummary} setShow={setShow4} />
                        <SolutionModal show={show4} setShow={setShow4} title={synergiesTitle} summary={synergiesShortSummary} description={synergiesDescription} img={img4} />
                    </Col>
                    {window.innerWidth < 750 ? <></> : 
                    <Col className='text-center'>
                        <Image fluid rounded className='shadow' src={synergiesImg}/>
                    </Col>
                    }
                </Row>
                <Row md={2} className={'py-2 my-1 border rounded border-' + (darkMode ? 'light' : 'dark')}>
                    {window.innerWidth < 750 ? <></> : 
                    <Col className='text-center'>
                        <Image fluid rounded className='shadow' src={productImg}/>
                    </Col>
                    }
                    <Col className='d-flex justify-content-center align-items-center mb-5'>
                        <SolutionCard title={digitalAdsTitle} text={digitalAdsSummary} setShow={setShow2} />
                        <SolutionModal show={show2} setShow={setShow2} title={digitalAdsTitle} summary={digitalAdsShortSummary} description={digitalAdsDescription} img={img2} />
                    </Col>
                </Row>
                <Row md={2} className='py-2 my-1 mb-4'>
                    <Col className='d-flex justify-content-center align-items-center mb-5'>
                        <SolutionCard title={insightsTitle} text={insightsSummary} setShow={setShow3} />
                    </Col>
                    <SolutionModal show={show3} setShow={setShow3} title={insightsTitle} summary={insightsShortSummary} description={insightsDescription} img={img3} />
                    {window.innerWidth < 750 ? <></> : 
                    <Col className='text-center'>
                        <Image fluid rounded className='shadow' src={insightsImg}/>
                    </Col>
                    }
                </Row>
                <BookDemo isSmallScreen={(window.innerHeight < 750)}/>
            </Container>
        </section>
    )
}

const SolutionCard = (props) => {
    const { getTextInv, getText, getThemeTextInv, getThemeBGInv } = useTheme()

    return (
        <Card className={'text-center bg-transparent p-3 border-0 pb-0' } style={{transition: 'border 0.5s', maxWidth: '1200px'}}>
            <Card.Header className={'fw-bold bg-transparent border-0 ' + getTextInv()} style={{transition: 'color 0.5s', fontSize: '32px'}}>{props.title}</Card.Header>
            <Card.Body className={(window.innerWidth < 750 ? ' ' : 'mb-4 ') + getThemeTextInv('info')} style={{transition: 'color 0.5s'}}>{props.text}</Card.Body>
            {window.innerWidth < 750 ? <></> : <Button className={'shadow rounded-pill border-0 ' + getText() + ' ' + getThemeBGInv('success')} style={{transition: 'background 0.5s, color 0.5s'}} onClick={() => props.setShow(true)}>Show Me!</Button>}
        </Card>
    )
}

const SolutionModal = (props) => {
    const { getBG, getTextInv, getThemeTextInv } = useTheme()
  
    return (
        <Modal size='xl' centered show={props.show} onHide={() => props.setShow(false)} className='text-center'>
            <Modal.Header className={getBG()} closeButton>
                <Modal.Title className={'fw-bold ' + getTextInv()}>{props.title}</Modal.Title>
            </Modal.Header>
            <Modal.Body className={'d-flex rounded-bottom ' + getBG()}>
                <Image width={250} height={250} rounded src={props.img} className='mt-1' />
                <div>
                    <h5 className={'fw-bold pb-1 ' + getThemeTextInv('info')}>{props.summary}</h5>
                    <p className={getThemeTextInv('info')}>{props.description}</p>
                </div>
            </Modal.Body>
        </Modal>
    )
}

export default Solutions;