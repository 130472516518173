import React, { useEffect, useState } from 'react'
import { Button, Card, Col, Form, Row, Table } from 'react-bootstrap';
import { useTheme } from '../../../../contexts/themeContext';
import { ArrowClockwise } from 'react-bootstrap-icons';

const AdTable = (props) => {
    const { getText, getCardStyle, getTextInv, darkMode } = useTheme() 

    const [data, setData] = useState()
    const [dataLen, setDataLen] = useState()
    const [dataOrder, setDataOrder] = useState( 0 )
    
    const formColor = darkMode ? 'my-bg-secondary-darkmode' : 'my-bg-white'

    const orderData = (keyIndex) => {
        setDataOrder(keyIndex)
        setData(data.sort((a, b) => (Object.values(a)[keyIndex] < Object.values(b)[keyIndex] ? 1 : -1)))
    }

    const resetTable = () => {
        document.getElementById('searchBar').reset()
        setDataLen(props.data.length)
        setDataOrder(0)
        setData(props.data)
    }

    useEffect(() => {
        setData(props.data)
        setDataLen(props.data.length)
    }, [props.data])

    return ( data && 
        <Card className={getCardStyle()}>
            <Card.Header className='py-3'>
                <p className='m-0 fw-bold'>{props.title}</p>
            </Card.Header>
            <Card.Body>
                <Row className='d-flex justify-content-between'>
                    <Col className='d-flex d-inline-flex justify-content-between pt-1'>
                        <div id='table-length' className='d-inline-flex align-items-center'>
                            <Form.Label className={'pt-1'}>Show</Form.Label>&nbsp;
                            <Form.Select size='sm' className={'bg-transition d-inline-flex ' + formColor + ' ' + getTextInv()} value={dataLen} onChange={(e) => setDataLen(e.target.value)} disabled={data.length < 10}>
                                <option value={data.length}>{data.length} (all)</option>
                                {data.length >= 10 && <option value='10'>10</option>}
                                {data.length >= 25 && <option value='25'>25</option>}
                                {data.length >= 50 && <option value='50'>50</option>}
                                {data.length >= 100 && <option value='100'>100</option>}
                            </Form.Select>
                        </div>
                        <div id='table-order' className='d-inline-flex align-items-center'>
                            <Form.Label className='text-nowrap pt-1'>Order by</Form.Label>&nbsp;
                            <Form.Select size='sm' className={'bg-transition d-inline-flex ' + formColor + ' ' + getTextInv()} value={dataOrder} onChange={(e) => orderData(e.target.value)}>
                                {props.tableCols.map( (col, index) => (<option key={index} value={index}>{col}</option>))}
                            </Form.Select>
                        </div>
                    </Col>
                    <Col className='text-md-end ps-5'>
                        <Form id='searchBar' className='d-flex align-items-center'>
                            <Form.Label className='mb-0'>Search</Form.Label>&nbsp;
                            <Form.Control 
                                id='searchBar'
                                className={'bg-transition ' + formColor + ' ' + getTextInv()}
                                size='sm' 
                                type='search' 
                                placeholder='Search' 
                                onChange={(e) => setData(data.filter( ad => (
                                    (ad.advertisementName && ad.advertisementName.toLowerCase().includes(e.target.value.toLowerCase())) ||
                                    (ad.advertiserName && ad.advertiserName.toLowerCase().includes(e.target.value.toLowerCase()))
                                )))}
                            />
                            <Button className='bg-transparent border-0' onClick={() => resetTable() }>
                                <ArrowClockwise className={getText()} style={{transition: 'color 0.5s'}}/>
                            </Button>
                        </Form>
                    </Col>
                </Row>
                <Table responsive className='mt-2' id='table' role='grid'>
                    <thead className='text-center'>
                        <tr>
                            {props.tableCols.map( (col, index) => ( <th key={`${col}-${index}`} className={'text-nowrap bg-transition ' + formColor + ' ' + getTextInv()}>{col}</th> ))}
                        </tr>
                    </thead>
                    <TableBodyData data={data.slice(0, dataLen)}/>
                    {dataLen ?
                        <tfoot className='text-center' style={{borderBottom: '0px solid'}}>
                            <tr style={{borderBottom: '0px solid'}}>
                                {props.tableCols.map( (col, index) => ( <th key={`${col}-${index}`} className={'text-nowrap bg-transition ' + formColor + ' ' + getTextInv()} style={{borderBottom: '0px solid'}}>{col}</th> ))}
                            </tr>
                        </tfoot>
                    : 
                        <></>
                    }
                </Table>
                {!dataLen ? <h2 className='w-100 text-center text-nowrap'>No Data to Display</h2> : <></>}
            </Card.Body>
        </Card>
    )
}

const TableBodyData = (props) => {
    const { getTextInv, darkMode } = useTheme() 
    const formColor = darkMode ? 'my-bg-secondary-darkmode' : 'my-bg-white'

    return (
            <tbody className='text-center'>
                {props.data.map( (row, index ) => ( 
                    <tr key={index} >
                        {Object.keys(row).map( key => <td key={key} className={'bg-transition ' + formColor + ' ' + getTextInv()}>{row[key]}</td> )}
                    </tr>
                ))}
            </tbody>
    )
}

export default AdTable;