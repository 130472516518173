import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { Container, Navbar, Nav, Row, Col } from 'react-bootstrap';

import LogIn from './login'
import ColorThemeToggle from './colorThemeToggle';
import Brand from '../common/brand';
import { useTheme } from '../../contexts/themeContext';
import { useAuth } from '../../contexts/authContext';

const Header = (props) => {
    const {error, success, warning} = useAuth()
    return (
        <header className='pt-0 d-flex justify-content-center my-bg-whtite' style={{zIndex: '0', transition: 'background 0.5s'}}>
            <HeaderNavBar appear={props.appear && !error && !success && !warning}/>
        </header>
    )
}

const HeaderNavBar = ({appear = false}) => {
    const { darkMode, isSmallScreen } = useTheme()
    const [expanded, setExpanded] = useState(false)

    const handleToggle = () => {
        setExpanded(!expanded) 
    }

    return (
        <Navbar 
            fixed='top' expand='md' expanded={expanded} onToggle={handleToggle} 
            className={'py-0 px-1 w-75 mt-4 ' + (!darkMode ? 'my-bg-white' : 'my-bg-light') + ' ' + (expanded ? 'rounded-1' : 'rounded-5')} 
            style={{
                backdropFilter: 'blur(20px)',
                transform: appear ? 'translateY(0)' : 'translateY(-200%)',
                transition: 'background 0.5s, transform 1s ease-in-out', 
                '--bs-bg-opacity': (isSmallScreen ? '1' : '0.4'), 
                marginLeft: '12%', 
                overflow: 'hidden',
            }}
        >
            <Container style={{zIndex: '2'}}>
                <Navbar.Brand className='pt-2'>
                    <Brand />
                </Navbar.Brand>
                <Navbar.Toggle aria-controls='basic-navbar-nav' />
                <Navbar.Collapse className='rounded-0'>
                    <Nav className='mx-auto fs-5 d-flex align-items-center'>
                        <Nav.Link as={Link} to='/solutions' className='my-text-dark' style={{transition: 'color 0.5s'}}>Solutions</Nav.Link>
                        <Nav.Link as={Link} to='/about' className='my-text-dark' style={{transition: 'color 0.5s'}}>About</Nav.Link>
                        <Nav.Link as={Link} to='/blog' className='my-text-dark' style={{transition: 'color 0.5s'}}>Blog</Nav.Link>
                        <Nav.Link as={Link} to='/contact' className='my-text-dark' style={{transition: 'color 0.5s'}}>Contact Us</Nav.Link>
                        <Nav.Link as={Link} to='/demo' className='my-text-dark' style={{transition: 'color 0.5s'}}>Book Demo</Nav.Link>
                    </Nav>
                    <Row className='flex-nowrap justify-content-between mb-1'>
                        <Col><LogIn type={1} /></Col>
                        <Col className={'d-flex justify-content-center ' + (isSmallScreen && 'mb-2')}><ColorThemeToggle /></Col>
                    </Row>
                </Navbar.Collapse>
            </Container>
        </Navbar>
    )
}

export default Header;