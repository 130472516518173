import React, { useState } from 'react';
import { Link, NavLink, useNavigate } from 'react-router-dom';
import { Button, Modal, Col, Row, Form, Image, Collapse } from 'react-bootstrap';

import { useAuth } from '../../contexts/authContext';
import { useTheme } from '../../contexts/themeContext';

const LogIn = ({type}) => {
    const [showModal, setShowModal] = useState(false)
    const [ isRegister, setIsRegister ] = useState(false)

    const { currentUser } = useAuth()
    const { getBG, getTextInv, darkMode } = useTheme()
    const textColor = type ? 'my-text-dark' : (darkMode ? 'my-text-primary-light' : 'my-text-dark')

    function closeModal() {
        setShowModal(false)
    }

    return (
        <div className='d-flex justify-content-center my-text-dark'>
            { currentUser ?
                <NavLink to='/dashboard' className='text-decoration-none fs-5 my-text-dark' style={{transition: 'color 0.5s'}}>
                    <div className='d-inline-flex pt-3'>
                        <p className='text-center text-nowrap me-1'>{currentUser.displayName ? currentUser.displayName : currentUser.email}&nbsp;</p>
                        {currentUser.photoURL ? <Image src={currentUser.photoURL} roundedCircle width={32} height={32} /> : <></>}
                    </div>
                </NavLink>
            :
                <Button className={'btn-primary btn-sm border-0 bg-transparent p-0 ps-3 pb-1 fs-5 arvo-regular text-nowrap ' + textColor} style={{transition: 'color 0.5s'}} onClick={() => setShowModal(true)}>Log In | Sign Up</Button>
            }
            <Modal show={showModal} onHide={closeModal} className='back-blur' >
                <Modal.Header closeButton className={'border-0 ' + getBG()}>
                    <Modal.Title className={getTextInv()}>
                        {isRegister ? 'Sign Up': 'Log In'}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body className={'rounded-bottom ' + getBG()}>
                    <Row className='px-4'>
                        <Col className='text-center m-0 p-0 mb-1'>
                            <Button className={`rounded-0 rounded-start-4 border-1 my-text-dark shadow btn-sm btn-block w-100 ${!isRegister ? 'border-white fw-bold my-bg-primary' : 'border-1 border-primary my-bg-white'}`} onClick={() => setIsRegister(false)}>Log In</Button>
                        </Col>
                        <Col className='text-center m-0 p-0 mb-1'>
                            <Button className={`rounded-0 rounded-end-4 border-1 my-text-dark shadow btn-sm btn-block w-100 ${isRegister ? 'border-white fw-bold my-bg-primary' : 'border-1 border-primary my-bg-white'}`} onClick={() => setIsRegister(true)}>Sign Up</Button>
                        </Col>
                    </Row>
                    <Row className='overflow-hidden m-0' style={{'width': '100%'}}>
                        <Col style={{'marginLeft': isRegister ? '-100%' : '0%', 'transition': 'all 0.3s ease'}}>
                            <LogInForm close={closeModal}/> 
                        </Col>
                        <Col style={{'marginRight': isRegister ? '0%' : '-100%', 'transition': 'all 0.3s ease'}}>
                            <RegisterForm close={closeModal}/>
                        </Col>
                    </Row>
                </Modal.Body>
            </Modal>
        </div>
    )
}

const LogInForm = (props) => {
    const [ inputPassword, setInputPassword ] = useState('')
    const [ inputEmail, setInputEmail ] = useState('')

    const [ loading, setLoading ] = useState( false )

    const { login, setError } = useAuth()
    const { getTextInv, getButtonStyle } = useTheme()

    const navigate = useNavigate()
    
    async function handleSubmit(e) {
        e.preventDefault()
        setError('')
        
        try {
            setLoading(true)
            await login( inputEmail, inputPassword )
            navigate('/dashboard')
        } catch (e) {
            setError('Failed to log in')
        } finally {
            props.close()
            setLoading(false)
        }
    }

    return (
        <Form onSubmit={handleSubmit} className='mt-1'>
            <Form.Group className='form-white mt-2 mb-3' controlId='loginEmail'>
                <Form.Control 
                    type='email'
                    value={inputEmail} 
                    placeholder='Email'
                    onChange={(e) => setInputEmail(e.target.value)}
                    required
                    className='form-control-lg' 
                />
            </Form.Group>
            <Form.Group className='form-white mb-3' controlId='loginPassword'>
                <Form.Control 
                    type='password'
                    value={inputPassword}
                    placeholder='Password'
                    onChange={(e) => setInputPassword(e.target.value)}
                    required
                    className='form-control-lg'
                />
            </Form.Group>
            <Row className='text-center'>
                <div className={'mb-4 ' + getTextInv()}>
                    Forgot your Password?&nbsp;
                    <Link to='/resetpassword' className='link-primary' onClick={() => props.close()}>Click Here!</Link>
                </div>
            </Row>
            <Row className='text-center mt-3'>
                { !loading ? (<Button className={'px-5 bg-transition ' + getButtonStyle('lg').replace('shadow', '')} style={{boxShadow: 'none'}} type='submit'>Login</Button>)
                    : (<Button className={'px-5 bg-transition ' + getButtonStyle('lg').replace('shadow', '')} style={{boxShadow: 'none'}} type='submit' disabled>Logging in...</Button>)
                }
            </Row>
        </Form>
    )
}

const RegisterForm = (props) => {
    const [ inputEmail, setInputEmail ] = useState('')
    const [ inputPassword, setInputPassword ] = useState('')
    const [ confirmPass, setConfirmPass ] = useState('') 
    const [ isCreator, setIsCreator ] = useState(true)
    const [ verificationCode, setVerificationCode ] = useState('')
    const [ sentVerificationCode, setSentVerificationCode ] = useState('')

    const [ loading, setLoading ] = useState( false )
    const [ showVerify, setShowVerify ] = useState(false)

    const { sendVerificationCode, register, setError } = useAuth()

    const { getTextInv, getButtonStyle } = useTheme()

    function clearForm() {
        setInputEmail('')
        setInputPassword('')
        setConfirmPass('')
        setIsCreator(false)
        setVerificationCode('')
        setSentVerificationCode('')
    }

    const navigate = useNavigate()

    async function sendVerificationCodeEmail() {
        setLoading(true)
        const code = await sendVerificationCode(inputEmail)
        if ( code.length !== 6) {
            setError('Failed to send verification code')
            clearForm()
            props.close()
        }
        else {
            setShowVerify(true)
            setSentVerificationCode(code)
        }
        setLoading(false)
    }
    
    async function handleSubmit(e) {
        e.preventDefault()
        setError('')

        if ( !showVerify ) {
            await sendVerificationCodeEmail()
            return
        }
        
        if ( inputPassword !== confirmPass) {
            setError("Passwords do not match")
            clearForm()
            props.close()
            return
        }


        if ( sentVerificationCode !== verificationCode ) {
            setError('Verification code is incorrect')
            clearForm()
            props.close()
            return
        }
        
        try {
            setLoading(true)
            await register( inputEmail, inputPassword, isCreator )
            navigate('/dashboard')
        } catch (err) {
            setError('Failed to register')
        } finally {
            props.close()
            clearForm()
            setLoading(false)
        }
    }
    return (
        <Form onSubmit={handleSubmit}>
            <Form.Group className='form-white mb-2' controlId='signUpEmail'>
                <Form.Control 
                    type='email'
                    value={inputEmail} 
                    placeholder='New Email'
                    onChange={(e) => setInputEmail(e.target.value)}
                    required
                    disabled={showVerify}
                    className='form-control-md mt-1' 
                />
            </Form.Group>
            <Form.Group className='form-white mb-2' controlId='signUpPassword'>
                <Form.Control 
                    type='password'
                    value={inputPassword}
                    placeholder='New Password'
                    onChange={(e) => setInputPassword(e.target.value)}
                    required
                    disabled={showVerify}
                    className='form-control-md'
                />
            </Form.Group>
            <Form.Group className='form-white mb-2' controlId='confirm-pass'>
                <Form.Control 
                    type='password'
                    value={confirmPass} 
                    placeholder='Confirm New Password'
                    onChange={(e) => setConfirmPass(e.target.value)}
                    required
                    disabled={showVerify}
                    className='form-control-md' 
                />
            </Form.Group>
            <Form.Group className='form-white d-flex fs-5 justify-content-between' controlId='account-type'>
                <div className={'ms-2 ' + getTextInv()}>Are You a:</div>
                <div className={'me-2 d-inline-flex ' + getTextInv()}>
                    <p className={!isCreator ? 'fw-bold' : 'fw-light'}>Advertiser</p>
                    <Form.Switch
                        defaultChecked={isCreator}
                        onChange={(e) => setIsCreator(!isCreator)}
                        className='form-control-lg m-0 p-0 ms-2 ps-5'
                        disabled={showVerify}
                    />
                    <p className={isCreator ? 'fw-bold' : 'fw-light'}>Creator</p>
                </div>
            </Form.Group>
            <Collapse in={showVerify}>
                <div>
                    <Form.Group className='form-white mb-2' controlId='verify-email'>
                        <Form.Label className={getTextInv()}>Please enter the code that was just sent to your email</Form.Label>
                        <Form.Control
                            type='text'
                            name='verifyEmail'
                            placeholder='Verification Code'
                            onChange={(e) => setVerificationCode(e.target.value)}
                            required={showVerify}
                            className='form-control-md'
                        />
                    </Form.Group>
                </div>
            </Collapse>
            <Row className='text-center'>
                <Button className={'px-5 bg-transition ' + getButtonStyle('lg').replace('shadow', '')} style={{boxShadow: 'none'}} type='submit' disabled={loading}>
                    Sign{loading && 'ing'} Up
                </Button>
            </Row>
        </Form>
    )
}

export default LogIn;