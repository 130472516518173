import React from 'react'
import { Col, Container, Row, Form, OverlayTrigger, Tooltip } from 'react-bootstrap'
import { QuestionCircleFill } from 'react-bootstrap-icons'

const DetailsSection = (props) => {
    const getDateString = (date) => {
        const year = date.getFullYear()
        const month = String(date.getMonth() + 1).padStart(2, '0')
        const day = String(date.getDate()).padStart(2, '0')
        return `${year}-${month}-${day}`
    }

    const getMaxDateString = () => {
        let dateList = getDateString(new Date()).split('-')
        dateList[0] = String(Number(dateList[0]) + 5)
        return dateList.join('-')
    }

    return (
        <Container fluid>
            <Row className='ps-3 pe-5'>
                <Col>
                    <Form.Group className='text-center'>
                        <Form.Label className='ps-3 mb-0'>Company Public Name</Form.Label>
                        <Form.Control
                            required
                            type='text'
                            name='advertiserName'
                            placeholder='Preferred Company Name'
                            value={props.formData.advertiserName || ''}
                            onChange={(e) => props.setFormData(prev => ({...prev, advertiserName: e.target.value}))}
                            className='form-control-sm'
                        />
                    </Form.Group>
                </Col>
                <Col>
                    <Form.Group className='text-center'>
                        <Form.Label className='ps-3 mb-0'>Advertisement Name</Form.Label>
                        <Form.Control
                            required
                            type='text'
                            name='adName'
                            placeholder='Name your Advertisement!'
                            value={props.formData.advertisementName || ''}
                            onChange={(e) => props.setFormData(prev => ({...prev, advertisementName: e.target.value}))}
                            className='form-control-sm'
                        />
                    </Form.Group>
                </Col>
                <Col>
                    <Form.Group className='text-center'>
                        <Form.Label className='ps-3 mb-0'>Ad Start Date</Form.Label>
                        <Form.Control 
                            required 
                            type='date' 
                            name='startDate' 
                            className='form-control-sm' 
                            value={getDateString(props.formData.startDate || new Date())} 
                            min={getDateString(new Date())}
                            max={getMaxDateString()}
                            onChange={(e) => props.setFormData(prev => ({...prev, startDate: new Date(e.target.value + 'T00:00:00')}))}
                        />
                    </Form.Group>
                </Col>
            </Row>
            <Row className='ps-3'>
                <Col className='pe-5'>
                    <Form.Group>
                        <Form.Label className='ps-3 mb-0'>Advertisement Description</Form.Label>
                        <Form.Control
                            required
                            name='adDesc'
                            as='textarea'
                            rows={3}
                            placeholder='Describe the product, the ad, and/or the advertiser. Add any additional instructions for creators.'
                            value={props.formData.description || ''}
                            onChange={(e) => props.setFormData(prev => ({...prev, description: e.target.value}))}
                            className='form-control-sm'
                        />
                    </Form.Group>
                </Col>
                <Col lg={3} className='d-flex flex-column justify-content-center align-items-start mt-4'>
                    <Form.Group className='text-center'>
                        <Form.Label>
                            Target Platform(s)
                            <sup>
                                <OverlayTrigger placement='top' overlay={<Tooltip id='tooltip-link'>Currently only Youtube is Supported</Tooltip>}>
                                    <QuestionCircleFill className='ms-1'/>
                                </OverlayTrigger>
                            </sup>
                        </Form.Label>
                        <Row>
                            <Col className='d-flex justify-content-center'>
                                <Form.Check
                                    name='youtube'
                                    label='Youtube'
                                    defaultChecked={props.formData.platforms && props.formData.platforms.includes('youtube')}
                                    disabled
                                    className='form-control-md mt-0'
                                />
                            </Col>
                        </Row>
                    </Form.Group>
                </Col>
            </Row>
        </Container>
    )
}

export default DetailsSection