import React, { useCallback, useEffect, useRef, useState } from 'react'
import { useTheme } from '../../../../../contexts/themeContext';
import useIntersectionObserver from '../../../../../utils/useIntersectionObserver';

import clip1 from '../../../../../assets/video/howItWorksCookingClip_processed.mp4'
import clip2 from '../../../../../assets/video/howItWorksDogClip_processed.mp4'
import clip3 from '../../../../../assets/video/howItWorksWorkoutClip_processed.mp4'
import clip4 from '../../../../../assets/video/howItWorksChessClip_processed.mp4'
import clip5 from '../../../../../assets/video/howItWorksSurfingClip_processed.mp4'
import clip6 from '../../../../../assets/video/howItWorksAirplaneClip_processed.mp4'
import clip7 from '../../../../../assets/video/howItWorksKittenClip_processed.mp4'

import  { HandThumbsUp, HandThumbsDown, Reply, ChatRightText } from 'react-bootstrap-icons'

import './howItWorks.css'

const BackgroundVideo = React.memo( ({video, playVideo}) => {
    const videoRef = useRef(null)

    useEffect(() => {
        try {
            if (playVideo) videoRef.current.play()
            else videoRef.current.pause()
        } catch (err) {
            console.warn(err)
        }
    }, [playVideo, videoRef])

    return (
        <video 
            ref={videoRef}
            src={video}
            webkit-playsinline='true'
            playsInline
            muted
            loop 
            preload='metadata'
            type='video/mp4'
            className='phoneVideo'
        />
    )
})

export const SmartPhone = (props) => {
    const { getThemeBGInv } = useTheme()
    const rectangleWidth = 16

    return (
        <div className='phoneWrapper' style={{width: `${rectangleWidth}rem`, height: `${rectangleWidth * 2}rem`, ...props.animations}}>
            <div className={'rectangle rounded-5 bg-transition ' + getThemeBGInv('primary')} style={{width: `${rectangleWidth}rem`, height: `${rectangleWidth * 2}rem`}}>
                <div className='h-100 w-100 my-bg-black'>
                    <BackgroundVideo video={props.video} playVideo={props.playVideo}/>
                </div>
            </div>
            <div className='overlap-group justify-content-end pb-2'>
                <div className='text-wrapper-0 fs-6'>YOUR AD HERE</div>
            </div>
            <div className='d-flex justify-content-center w-100 position-absolute' style={{placeSelf: 'start center', top: '2%'}}>
                <div className='cameraBar rounded-pill z-3 d-inline-flex justify-content-start align-content-center' style={{paddingTop: '1.5px'}}>
                    <div className='rounded-circle ms-1 me-1' style={{backgroundColor: '#190E4B', height: '12px', width: '12px'}}/>
                    <div className='rounded-circle' style={{backgroundColor: '#190E4B', height: '12px', width: '12px'}}/>
                    <div className='d-flex flex-fill justify-content-end pe-2'>
                        <div className='rounded-circle' style={{backgroundColor: '#190E4B', height: '12px', width: '12px'}}/>
                    </div>
                </div>
            </div>
            <HandThumbsUp className='hand-thumbs-up icons' />
            <HandThumbsDown className='hand-thumbs-down icons' />
            <Reply className='reply-instance icons' />
            <ChatRightText className='chat-right-text icons' />
        </div>
    )
}

export const SmartPhoneArray = (props) => {
    const [hasFocus, setFocus] = useState(true)
    const [currPhone, setCurrPhone] = useState(0)
    const phoneTransforms = [
        {zIndex: 7, transform: 'scale(1) translateX(0%)'},
        {zIndex: 6, transform: 'scale(0.95) translateX(-70%)'},
        {zIndex: 5, transform: 'scale(0.90) translateX(-120%)'},
        {zIndex: 4, transform: 'scale(0.83) translateX(-160%)'},
        {zIndex: 3, transform: 'scale(0.83) translateX(160%)'},
        {zIndex: 5, transform: 'scale(0.90) translateX(120%)'},
        {zIndex: 6, transform: 'scale(0.95) translateX(70%)'},
    ]
    const clips = [clip1, clip2, clip3, clip4, clip5, clip6, clip7]

    useEffect(() => {
        window.addEventListener('focus', () => setFocus(true))
        window.addEventListener('blur', () => setFocus(false))

        if (hasFocus && props.animate) {
            setTimeout(() => {
                setCurrPhone(currPhone + 1)
            }, 5000)
        }
    }, [currPhone, props.animate, hasFocus])

    return (
        <div id='smartPhoneArray' className='wrapper mt-5 d-flex w-100 align-content-center justify-content-center overflow-hidden position-sticky' style={{transform: 'translateY(20vh) scale(90%)', transition: 'transform 1s ease-in-out', minHeight: '650px', height: 'max-content'}}>
            {clips.map((clip, index) => (
                <SmartPhone key={index} animations={phoneTransforms[(currPhone + index) % clips.length]} video={clip} playVideo={props.playVideo && hasFocus && !((currPhone + index) % clips.length)}/>
            ))}
        </div>
    )
}

const HowItWorks = ({ playVideo }) => {
    const { darkMode, isSmallScreen } = useTheme()

    const [ref, isIntersecting] = useIntersectionObserver({
        root: null, 
        threshold: 0.15
    })

    const updateStyles = useCallback(async () => {
        const smartPhoneArray = document.getElementById('smartPhoneArray')
        const titleText = document.getElementById('titleTextId')
        if (isSmallScreen) {
            if (isIntersecting && smartPhoneArray && titleText) {
                smartPhoneArray.style.transform = 'translateY(0) scale(50%)'
                titleText.style.transform = 'translateY(0) scale(100%)'
                ref.current.style.backgroundColor = `var(--my-primary${darkMode ? '-light' : ''})`
            }
        } else {
            if (isIntersecting && smartPhoneArray && titleText) {
                smartPhoneArray.style.transform = 'translateY(0) scale(100%)'
                titleText.style.transform = 'translateY(0) scale(100%)'
                ref.current.style.backgroundColor = `var(--my-primary${darkMode ? '-light' : ''})`
            } else if (!isIntersecting && smartPhoneArray && titleText){
                smartPhoneArray.style.transform = 'translateY(20vh) scale(90%)'
                titleText.style.transform = 'translateY(20vh) scale(90%)'
                ref.current.style.backgroundColor = 'transparent'
            }
        }
    }, [isIntersecting, darkMode, ref, isSmallScreen])

    useEffect(() => {
        if (!isSmallScreen) {
            updateStyles()
        }
    }, [updateStyles, isSmallScreen])

    return (
        !isSmallScreen && 
        <div ref={ref} className='bg-transition' style={{backdropFilter: 'blur(20px)'}}>
            <div id='titleTextId' className='mb-3 title-text text-center arvo-regular fw-bold pt-5 overflow-wrap-normal my-text-dark' style={{placeSelf: 'start center', transition: 'opacity 1s, transform 1s'}}>
                <p>Creator Marketing Across 1000s of Channels</p>
            </div>
            <SmartPhoneArray animate={isIntersecting} playVideo={isIntersecting && playVideo} />
            <div className='overflow-hidden h-100 w-100 position-absolute top-0' style={{zIndex: '3'}}>
                <div className='rounded-circle position-absolute' style={{animation: 'breathe 60s infinite', height: '600px', width:'600px', top: '70%', left: '0%', backdropFilter: 'brightness(105%)', opacity: '1'}}/>
                <div className='rounded-circle position-absolute' style={{animation: 'breathe 60s infinite', height: '900px', width:'900px', top: '20%', left: '70%', backdropFilter: 'brightness(102.5%)', opacity: '1'}}/>
                <div className='rounded-circle position-absolute' style={{animation: 'breathe 60s infinite', height: '500px', width:'500px', top: '20%', left: '0%', backdropFilter: 'brightness(97.5%)', opacity: '1'}}/>
                <div className='rounded-circle position-absolute' style={{animation: 'breathe 60s infinite', height: '300px', width:'300px', top: '40%', left: '50%', backdropFilter: 'brightness(101.15%)', opacity: '1'}}/>
                <div className='rounded-circle position-absolute' style={{animation: 'breathe 60s infinite', height: '400px', width:'400px', top: '50%', left: '0%', backdropFilter: 'brightness(99%)', opacity: '1'}}/>
                <div className='rounded-circle position-absolute' style={{animation: 'breathe 60s infinite', height: '650px', width:'650px', top: '55%', left: '25%', backdropFilter: 'brightness(101%)', opacity: '1'}}/>
                <div className='rounded-circle position-absolute' style={{animation: 'breathe 60s infinite', height: '900px', width:'900px', top: '60%', left: '60%', backdropFilter: 'brightness(98%)', opacity: '1'}}/>
                <div className='rounded-circle position-absolute' style={{animation: 'breathe 60s infinite', height: '350px', width:'350px', top: '10%', left: '55%', backdropFilter: 'brightness(97.5%)', opacity: '1'}}/>
            </div>
        </div>
    )
}


export default HowItWorks; 


