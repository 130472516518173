import React from 'react'
import { Container, Row, Col, Form } from 'react-bootstrap'
import { useTheme } from '../../../../../contexts/themeContext'

const CategoriesSection = ({ bucketNames, formData, setFormData }) => {
    const {getThemeBG} = useTheme()

    const updateBuckets = (e) => {
        if (e.target.checked)
            setFormData(prev => ({...prev, buckets: [...prev.buckets, e.target.name.split('-')[2]]}))
        else
            setFormData(prev => ({...prev, buckets: [...prev.buckets.filter(b => b !== e.target.name.split('-')[2])]}))
    }
    
    return (
        <Container fluid className='px-5'>
            <Row>
                <Col>
                    <Form.Group>
                        <Form.Label className='ps-3 mt-1 mb-0'>Advertisement Categories</Form.Label>
                        <Row>
                            {[0,1,2,3,4].map(col => (
                                <Col key={col} className={'rounded m-1 my-text-dark bg-transition ' + getThemeBG('primary')}>
                                    {bucketNames.slice(Math.ceil(col * bucketNames.length / 5), Math.ceil((col + 1) * bucketNames.length / 5)).map(bucket => (
                                        <BucketRow key={bucket} bucket={bucket} checked={formData.buckets && formData.buckets.includes(bucket)} onChange={updateBuckets} />
                                    ))}
                                </Col>
                            ))}
                        </Row>
                    </Form.Group>
                </Col>
            </Row>
            <Row className='pt-1'>
                <Col>
                    <Form.Group>
                        <Form.Label className='ps-3 mb-0'>Other Ad Categories</Form.Label>
                        <Form.Control
                            name='otherBuckets'
                            type='text'
                            placeholder='(Optional) Separate multiple with commas'
                            value={formData.otherBuckets}
                            onChange={(e) => setFormData(prev => ({...prev, otherBuckets: e.target.value.split(',').map(b => b.trim())}))}
                            className='form-control-md'
                        />
                    </Form.Group>
                </Col>
            </Row>
        </Container>
    )
}

const BucketRow = ({ bucket, checked, onChange }) => {
    return (
        <Row>
            <Col lg={2} className='me-1 text-start ps-0'>
                <Form.Check
                    name={'bucket-check-' + bucket}
                    checked={checked || false}
                    onChange={(e) => onChange(e)}
                    className='form-control-lg'
                />
            </Col>
            <Col className='d-flex align-items-center ps-0 ms-3' style={{paddingTop: '6px'}}>
                <Form.Label>{bucket}</Form.Label>
            </Col>
        </Row>
    )
}

export default CategoriesSection