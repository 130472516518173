import React, { useEffect, useRef, useState } from 'react'
import { useAuth } from '../../../../contexts/authContext'
import { Button, Card, Col, Collapse, Form, Modal, ProgressBar, Row, Spinner } from 'react-bootstrap'
import { useTheme } from '../../../../contexts/themeContext'
import { CheckCircleFill, XCircleFill } from 'react-bootstrap-icons'

const AdVerificationCardBody = (props) => {
    const { userData, updateChannelAdContent, uploadCreatorVideo, deleteAdVerification, verifyCreatorAdVideo, setError, setSuccess, earnMedal } = useAuth()
    const { getBG, getTextInv, getButtonThemeStyle, getButtonStyle } = useTheme()

    const [videoFile, setVideoFile] = useState()
    const [videoFileUrl, setVideoFileUrl] = useState()
    const [uploadProgress, setUploadProgress] = useState(0)
    const [videoDuration, setVideoDuration] = useState()

    const [loading, setLoading] = useState(false)
    const [loadingVerification, setLoadingVerification] = useState()
    const [isVerified, setIsVerified] = useState()
    const [lastVerified, setLastVerified] = useState()
    const [verificationDetails, setVerificationDetails] = useState()
    const [isValid, setIsValid] = useState(true)
    const [showRequirements, setShowRequirements] = useState()
    const [showConfirm, setShowConfirm] = useState(false)

    const videoInputRef = useRef(null)
    const handleVideoChange = (e) => {
        const file = e.target.files[0]
        if (file) {
            setVideoFile(file)
            setVideoFileUrl(URL.createObjectURL(file))
        } else {
            setVideoFile(null)
        }
    }

    const getDefaultTimestamps = (type, time, unit) => {
        const requirementTimes = props.data.requirementTimes
        if (!requirementTimes || !requirementTimes[type] || !requirementTimes[type][time]) return ""
        let retVal = ""
        if (unit === 'hours') retVal = Math.round(requirementTimes[type][time] / 3600)
        if (unit === 'minutes') retVal = Math.round((requirementTimes[type][time] % 3600) / 60)
        if (unit === 'seconds') retVal = (requirementTimes[type][time] % 3600) % 60
        return retVal + unit[0]
    }

    const handleUpload = () => {
        setUploadProgress(2)
        setLoading(true)

        try {
            const filePath = `${props.adId}/creatorVideos/${props.selected.split(':').reverse().join('-')}.${videoFile.name.split('.').pop()}`
            uploadCreatorVideo(videoFile, filePath, (progress) => {
                setUploadProgress(Math.round(progress))
            }, async (url) => {
                await updateChannelAdContent(props.selected.split(':')[0], props.selected.split(':')[1], props.adId, {videoUrl: url})
                setUploadProgress(100)
                setVideoFileUrl(url)
                setShowRequirements(true)
            })
        } catch (err) {
            setError("Failed to upload video, please try again later")
        } finally {
            setLoading(false)
        }
    }

    const checkValidity = (requirementTimes) => {
        return userData.history[props.adId].types.filter(type => type !== 'link').reduce((valid, type) => {
            if (!requirementTimes[type]) return false
            const startTime = requirementTimes[type].start
            const endTime = requirementTimes[type].end
            return valid && startTime && endTime && startTime >= 0 && startTime < videoDuration && endTime > startTime && endTime < videoDuration
        }, true)
    }

    const triggerVideoVerification = (platform, channel) => {
        verifyCreatorAdVideo(platform, channel, props.adId, (details) => {
            setLoadingVerification(false)
            setLastVerified(details.lastVerified)
            setVerificationDetails(details.verificationDetails)
            earnMedal(8)
        }, () => {
            updateChannelAdContent(platform, channel, props.adId, {isVerifying: false})
            setLoadingVerification(false)
            setVideoFileUrl(props.data.videoUrl)
            setError("Failed to verify video, please check the provided timestamps and try again")
        })
    }

    const submitForVerification = async (e) => {
        e.preventDefault()
        setLoading(true)
        setSuccess("")
        setError("")

        const platform = props.selected.split(':')[0]
        const channel = props.selected.split(':')[1]

        const formData = [...new FormData(e.target).entries()]
        let requirementTimes = {}
        let valid = false
        if (props.data.requirementTimes && checkValidity(props.data.requirementTimes)) {
            requirementTimes = props.data.requirementTimes
            valid = true
            setIsValid(valid)
        } else {
            for (const timestamp of formData) {
                let [type, time, unit] = timestamp[0].split("_")
                if (requirementTimes[type] && requirementTimes[type][time]) {
                    requirementTimes[type][time] += (unit === 'hours' ? 3600 : (unit === 'minutes' ? 60 : 1)) * timestamp[1]
                } else {
                    requirementTimes[type] = {
                        ...requirementTimes[type],
                        [time]: (unit === 'hours' ? 3600 : (unit === 'minutes' ? 60 : 1)) * timestamp[1]
                    }
                }
            }
            valid = checkValidity(requirementTimes)
            setIsValid(valid)
        }

        if (!valid) {
            setLoading(false)
            return
        }

        try {
            await updateChannelAdContent(platform, channel, props.adId, {requirementTimes: requirementTimes, isVerifying: true, videoUrl: videoFileUrl})
            setLoadingVerification(true)
            triggerVideoVerification(platform, channel)
            setSuccess("Saved Verification Details, check back soon for the verification status")
        } catch (err) {
            setError("Failed to save verification details, please try again later")
        } finally {
            setLoading(false)
        }
    }

    const resetVerification = () => {
        try {
            const urlNoParams = videoFileUrl.split('?')[0]
            const extension = urlNoParams.split('.').pop()
            deleteAdVerification(props.selected.split(':').join('-'), props.adId, `${props.adId}/creatorVideos/${props.selected.split(':').reverse().join('-')}.${extension}`)
            setVideoFileUrl(null)
            setSuccess('Verification reset, please re-upload and enter the new timestamps')
        } catch (err) {
            console.log(err)
            setError('Failed to reset verification, please try again later')
        }
    }

    const handleConfirmSubmit = () => {
        setShowConfirm(false)
        const form = document.getElementById('verification-requirements-form')
        if (form) form.dispatchEvent(new Event('submit', { cancelable: true, bubbles: true }))
    }

    useEffect(() => {
        setIsVerified(props.data.isVerified || false)
        setLastVerified(props.data.lastVerified || 0)
        setVideoFileUrl(props.data.videoUrl || null)
        setLoadingVerification(props.data.isVerifying || false)
        setVerificationDetails(props.data.verificationDetails || null)
        setShowRequirements(!!props.data.videoUrl)
    }, [props.data])

    return (
        <Card.Body className='px-4'>
            <Row className='text-center'>
                <h1 className='fw-bold'>
                    {isVerified ? "Video was Successfully Verified - Post It Soon!" :
                        (loadingVerification  ? "Awaiting Video Verification..." :
                            (verificationDetails ? 'Video failed verification, please correct the issues and try again' : 
                                (videoFileUrl ?  "Submit ad verification details before posting your video!" : "Upload your video here first to verify the ad requirements!")))
                    }
                </h1>
            </Row>
            {videoFile || videoFileUrl ? 
                <div>
                    <div className='d-flex justify-content-center'>
                        <video key={videoFileUrl || videoFile} height={500} controls preload='auto' onLoadedMetadata={(e) => setVideoDuration(e.target.duration)} className='rounded-1'>
                            <source src={videoFileUrl || videoFile} />
                            Your Browser does not support embedded videos
                        </video>
                    </div>
                    <div className='d-flex justify-content-center mt-3'>
                        {uploadProgress > 0 ? (
                            uploadProgress < 100 ?
                                <ProgressBar now={uploadProgress} label={`${uploadProgress}%`} style={{minWidth: '40%'}} className='my-2' />
                            :
                                <h5 className='fw-bold my-text-success'>Video Uploaded!</h5>
                            )
                        :
                            <Button onClick={handleUpload} disabled={loading || !videoFile || !!verificationDetails || props.data.isVerifying} className={getButtonStyle('sm')}>Upload to Fiable</Button>
                        }
                    </div>
                </div>
            :
                <Row>
                    <Col className='d-flex justify-content-center'>
                        <Form.Group className='text-center'>
                            <Form.Control ref={videoInputRef} type='file' onChange={handleVideoChange} accept='video/*' />
                        </Form.Group>
                    </Col>
                </Row>
            }
            <Collapse in={showRequirements || loadingVerification || !!verificationDetails}>
                <div>
                    <Row className='text-center pt-2 pb-1'>
                        <h3 className='fw-bold'>Ad Verification Requirements{(videoFileUrl ? "" : " (upload video first)")}:</h3>
                    </Row>
                    <Form id='verification-requirements-form' onSubmit={(e) => submitForVerification(e)}>
                        {userData.history[props.adId].types.filter(type => type !== 'link').map(type => (
                            <Row key={type} className='d-inline-flex justify-content-between px-2 w-100'>
                                <Col className='d-flex align-items-end pb-1 justify-content-center'>
                                    <h3>
                                        {verificationDetails ?
                                            type.split(" ").map(word => word[0].toUpperCase() + word.slice(1)).join(" ") + ' Verification Status:'
                                        :
                                            type.split(" ").map(word => word[0].toUpperCase() + word.slice(1)).join(" ") + ' Timestamps:' 
                                        }
                                    </h3>
                                </Col>
                                {verificationDetails && verificationDetails[type.replaceAll(" ", "") + "Verification"] ? 
                                    <Col className='d-flex d-inline-flex justify-content-center'>
                                        <h3>{verificationDetails[type.replaceAll(" ", "") + "Verification"].isVerified ? "Verified" : "Not Verified"}</h3>
                                        {verificationDetails[type.replaceAll(" ", "") + "Verification"].isVerified ? <CheckCircleFill className='mt-1 ms-2 fs-4 my-text-success' /> : <XCircleFill className='mt-1 ms-2 fs-4 my-text-danger' />}
                                    </Col>
                                :
                                <>
                                    <Col>
                                        <Form.Label className='ms-2'>Start Timestamp (hours:minutes:seconds)</Form.Label>
                                        <Form.Group className='d-inline-flex h-50'>
                                            <Form.Control type='numeric' placeholder='0h' name={type + '_start_hours'} disabled={props.data.isVerifying} defaultValue={getDefaultTimestamps(type, 'start', 'hours')} />
                                            <Form.Label className="d-flex align-items-center px-1 pt-1 fs-4">:</Form.Label>
                                            <Form.Control type='numeric' placeholder='0m' name={type + '_start_minutes'} disabled={props.data.isVerifying} defaultValue={getDefaultTimestamps(type, 'start', 'minutes')} />
                                            <Form.Label className="d-flex align-items-center px-1 pt-1 fs-4">:</Form.Label>
                                            <Form.Control type='numeric' placeholder='0s' name={type + '_start_seconds'} disabled={props.data.isVerifying} defaultValue={getDefaultTimestamps(type, 'start', 'seconds')} />
                                        </Form.Group>
                                    </Col>
                                    <Col>
                                        <Form.Label className='ms-2'>End Timestamp (hours:minutes:seconds)</Form.Label>
                                        <Form.Group className='d-inline-flex h-50'>
                                            <Form.Control type='numeric' placeholder='0h' name={type + '_end_hours'} disabled={props.data.isVerifying} defaultValue={getDefaultTimestamps(type, 'end', 'hours')} />
                                            <Form.Label className="d-flex align-items-center px-1 pt-1 fs-4">:</Form.Label>
                                            <Form.Control type='numeric' placeholder='0m' name={type + '_end_minutes'} disabled={props.data.isVerifying} defaultValue={getDefaultTimestamps(type, 'end', 'minutes')} />
                                            <Form.Label className="d-flex align-items-center px-1 pt-1 fs-4">:</Form.Label>
                                            <Form.Control type='numeric' placeholder='0s' name={type + '_end_seconds'} disabled={props.data.isVerifying} defaultValue={getDefaultTimestamps(type, 'end', 'seconds')} />
                                        </Form.Group>
                                    </Col>
                                </>
                                }
                            </Row>
                        ))}
                        <Row className='text-center pt-1'>
                            <h5>After submitting these details, Fiable will verify your video meets the ad requirements. The verification process should take no longer than the length of the video, and you will be notified when it completes. Videos must be verified before they can receive Ad Revenues from Fiable.</h5>
                        </Row>
                        <Row>
                            {loadingVerification ?
                                <Col className='d-flex justify-content-center pt-3'>
                                    <Spinner />
                                    <h5>...Awaiting verification...</h5>
                                </Col>
                            :
                                (verificationDetails ?
                                    <Col className='py-2 d-flex flex-column align-items-center'>
                                        <div className='d-inline-flex'>
                                            <h3>Video Verification Complete, Status: {Object.values(verificationDetails).reduce((sum, curr) => sum && curr.isVerified, true) ? 'Verified' : 'Not Verified'}</h3>
                                            {Object.values(verificationDetails).reduce((sum, curr) => sum && curr.isVerified, true) ? <CheckCircleFill className='mt-1 ms-2 fs-4 my-text-success' /> : <XCircleFill className='mt-1 ms-2 fs-4 my-text-danger' />}
                                        </div>
                                        <div>
                                            {verificationDetails.isVerified ? <></> :
                                                <Button className={getButtonStyle('sm')} onClick={() => resetVerification()} disabled={new Date() - new Date(lastVerified) < (60 * 60 * 1000)}>Reset and Try Again</Button>
                                            }
                                        </div>
                                    </Col>
                                :
                                    <Col className='d-flex justify-content-center align-items-center'>
                                        <Button onClick={() => setShowConfirm(true)} className='mb-2 mt-2' disabled={loading || !videoFileUrl || props.data.isVerifying || (lastVerified && (Date.now() - new Date(lastVerified) < (60 * 60 * 1000)))}>
                                            {props.data.isVerifying ? "Verifying..." : "Submit Verification Details"}
                                        </Button>
                                        {(lastVerified && (Date.now() - new Date(lastVerified) < (60 * 60 * 1000))) ? <p className='ms-2 mt-3'>Please wait 15 minutes before verifying again</p> : <></>}
                                        <Modal size='lg' show={showConfirm} onHide={() => setShowConfirm(false)}>
                                            <Modal.Header className={getBG() + ' ' + getTextInv()} closeButton>Confirm Verification</Modal.Header>
                                            <Modal.Body className={'rounded-bottom ' + getBG() + ' ' + getTextInv()}>
                                                <Row className='text-center'>
                                                    <h2 className='fw-bold'>Are you sure you are ready to verify this video?</h2>
                                                    <h5>Fiable video verification should be the last step before publishing this video to your channel, please make sure all timestamps are correct, and that you don't intend to edit or alter it further.</h5>
                                                    <h5>To ensure your data privacy, this video will be deleted from our servers after it has been verified and posted on your channel</h5>
                                                </Row>
                                                <Row>
                                                    <Col className='d-flex justify-content-center'>
                                                        <Button className={getButtonThemeStyle('md', 'danger')} onClick={() => setShowConfirm(false)}>
                                                            No, I need to review
                                                        </Button>
                                                    </Col>
                                                    <Col className='d-flex justify-content-center'>
                                                        <Button className={getButtonThemeStyle('md', 'success')} onClick={handleConfirmSubmit}>
                                                            Yes, please verify this video
                                                        </Button>
                                                    </Col>
                                                </Row>
                                            </Modal.Body>
                                        </Modal>
                                    </Col>
                                )
                            }
                        </Row>
                        {!isValid ? 
                            <Row>
                                <Col className='d-flex justify-content-center'>
                                    <p className='my-text-danger'>Please make sure each ad requirement has a start and end timestamp within the length of the video.</p>
                                </Col>
                            </Row>
                        :
                            <></>
                        }
                    </Form>
                </div>
            </Collapse>
        </Card.Body>
    )
}

export default AdVerificationCardBody