import React, { useState, useEffect } from 'react';
import { useTheme } from '../../../contexts/themeContext';
import useIntersectionObserver from '../../../utils/useIntersectionObserver';

import Header from '../../header/header'
import OurSolutions from './subcomponents/solutions/ourSolutions';
import DetailsCardDeck from './subcomponents/detailsCards/detailsCardDeck';
import NewsletterSignup from '../../common/newsletterSignup';
import AboutSummary from './subcomponents/about/aboutSummary';
import ContactUs from '../../common/contactUs';
import HeroSection from './subcomponents/landing/heroSection';
import BookDemoExtended from './subcomponents/bookDemoDivider/bookDemoExtended';
import Fiawords from './subcomponents/fiawords/fiawords';
import ProductDemo from './subcomponents/platformDemo/productDemo';
import BackgroundSVG from './subcomponents/backgroundSVG';


const Home = () => {
    const { darkMode, isSmallScreen, screenHeight, screenWidth } = useTheme()
    const [loadVideo, setLoadVideo] = useState(false)

    const [homeRef, isIntersectingHome] = useIntersectionObserver({
        root: null,
        threshhold: 0
    })

    useEffect(() => {
        if (!isSmallScreen)
            setLoadVideo(true)
    },[isSmallScreen, setLoadVideo])
       
    return (
        <>
            <BackgroundSVG />
            {isSmallScreen ? <></> : <Header id='header' appear={isIntersectingHome} />}
            <section className='d-flex justify-content-center align-items-center back-blur-30' style={{opacity: '0.9', height: (isSmallScreen ? '600px' : screenHeight + 50), minHeight: (isSmallScreen ? '650px' : screenHeight + 50), paddingTop: (isSmallScreen ? '125px' : '0')}}>
                <HeroSection />
            </section>
            <section ref={homeRef} className='pt-5 back-blur-30 ' style={{opacity: '0.9', position: 'relative', top: (!isSmallScreen ? Math.max(Math.round((screenHeight - screenWidth) / 4), 0) : 0), marginBottom: (!isSmallScreen ? Math.max(Math.round((screenHeight - screenWidth) / 4) - 5, 0) : 0)}}>
                <DetailsCardDeck />
                <BookDemoExtended playVideo={loadVideo} />
                <Fiawords />
                <ProductDemo />
                <OurSolutions />
                <div className={'bg-transition my-bg-' + (!darkMode ? 'light' : 'dark') + ' ' + (isSmallScreen ? 'pb-5' : 'py-5')}>
                    <ContactUs />
                </div>
                <div className={'py-3 my-bg-' + (!darkMode ? 'light' : 'dark')} style={{transition: 'background 0.5s'}}>
                    <AboutSummary />
                </div>
                <div className={(isSmallScreen ? 'mx-3' : '') + ' py-5 bg-transition my-bg-' + (!darkMode ? 'light' : 'dark')}>
                    <NewsletterSignup />
                </div>
            </section>
        </>
    )
}

export default Home;