import React from 'react'
import { useAuth } from '../../../../../contexts/authContext'
import AdTable from '../adTable'
import { transactionTypes } from '../../../payment/payment'
import formatDollars from '../../../../../utils/formatDollars'

const PaymentHistoryTable = () => {
    const { userData } = useAuth()

    const cols = ['Transaction Type', 'Amount', 'Date', 'For']
    const data = (userData.paymentHistory || []).map( payment => ({
        type: transactionTypes[payment.type].type,
        amount: formatDollars(payment.amount * transactionTypes[payment.type].mult),
        data: payment.date.toDate().toLocaleDateString(),
        for: (payment.type > 2 ? (userData.history[payment.for] ? userData.history[payment.for].advertisementName : 'Deleted Ad') : payment.for),
    })).reverse()

    return (
        <AdTable title='Transaction History' tableCols={cols} data={data} bgTheme='secondary' />
    )
}

export default PaymentHistoryTable