import React from 'react'
import { Button } from 'react-bootstrap'
import { BrightnessLowFill, MoonFill } from 'react-bootstrap-icons'
import { useTheme } from '../../contexts/themeContext'

const ColorThemeToggle = () => {
    const { darkMode, setDarkMode } = useTheme()

    return (
        <Button onClick={() => setDarkMode(!darkMode)} className='bg-transparent border-0 p-0 m-0 ms-3'>
            {darkMode ? 
                <BrightnessLowFill className='my-text-primary-light' style={{transform: 'scale(140%)'}}/>
            : 
                <MoonFill className='my-text-dark' /> 
            }
        </Button>
    )
}

export default ColorThemeToggle