import React, { useState } from 'react'
import { useAuth } from '../../../../../contexts/authContext'
import { useTheme } from '../../../../../contexts/themeContext'
import { Button, Card, Col, Row, Modal, Form } from 'react-bootstrap'
import { Cart } from 'react-bootstrap-icons'
import { useNavigate } from 'react-router-dom'
import formatDollars from '../../../../../utils/formatDollars'

const SavedAdvertisementsCard = (props) => {
    const {userData} = useAuth()
    const {darkMode, getTextInv, getCardStyle} = useTheme()
    const navigate = useNavigate()

    const [showBookAll, setShowBookAll] = useState(false)

    return (
        <Card className={'h-100 ' + getCardStyle()} style={{transition: 'background 0.5s, color 0.5s', minHeight: '425px'}}>
            <Card.Header>
                <Card.Title className='d-flex justify-content-between'>
                    <h3 className='fw-bold'>Saved Advertisements</h3>
                    <Cart className='mt-2'/>
                </Card.Title>
            </Card.Header>
            <Card.Body style={{paddingInline: '12px'}}>
                <Row className={'mb-2 border-bottom border-1 border-' + (darkMode ? 'dark' : 'light' )} style={{transition: 'border 0.5s'}}>
                    <Col className='ms-4 text-start'><h5>Name</h5></Col>
                    <Col className='me-4 text-end'><h5>Cost</h5></Col>
                </Row>
                {Object.values(userData.history).filter(ad => ad.isPotential).length ? 
                    Object.keys(userData.history).filter(ad => userData.history[ad].isPotential).map((ad, index) => (
                        <Row className={'my-bg-' + (props.selected === ad ? 'success' : 'transparent')} key={index}>
                            <Button className='p-1 border-0 bg-transparent d-flex flex-row justify-content-between my-text-dark' style={{transition: 'none', opacity: 1}} disabled={props.hideSelected} onClick={() => props.setSelected(ad)}>
                                <Col lg={8} className='text-start'><h4>{userData.history[ad].advertisementName}</h4></Col>
                                <Col className='text-end'><h4>{formatDollars(userData.history[ad].totalCost)}</h4></Col>
                            </Button>
                        </Row>
                    ))
                : 
                    <h2 className='text-center pt-5 mt-4'>No Saved Advertisements</h2>
                }
            </Card.Body>
            <Card.Footer>
                <Row className='d-flex justify-content-between'>
                    <Col className='px-5'>
                        <Button 
                            className={'w-100 m-1 border-1 shadow border-primary bg-gradient ' + (darkMode ? 'my-bg-dark' : 'my-bg-secondary-light') + ' ' + getTextInv()} 
                            style={{transition: 'background 0.5s, color 0.5s'}}
                            disabled={props.hideSelected}
                            onClick={() => navigate('/create-ad')}
                        >
                            Create Ad{props.hideSelected ? ' (Not Available on Mobile)' : ''}
                        </Button>
                    </Col>
                    {/* Disabling book all for now 
                    <Col className='text-end'>
                        <Button 
                            className={'w-100 m-1 border-1 shadow border-primary bg-gradient ' + (darkMode ? 'my-bg-dark' : 'my-bg-secondary-light') + ' ' + getTextInv()} 
                            style={{transition: 'background 0.5s, color 0.5s'}} 
                            onClick={() => setShowBookAll(true)}
                            disabled={!(Object.values(userData.history).filter(ad => ad.isPotential).length) || Object.values(userData.history).filter(ad => ad.isPotential).reduce((acc, ad) => acc + ad.totalCost, 0) > userData.availableFunds || true}
                        >
                            Book All
                        </Button>
                    </Col> */}
                </Row>
            </Card.Footer>
            {props.hideSelected ? <></> : <BookAllAdsModal show={showBookAll} setShow={setShowBookAll} />}
        </Card>
    )
}

const BookAllAdsModal = (props) => {
    const {userData, bookAllSavedAdvertisements, setError, setSuccess} = useAuth()
    const {getBG, getTextInv} = useTheme()

    const [agree, setAgree] = useState(false)
    const [loading, setLoading] = useState(false)

    const getTotalCost = () => Object.values(userData.history).filter(ad => ad.isPotential).reduce((acc, ad) => acc + ad.totalCost, 0)

    const bookAd = async () => {
        setLoading(true)
        setError('')
        setSuccess('')

        try {
            await bookAllSavedAdvertisements()
            setSuccess('Booked Advertisements!')
        } catch (err) {
            setError('Failed to book ads, please try again later')
        } finally {
            setLoading(false)
            props.setShow(false)
        }
    }

    return (
        <Modal size='lg' show={props.show} onHide={() => props.setShow(false)}>
            <Modal.Header className={getBG() + ' ' + getTextInv()} closeButton>
                <Modal.Title className='fs-3'>Final Review (Cost: {getTotalCost()})</Modal.Title>
            </Modal.Header>
            <Modal.Body className={getBG() + ' ' + getTextInv()}>
                <Row><h2 className='text-center'>Before you book:</h2></Row> 
                <ul>
                    <li>Fiable will distribute these ads to the creators we determine are best associated with the ad categories, your product, and your brand. Additionally, creators are vetted by Fiable before receiving any ad. If you find your ad on a channel you do not approve of, please reach out at legal@fiable-solutions.com</li>
                    <li>Once you Book an ad, you will have a short period to cancel it and withdraw your funds. After the cancellation period, once a creator accepts the advertisement, you will no longer be able to cancel it, and you will not be able to withdraw the amount of the ad.</li>
                    <li>You will recieve frequent updates from Fiable regarding the status of the Advertisement. Ads have a default life cycle of one month, and you will receive a detailed report at the end of that cycle. Additionally, you can see live feedback in the dashboard.</li>
                    <li>Fiable does not guarantee you will achieve the target impressions during the advertisements life cycle, however if the ad does not reach the desired impressions, You will have the option to be refunded for the missed impressions.</li>
                </ul>
                <Row className='ps-3'>
                    <Form.Check onChange={(e) => setAgree(!agree)} className='form-control-lg' label={<h5>I agree to the <a href='/advertiserAgreement'>Fiable Advertiser Agreement</a></h5>} />
                </Row>
            </Modal.Body>
            <Modal.Footer className={'d-flex justify-content-between ' + getBG() + ' ' + getTextInv()}>
                <Button onClick={() => props.setShow(false)}>I'm not ready</Button>
                <Button disabled={!agree || loading} onClick={bookAd}>Book Now!</Button>
            </Modal.Footer>
        </Modal>
    )
}

export default SavedAdvertisementsCard