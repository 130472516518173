import React, { useState } from 'react'
import { Button, Col, Container, Row, Form } from 'react-bootstrap';
import { Envelope, Pin, Telephone } from 'react-bootstrap-icons';
import { useTheme } from '../../../../contexts/themeContext';
import { useAuth } from '../../../../contexts/authContext';

const ContactUsCard = () => {
    const { setError, setSuccess, sendEmail } = useAuth()
    const { darkMode, getTextInv, getThemeText, getThemeTextInv, getThemeBGInv, getThemeBGPlatform } = useTheme()

    const [loading, setLoading] = useState( false )

    const handleSubmit = async (e) => {
        e.preventDefault()
        setLoading(true)

        const data = [...(new FormData(e.target)).entries()]
        
        try {
            await sendEmail('fiable@fiable-solutions.com', 'New Contact!', 'Someone just tried to contact us form with the following info: \n' + data.map(entry => entry[0] + ': ' + entry[1] + '\n'), false)
            setSuccess('Message sent! Thank you for getting in touch!')
        } catch (err) {
            setError('Failed to send message, please try again later')
        } finally {
            document.getElementById('contactForm').reset()
            setLoading(false)
        }
    }

    return (
        <Container className='pb-5'>
            <Row className='d-flex justify-content-center'>
                <Col>
                    <div>
                        <Form className='p-3' onSubmit={handleSubmit} id='contactForm' >
                            <Form.Control required className={'mb-3 bg-transition border-0 ' + (darkMode ? 'my-bg-secondary-darkmode-light' : '')} type='text' id='name-1' name='name' placeholder='Name' />
                            <Form.Control required className={'mb-3 bg-transition border-0 ' + (darkMode ? 'my-bg-secondary-darkmode-light' : '')} type='email' id='email-1' name='email' placeholder='Email'/>
                            <Form.Control required className={'mb-3 bg-transition border-0 ' + (darkMode ? 'my-bg-secondary-darkmode-light' : '')} id='message-1' name='message' as='textarea' rows='3' placeholder='Message'/>
                            <div>
                                <Button 
                                    type='submit'
                                    disabled={loading} 
                                    className={'shadow border-1 border-primary d-block w-100 ' + getThemeBGPlatform('secondary') + ' ' + getTextInv()}
                                    style={{transition: 'background 0.5s, color 0.5s'}}
                                >
                                    Send
                                </Button>
                            </div>
                        </Form>
                    </div>
                </Col>
                {window.innerWidth < 750 ? <></> : 
                <Col className='d-flex justify-content-center mt-2'>
                    <div className='d-flex flex-column justify-content-center h-100'>
                        <div className='d-flex align-items-center py-2'>
                            <div 
                                className={'bs-icon-md bs-icon-circle shadow d-flex flex-shrink-0 justify-content-center align-items-center d-inline-block bs-icon ' + getThemeBGInv('primary')}
                                style={{transition: 'background 0.5s'}}
                            >
                                <Telephone className={getThemeText('primary')} style={{transition: 'color 0.5s'}}/>
                            </div>
                            <div className='px-2'>
                                <h6 className={'fw-bold mb-0 my-text-' + (darkMode ? 'light' : 'dark')} style={{transition: 'color 0.5s'}}>Phone</h6>
                                <p className={'fw-bold mb-0 ' + getThemeTextInv('primary')} style={{transition: 'color 0.5s'}}>+1 (202) 525-0775</p>
                            </div>
                        </div>
                        <div className='d-flex align-items-center py-2'>
                            <div 
                                className={'bs-icon-md bs-icon-circle shadow d-flex flex-shrink-0 justify-content-center align-items-center d-inline-block bs-icon ' + getThemeBGInv('primary')}
                                style={{transition: 'background 0.5s'}}
                            >
                                <Envelope className={getThemeText('primary')} style={{transition: 'color 0.5s'}}/>
                            </div>
                            <div className='px-2'>
                                <h6 className={'fw-bold mb-0 my-text-' + (darkMode ? 'light' : 'dark')} style={{transition: 'color 0.5s'}}>Email</h6>
                                <p className={'fw-bold mb-0 ' + getThemeTextInv('primary')} style={{transition: 'color 0.5s'}}>fiable@fiable-solutions.com</p>
                            </div>
                        </div>
                        <div className='d-flex align-items-center py-1'>
                            <div 
                                className={'bs-icon-md bs-icon-circle shadow d-flex flex-shrink-0 justify-content-center align-items-center d-inline-block bs-icon ' + getThemeBGInv('primary')}
                                style={{transition: 'background 0.5s'}}
                            >
                                <Pin className={getThemeText('primary')} style={{transition: 'color 0.5s'}}/>
                            </div>
                            <div className='px-2'>
                                <h6 className={'fw-bold mb-0 my-text-' + (darkMode ? 'light' : 'dark')} style={{transition: 'color 0.5s'}}>Location</h6>
                                <p className={'fw-bold mb-0 ' + getThemeTextInv('primary')} style={{transition: 'color 0.5s'}}>3131 NE 7th Ave. Suite 2701</p>
                                <p className={'fw-bold mb-0 ' + getThemeTextInv('primary')} style={{transition: 'color 0.5s'}}>Miami Fl. 33137</p>
                            </div>
                        </div>
                    </div>
                </Col>
                }
            </Row>
        </Container>
    )
}

export default ContactUsCard;