import React, { useCallback, useEffect, useState } from 'react'
import { Container, Row, Col } from 'react-bootstrap'
import PotentialAdsCard from '../profile/subcomponents/creator/potentialAdsCard'
import EarningsCards from './subcomponents/creator/earningsCards'
import { useAuth } from '../../../contexts/authContext'
import EarningsCard from './subcomponents/creator/earningsCard'
import PaymentDetails from './subcomponents/creator/paymentDetails'
import SavedAdvertisementsCard from './subcomponents/advertiser/savedAdvertisementsCard'
import BookAdvertisementCard from './subcomponents/advertiser/bookAdvertisementCard'
import { useParams } from 'react-router-dom'
import PaymentHistoryTable from '../history/subcomponents/advertiser/paymentHistoryTable'
import { useTheme } from '../../../contexts/themeContext'

// 0 is nothing, 1 is add funds, 2 is withdraw funds, 3 is book ad, 4 is refund/cancel ad
export const transactionTypes = [{}, {type: 'Deposit', mult: 1}, {type: 'Withdrawal', mult: -1}, {type: 'Booking', mult: -1}, {type: 'Refund', mult: 1}]

const Payment = () => {
    const {type, userData, getCreatorEarnings} = useAuth()
    const { isSmallScreen } = useTheme()

    const params = useParams()
    const adId = params.adId && params.adId !== 'undefined' ? params.adId : ''
    const [selectedAd, setSelectedAd] = useState(adId)
    const [earnings, setEarnings] = useState({'next': 0, 'total': 0, 'potential': 0, 'available': 0, 'nextList': [], 'totalList': [], 'potentialList': [], 'availableList': []})

    const getEarnings = useCallback(() => {
        const creatorEarnings = getCreatorEarnings()
        setEarnings(creatorEarnings)
    }, [getCreatorEarnings])
    
    useEffect(() => {
        if (type) {
            getEarnings()
        } else {
            if (adId.length) setSelectedAd(adId)
            else if (Object.keys(userData.history).filter(ad => userData.history[ad].isPotential).length)
                setSelectedAd(Object.keys(userData.history).filter(ad => userData.history[ad].isPotential)[0])
        }
    }, [getEarnings, adId, type, userData.history])

    return ( type === 1 ?
        <Container fluid className='p-2'>
            <Row>
                <Col><PotentialAdsCard /></Col>
            </Row>
            <Row>
                <Col><EarningsCards /></Col>
            </Row>
            <Row className='pb-3'>
                <Col className='ms-1 my-1'>
                    <EarningsCard rows={earnings['nextList']} title={'Next Payment Breakdown'} footer='Total: ' footerValue={earnings['next']}/>
                </Col>
                <Col className='my-1'>
                    <EarningsCard rows={earnings['totalList']} title='Total Payments Breakdown' footer='Total: ' footerValue={earnings['total']}/>
                </Col>
                <Col className='me-1 my-1'>
                    <EarningsCard rows={earnings['potentialList']} title='Potential Earnings Breakdown' footer='Total: ' footerValue={earnings['potential']}/>
                </Col>
            </Row>
            {isSmallScreen ? <></> :
                <Row>
                    <Col>
                        <PaymentDetails available={earnings['available']} availableList={earnings['availableList']} />
                    </Col>
                </Row>
            }
        </Container>
        :
        <Container fluid className='p-2'>
            {isSmallScreen ?
                <Row>
                    <Col><SavedAdvertisementsCard hideSelected /></Col>
                </Row>
            :
            <>
                <Row>
                    <Col lg={4}><SavedAdvertisementsCard selected={selectedAd} setSelected={setSelectedAd} /></Col>
                    <Col lg={8}><BookAdvertisementCard selected={selectedAd} setSelected={setSelectedAd} /></Col>
                </Row>
                <Row className='pt-2'>
                    <Col><PaymentHistoryTable /></Col>
                </Row>
            </>}
        </Container>
    )
}

export default Payment