import React from "react";
import { useTheme } from "../../../../contexts/themeContext";

const BackgroundSVG = (props) => {
    const { darkMode } = useTheme()
    const lightModeDot = (props.invert ? '#00BBFF' : 'url(#grad1)')
    const darkModeDot = (props.invert ? 'url(#grad1)' : '#FFEECD')
    const darkModeShadow = (props.invert ? '#FFEECD' : '#ED5113')
    const lightModeShadow = (props.invert ? '#ED5113' : '#FFEECD')
    return (
        <svg width={window.innerWidth * 1.5} height={window.innerHeight * 1.5} viewBox="0 0 291 215" fill="none" xmlns="http://www.w3.org/2000/svg" className='position-absolute z-0' style={{transform: 'translate(-17%, -12%)', opacity: '0.6', filter: (darkMode ? `drop-shadow(1mm -5mm 4mm ${darkModeShadow})` : `drop-shadow(1mm -5mm 4mm ${lightModeShadow})`), animation: 'slingy 4s infinite alternate-reverse ease-in-out', transition: 'all 0.5s'}}>
            <path d="M102.578 18.5941L259.7 6.50781L284.492 130.16L86.1532 207.695L6.50781 141.006L19.5238 82.4343L25.1021 27.8912L102.578 18.5941Z" fill="url(#paint0_radial_329_546)"/>
            <path d="M102.578 18.5941L259.7 6.50781L102.578 19.5941L68.4887 49.8944M102.578 18.5941L25.1021 27.8912M259.7 6.50781L225.3 30.9902M259.7 6.50781L284.492 130.16M225.3 30.9902L284.492 130.16M225.3 30.9902V111.565M225.3 30.9902L153.093 53.9232M225.3 30.9902L192.76 70.658M284.492 130.16L86.1532 207.695M284.492 130.16L225.3 111.565M86.1532 207.695L102.578 111.565M86.1532 207.695L6.50781 141.006M86.1532 207.695L156.192 87.3928M102.578 111.565L99.1692 70.658M102.578 111.565L53.3034 109.396M99.1692 70.658L53.3034 109.396M99.1692 70.658L153.093 53.9232M53.3034 109.396L68.4887 49.8944M53.3034 109.396L6.50781 141.006M68.4887 49.8944L153.093 53.9232M68.4887 49.8944L25.1021 27.8912M153.093 53.9232L156.192 87.3928M153.093 53.9232L192.76 70.658M156.192 87.3928L225.3 111.565M225.3 111.565L207.713 97.1251C200.058 90.8399 194.87 82.0515 193.067 72.3123L192.76 70.658M25.1021 27.8912L19.5238 82.4343L6.50781 141.006" 
            stroke={props.invert ? (darkMode ? '#D6D6D6' : "white") : (darkMode ? "#852D0B" : '#D6D6D6')} strokeWidth="1" />
            <defs>
                <linearGradient id="grad1" x1="0%" y1="0%" x2="100%" y2="100%">
                    <stop offset="12%" style={{ stopColor: 'rgba(209,120,12,1)' }} />
                    <stop offset="25%" style={{ stopColor: 'rgba(255,184,0,1)' }} />
                    <stop offset="45%" style={{ stopColor: 'rgba(255,184,0,1)' }} />
                    <stop offset="100%" style={{ stopColor: 'rgba(255,238,205,1)' }} />
                </linearGradient>
             </defs>
            <mask id="path-3-inside-1_329_546" fill="white" >
            <ellipse cx="99.4797" cy="71.8976" rx="6.50799" ry="6.50799"/>
            </mask>
            <ellipse cx="99.4797" cy="71.8976" rx="6.50799" ry="6.50799" fill={darkMode ? darkModeDot : lightModeDot} />
                <path d="M94.9877 71.8976C94.9877 69.4168 96.9988 67.4056 99.4797 67.4056V89.4056C109.149 89.4056 116.988 81.567 116.988 71.8976H94.9877ZM99.4797 67.4056C101.961 67.4056 103.972 69.4168 103.972 71.8976H81.9717C81.9717 81.567 89.8103 89.4056 99.4797 89.4056V67.4056ZM103.972 71.8976C103.972 74.3785 101.961 76.3896 99.4797 76.3896V54.3896C89.8103 54.3896 81.9717 62.2282 81.9717 71.8976H103.972ZM99.4797 76.3896C96.9988 76.3896 94.9877 74.3785 94.9877 71.8976H116.988C116.988 62.2282 109.149 54.3896 99.4797 54.3896V76.3896Z" fill={darkMode ? darkModeDot : lightModeDot} mask="url(#path-3-inside-1_329_546)"/>
                <mask id="path-5-inside-2_329_546" fill="white">
            <ellipse cx="101.958" cy="111.566" rx="6.50799" ry="6.50799"/>
            </mask>
            <ellipse cx="101.958" cy="111.566" rx="6.50799" ry="6.50799" fill={darkMode ? darkModeDot : lightModeDot}/>
                <path d="M97.4662 111.566C97.4662 109.085 99.4773 107.074 101.958 107.074V129.074C111.628 129.074 119.466 121.235 119.466 111.566H97.4662ZM101.958 107.074C104.439 107.074 106.45 109.085 106.45 111.566H84.4502C84.4502 121.235 92.2888 129.074 101.958 129.074V107.074ZM106.45 111.566C106.45 114.046 104.439 116.058 101.958 116.058V94.0576C92.2888 94.0576 84.4502 101.896 84.4502 111.566H106.45ZM101.958 116.058C99.4773 116.058 97.4662 114.046 97.4662 111.566H119.466C119.466 101.896 111.628 94.0576 101.958 94.0576V116.058Z" fill={darkMode ? darkModeDot : lightModeDot} mask="url(#path-5-inside-2_329_546)"/>
                <mask id="path-7-inside-3_329_546" fill="white">
            <ellipse cx="54.5431" cy="109.396" rx="6.50799" ry="6.50799"/>
            </mask>
            <ellipse cx="54.5431" cy="109.396" rx="6.50799" ry="6.50799" fill={darkMode ? darkModeDot : lightModeDot}/>
                <path d="M50.0511 109.396C50.0511 106.915 52.0623 104.904 54.5431 104.904V126.904C64.2125 126.904 72.0511 119.065 72.0511 109.396H50.0511ZM54.5431 104.904C57.024 104.904 59.0352 106.915 59.0352 109.396H37.0352C37.0352 119.065 44.8738 126.904 54.5431 126.904V104.904ZM59.0352 109.396C59.0352 111.877 57.024 113.888 54.5431 113.888V91.8877C44.8738 91.8877 37.0352 99.7263 37.0352 109.396H59.0352ZM54.5431 113.888C52.0623 113.888 50.0511 111.877 50.0511 109.396H72.0511C72.0511 99.7263 64.2125 91.8877 54.5431 91.8877V113.888Z" fill={darkMode ? darkModeDot : lightModeDot} mask="url(#path-7-inside-3_329_546)"/>
                <mask id="path-9-inside-4_329_546" fill="white">
            <ellipse cx="67.5588" cy="50.5138" rx="6.50799" ry="6.50799"/>
            </mask>
            <ellipse cx="67.5588" cy="50.5138" rx="6.50799" ry="6.50799" fill={darkMode ? darkModeDot : lightModeDot}/>
                <path d="M63.0668 50.5138C63.0668 48.033 65.0779 46.0218 67.5588 46.0218V68.0218C77.2282 68.0218 85.0668 60.1832 85.0668 50.5138H63.0668ZM67.5588 46.0218C70.0396 46.0218 72.0508 48.033 72.0508 50.5138H50.0508C50.0508 60.1832 57.8894 68.0218 67.5588 68.0218V46.0218ZM72.0508 50.5138C72.0508 52.9947 70.0396 55.0059 67.5588 55.0059V33.0059C57.8894 33.0059 50.0508 40.8445 50.0508 50.5138H72.0508ZM67.5588 55.0059C65.0779 55.0059 63.0668 52.9947 63.0668 50.5138H85.0668C85.0668 40.8445 77.2282 33.0059 67.5588 33.0059V55.0059Z" fill={darkMode ? darkModeDot : lightModeDot} mask="url(#path-9-inside-4_329_546)"/>
                <mask id="path-11-inside-5_329_546" fill="white">
            <ellipse cx="101.958" cy="18.5939" rx="6.50799" ry="6.50799"/>
            </mask>
            <ellipse cx="101.958" cy="18.5939" rx="6.50799" ry="6.50799" fill={darkMode ? darkModeDot : lightModeDot}/>
                <path d="M97.4662 18.5939C97.4662 16.1131 99.4773 14.1019 101.958 14.1019V36.1019C111.628 36.1019 119.466 28.2633 119.466 18.5939H97.4662ZM101.958 14.1019C104.439 14.1019 106.45 16.1131 106.45 18.5939H84.4502C84.4502 28.2633 92.2888 36.1019 101.958 36.1019V14.1019ZM106.45 18.5939C106.45 21.0748 104.439 23.0859 101.958 23.0859V1.08594C92.2888 1.08594 84.4502 8.92453 84.4502 18.5939H106.45ZM101.958 23.0859C99.4773 23.0859 97.4662 21.0748 97.4662 18.5939H119.466C119.466 8.92453 111.628 1.08594 101.958 1.08594V23.0859Z" fill={darkMode ? darkModeDot : lightModeDot} mask="url(#path-11-inside-5_329_546)"/>
                <mask id="path-13-inside-6_329_546" fill="white">
            <ellipse cx="25.7219" cy="28.5109" rx="6.50799" ry="6.50799"/>
            </mask>
            <ellipse cx="25.7219" cy="28.5109" rx="6.50799" ry="6.50799" fill={darkMode ? darkModeDot : lightModeDot}/>
                <path d="M21.2298 28.5109C21.2298 26.03 23.241 24.0189 25.7219 24.0189V46.0189C35.3913 46.0189 43.2298 38.1803 43.2298 28.5109H21.2298ZM25.7219 24.0189C28.2027 24.0189 30.2139 26.03 30.2139 28.5109H8.21387C8.21387 38.1803 16.0525 46.0189 25.7219 46.0189V24.0189ZM30.2139 28.5109C30.2139 30.9918 28.2027 33.0029 25.7219 33.0029V11.0029C16.0525 11.0029 8.21387 18.8415 8.21387 28.5109H30.2139ZM25.7219 33.0029C23.241 33.0029 21.2298 30.9918 21.2298 28.5109H43.2298C43.2298 18.8415 35.3913 11.0029 25.7219 11.0029V33.0029Z" fill={darkMode ? darkModeDot : lightModeDot} mask="url(#path-13-inside-6_329_546)"/>
                <mask id="path-15-inside-7_329_546" fill="white">
            <ellipse cx="193.38" cy="69.7287" rx="6.50799" ry="6.50799"/>
            </mask>
            <ellipse cx="193.38" cy="69.7287" rx="6.50799" ry="6.50799" fill={darkMode ? darkModeDot : lightModeDot}/>
                <path d="M188.888 69.7287C188.888 67.2478 190.899 65.2367 193.38 65.2367V87.2367C203.049 87.2367 210.888 79.3981 210.888 69.7287H188.888ZM193.38 65.2367C195.861 65.2367 197.872 67.2478 197.872 69.7287H175.872C175.872 79.3981 183.711 87.2367 193.38 87.2367V65.2367ZM197.872 69.7287C197.872 72.2096 195.861 74.2207 193.38 74.2207V52.2207C183.711 52.2207 175.872 60.0593 175.872 69.7287H197.872ZM193.38 74.2207C190.899 74.2207 188.888 72.2096 188.888 69.7287H210.888C210.888 60.0593 203.049 52.2207 193.38 52.2207V74.2207Z" fill={darkMode ? darkModeDot : lightModeDot} mask="url(#path-15-inside-7_329_546)"/>
                <mask id="path-17-inside-8_329_546" fill="white">
            <ellipse cx="225.609" cy="31.6096" rx="6.50799" ry="6.50799"/>
            </mask>
            <ellipse cx="225.609" cy="31.6096" rx="6.50799" ry="6.50799" fill={darkMode ? darkModeDot : lightModeDot}/>
                <path d="M221.117 31.6096C221.117 29.1287 223.128 27.1175 225.609 27.1175V49.1175C235.278 49.1175 243.117 41.2789 243.117 31.6096H221.117ZM225.609 27.1175C228.089 27.1175 230.101 29.1287 230.101 31.6096H208.101C208.101 41.2789 215.939 49.1175 225.609 49.1175V27.1175ZM230.101 31.6096C230.101 34.0904 228.089 36.1016 225.609 36.1016V14.1016C215.939 14.1016 208.101 21.9402 208.101 31.6096H230.101ZM225.609 36.1016C223.128 36.1016 221.117 34.0904 221.117 31.6096H243.117C243.117 21.9402 235.278 14.1016 225.609 14.1016V36.1016Z" fill={darkMode ? darkModeDot : lightModeDot} mask="url(#path-17-inside-8_329_546)"/>
                <mask id="path-19-inside-9_329_546" fill="white">
            <ellipse cx="153.711" cy="54.5431" rx="6.50799" ry="6.50799"/>
            </mask>
            <ellipse cx="153.711" cy="54.5431" rx="6.50799" ry="6.50799" fill={darkMode ? darkModeDot : lightModeDot}/>
                <path d="M149.219 54.5431C149.219 52.0623 151.23 50.0511 153.711 50.0511V72.0511C163.381 72.0511 171.219 64.2125 171.219 54.5431H149.219ZM153.711 50.0511C156.192 50.0511 158.203 52.0623 158.203 54.5431H136.203C136.203 64.2125 144.042 72.0511 153.711 72.0511V50.0511ZM158.203 54.5431C158.203 57.024 156.192 59.0352 153.711 59.0352V37.0352C144.042 37.0352 136.203 44.8737 136.203 54.5431H158.203ZM153.711 59.0352C151.23 59.0352 149.219 57.024 149.219 54.5431H171.219C171.219 44.8737 163.381 37.0352 153.711 37.0352V59.0352Z" fill={darkMode ? darkModeDot : lightModeDot} mask="url(#path-19-inside-9_329_546)"/>
                <mask id="path-21-inside-10_329_546" fill="white">
            <ellipse cx="156.192" cy="87.7033" rx="6.50799" ry="6.50799"/>
            </mask>
            <ellipse cx="156.192" cy="87.7033" rx="6.50799" ry="6.50799" fill={darkMode ? darkModeDot : lightModeDot}/>
                <path d="M151.7 87.7033C151.7 85.2224 153.711 83.2113 156.192 83.2113V105.211C165.861 105.211 173.7 97.3727 173.7 87.7033H151.7ZM156.192 83.2113C158.672 83.2113 160.684 85.2224 160.684 87.7033H138.684C138.684 97.3727 146.522 105.211 156.192 105.211V83.2113ZM160.684 87.7033C160.684 90.1842 158.672 92.1953 156.192 92.1953V70.1953C146.522 70.1953 138.684 78.0339 138.684 87.7033H160.684ZM156.192 92.1953C153.711 92.1953 151.7 90.1842 151.7 87.7033H173.7C173.7 78.0339 165.861 70.1953 156.192 70.1953V92.1953Z" fill={darkMode ? darkModeDot : lightModeDot} mask="url(#path-21-inside-10_329_546)"/>
                <mask id="path-23-inside-11_329_546" fill="white">
            <ellipse cx="86.464" cy="207.713" rx="6.50799" ry="6.50799"/>
            </mask>
            <ellipse cx="86.464" cy="207.713" rx="6.50799" ry="6.50799" fill={darkMode ? darkModeDot : lightModeDot}/>
                <path d="M81.972 207.713C81.972 205.232 83.9832 203.221 86.464 203.221V225.221C96.1334 225.221 103.972 217.382 103.972 207.713H81.972ZM86.464 203.221C88.9449 203.221 90.9561 205.232 90.9561 207.713H68.9561C68.9561 217.382 76.7947 225.221 86.464 225.221V203.221ZM90.9561 207.713C90.9561 210.194 88.9449 212.205 86.464 212.205V190.205C76.7947 190.205 68.9561 198.044 68.9561 207.713H90.9561ZM86.464 212.205C83.9832 212.205 81.972 210.194 81.972 207.713H103.972C103.972 198.044 96.1334 190.205 86.464 190.205V212.205Z" fill={darkMode ? darkModeDot : lightModeDot} mask="url(#path-23-inside-11_329_546)"/>
                <mask id="path-25-inside-12_329_546" fill="white">
            <ellipse cx="258.461" cy="6.50799" rx="6.50799" ry="6.50799"/>
            </mask>
            <ellipse cx="258.461" cy="6.50799" rx="6.50799" ry="6.50799" fill={darkMode ? darkModeDot : lightModeDot}/>
                <path d="M253.969 6.50799C253.969 4.02712 255.98 2.01598 258.461 2.01598V24.016C268.131 24.016 275.969 16.1774 275.969 6.50799H253.969ZM258.461 2.01598C260.942 2.01598 262.953 4.02712 262.953 6.50799H240.953C240.953 16.1774 248.792 24.016 258.461 24.016V2.01598ZM262.953 6.50799C262.953 8.98886 260.942 11 258.461 11V-11C248.792 -11 240.953 -3.16141 240.953 6.50799H262.953ZM258.461 11C255.98 11 253.969 8.98886 253.969 6.50799H275.969C275.969 -3.16141 268.131 -11 258.461 -11V11Z" fill={darkMode ? darkModeDot : lightModeDot} mask="url(#path-25-inside-12_329_546)"/>
                <mask id="path-27-inside-13_329_546" fill="white">
            <ellipse cx="284.491" cy="129.54" rx="6.50799" ry="6.50799"/>
            </mask>
            <ellipse cx="284.491" cy="129.54" rx="6.50799" ry="6.50799" fill={darkMode ? darkModeDot : lightModeDot}/>
                <path d="M279.999 129.54C279.999 127.059 282.011 125.048 284.491 125.048V147.048C294.161 147.048 301.999 139.21 301.999 129.54H279.999ZM284.491 125.048C286.972 125.048 288.983 127.059 288.983 129.54H266.983C266.983 139.21 274.822 147.048 284.491 147.048V125.048ZM288.983 129.54C288.983 132.021 286.972 134.032 284.491 134.032V112.032C274.822 112.032 266.983 119.871 266.983 129.54H288.983ZM284.491 134.032C282.011 134.032 279.999 132.021 279.999 129.54H301.999C301.999 119.871 294.161 112.032 284.491 112.032V134.032Z" fill={darkMode ? darkModeDot : lightModeDot} mask="url(#path-27-inside-13_329_546)"/>
                <mask id="path-29-inside-14_329_546" fill="white">
            <ellipse cx="225.609" cy="111.566" rx="6.50799" ry="6.50799"/>
            </mask>
            <ellipse cx="225.609" cy="111.566" rx="6.50799" ry="6.50799" fill={darkMode ? darkModeDot : lightModeDot}/>
                <path d="M221.117 111.566C221.117 109.085 223.128 107.074 225.609 107.074V129.074C235.278 129.074 243.117 121.235 243.117 111.566H221.117ZM225.609 107.074C228.089 107.074 230.101 109.085 230.101 111.566H208.101C208.101 121.235 215.939 129.074 225.609 129.074V107.074ZM230.101 111.566C230.101 114.046 228.089 116.058 225.609 116.058V94.0576C215.939 94.0576 208.101 101.896 208.101 111.566H230.101ZM225.609 116.058C223.128 116.058 221.117 114.046 221.117 111.566H243.117C243.117 101.896 235.278 94.0576 225.609 94.0576V116.058Z" fill={darkMode ? darkModeDot : lightModeDot} mask="url(#path-29-inside-14_329_546)"/>
                <mask id="path-31-inside-15_329_546" fill="white">
            <ellipse cx="19.5236" cy="82.7443" rx="6.50799" ry="6.50799"/>
            </mask>
            <ellipse cx="19.5236" cy="82.7443" rx="6.50799" ry="6.50799" fill={darkMode ? darkModeDot : lightModeDot}/>
                <path d="M15.0316 82.7443C15.0316 80.2634 17.0427 78.2523 19.5236 78.2523V100.252C29.193 100.252 37.0316 92.4137 37.0316 82.7443H15.0316ZM19.5236 78.2523C22.0045 78.2523 24.0156 80.2634 24.0156 82.7443H2.01562C2.01562 92.4137 9.85422 100.252 19.5236 100.252V78.2523ZM24.0156 82.7443C24.0156 85.2252 22.0045 87.2363 19.5236 87.2363V65.2363C9.85422 65.2363 2.01562 73.0749 2.01562 82.7443H24.0156ZM19.5236 87.2363C17.0427 87.2363 15.0316 85.2252 15.0316 82.7443H37.0316C37.0316 73.0749 29.193 65.2363 19.5236 65.2363V87.2363Z" fill={darkMode ? darkModeDot : lightModeDot} mask="url(#path-31-inside-15_329_546)"/>
                <mask id="path-33-inside-16_329_546" fill="white">
            <ellipse cx="6.50799" cy="141.007" rx="6.50799" ry="6.50799"/>
            </mask>
            <ellipse cx="6.50799" cy="141.007" rx="6.50799" ry="6.50799" fill={darkMode ? darkModeDot : lightModeDot}/>
            <path d="M2.01598 141.007C2.01598 138.526 4.02712 136.515 6.50799 136.515V158.515C16.1774 158.515 24.016 150.676 24.016 141.007H2.01598ZM6.50799 136.515C8.98886 136.515 11 138.526 11 141.007H-11C-11 150.676 -3.1614 158.515 6.50799 158.515V136.515ZM11 141.007C11 143.488 8.98886 145.499 6.50799 145.499V123.499C-3.1614 123.499 -11 131.338 -11 141.007H11ZM6.50799 145.499C4.02712 145.499 2.01598 143.488 2.01598 141.007H24.016C24.016 131.338 16.1774 123.499 6.50799 123.499V145.499Z" fill={darkMode ? darkModeDot : lightModeDot} mask="url(#path-33-inside-16_329_546)"/>
            <defs>
            {/* <radialGradient id="paint0_radial_329_546" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(115.284 86.773) rotate(-147.478) scale(160.248 139.753)">
            <stop stop-color="#FFE6B7"/>
            <stop offset="0.5" stop-color="#FFB800"/>
            <stop offset="1" stop-color="#A44303"/>
            </radialGradient> */}
            </defs>
        </svg>
    )
}

export default BackgroundSVG