import React from 'react'
import { Col, Row } from 'react-bootstrap'
import MedalView from './medals/medalView'
import { LockFill } from 'react-bootstrap-icons'
import { useTheme } from '../../../../contexts/themeContext'
import { medals } from './medals/medals'


const TierDisplay = ({tier, userTier}) => {
    const {darkMode} = useTheme()

    const tierNames = [
        'Getting Started',
        'Increasing Your Presence',
        'Engaging with Advertisers',
        'Building Your Brand',
        'Making Your Mark'
    ]

    return (
        <Row className={'d-flex justify-content-center border-bottom border-2 border-' + (darkMode ? 'light' : 'dark')} style={{opacity: (tier <= userTier ? 1 : 0.6), transition: 'border 0.5s'}}>
            <span className='pb-2'>Tier {tier} ({tierNames[tier - 1]})</span>
            {Object.keys(medals).filter(m => medals[m].tier === tier).map(medalId => (
                <Col key={medalId} lg={3} className='d-flex justify-content-center' style={{height: '120px'}}>
                    <MedalView medalId={medalId} size={120} clickable={tier <= userTier} />
                </Col>
            ))}
            {tier > userTier ?
                <div className='position-absolute d-flex justify-content-center pt-4 mt-3' style={{fontSize: '72px', opacity: 0.4, zIndex: 5}}><LockFill /></div>
            : <></>
            }
        </Row>
    )
}

export default TierDisplay