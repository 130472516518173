import React, { useState } from 'react'
import { Card, Col, Dropdown } from 'react-bootstrap';
import { useTheme } from '../../../../../contexts/themeContext';
import { ThreeDotsVertical } from 'react-bootstrap-icons';
import { useAuth } from '../../../../../contexts/authContext';
import LineGraph from '../lineGraph';

const EarningsLineGraph = () => {
    const {userData} = useAuth()
    const { getBG, getCardStyle, getTextInv } = useTheme()

    const [range, setRange] = useState([0, 12])

    const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec']
    const getLineData = () => {
        let monthValues = {}
        let currentDate = new Date()

        months.forEach( monthName => {
            monthValues[monthName] = 0
        })
        
        Object.values(userData.history).forEach( ad => {
            let adDate = ad.startDate.toDate()
            let adRevenue = Math.round((ad.totalCost * (ad.currentImpressions / ad.totalImpressions ) * 100)) / 100

            if ( ((currentDate - adDate) / (1000 * 3600 * 24 * 365)) <= 1) {
                monthValues[months[adDate.getMonth()]] += adRevenue
            }
        })

        let result = []
        months.forEach( month => {
            result.push({ name: month, value: monthValues[month]})
        })
        result = result.slice(currentDate.getMonth() + 1).concat(result.slice(0, currentDate.getMonth() + 1))
        
        return result
    }

    return (
        <Col>
            <Card className={getCardStyle()}>
                <Card.Header className='d-flex justify-content-between align-items-center'>
                    <h6 className='fw-bold m-0'>
                        Earnings Overview
                    </h6>
                    <Dropdown className='rounded bg-transparent'>
                        <Dropdown.Toggle className='dropdown-toggle-arrowless border-0 bg-transparent'>
                            <ThreeDotsVertical />
                        </Dropdown.Toggle>
                        <Dropdown.Menu className={'shadow ' + getBG()}>
                            <Dropdown.Item className={getTextInv()} onClick={() => setRange([0, 12])}>
                                1 Year (default)
                            </Dropdown.Item>
                            <Dropdown.Item className={getTextInv()} onClick={() => setRange([6, 12])}>
                                6 Months
                            </Dropdown.Item>
                            <Dropdown.Item className={getTextInv()} onClick={() => setRange([11 - (new Date()).getMonth(), 12])}>
                                Year to Date
                            </Dropdown.Item>
                        </Dropdown.Menu>
                    </Dropdown>
                </Card.Header>
                <Card.Body className='d-flex justify-content-start align-items-center'>
                    <LineGraph lineData={getLineData().slice(range[0], range[1])} />
                </Card.Body>
            </Card>
        </Col>
    )
}

export default EarningsLineGraph;