import React, { useCallback, useEffect, useState } from 'react'
import { Button, Card, Col, Modal, Row } from 'react-bootstrap'
import { ArrowLeftCircle, CheckCircleFill, ExclamationCircleFill } from 'react-bootstrap-icons'
import { useAuth } from '../../../../contexts/authContext'
import { useTheme } from '../../../../contexts/themeContext'
import { useNavigate } from 'react-router-dom'
import CreatorApplicationModal from './creator/creatorApplicationModal'
import AdvertiserApplicationModal from './advertiser/advertiserApplicationModal'
import ConsumerProfileTool from './tools/advertiser/consumerProfileTool'
import AdGuideTool from './tools/creator/adGuideTool'
import BudgetMakerTool from './tools/advertiser/budgetMakerTool'
import ConnectChannelTool from './tools/creator/connectChannelTool'
import SocialPage from '../../social/subcomponents/socialPage'
// import RevenueTool from './tools/creator/revenueTool'

const AccountActions = ({fromDashboard}) => {
    const { userData, type } = useAuth()
    const { getCardStyle } = useTheme()

    return (
        <Card className={(fromDashboard ? '' : 'mb-3') + ' pb-3 ' + getCardStyle()} style={{transition: 'all 0.5s'}}>
            <Card.Header className='fw-bold d-flex justify-content-between rounded-top fs-4'>
                Account Actions {userData.firstTimeUser ? '(Complete these in order to finalize your account)' : ''}
                {userData.firstTimeUser ? <ExclamationCircleFill className='my-text-danger fs-4 mt-1'/> : <></>}
            </Card.Header>
            {type === 1 ? <CreatorViewBody /> : <AdvertiserViewBody />}
        </Card>
    )
}

const CreatorViewBody = () => {
    const {userData} = useAuth()

    return (
        <Card.Body className='d-inline-flex justify-content-between flex-wrap text-center py-2 px-4 m-0'>
            <AccountActionCard><JoinSocial /></AccountActionCard>
            <AccountActionCard><ConnectChannel /></AccountActionCard>
            <AccountActionCard><BucketSelection /></AccountActionCard>
            <AccountActionCard>{userData.firstTimeUser || userData.pending ? <ActivationApplication /> : <AdTypePreferences />}</AccountActionCard>
        </Card.Body>
    )
}

const AdvertiserViewBody = () => {
    const {userData} = useAuth()
    
    return (
        <Card.Body className='d-inline-flex justify-content-between flex-wrap text-center py-2 px-4 m-0'>
            <AccountActionCard><SetBudget /></AccountActionCard>
            <AccountActionCard><BucketSelection /></AccountActionCard>
            <AccountActionCard><AdTypePreferences /></AccountActionCard>
            <AccountActionCard>{userData.firstTimeUser || userData.pending ? <ActivationApplication /> : <BookAnAd />}</AccountActionCard>
        </Card.Body>
    )
}

const AccountActionCard = (props) => {
    const { getThemeBG } = useTheme()

    return (
        <Card className={'shadow rounded border-1 py-4 my-text-dark ' + getThemeBG('primary')} style={{minWidth: '230px'}}>
            <Card.Body>
                <Row>
                    {props.children}
                </Row>
            </Card.Body>
        </Card>
    )
}

const ActionSubText = ({text}) => <h6 className='fst-italic pb-3' style={{fontSize: '14px'}}>{text}</h6>

const ActivationApplication = () => {
    const { userData, type } = useAuth()
    const { getButtonStyle } = useTheme()

    const [ showApplication, setShowApplication ] = useState(false)
    const [ isPending, setIsPending ] = useState( userData.pending || userData.pending === 'true' )

    return (
        <Col>
            <h6 className='fw-bold pt-1 mb-1'>
                {isPending ? <CheckCircleFill className='my-text-success me-1 pb-1' /> : <></>}
                Account Activation Form
            </h6>
            <ActionSubText text='Help us get to know you' />
            <Button className={'m-1 ' + getButtonStyle('sm')} disabled={isPending} onClick={() => setShowApplication(true)}>
                {isPending ? 'Awaiting Review' : 'Activate Account'}
            </Button>
            {type === 1 ? 
                <CreatorApplicationModal show={showApplication} setShow={setShowApplication} setPending={setIsPending} />
            :
                <AdvertiserApplicationModal show={showApplication} setShow={setShowApplication} setPending={setIsPending} />
            }
        </Col>
    )
}

const SetBudget = () => {
    const { userData } = useAuth()
    const {getButtonStyle, getTextInv, getBG } = useTheme()

    const [showBudget, setShowBudget] = useState(false)

    return (
        <Col>
            <h6 className='fw-bold pt-1'>
                {userData.budget ? <CheckCircleFill className='my-text-success me-1 pb-1' /> : <></>}
                Review Budget
            </h6>
            <ActionSubText text='Set or update a budget' />
            <Button className={'m-1 ' + getButtonStyle('sm')} onClick={() => setShowBudget(true)}>
                Budget Tool
            </Button>
            <Modal size='xl' show={showBudget} onHide={() => setShowBudget(false)} className={getTextInv()}>
                <Modal.Header className={getBG()} closeButton>Set a Budget</Modal.Header>
                <Modal.Body className={'rounded-bottom ' + getBG()}>
                    <BudgetMakerTool />
                </Modal.Body>
            </Modal>
        </Col>
    )
}

const ConnectChannel = () => {
    const { userData } = useAuth()
    const { getThemeBGPlatform, getTextInv, getButtonStyle } = useTheme()

    const hasChannel = userData.channels ? userData.channels.length : false
    const navigate = useNavigate()

    const [showChannel, setShowChannel] = useState(false)

    return (
        <Col>
            <h6 className='fw-bold pt-1 mb-1'>
                {hasChannel ? <CheckCircleFill className='my-text-success me-1 pb-1' /> : <></>}
                {hasChannel ? 'Refresh Channel Data' : 'Connect a Channel'}
            </h6>
            <ActionSubText text={hasChannel ? 'Helps keep ads relevant' : 'Necessary to receive Ads'} />
            <Button className={'m-1 ' + getButtonStyle('sm')} onClick={() => hasChannel ? navigate('/channel') : setShowChannel(true)}>
                {hasChannel ? 'Go to Channels' : 'Connection Guide'}
            </Button>
            <Modal size='xl' show={showChannel} onHide={() => setShowChannel(false)} className={'back-blur ' + getTextInv()}>
                <Modal.Header className={'fw-bold fs-5 ' + getThemeBGPlatform('secondary')} closeButton>Connection Instructions</Modal.Header>
                <Modal.Body className={'rounded-bottom ' + getThemeBGPlatform('secondary')}>
                    <ConnectChannelTool />
                </Modal.Body>
            </Modal>
        </Col>
    )
}

const JoinSocial = () => {
    const { userData } = useAuth()
    const { getButtonStyle, getBG } = useTheme()
    const navigate = useNavigate()

    const [showSocial, setShowSocial] = useState(false)

    return (
        <Col>
            <h6 className='fw-bold pt-1 mb-1'>
                {userData.socialCode && userData.social ? <CheckCircleFill className='my-text-success me-1 pb-1' /> : <></>}
                {userData.socialCode && userData.social ? 'View Your Social Page' : 'Join the SociAble Network'}
            </h6>
            <ActionSubText text='Connect with similar creators' />
            <Button className={'m-1 ' + getButtonStyle('sm')} onClick={() => userData.socialCode ? setShowSocial(true) : navigate('/social')}>
                {userData.socialCode ? 'Social Page' : 'Join SociAble'}
            </Button>
            <Modal size='xl' show={showSocial} onHide={() => setShowSocial(false)} className={'back-blur'}>
                <Modal.Body className={'rounded ' + getBG()}>
                    <SocialPage socialCode={userData.socialCode} loadedData={userData.social} />
                </Modal.Body>
            </Modal>
        </Col>
    )
}

const BucketSelection = () => {
    const { getBuckets, userData, type } = useAuth()
    const { getThemeBGPlatform, getTextInv, getButtonStyle} = useTheme()

    const [showBucketSelection, setShowBucketSelection] = useState(false)
    const [bucketNames, setBucketNames] = useState([])

    const fetchBucketData = useCallback(async () => {
        const bucketData = []
        await getBuckets().then(res => res.forEach(docSnap => {
            bucketData.push(docSnap.id)
        }));
        setBucketNames(bucketData)
    }, [getBuckets])

    useEffect(() => {
        fetchBucketData()
    }, [fetchBucketData])

    return (
        <Col>
            <h6 className='fw-bold pt-1 mb-1'>
                {userData.buckets && userData.buckets.length ? <CheckCircleFill className='my-text-success me-1 pb-1' /> : <></>}
                {type === 1 ? 'Add Content Categories' : 'Set Ad Categories'}
            </h6>
            <ActionSubText text='Help us find you Ads' />
            <Button className={'m-1 ' + getButtonStyle('sm')} onClick={() => setShowBucketSelection(true)}>
                Show Categories
            </Button>
            {bucketNames.length ? 
                <Modal size='xl' show={showBucketSelection} onHide={() => setShowBucketSelection(false)} className={'back-blur ' + getTextInv()}>
                    <Modal.Header className={'fw-bold fs-5 ' + getThemeBGPlatform('secondary')} closeButton>Category Selection</Modal.Header>
                    <Modal.Body className={'rounded-bottom ' + getThemeBGPlatform('secondary')}>
                        <ConsumerProfileTool bucketNames={bucketNames} />
                    </Modal.Body>
                </Modal>
            :
                <></>
            }
        </Col>
    )
}

const AdTypePreferences = () => {
    const { userData } = useAuth()
    const { getTextInv, getBG, getButtonStyle } = useTheme()

    const [showAdTypes, setShowAdTypes] = useState(false)

    const [modalPage, setModalPage] = useState('Advertisement Types')

    return (
        <Col>
            <h6 className='fw-bold pt-1 mb-1'>
                {userData.adTypes && userData.adTypes.length > 1 ? <CheckCircleFill className='my-text-success me-1 pb-1' /> : <></>}
                Advertisement Types
            </h6>
            <ActionSubText text='Understand the Ad Process' />
            <Button className={'m-1 ' + getButtonStyle('sm')} onClick={() => setShowAdTypes(true)}>
                View Types and Bundles
            </Button>
            <Modal size='xl' show={showAdTypes} onHide={() => setShowAdTypes(false)} className={getTextInv()}>
                <Modal.Header className={getBG()} closeButton>
                    <Button className={'m-0 p-0 pe-1 bg-transparent border-0 ' + getTextInv()} disabled={modalPage === 'Advertisement Types'} onClick={() => setModalPage('Advertisement Types')}>
                        <ArrowLeftCircle style={{marginTop: '-3px'}} />
                    </Button>
                    {modalPage === 'Advertisement Types' ? modalPage : modalPage + 's In Depth'}
                </Modal.Header>
                <Modal.Body className={'rounded-bottom ' + getBG()}>
                    <AdGuideTool hidePreferences page={modalPage} setPage={setModalPage} />
                </Modal.Body>
            </Modal>
        </Col>
    )
}

// const RevenueTips = () => {
//     const { getTextInv, getButtonStyle, getThemeBGPlatform } = useTheme()

//     const [completed, setCompleted] = useState(false)
//     const [showTips, setShowTips] = useState(false)

//     return (
//         <Col>
//             <h6 className='fw-bold pt-1 mb-1'>
//                 {completed ? <CheckCircleFill className='my-text-success me-1 pb-1' /> : <></>}
//                 Review Revenue Tips
//             </h6>
//             <ActionSubText text='Find ways to make more' />
//             <Button className={'m-1 ' + getButtonStyle('sm')} onClick={() => setShowTips(true) || setCompleted(true)}>
//                 Show Revenue Tips
//             </Button>
//             <Modal size='xl' show={showTips} onHide={() => setShowTips(false)} className={'back-blur ' + getTextInv()}>
//                 <Modal.Header className={'fs-5 fw-bold ' + getThemeBGPlatform('secondary')} closeButton>Maximize Your Fiable Revenues</Modal.Header>
//                 <Modal.Body className={'rounded-bottom ' + getThemeBGPlatform('secondary')}>
//                     <RevenueTool />
//                 </Modal.Body>
//             </Modal>
//         </Col>
//     )
// }

const BookAnAd = () => {
    const { getButtonStyle } = useTheme()
    const navigate = useNavigate()

    return (
        <Col>
            <h6 className='fw-bold pt-1 mb-1'>
                Book an Ad!
            </h6>
            <ActionSubText text='Create and Deploy an Ad!' />
            <Button className={'m-1 ' + getButtonStyle('sm')} onClick={() => navigate('/create-ad')}>
                Go to Create an Ad Page
            </Button>
        </Col>
    )
}

export default AccountActions