import React, { useEffect, useState } from 'react'
import { Button, Card, Col, Container, Row } from 'react-bootstrap';
import EarningsLineGraph from './subcomponents/creator/earningsLineGraph';
import AdDistPieChart from './subcomponents/creator/adDistPieChart';
import PotentialAdsCard from '../profile/subcomponents/creator/potentialAdsCard';
import { useAuth } from '../../../contexts/authContext';
import AdCategoriesPieChart from './subcomponents/advertiser/adCategoriesPieChart';
import AdSpendingLineGraph from './subcomponents/advertiser/adSpendingLineGraph';
import { useTheme } from '../../../contexts/themeContext';
import AtAGlance from '../profile/subcomponents/atAGlance';
import './dropdown-no-arrow.css'
import AccountActions from '../profile/subcomponents/accountActions';
import AdvertisementsCard from '../advertisements/subcomponents/advertisementsCard';
import ChannelsCard from '../channel/subcomponents/channelsCard';
import { useNavigate, useParams } from 'react-router-dom';
import AdvertisementDetailCard from '../advertisements/subcomponents/advertisementDetailCard';
import AdVisualizationsCard from '../advertisements/subcomponents/adVisualizationsCard';

const Dashboard = () => {
    const { type, userData } = useAuth()
    const { isSmallScreen } = useTheme()
    
    const params = useParams()
    const [selectedAd, setSelectedAd] = useState(params.adId)

    useEffect(() => {
        if (type === 0 && !params.adId && Object.keys(userData.history).length)
            setSelectedAd(Object.keys(userData.history).filter(adId => userData.history[adId].isActive).sort((a, b) => userData.history[b].startDate - userData.history[a].startDate)[0])
    }, [userData.history, params.adId, type, setSelectedAd])

    return (
        <Container fluid className='p-2'>
            {type === 1 ? <PotentialAdsCard /> : <></>}
            <Row className='pb-3'>
                <Col>{userData.firstTimeUser ? (isSmallScreen ? <></> : <AccountActions fromDashboard />) : <AtAGlance isDemo={false}/>}</Col>
            </Row>
            <Row className='pb-3 gx-2' style={{flexDirection: (type === 1 ? 'row' : 'row-reverse')}}>
                <Col>
                    {type === 1 ? <ChannelsCard hideSelect /> : (isSmallScreen ? <></> : (selectedAd ? <AdvertisementDetailCard selected={selectedAd} setSelected={setSelectedAd} /> : <BookAdCard />))}
                </Col>
                <Col lg={isSmallScreen ? 12 : (!type && selectedAd ? 4 : '')}>
                    {type === 1 ? <AdvertisementsCard hideSelect /> : <AdvertisementsCard selected={selectedAd} setSelected={setSelectedAd} hideSelect={isSmallScreen} />}
                </Col>
            </Row>
            {!isSmallScreen && type === 0 ?
                <Row className='pb-3'>
                    <Col><AdVisualizationsCard selected={selectedAd} /></Col>
                </Row> 
            : <></>}
            {!isSmallScreen ? 
                <Row className='gx-2'>
                    <Col>
                        {type === 1 ? <EarningsLineGraph /> : <AdSpendingLineGraph />}
                    </Col>
                    <Col>
                        {type === 1 ? <AdDistPieChart /> : <AdCategoriesPieChart />}
                    </Col>
                </Row>
            : <></>}
        </Container>
    )
}

const BookAdCard = () => {
    const {getCardStyle, getButtonStyle} = useTheme()
    const navigate = useNavigate()
    
    return (
        <Card className={'h-100 ' + getCardStyle()}>
            <Card.Body className='d-flex justify-content-center align-items-center'>
                <Button className={getButtonStyle('lg')} onClick={() => navigate('/create-ad')} style={{transform: 'scale(175%)'}}>
                    Book an Ad!
                </Button>
            </Card.Body>
        </Card>
    )
}

export default Dashboard;